import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {APP_SECTION_TYPES, EXTERNAL_LINK_TYPES} from '../consts/externalLinkTypes';
import * as sourceTypes from '../consts/sourceTypes';
import appRouterService from '../services/route/appRouterService';
import {selectIsConsumerDataFetched} from '../state/selectors/consumer';
import {selectIsTncApproved} from '../state/selectors/consumerAggregatedSettings';
import {selectSwMessage} from '../state/selectors/global';
import {clearSwMessage} from '../state/slices/globalSlice';
import {showFirmwareAvailableModalAction, showLeviaLearnMoreModalAction} from '../state/slices/modalSlice';

const useSWMessageHandler = () => {
    const swMessage = useSelector(selectSwMessage);
    const isConsumerDataFetched = useSelector(selectIsConsumerDataFetched);
    const isTncApproved = useSelector(selectIsTncApproved);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!swMessage) return;
        if (!isConsumerDataFetched) return;
        if (!isTncApproved) return;

        const {messageId, actionTypeCode, appSection} = swMessage;

        if (actionTypeCode !== EXTERNAL_LINK_TYPES.SECTION_LINK) return;

        // NOTE: update selectIsRedirectionRequiredAfterNotificationClick if new appSection will be added
        switch (appSection) {
            case APP_SECTION_TYPES.LEVIA:
                dispatch(showLeviaLearnMoreModalAction({source: sourceTypes.HOMEPAGE}));
                break;
            case APP_SECTION_TYPES.FIRMWARE_AVAILABLE:
                dispatch(showFirmwareAvailableModalAction({source: sourceTypes.HOMEPAGE}));
                break;
            case APP_SECTION_TYPES.DETAILS:
                if (messageId) {
                    appRouterService.forwardToNotificationDetailsPage(messageId, sourceTypes.HOMEPAGE);
                } else {
                    appRouterService.forwardToHomePage();
                }
                break;
            default:
                break;
        }

        dispatch(clearSwMessage());
    }, [swMessage, isConsumerDataFetched, isTncApproved]);
};

export default useSWMessageHandler;
