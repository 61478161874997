import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import SignedImage from '../../System/SignedImage';

const ContactUsHeader = ({imagePath}) => {
    return (
        <div className='flex flex-col gap-8 mt-safe-offset-14'>
            <div className='flex flex-col gap-4 px-4'>
                <h1 className='mb-0'>
                    <AemEditableText pageName='contact-us' itemName='contactUsTitle' />
                </h1>
                <p>
                    <AemEditableText pageName='contact-us' itemName='contactUsDesc' />
                </p>
            </div>
            <SignedImage
                imgClassName='w-full md:h-[244px] lg:h-[335px] object-cover bg-center'
                isThumbnailAvailable
                mediaData={imagePath}
                signIfNull
            />
        </div>
    );
};

export default ContactUsHeader;
