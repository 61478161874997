import appConfig from '../../config/appConfig';
import * as logMessageTypes from '../../consts/log/logMessageTypes';
import {selectConsumerData} from '../../state/selectors/consumer';
import {getState} from '../../state/store';
import appInstanceService from '../app/appInstanceService';
import cloudWatchProvider from '../aws/cloudWatchProvider';

const logLevels = {
    DEBUG: {
        id: 1,
        name: 'DEBUG',
    },
    INFO: {
        id: 2,
        name: 'INFO',
    },
    ERROR: {
        id: 3,
        name: 'ERROR',
    },
    SILENT: {
        id: 4,
        name: 'SILENT',
    },
    FATAL: {
        id: 5,
        name: 'FATAL',
    },
};

let instance = null;

const LOG_COUNT_TO_PUSH = 10;
const TIME_TO_PUSH_MS = 5 * 1000;
const PUT_EVENTS_ERROR_ATTEMPTS = 5;

class SmartLogger {
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;
    }

    logsExposeTimeout = null;
    queue = [];
    isPushInProgress = false;
    currentPutEventsErrorAttempts = 0;
    isLogExposePossible = true;

    level = logLevels.SILENT.id;

    setLevel = (level) => (this.level = level);
    getLevel = () => logLevels[this.level]?.id;
    debug = (msg, options) => this.log(logLevels.DEBUG, msg, options);
    error = (msg, options) => this.log(logLevels.ERROR, msg ? msg : 'Unknown message', options);
    info = (msg, options) => this.log(logLevels.INFO, msg, options);
    fatal = (msg, options) => this.log(logLevels.FATAL, msg, options);
    resetLogger = () => {
        this.currentPutEventsErrorAttempts = 0;
        this.isLogExposePossible = true;
    };

    log = (level, msg, options = {}) => {
        const isLoggingAvailable = level.id >= this.getLevel();

        if (isLoggingAvailable) {
            const logMessage = this.getFullLogMessage(level.name, msg, options);

            this.addToQueue(logMessage);

            if (this.isLogExposePossible) {
                if (this.queue.length >= LOG_COUNT_TO_PUSH - 1 || level >= logLevels.ERROR) {
                    this.exposeEvents();
                } else {
                    this.setTimeOutIfNeeded();
                }
            }
        }
    };

    addToQueue = (message) =>
        this.queue.push({
            message,
            timestamp: Date.now(),
        });

    getDynamicTimeout = () => {
        if (this.currentPutEventsErrorAttempts++ >= PUT_EVENTS_ERROR_ATTEMPTS) {
            this.isLogExposePossible = false;
            return false;
        }
        return this.currentPutEventsErrorAttempts;
    };

    exposeEvents = async () => {
        if (this.isPushInProgress) {
            this.setTimeOutIfNeeded();
        } else {
            if (this.queue.length) {
                this.isPushInProgress = true;
                const logsToPush = this.queue.splice(0, this.queue.length);

                await cloudWatchProvider.putEvents({
                    eventMessages: logsToPush,
                    onError: () => {
                        this.queue.unshift(...logsToPush); //return logs to queue
                        this.isPushInProgress = false;

                        this.setTimeOutIfNeeded(this.getDynamicTimeout());
                    },
                    onComplete: () => {
                        this.currentPutEventsErrorAttempts = 0;
                        this.isPushInProgress = false;
                    },
                });
            }
        }
    };

    collectData = (additionalData) => {
        const consumerData = selectConsumerData(getState());
        const consumerId = consumerData?.consumerId;
        const appInstanceId = appInstanceService.getAppInstanceId();
        const now = new Date();
        const marketName = appConfig.getIccMarketName();

        return {
            app_id: appInstanceId,
            consumer_id: this.getLogValue(consumerId),
            date: now.toISOString(),
            environment: appConfig.getEnvironment(),
            iqos_connect_version: appConfig.getAppVersion(),
            market: marketName,
            timestamp: now.getTime(),
            ...additionalData,
        };
    };

    getLogValue = (prop) => (prop !== undefined ? prop : 'n/a');

    getFullLogMessage = (level, message, options) => {
        const {logMessageType = logMessageTypes.INTERNAL, ...rest} = options;
        const logData = this.collectData(rest);
        const fullLog = {
            ...logData,
            level,
            message_scope: logMessageType,
            message,
        };
        const logMessage = JSON.stringify(fullLog);

        return logMessage;
    };

    setTimeOutIfNeeded = (timeoutMultiplier) => {
        const timeout = timeoutMultiplier ? timeoutMultiplier * TIME_TO_PUSH_MS : TIME_TO_PUSH_MS;

        if (this.queue.length && !this.logsExposeTimeout && this.isLogExposePossible) {
            this.logsExposeTimeout = setTimeout(() => {
                this.logsExposeTimeout = null;
                this.exposeEvents();
            }, timeout);
        }
    };
}

export default SmartLogger;
