import React, {useState} from 'react';

import AemEditableText from '../../../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../../../components/Button';
import {DeviceUnregisterIcon} from '../../../../../../components/Icons';
import PopupCentered from '../../../../../../components/Popup/PopupCentered';
import * as localizationKeys from '../../../../../../consts/localization/localizationKeys';
import amplitudeDeviceTracking from '../../../../../../services/amplitude/amplitudeDeviceTracking';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import productHelpers from '../../../../../../services/product/productHelpers';
import productService from '../../../../../../services/product/productService';
import helpers from '../../../../../../utils/helpers';

const UnregisterButton = ({device, hideDeviceListPopup}) => {
    const localizedStrings = getLocalizedStrings();
    const name = productHelpers.getProductName(device);
    const [isVisiblePopup, setIsVisiblePopup] = useState(false);

    const onUnregisterClick = () => {
        setIsVisiblePopup(true);
    };

    const closePopup = () => {
        setIsVisiblePopup(false);
    };

    const startUnreg = async () => {
        helpers.runFunction(hideDeviceListPopup);
        const {deviceSerialNumber, type, holder} = device;
        const result = await productService.unregisterConsumerProduct(device);
        const {chargerResult, holderResult} = result || {};

        if (chargerResult != null) {
            amplitudeDeviceTracking.trackUnregisterDeviceClick(deviceSerialNumber, type, chargerResult);
        }
        if (holderResult != null && holder) {
            amplitudeDeviceTracking.trackUnregisterHolderClick(holder?.deviceSerialNumber, holder?.type, holderResult);
        }
    };

    return (
        <>
            {isVisiblePopup && (
                <PopupCentered className={'right-0 z-[102] rounded-2xl'}>
                    <div className='flex flex-col text-justify text-18'>
                        <b>
                            <AemEditableText pageName='devices' itemName='unregisterYourDevicePopupTitle' />
                        </b>
                        <b className='break-words text-left'>{name}</b>
                        <span className='mt-2 text-14'>
                            <AemEditableText pageName='devices' itemName='unregisterYourDevicePopupDescription' />
                        </span>
                    </div>
                    <div className='mt-4 flex justify-end text-14'>
                        <AppButton className='mt-0 w-auto p-0 pr-10 text-end' onClick={closePopup}>
                            <AemEditableText pageName='devices' itemName='unregisterYourDevicePopupCancel' />
                        </AppButton>
                        <AppButton className='mt-0 w-auto p-0 text-end font-bold' onClick={startUnreg}>
                            <AemEditableText pageName='devices' itemName='unregisterYourDevicePopupUnregister' />
                        </AppButton>
                    </div>
                </PopupCentered>
            )}
            <div
                onClick={onUnregisterClick}
                className='flex h-full w-[88px] flex-col items-center justify-center text-center text-12 tracking-02'
            >
                <DeviceUnregisterIcon className='h-6 w-6' />
                <span className='max-w-full truncate px-1'>
                    {localizedStrings[localizationKeys.DEVICE_UNREGISTER_BUTTON_TEXT]}
                </span>
            </div>
        </>
    );
};

export default UnregisterButton;
