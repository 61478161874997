import {EditableComponent} from '@adobe/aem-react-editable-components';
import React from 'react';

import {FormInputPassword} from './FormInputPassword';

const EditableFormInputPassword = (props) => (
    <EditableComponent {...props}>
        <FormInputPassword {...props} />
    </EditableComponent>
);

export default EditableFormInputPassword;
