import React from 'react';

import AppImage from '../../../../components/Image/AppImage';

const FeatureItem = (props) => {
    const {subTitle, description, icon} = props;

    return (
        <div className='flex min-h-18 gap-4'>
            {icon && <AppImage className='h-auto w-12 shrink-0 self-start' src={icon} />}
            <div className='flex flex-col'>
                <span className='font-bold'>{subTitle}</span>
                <div>{description}</div>
            </div>
        </div>
    );
};

const NotificationDetailsNewFeatures = ({newFeatures, newFeaturesTitle}) => {
    return (
        <div className='my-8 gap-4'>
            {newFeaturesTitle && <h3>{newFeaturesTitle}</h3>}
            <div className='my-8 flex flex-col gap-4'>
                {newFeatures.map((feature, index) => {
                    return <FeatureItem {...feature} key={index} />;
                })}
            </div>
        </div>
    );
};

export default NotificationDetailsNewFeatures;
