import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import * as tncTypes from '../../../consts/tnc/tncTypes';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../state/selectors/consumer';
import PreferenceCenter from './PreferenceCenter';

const PreferenceCenterControl = (props) => {
    const {onApproved, backButtonClick, isEnableAllAvailable} = props;
    const dataCollectionTnc = useSelector(makeSelectTncByType(tncTypes.WEB_PREFERENCE_CENTER));
    const acceptedTermsOfUse = useSelector(makeSelectAcceptedTncByType(tncTypes.WEB_PREFERENCE_CENTER));
    const {titleDescription: newTitleDescription, legals = []} = dataCollectionTnc || {};
    const {titleDescription: acceptedTitleDescription} = acceptedTermsOfUse || {};
    const titleDescription = dataCollectionTnc ? newTitleDescription : acceptedTitleDescription;
    const acceptedLegals = acceptedTermsOfUse?.legals;
    const acceptedLegalsFiltered = useMemo(() => {
        const result = acceptedLegals
            ? acceptedLegals.filter((al) => !legals.some((l) => l.code.toLowerCase() === al.code.toLowerCase()))
            : [];

        return result;
    }, [acceptedLegals]);

    return (
        <PreferenceCenter
            legals={legals}
            acceptedLegals={acceptedLegalsFiltered}
            onApproved={onApproved}
            isEnableAllAvailable={isEnableAllAvailable}
            description={titleDescription}
            onBackButtonClick={backButtonClick}
        />
    );
};

export default PreferenceCenterControl;
