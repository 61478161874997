import helpers from '../../utils/helpers';
import iccMarketService from './iccMarketService';

const deliverAsap = async ({asapDeliveryDate, onSuccess, onError}) => {
    try {
        if (asapDeliveryDate) {
            const response = await iccMarketService.deliverAsap(asapDeliveryDate);

            if (isSuccess(response?.data)) {
                helpers.runFunction(onSuccess, response.data);
                return;
            } else {
                const errorMessage = getErrorMessage(response);

                helpers.runFunction(onError, errorMessage);
            }
        }
    } catch (e) {
        const errorMessage = getErrorMessage(e);

        helpers.runFunction(onError, errorMessage);
        return;
    }
    const errorMessage = 'ASAP Delivery Date has not provided';

    helpers.runFunction(onError, errorMessage);
};

const skipAutoDelivery = async ({onSuccess, onError}) => {
    try {
        const response = await iccMarketService.skipAutoDelivery();

        if (isSuccess(response?.data)) {
            helpers.runFunction(onSuccess, response.data);
            return;
        } else {
            const errorMessage = getErrorMessage(response);

            helpers.runFunction(onError, errorMessage);
        }
    } catch (e) {
        const errorMessage = getErrorMessage(e);

        helpers.runFunction(onError, errorMessage);
    }
};

const isSuccess = (data) => {
    if (!data) return false;

    const {serviceError} = data;

    if (serviceError) return false;

    return true;
};

const getErrorMessage = (e) => {
    const serverErrorMessage = e?.data
        ? e?.data?.serviceError?.message?.value
        : e?.response?.data?.serviceError?.message?.value;

    const defaultErrorMessage = 'Error has not provided by the backend';

    return serverErrorMessage ? serverErrorMessage : e?.message ? e.message : defaultErrorMessage;
};

export default {deliverAsap, skipAutoDelivery};
