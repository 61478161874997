import React from 'react';

import setUpIqos from '../../../../../assets/images/devices/setUpYourIqos/set-up-iqos.png';
import {ContentWrapper, MaxWidthContainer} from '../../../../components/Layout';
import appConfig from '../../../../config/appConfig';
import {NO_DEVICES_PAGE_HEADER_TITLE} from '../../../../consts/localization/localizationKeys';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '../../../../services/homePageNavigationService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import HomePageNavigationBar from '../../../HomePageNavigationBar/HomePageNavigationBar';
import HomePageTopNavigation from '../../../HomePageTopNavigation/HomePageTopNavigation';
import SignedImage from '../../../System/SignedImage';
import PageWarningBannersContainer from '../../../WarningBanners/PageWarningBannersContainer';
import DevicesPageButtons from './DevicesPageButtons';
import DevicesPageHeader from './DevicesPageHeader';

const NoDevicesView = () => {
    const localizedStrings = getLocalizedStrings();
    const imagePath = appConfig.getSetUpYourIqosImagePath();
    const imagePathCombined = appConfig.getCombinedPathAssetPath(imagePath);

    return (
        <div className='flex w-full flex-col'>
            <ContentWrapper className='mx-4 min-h-[calc(100vh-79px)] flex-1 items-start pb-8'>
                <MaxWidthContainer className='mx-auto w-full flex-1'>
                    <HomePageTopNavigation
                        title={localizedStrings[NO_DEVICES_PAGE_HEADER_TITLE]}
                        source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.DEVICES}
                    />
                    <DevicesPageHeader />
                    <div className='relative w-full flex-1'>
                        <SignedImage
                            imgClassName='m-auto absolute left-0 right-0 h-full max-w-[327px] object-contain'
                            mediaData={imagePathCombined}
                            defaultImage={setUpIqos}
                            signIfNull
                        />
                    </div>
                    <DevicesPageButtons />
                </MaxWidthContainer>
            </ContentWrapper>
            <PageWarningBannersContainer />
            <HomePageNavigationBar />
        </div>
    );
};

export default NoDevicesView;
