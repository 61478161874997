import React from 'react';

import Icon from '../Icon/Icon';

const InfoIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <circle cx='12' cy='12' r='8.25' stroke='#34303D' fill='none' strokeWidth='1.5' />
            <circle cx='12' cy='8.53125' r='0.65625' fill='#34303D' stroke='#34303D' strokeWidth='0.5' />
            <rect
                x='11.4375'
                y='10.2783'
                width='1.125'
                height='6'
                rx='0.5625'
                fill='#34303D'
                stroke='#34303D'
                strokeWidth='0.5'
            />
        </Icon>
    );
};

export default InfoIcon;
