import React from 'react';
import {useSelector} from 'react-redux';

import {LoyaltyIcon} from '../../../../components/Icons';
import InlineProgress from '../../../../components/InlineProgress/InlineProgress';
import appConfig from '../../../../config/appConfig';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import {selectLoyaltyLevel, selectLoyaltyPoints} from '../../../../state/selectors/consumer';

const HomePageLoyaltyBanner = ({onClick, loyaltyTopBanner}) => {
    const {titleKey, descriptionKey, subTitleKey} = loyaltyTopBanner || {};
    const isLoyaltyProgressBarEnabled = appConfig.getIsLoyaltyProgressBarEnabled();
    const loyaltyPoints = useSelector(selectLoyaltyPoints);
    const loyaltyLevel = useSelector(selectLoyaltyLevel);
    const {nextLevel, color, maxPoints, minPoints, titleKey: loyaltyKey} = loyaltyLevel || {};
    const {minPoints: nextMinPoints, titleKey: nextTitleKey} = nextLevel || {};
    const pointsToNextLevel = nextMinPoints - loyaltyPoints;
    const isShowNextLevelText = pointsToNextLevel > 0 && nextLevel;

    const localizedStrings = getLocalizedStrings();
    const title = localizedStrings.formatString(localizedStrings[titleKey], localizedStrings[loyaltyKey]);
    const subTitle = localizedStrings[subTitleKey];
    const description = localizedStrings.formatString(
        localizedStrings[descriptionKey],
        pointsToNextLevel,
        localizedStrings[nextTitleKey]
    );

    const getPercentage = () => {
        if ((!minPoints && minPoints !== 0) || loyaltyPoints < minPoints) return 0;

        if (!maxPoints || loyaltyPoints > maxPoints) return 100;

        return ((loyaltyPoints - minPoints) * 100) / (maxPoints - minPoints);
    };

    return (
        <div onClick={onClick}>
            <div className='flex flex-row gap-4'>
                <LoyaltyIcon className='flex-shrink-0' style={{color}} />
                <div>
                    <h1 className='m-0 p-0 text-18 text-mischka'>{title}</h1>
                    <span className='text-32'>{loyaltyPoints.toLocaleString()}</span>
                    <span> </span>
                    <span className='text-18'>{subTitle}</span>
                </div>
            </div>
            {isLoyaltyProgressBarEnabled && (
                <div className='mt-4'>
                    {loyaltyLevel && (
                        <InlineProgress
                            className='bg-romance'
                            progress={getPercentage()}
                            progressStyle={{backgroundColor: color}}
                        />
                    )}
                    {isShowNextLevelText && (
                        <div
                            className='mt-2 whitespace-pre-line text-16 font-normal'
                            dangerouslySetInnerHTML={{__html: description}}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default HomePageLoyaltyBanner;
