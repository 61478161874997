import {createSlice} from '@reduxjs/toolkit';

import productHelpers from '../../services/product/productHelpers';

const initialState = {
    aggregatedSettings: undefined,
    acceptedTnc: undefined,
    tnc: undefined,
    consumerFetchDate: undefined,
    data: undefined,
    products: undefined,
    productsFetchDate: undefined,
    productsAsset: [],
    productsInfo: undefined,
    productsToRegUnreg: [],
    externalLinks: [],
};

export const slice = createSlice({
    name: 'consumer',
    initialState,
    reducers: {
        setConsumerAggregatedSettings: (state, action) => {
            state.aggregatedSettings = action.payload;

            return state;
        },
        setConsumerAcceptedTnc: (state, action) => {
            state.acceptedTnc = action.payload;

            return state;
        },
        setConsumerTnc: (state, action) => {
            state.tnc = action.payload;

            return state;
        },
        setConsumerData: (state, action) => {
            state.data = {...state.data, ...action.payload};
            state.consumerFetchDate = new Date().getTime();

            return state;
        },
        setConsumerProducts: (state, action) => {
            state.products = action.payload;
            state.productsFetchDate = new Date().getTime();

            return state;
        },
        setConsumerProductsInfo: (state, action) => {
            state.productsInfo = action.payload;

            return state;
        },
        updateConsumerProductsInfo: (state, action) => {
            const {productsInfo = []} = state || {};

            if (!action.payload?.length) return {...state, productsInfo};

            const deviceSerialNumbers = action.payload.map((x) => x.deviceSerialNumber);
            let mergedProductsInfo = productsInfo.filter(
                (productInfo) => !deviceSerialNumbers.includes(productInfo.deviceSerialNumber)
            );

            mergedProductsInfo = mergedProductsInfo.concat(...action.payload);

            return {
                ...state,
                productsInfo: mergedProductsInfo,
            };
        },
        updateConsumerProductData: (state, action) => {
            const {deviceSerialNumber, codentify, ...data} = action.payload;

            state.products = productHelpers.getNewProductsListWithUpdatedItem({
                products: state.products,
                deviceSerialNumber,
                codentify,
                data,
            });

            return state;
        },
        removeConsumerProduct: (state, action) => {
            const codentify = action.payload;

            state.products = state.products?.filter((x) => x.codentify !== codentify) || [];

            return state;
        },
        addConsumerProductToRegUnreg: (state, action) => {
            const {codentify, isReg} = action.payload;
            const productsToRegUnreg = state.productsToRegUnreg?.filter((x) => x.codentify !== codentify) || [];

            return {
                ...state,
                productsToRegUnreg: [...productsToRegUnreg, {codentify, isReg}],
            };
        },
        removeConsumerProductFromRegUnreg: (state, action) => {
            const codentify = action.payload;

            state.productsToRegUnreg = state.productsToRegUnreg?.filter((x) => x.codentify !== codentify) || [];

            return state;
        },
        removeAllConsumerProductsFromRegUnreg: (state, action) => {
            state.productsToRegUnreg = [];

            return state;
        },
        updateExternalLink: (state, action) => {
            if (!action.payload) return state;

            const {externalLinks = []} = state || {};
            const mergedExternalLinks = externalLinks.filter(
                (x) =>
                    x.key?.toLowerCase() !== action.key?.toLowerCase() &&
                    x.locale?.toLowerCase() !== action.locale?.toLowerCase()
            );

            return {
                ...state,
                externalLinks: [...mergedExternalLinks, action.payload],
            };
        },
    },
});

export const {
    setConsumerAggregatedSettings,
    setConsumerAcceptedTnc,
    setConsumerTnc,
    setConsumerData,
    setConsumerProducts,
    setConsumerProductsInfo,
    updateConsumerProductsInfo,
    updateConsumerProductData,
    removeConsumerProduct,
    addConsumerProductToRegUnreg,
    removeConsumerProductFromRegUnreg,
    removeAllConsumerProductsFromRegUnreg,
    updateExternalLink,
} = slice.actions;

export default slice.reducer;
