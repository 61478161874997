import React, {useEffect} from 'react';

import AppLoaderRaw from '../../../components/Loader/AppLoader/AppLoaderRaw';
import queryParameters from '../../../consts/route/queryParameters';
import amplitudeInboxTracking from '../../../services/amplitude/amplitudeInboxTracking';
import appRouterService from '../../../services/route/appRouterService';
import urlUtils from '../../../utils/urlUtils';
import NotificationDetails from './NotificationDetails';
import useNotificationDetails from './useNotificationDetails';

const NotificationDetailsPage = ({messageId}) => {
    const onBackButtonClick = () => {
        appRouterService.forwardToInboxPage();
    };

    const {nextMessage, detailedMessage, isLoaderActive} = useNotificationDetails({
        messageId,
        onErrorHandler: onBackButtonClick,
    });

    useEffect(() => {
        if (detailedMessage) {
            const [source] = urlUtils.getDataFromUrl(queryParameters.SOURCE);

            amplitudeInboxTracking.trackNotificationDetailedScreen(source, detailedMessage.campaignName);
        }
    }, [detailedMessage]);

    return (
        <>
            {isLoaderActive ? (
                <AppLoaderRaw />
            ) : (
                <NotificationDetails
                    detailedMessage={detailedMessage}
                    nextMessage={nextMessage}
                    onBackButtonClick={onBackButtonClick}
                />
            )}
        </>
    );
};

export default NotificationDetailsPage;
