import React, {forwardRef} from 'react';

import {twx} from '../../utils/tailwindUtils';

const Textarea = forwardRef((props, ref) => {
    const {className, value, maxLength, minLength, onChange, placeholder, testId, ariaLabel, ariaLabelledby, id} =
        props;
    const textareaClassName = twx('block w-full border border-border text-16 outline-none p-4', className);

    return (
        <textarea
            ref={ref}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledby}
            className={textareaClassName}
            data-testid={testId}
            id={id}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
        />
    );
});

Textarea.displayName = 'Textarea';

export default Textarea;
