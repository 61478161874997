import React from 'react';

import Icon from '../Icon/Icon';

const BinIcon = (props) => {
    return (
        <Icon width='24' height='24' fill='none' {...props}>
            <path
                d='M5.81836 6.40869H18.182V18.5905C18.182 19.4189 17.5104 20.0905 16.682 20.0905H7.31836C6.48993 20.0905 5.81836 19.4189 5.81836 18.5905V6.40869Z'
                stroke='#34303D'
                strokeWidth='1.5'
            />
            <path d='M4 6.40887H20' stroke='#34303D' strokeWidth='1.5' strokeLinecap='round' />
            <path
                d='M9.45508 6.2271V6.2271C9.45508 4.82128 10.5947 3.68164 12.0005 3.68164V3.68164C13.4063 3.68164 14.546 4.82128 14.546 6.2271V6.2271'
                stroke='#34303D'
                strokeWidth='1.5'
            />
            <path d='M10 9.75V16.5' stroke='#34303D' strokeWidth='1.5' strokeLinecap='round' />
            <path d='M14.1133 9.75V16.5' stroke='#34303D' strokeWidth='1.5' strokeLinecap='round' />
        </Icon>
    );
};

export default BinIcon;
