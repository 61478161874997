import React from 'react';

import Icon from '../Icon/Icon';

const MyDevicesIcon = (props) => {
    return (
        <Icon width='48' height='48' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9 42C8.37688 42 7.71022 41.1072 7 39.3215V19.3523C7 18.5189 7.39777 17.7348 8.1933 17H12.8067C13.6022 17.7348 14 18.5189 14 19.3523V39.3215C13.2898 41.1072 12.6231 42 12 42H9Z'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinejoin='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M20.3 42L20.3003 41.8478C19.6675 41.4676 19.0007 40.3755 18.3 38.5715V13.0109C18.3 11.9441 18.7972 10.9405 19.7916 10H23.8084C24.8028 10.9405 25.3 11.9441 25.3 13.0109V38.5715C24.5997 40.3746 23.9332 41.4665 23.3006 41.8472L23.3 42H20.3Z'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinejoin='round'
            />
            <path
                d='M21.8565 26.0976C22.6609 26.0976 23.313 25.4455 23.313 24.6411C23.313 23.8367 22.6609 23.1846 21.8565 23.1846C21.0521 23.1846 20.4 23.8367 20.4 24.6411C20.4 25.4455 21.0521 26.0976 21.8565 26.0976Z'
                fill='#34303D'
                stroke='#00D1D2'
            />
            <path
                d='M10.4565 26.0976C11.2609 26.0976 11.913 25.4455 11.913 24.6411C11.913 23.8367 11.2609 23.1846 10.4565 23.1846C9.65211 23.1846 9 23.8367 9 24.6411C9 25.4455 9.65211 26.0976 10.4565 26.0976Z'
                fill='#34303D'
                stroke='#00D1D2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M35.25 8C36.8378 8 38.2753 8.64359 39.3159 9.68414C40.3564 10.7247 41 12.1622 41 13.75V40C41 40.5523 40.7761 41.0523 40.4142 41.4142C40.0523 41.7761 39.5523 42 39 42H31.5C30.9477 42 30.4477 41.7761 30.0858 41.4142C29.7239 41.0523 29.5 40.5523 29.5 40V10C29.5 9.44772 29.7239 8.94772 30.0858 8.58579C30.4477 8.22386 30.9477 8 31.5 8H35.25Z'
                stroke='#34303D'
                strokeWidth='2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M33.25 11H35.25C36.9069 11 38.25 12.3431 38.25 14V38C38.25 38.5523 37.8023 39 37.25 39H33.25C32.6977 39 32.25 38.5523 32.25 38V12C32.25 11.4477 32.6977 11 33.25 11Z'
                fill='#00D1D2'
            />
        </Icon>
    );
};

export default MyDevicesIcon;
