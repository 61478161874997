import {createSelector} from 'reselect';

import {selectConsumerProductsMergedInfo} from './consumer';

const selectDevice = (state) => state.device;

const selectDeviceData = createSelector(selectDevice, (globalState) => globalState.data);

const selectHolderData = createSelector(selectDevice, (globalState) => globalState.holderData);

const selectDeviceCodentify = createSelector(selectDevice, (globalState) => globalState.codentify);

const selectCurrentProductInfo = createSelector(
    selectDeviceCodentify,
    selectConsumerProductsMergedInfo,
    (codentify, products) => {
        if (!codentify) return;
        if (!products) return;

        const product = products.find((product) => product.codentify === codentify);

        if (!product) return;

        return {
            ...product,
        };
    }
);

const selectDeviceType = createSelector(selectCurrentProductInfo, (product) => product?.type);

export {selectCurrentProductInfo, selectDeviceCodentify, selectDeviceData, selectDeviceType, selectHolderData};
