import React from 'react';

import Icon from '../Icon/Icon';

const PreferenceCenterIcon = (props) => {
    return (
        <Icon width='32' height='32' fill='none' {...props}>
            <g clipPath='url(#clip0_3050_12168)'>
                <path
                    d='M15.9427 5.86133L25.1667 7.80533H25.1654L25.1667 13.6947C25.164 19.1613 22.1147 23.3107 16.0214 26.1387L15.9987 26.0707L15.9787 26.1387C10.0374 23.3813 6.99204 19.368 6.83871 14.1013L6.83337 13.6947V7.80533L15.9427 5.86133Z'
                    stroke='#34303D'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M12.6627 15.956L14.9667 18.0707L20.3921 12.5507'
                    stroke='#34303D'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_3050_12168'>
                    <rect width='32' height='32' fill='white' />
                </clipPath>
            </defs>
        </Icon>
    );
};

export default PreferenceCenterIcon;
