import React from 'react';

import {ButtonRaw} from '../../../../components/Button';
import appConfig from '../../../../config/appConfig';
import AMPLITUDE_EVENT_VALUES from '../../../../consts/amplitude/amplitudeEventValues';
import {
    AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK,
    AUTO_DELIVERY_VIEW_ORDER_SUMMARY_TEXT,
} from '../../../../consts/localization/localizationKeys';
import useConfigExternalLink from '../../../../hooks/useConfigExternalLink';
import amplitudeAutoDeliveryTracking from '../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';

const AutoDeliveryOrderSummaryMessage = () => {
    const autoDeliveryOrderSummaryExternalLink = appConfig.getAutoDeliveryOrderSummaryExternalLink();
    const redirectToExternalLink = useConfigExternalLink(autoDeliveryOrderSummaryExternalLink);

    if (!redirectToExternalLink) return;

    const onLinkClick = () => {
        amplitudeAutoDeliveryTracking.trackAutoDeliveryOrderSummaryLinkClick(AMPLITUDE_EVENT_VALUES.ACTIVE);
        redirectToExternalLink();
    };

    const localizedStrings = getLocalizedStrings();

    return (
        <div class='mt-4 rounded-xl bg-secondary p-4'>
            {localizedStrings.formatString(
                localizedStrings[AUTO_DELIVERY_VIEW_ORDER_SUMMARY_TEXT],
                <ButtonRaw className='inline font-bold underline' onClick={onLinkClick}>
                    {localizedStrings[AUTO_DELIVERY_VIEW_ORDER_SUMMARY_LINK]}
                </ButtonRaw>
            )}
        </div>
    );
};

export default AutoDeliveryOrderSummaryMessage;
