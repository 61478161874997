import {AppButton} from '../../../../components/Button';
import CloseButton from '../../../../components/CloseButton/CloseButton';
import {StickyPageLayout} from '../../../../components/Layout';
import PopupFullPage from '../../../../components/Popup/PopupFullPage';

const EditDeliveryResultPopup = ({icon, title, description, button, onClose, onButtonClick}) => {
    return (
        <PopupFullPage onClose={onClose} className='fixed left-0 right-0 z-[21] flex h-screen flex-col bg-romance'>
            <StickyPageLayout
                contentClassName='flex-1 p-8 flex flex-col justify-center items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                }}
                footer={
                    <AppButton primary light className='mt-0' onClick={onButtonClick}>
                        {button}
                    </AppButton>
                }
            >
                {icon}
                <h2 className='mb-0 mt-8 text-32'>{title}</h2>
                <p className='text-20 tracking-02'>{description}</p>
            </StickyPageLayout>
        </PopupFullPage>
    );
};

export default EditDeliveryResultPopup;
