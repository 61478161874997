import cn from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import StickyHeader from '../../../components/Layout/StickyHeader';
import {PopupFullPage} from '../../../components/Popup';
import AemWarningBannerSourceTypes from '../../../consts/aem/aemWarningBannerSourceTypes';
import {TNC_PAGE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import useModalRoute from '../../../hooks/useModalRoute';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import tncService from '../../../services/tncService';
import {selectTncLegals} from '../../../state/selectors/consumer';
import {selectTncPopupCodesList} from '../../../state/selectors/tnc';
import {removeLastTncPopupCode} from '../../../state/slices/tncSlice';
import SpecificSourceWarningBanners from '../../WarningBanners/SpecificSourceWarningBanners';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';
import styles from './TermsOfUsePopup.module.scss';

const mapStateToProps = createStructuredSelector({
    legals: selectTncLegals,
    tncPopupCodesList: selectTncPopupCodesList,
});

const TermsOfUsePopup = (props) => {
    const {dispatch, tncPopupCodesList, legals} = props;
    const localizedStrings = getLocalizedStrings();
    const tncPopupCode = tncPopupCodesList.slice(-1)[0];
    let legalData = tncService.getTncLegalByTermsCode(legals, tncPopupCode);

    if (!legalData) {
        dispatch(removeLastTncPopupCode());
        legalData = {};
    }
    const {text} = legalData;

    const closeModal = () => dispatch(removeLastTncPopupCode());

    const {onClose} = useModalRoute({modalType: tncPopupCode, closeModal});

    return (
        <PopupFullPage className='flex h-screen flex-col' key={tncPopupCode}>
            <StickyHeader onBack={onClose} title={localizedStrings[TNC_PAGE_HEADER_TITLE]} />
            <section className='w-full px-4 pb-4 pt-safe-offset-14'>
                <TermsOfUseDescription
                    innerHtml={text}
                    className={cn(styles.DescriptionContainer, 'break-words text-justify')}
                />
            </section>
            <SpecificSourceWarningBanners source={AemWarningBannerSourceTypes.TNC_DETAILS} />
        </PopupFullPage>
    );
};

export default connect(mapStateToProps)(TermsOfUsePopup);
