import React from 'react';

import Icon from '../Icon/Icon';

const InputInvisibleEyeIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <g transform='translate(3 4.945)' stroke='#B8B7BB' strokeWidth='1.5' fill='none' fillRule='evenodd'>
                <circle cx='9' cy='6.917' r='3.75' />
                <path d='M8.998 1.03c2.135 0 3.98.765 5.387 1.794C16.12 4.09 17.25 5.767 17.25 6.86c0 1.094-1.131 2.77-2.865 4.037-1.407 1.029-3.252 1.794-5.387 1.794-2.138 0-3.985-.765-5.392-1.794C1.877 9.634.75 7.961.75 6.861s1.127-2.773 2.856-4.037C5.013 1.794 6.86 1.03 8.998 1.03z' />
                <path strokeLinecap='round' d='M2.39.354 15.578 13.54' />
            </g>
        </Icon>
    );
};

export default InputInvisibleEyeIcon;
