import React, {useEffect} from 'react';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';
import SupportAndStoreTemplate from '../../../components/SupportAndStoreTemplate/SupportAndStoreTemplate';
import appConfig from '../../../config/appConfig';
import * as advanceDiagnosticIconTypes from '../../../consts/advanceDiagnostic/advanceDiagnosticIconTypes';
import * as advanceDiagnosticScreenTypes from '../../../consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import amplitudeADTracking from '../../../services/amplitude/amplitudeADTracking';
import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticIcon from '../components/AdvancedDiagnosticIcon';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import NotNowButtonAD from '../components/NotNowButtonAD';
import useADMarketScreen from './useADMarketScreen';

export const AdvancedDiagnosticQuestionnaireMarketScreen = () => {
    const {inferenceCode, redirectToSupport, isHolder, isQuestionnaireThirdStep} = useADMarketScreen();
    const isQureMarket = appConfig.getIsQureMarket();

    const itemNameTitle = isQureMarket
        ? isQuestionnaireThirdStep
            ? 'quremarketthirdsteptitle'
            : 'quremarkettitle'
        : 'notquremarkettitle';

    const itemNameDescription = isQureMarket
        ? isQuestionnaireThirdStep
            ? 'quremarketthirdstepdesc'
            : 'quremarketdesc'
        : 'notquremarketdesc';

    const itemNameAcceptButtonText = isQuestionnaireThirdStep ? 'acceptthirdstepbutton' : 'acceptbutton';

    useEffect(() => {
        amplitudeADTracking.trackADInitialQuestionnaireScreen();
    }, []);

    const NotNowButton = ({itemName, isTertiary}) => (
        <NotNowButtonAD
            isHolder={isHolder}
            inferenceCode={inferenceCode}
            screenName={advanceDiagnosticScreenTypes.QUESTIONNAIRE_MARKET_SCREEN}
            isQuestionnaireThirdStep={isQuestionnaireThirdStep}
            isTertiary={isTertiary}
        >
            <AemComponent component={EditableText} pageName='ad' itemName={itemName} />
        </NotNowButtonAD>
    );

    return (
        <AdvancedDiagnosticContainer isDeviceHero>
            <AdvancedDiagnosticIcon type={advanceDiagnosticIconTypes.ERROR} />
            <div className='px-8'>
                <AdvancedDiagnosticTitle itemName={itemNameTitle} />
                <AdvancedDiagnosticDescription itemName={itemNameDescription} />
            </div>
            <div className='mt-10 w-full md:w-auto'>
                {isQureMarket ? (
                    <div className='px-8'>
                        <AppButton primary light className='mb-4 mt-0 min-w-[312px]' onClick={redirectToSupport}>
                            <AemComponent component={EditableText} pageName='ad' itemName={itemNameAcceptButtonText} />
                        </AppButton>
                        <NotNowButton itemName='quremarketbutton' />
                    </div>
                ) : (
                    <>
                        <SupportAndStoreTemplate className='mb-4 mt-0 md:mb-6 md:flex-col lg:flex-row' />
                        <NotNowButton isTertiary itemName='notquremarketbutton' />
                    </>
                )}
            </div>
        </AdvancedDiagnosticContainer>
    );
};

export default AdvancedDiagnosticQuestionnaireMarketScreen;
