import appConfig from '../../../config/appConfig';

const useGetSpecificSourceWarningBanners = (source) => {
    const warningBanners = appConfig.getWarningBanners();

    const result = warningBanners?.filter((b) => b.pages?.some((page) => source === page));

    return result;
};

export default useGetSpecificSourceWarningBanners;
