import React from 'react';

import Icon from '../Icon/Icon';

const SocialChannelFacebookIcon = (props) => {
    return (
        <Icon width='63' height='44' fill='none' {...props}>
            <rect x='0.599976' width='62.6' height='44' rx='4' fill='#0866FF' />
            <g clipPath='url(#clip0_3955_10033)'>
                <path
                    d='M40.9 22C40.9 17.0295 36.8705 13 31.9 13C26.9294 13 22.9 17.0295 22.9 22C22.9 26.2206 25.8059 29.7623 29.7259 30.735V24.7504H27.8701V22H29.7259V20.8149C29.7259 17.7516 31.1123 16.3318 34.1197 16.3318C34.69 16.3318 35.6738 16.4438 36.0763 16.5554V19.0484C35.8639 19.026 35.4949 19.0149 35.0366 19.0149C33.561 19.0149 32.9908 19.574 32.9908 21.0273V22H35.9305L35.4254 24.7504H32.9908V30.9341C37.4472 30.3959 40.9003 26.6015 40.9003 22H40.9Z'
                    fill='white'
                />
                <path
                    d='M35.4251 24.7504L35.9302 22H32.9904V21.0273C32.9904 19.5739 33.5606 19.0149 35.0363 19.0149C35.4946 19.0149 35.8636 19.026 36.076 19.0483V16.5553C35.6735 16.4434 34.6896 16.3318 34.1194 16.3318C31.1119 16.3318 29.7256 17.7516 29.7256 20.8149V22H27.8698V24.7504H29.7256V30.735C30.4218 30.9078 31.1501 31 31.8996 31C32.2686 31 32.6326 30.9773 32.99 30.9341V24.7504H35.4247H35.4251Z'
                    fill='#0866FF'
                />
            </g>
            <defs>
                <clipPath id='clip0_3955_10033'>
                    <rect width='18' height='18' fill='white' transform='translate(22.9 13)' />
                </clipPath>
            </defs>
        </Icon>
    );
};

export default SocialChannelFacebookIcon;
