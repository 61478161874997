import React from 'react';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../../components/Button';
import appConfig from '../../../../config/appConfig';
import useConfigExternalLink from '../../../../hooks/useConfigExternalLink';

const FirmwareAvailableButtons = ({onCancel}) => {
    const checkCompatibilityExternalLink = appConfig.getFirmwareAvailableCheckCompatibilityExternalLink();
    const learnMoreExternalLink = appConfig.getFirmwareAvailableLearnMoreExternalLink();
    const redirectToExternalLink = useConfigExternalLink(checkCompatibilityExternalLink);
    const redirectToLearnMore = useConfigExternalLink(learnMoreExternalLink);

    return (
        <div className='m-auto flex max-w-[312px] flex-col items-center gap-4'>
            {checkCompatibilityExternalLink && (
                <AppButton className='mt-0' primary light onClick={redirectToExternalLink}>
                    <AemComponent
                        component={EditableText}
                        pageName='modals'
                        itemName='fwAvailableCompatibilityButton'
                    />
                </AppButton>
            )}
            {learnMoreExternalLink && (
                <AppButton className='mt-0' secondary light onClick={redirectToLearnMore}>
                    <AemComponent component={EditableText} pageName='modals' itemName='fwAvailableLearnMoreButton' />
                </AppButton>
            )}
            <AppButton tertiary className='mt-0 text-center' onClick={onCancel}>
                <AemComponent component={EditableText} pageName='modals' itemName='fwAvailableSkipButton' />
            </AppButton>
        </div>
    );
};

export default FirmwareAvailableButtons;
