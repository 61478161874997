import React, {Component} from 'react';

import log from '../../services/logger/log';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        if (error) {
            log.fatal(`ErrorBoundary: error: ${error}`);
        } else {
            log.error(`ErrorBoundary: empty error`);
        }
        log.info(JSON.stringify(errorInfo));
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
