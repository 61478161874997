import React from 'react';

import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {twx} from '../../../utils/tailwindUtils';

const WeCare = ({titleKey, descriptionKey, className}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <article className={twx('p-8', className)}>
            <div className='flex flex-col gap-2 rounded-lg bg-secondary p-6'>
                <p className='text-20 font-bold leading-[24px]'>{localizedStrings[titleKey]}</p>
                <p className='text-16 leading-[24px]'>{localizedStrings[descriptionKey]}</p>
            </div>
        </article>
    );
};

export default WeCare;
