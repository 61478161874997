import React from 'react';

import Icon from '../Icon/Icon';

const FirmwareUpdateIcon = (props) => {
    return (
        <Icon width='64' height='64' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M29.7749 47.8925H25.7812C22.3823 47.8925 19.5903 45.2163 19.5571 41.9292L19.5564 41.8581V24.4335H12.1464C11.6095 24.4335 11.1344 24.0845 10.9731 23.5738C10.8115 23.0599 11.0009 22.5039 11.4411 22.1936L29.9833 9.22302C30.4071 8.92566 30.9711 8.92566 31.3942 9.22302L49.9367 22.1936C50.3769 22.5039 50.5667 23.0599 50.405 23.5738C50.2438 24.0845 49.7686 24.4335 49.2314 24.4335H41.8214V31.4085C41.8214 32.0873 41.2707 32.6367 40.5909 32.6367C39.9115 32.6367 39.3605 32.0873 39.3605 31.4085V23.2021C39.3605 22.5233 39.9115 21.9739 40.5909 21.9739H45.3264L30.6889 11.7312L16.0515 21.9739H20.7869C21.4666 21.9739 22.0174 22.5233 22.0174 23.2021L22.0177 41.9065C22.0374 43.8491 23.7259 45.4296 25.7812 45.4296H29.7749C30.4546 45.4296 31.0057 45.9823 31.0057 46.661C31.0057 47.3398 30.4546 47.8925 29.7749 47.8925Z'
                fill='#272830'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M40.1872 32.6369C34.7003 32.6369 30.2367 37.1005 30.2367 42.5887C30.2367 48.0737 34.7003 52.5405 40.1872 52.5405C45.6741 52.5405 50.1377 48.0737 50.1377 42.5887C50.1377 37.1005 45.6741 32.6369 40.1872 32.6369ZM40.1872 55.0002C33.3434 55.0002 27.7754 49.4312 27.7754 42.5887C27.7754 35.743 33.3434 30.1772 40.1872 30.1772C47.0309 30.1772 52.599 35.743 52.599 42.5887C52.599 49.4312 47.0309 55.0002 40.1872 55.0002Z'
                fill='#282930'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M47.2184 42.5885C47.2184 46.4703 44.0706 49.6185 40.1881 49.6185C36.3057 49.6185 33.1582 46.4703 33.1582 42.5885C33.1582 38.7067 36.3057 35.5586 40.1881 35.5586C44.0706 35.5586 47.2184 38.7067 47.2184 42.5885Z'
                fill='#00D1D2'
            />
            <path
                d='M36.791 43.3407L38.6299 45.2653L43.294 40.4736'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default FirmwareUpdateIcon;
