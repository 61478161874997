import React from 'react';

import Icon from '../Icon/Icon';

const RenameBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='m34.32 9.43 2.12-2.122a1 1 0 0 1 1.415 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121a1 1 0 0 1-1.414 0l-2.828-2.828a1 1 0 0 1 0-1.414z'
                    fill='#00D1D2'
                />
                <path
                    stroke='#00D1D2'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m13.474 34.546 19.8-19.799'
                />
                <path
                    d='m10 33.748 26.44-26.44a1 1 0 0 1 1.415 0l2.857 2.857a1 1 0 0 1 0 1.415l-26.44 26.44-6.24 2.85a.5.5 0 0 1-.67-.644L10 33.748z'
                    stroke='#34303D'
                    strokeWidth='2'
                />
                <path
                    stroke='#34303D'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M29 40.92H8M34.467 10.696l2.83 2.829M32.552 12.611l2.828 2.83'
                />
                <path stroke='#34303D' strokeWidth='1.75' d='M10.052 34.192h3.775v3.776' />
            </g>
        </Icon>
    );
};

export default RenameBlueIcon;
