import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import Banner from '../../../../components/Banner/Banner';
import {AppButton} from '../../../../components/Button';
import useConfigExternalLink from '../../../../hooks/useConfigExternalLink';
import useRefetchExternalConsumerProfile from '../../../../hooks/useRefetchExternalConsumerProfile';
import amplitudeHomePageTracking from '../../../../services/amplitude/amplitudeHomePageTracking';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import userService from '../../../../services/user/userService';
import {selectConsumerFirstName} from '../../../../state/selectors/consumer';
import helpers from '../../../../utils/helpers';
import {twx} from '../../../../utils/tailwindUtils';
import HomePageLoyaltyButtonsBar from './components/HomePageLoyaltyButtonsBar';
import WelcomeContent from './components/WelcomeContent';
import HomePageLoyaltyBanner from './HomePageLoyaltyBanner';
import useTopBanner from './hooks/useTopBanner';
import useWelcomeTopBannerAnimation from './useWelcomeTopBannerAnimation';

const HomePageTopBanner = () => {
    const name = useSelector(selectConsumerFirstName);

    const {
        bannerGreetingRef,
        welcomeGreetingRef,
        isWelcomeModeInProgress,
        isAnimationStarted,
        startAnimation,
        translation,
        bannerAnimationClassName,
        greetingAnimationClassName,
        greetingAnimationStyle,
        bannerContentAnimationClassName,
        loyaltyButtonsBarAnimationClassName,
    } = useWelcomeTopBannerAnimation();

    const {isLoyaltyBanner, topBanner, defaultImage, loyaltyButtons} = useTopBanner();

    const {titleKey, descriptionKey, buttonActionKey, imagePath, action, isGradient, amplitudeTrackingValue} =
        topBanner || {};

    const redirectToExternalLink = useConfigExternalLink(action);
    const isShowButton = buttonActionKey && redirectToExternalLink;

    const greeting = userService.getGreeting();
    const localizedStrings = getLocalizedStrings();
    const isLoyaltyButtonsBarEnabled = isLoyaltyBanner && loyaltyButtons?.length;

    useRefetchExternalConsumerProfile();

    const onButtonClick = () => {
        amplitudeHomePageTracking.trackHomePageTopBannerButtonClick(amplitudeTrackingValue);
        helpers.runFunction(redirectToExternalLink);
    };

    return (
        <div className={cn('bg-romance', isLoyaltyButtonsBarEnabled ? 'h-[574px]' : 'h-[518px]')}>
            <Banner
                className={twx('h-[518px] w-full font-bold text-romance', bannerAnimationClassName)}
                imgClassName='object-top animate-fade-in'
                imagePath={imagePath}
                isGradient={isGradient}
                gradient='linear-gradient(180deg, rgba(52, 48, 61, 0.00) 0.01%, rgba(48, 44, 56, 0.17) 8.34%, rgba(33, 31, 39, 0.78) 59.37%, #1C1A21 99.99%)'
                defaultImage={defaultImage}
            >
                <div
                    className={twx('absolute w-full px-4 text-start pt-safe-or-4', greetingAnimationClassName)}
                    style={greetingAnimationStyle}
                    ref={bannerGreetingRef}
                >
                    <p className='text-14'>{greeting}</p>
                    <p className='text-28'>{name}</p>
                </div>
                <div
                    className={twx(
                        'absolute bottom-8 w-full px-4 text-start',
                        {'bottom-[64px]': isLoyaltyButtonsBarEnabled},
                        bannerContentAnimationClassName
                    )}
                >
                    {isLoyaltyBanner && <HomePageLoyaltyBanner onClick={onButtonClick} loyaltyTopBanner={topBanner} />}
                    {!isLoyaltyBanner && (
                        <>
                            <h1 className='text-32'>{localizedStrings[titleKey]}</h1>
                            <p className='font-normal'>{localizedStrings[descriptionKey]}</p>
                        </>
                    )}
                    {isShowButton && (
                        <AppButton primary dark className='mt-8 font-normal' onClick={onButtonClick}>
                            {localizedStrings[buttonActionKey]}
                        </AppButton>
                    )}
                </div>
                {isLoyaltyButtonsBarEnabled && (
                    <HomePageLoyaltyButtonsBar
                        buttons={loyaltyButtons}
                        className={twx('absolute bottom-[-56px] left-4 right-4', loyaltyButtonsBarAnimationClassName)}
                    />
                )}
                {isWelcomeModeInProgress && (
                    <WelcomeContent
                        greeting={greeting}
                        name={name}
                        isAnimationStarted={isAnimationStarted}
                        onClick={startAnimation}
                        greetingRef={welcomeGreetingRef}
                        greetingTranslation={translation}
                    />
                )}
            </Banner>
        </div>
    );
};

export default HomePageTopBanner;
