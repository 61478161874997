import cn from 'classnames';
import React from 'react';

import deviceTypesService from '../../services/device/deviceTypesService';
import SignedImage from '../System/SignedImage';

const ProductImage = (props) => {
    const {mediaIdList, imgClassName, type, alt, isHolderSingle, ...rest} = props;
    const deviceDefaultData = deviceTypesService.getDeviceTypesDataByType(type);
    const defaultImageUrl = deviceDefaultData
        ? isHolderSingle
            ? deviceDefaultData.defaultSingleHolder
            : deviceDefaultData.defaultImageSrc
        : null;

    return (
        <SignedImage
            isThumbnailAvailable
            alt={alt}
            mediaData={mediaIdList}
            defaultImage={defaultImageUrl}
            imgClassName={cn('h-full w-auto m-auto object-cover', imgClassName)}
            signIfNull
            {...rest}
        />
    );
};

export default ProductImage;
