import {track} from '@amplitude/analytics-browser';

import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';

const trackEnableNotificationScreen = () => {
    track(AMPLITUDE_EVENT_TYPES.ENABLE_NOTIFICATION);
};

const trackEnableNotificationScreenNextButton = () => {
    track(AMPLITUDE_EVENT_TYPES.NEXT_BUTTON_CLICKED);
};

const trackSubscribeToReceiveMessages = () => {
    track(AMPLITUDE_EVENT_TYPES.NOTIFICATION_ALLOWED, {
        [AMPLITUDE_EVENT_PROPERTIES.SUCCESS]: true,
    });
};

const trackUnsubscribeToReceiveMessages = () => {
    track(AMPLITUDE_EVENT_TYPES.NOTIFICATION_ALLOWED, {
        [AMPLITUDE_EVENT_PROPERTIES.SUCCESS]: false,
    });
};

export default {
    trackEnableNotificationScreen,
    trackEnableNotificationScreenNextButton,
    trackSubscribeToReceiveMessages,
    trackUnsubscribeToReceiveMessages,
};
