import * as RadioGroup from '@radix-ui/react-radio-group';
import cn from 'classnames';
import React from 'react';

import {StarIcon} from '../../../../components/Icons';

const EstimationRadioGroup = ({selectedValue, onChange}) => {
    return (
        <RadioGroup.Root
            onValueChange={(value) => onChange(value + 1)}
            className='my-4 flex w-full justify-around px-2'
            required
        >
            {[...Array(5)].map((star, index) => {
                return (
                    <RadioGroup.Item className='p-0' key={index} value={index} id={index}>
                        <StarIcon
                            className={cn('cursor-pointer', {
                                'fill-sandy stroke-sandy': index < selectedValue,
                            })}
                        />
                    </RadioGroup.Item>
                );
            })}
        </RadioGroup.Root>
    );
};

export default EstimationRadioGroup;
