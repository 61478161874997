import {hideLoader, showLoader} from '../../state/slices/loadersSlice';
import {showBrowserNotCompatibleModalAction} from '../../state/slices/modalSlice';
import {dispatch} from '../../state/store';
import iccMarketService from '../icc/iccMarketService';
import iccSsoService from '../icc/iccSsoService';
import routerService from '../route/routerService';
import appErrorService from './appErrorService';

const REDIRECT_LOADER_NAME = 'REDIRECT_LOADER_NAME';

const openExternalLink = async ({
    ssoTemplate,
    externalLink,
    externalLinkCode,
    openInBluefy,
    linkProcessor,
    ssoParams,
    link,
}) => {
    let url = link;

    if (!url) {
        if (ssoTemplate) {
            dispatch(showLoader(REDIRECT_LOADER_NAME));

            url = await iccSsoService.getSsoLink({
                ssoTemplate,
                params: ssoParams,
                linkProcessor,
            });

            dispatch(hideLoader(REDIRECT_LOADER_NAME));

            if (!url) {
                appErrorService.showGlobalError();
                return;
            }
        }

        if (url && linkProcessor) {
            url = linkProcessor(url);
        } else {
            url = url || externalLink;
        }
    }

    if (!url && externalLinkCode) {
        dispatch(showLoader(REDIRECT_LOADER_NAME));

        url = (await iccMarketService.getExternalLink(externalLinkCode))?.value;

        dispatch(hideLoader(REDIRECT_LOADER_NAME));

        if (!url) {
            appErrorService.showGlobalError();
            return;
        }
    }

    if (openInBluefy) {
        showBrowserNotCompatibleModal({
            link: url,
            ssoTemplate,
            externalLink,
            openInBluefy,
            linkProcessor,
            ssoParams,
        });
    } else {
        openLinkInNewTab(url);
    }

    return url;
};

const openLinkInNewTab = (url) => {
    if (url) routerService.blankLinkOpen(url);
};

const showBrowserNotCompatibleModal = (props) => {
    dispatch(showBrowserNotCompatibleModalAction(props));
};

export default {
    openExternalLink,
    openLinkInNewTab,
};
