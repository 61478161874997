import cn from 'classnames';
import React from 'react';

import InlineProgress from '../../components/InlineProgress/InlineProgress';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {twx} from '../../utils/tailwindUtils';

const AutoDeliveryProgress = ({className, progressClassName, progress, steps}) => {
    if (!steps?.length) return;
    const localizedStrings = getLocalizedStrings();
    const isSingleStep = steps.length === 1;

    return (
        <div className={twx('text-14 tracking-04', className)}>
            <InlineProgress
                className='bg-border bg-opacity-60'
                progressClassName={progressClassName}
                progress={progress}
            />
            <div className={cn('mt-2 flex gap-2 text-center', isSingleStep ? 'justify-center' : 'justify-between')}>
                {steps.map((step, i) => (
                    <span
                        className={cn(
                            'flex-1 truncate',
                            !isSingleStep && {
                                'text-start': i === 0,
                                'text-end': i === steps.length - 1,
                            }
                        )}
                        key={i}
                    >
                        {localizedStrings[step]}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default AutoDeliveryProgress;
