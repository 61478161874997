import {EditableComponent} from '@adobe/aem-react-editable-components';
import React from 'react';

import {DeviceRenameItems} from './DeviceRenameItems';

const EditableDeviceRenameItems = (props) => (
    <EditableComponent className='w-full' {...props}>
        <DeviceRenameItems {...props} />
    </EditableComponent>
);

export default EditableDeviceRenameItems;
