import stringFormatService from '../services/stringFormatService';
import urlUtils from '../utils/urlUtils';
import {getBaseConfig, getConfig, getExternalLinksConfig, getLoyaltyConfig, getPagesConfig} from './config';

const getIsDebug = () => getConfig().logLevel !== 'SILENT';
const getLogLevel = () => getConfig().logLevel;
const getAwsRegion = () => 'eu-west-1';
const getAwsLogLevel = () => getConfig().awsLogLevel;
const getAuthLogsUrl = () => getConfig().authLogsUrl;

const getLoginEmailRegex = () => getConfig().loginEmailRegex || '.*';
const getLoginPasswordRegex = () => getConfig().loginPasswordRegex || '.*';
const getRegisterLinkCode = () => getConfig().registerLinkCode;
const getForgotPasswordLinkCode = () => getConfig().forgotPasswordLinkCode;

const getIccApiUrl = () => getConfig().iccEndpoint;
const getIccNotificationApiUrl = () => getConfig().iccNotificationApiEndpoint;
const getIccApiHeaderAuthorization = () => getConfig().iccAuthorizationHeader;
const getIccApiHeader_a = () => getConfig().iccApplicationHeader;
const getIccMarketName = () => getConfig().iccMarketHeader;
const getIccAnonymousToken = () => getConfig().iccAnonymousToken;

const getMarketLanguages = () => getBaseConfig().marketLanguages || [];

const getEnvironment = () => getConfig().environment;
const getAppVersion = () => process.env.REACT_APP_VERSION;

const getDeviceSupport = () => getConfig().deviceSupport || [];

const getContentPath = () => '/assets';
const getStoragePath = () => getConfig().storagePath;

const getCombinedPathAssetPath = (customPath) => {
    const storagePath = getStoragePath();

    if (storagePath && customPath) {
        const combinedPath = urlUtils.join(storagePath, customPath);

        return combinedPath;
    }
};

const getAssetsPath = () => getCombinedPathAssetPath(getConfig().assetsPath);
const getRtlLanguageCodes = () => getCombinedPathAssetPath(getConfig().rtlLanguageCodes);

const getLearnMoreUrl = () => getConfig().learnMoreUrl;
const getLearnMoreUrlFormatted = () => stringFormatService.getLanguageFormattedString(getLearnMoreUrl());

const getHomePageForYouSection = () => getConfig().forYouSection || [];
const getHomePageIsForYouSectionSwiperEnabled = () => getConfig().isForYouSectionSwiperEnabled;
const getHomePageAutoDeliveryBanner = () => getConfig().homePageAutoDeliveryBanner;

const getLeviaCheckCompatibilityExternalLink = () => getExternalLinksConfig().leviaCheckCompatibilityExternalLink;
const getLeviaLearnMoreExternalLink = () => getExternalLinksConfig().leviaLearnMoreExternalLink;

const getFirmwareAvailableCheckCompatibilityExternalLink = () =>
    getExternalLinksConfig().firmwareAvailableCheckCompatibilityExternalLink;
const getFirmwareAvailableLearnMoreExternalLink = () => getExternalLinksConfig().firmwareAvailableLearnMoreExternalLink;

const getSuggestForYouSection = () => getConfig().suggestForYouSection || [];
const getWarningBanners = () => getConfig().warningBanners || [];

const getIsQureMarket = () => getBaseConfig().isQureMarket;
const getIsUnregistrationEnabled = () => getBaseConfig().isUnregistrationEnabled;
const getIsWelcomeScreenEnabled = () => getBaseConfig().isWelcomeScreenEnabled;
const getIsLoyaltyEnabled = () => getLoyaltyConfig().isLoyaltyEnabled;
const getIsLoyaltyApiEnabled = () => getLoyaltyConfig().isLoyaltyApiEnabled;
const getIsLoyaltyProgressBarEnabled = () => getLoyaltyConfig().isLoyaltyProgressBarEnabled;
const getLoyaltyLevels = () => getLoyaltyConfig().loyaltyLevels;
const getIsAutoDeliveryEnabled = () => getBaseConfig().isAutoDeliveryEnabled;
const getIsTradeInEnabled = (deviceType) => {
    const tradeInDeviceTypes = getBaseConfig().tradeInDeviceTypes || [];

    return tradeInDeviceTypes.some((x) => x.deviceType === deviceType);
};
const getIsRestrictedMarket = () => false;
const getIsWarningIconEnabled = () => true;

const getAdConfig = (deviceType, step) => {
    const adConfigs = getConfig().adConfigs;
    const adConfig = adConfigs?.find((x) => x.device.deviceType === deviceType);

    if (!adConfig) return [];

    if (!step) return adConfig;

    return adConfig[step] || [];
};

const getSupportExternalLink = () => getExternalLinksConfig().supportExternalLink;
const getIqosStoreExternalLink = () => getExternalLinksConfig().iqosStoreExternalLink;

const getConsumerProfileExternalLink = () => getExternalLinksConfig().consumerProfileExternalLink;
const getMarketWebSiteExternalLink = () => getExternalLinksConfig().marketWebSiteExternalLink;
const getShopDevicesExternalLink = () => getExternalLinksConfig().shopDevicesExternalLink || undefined;
const getShopAccessoriesExternalLink = () => getExternalLinksConfig().shopAccessoriesExternalLink || undefined;
const getTradeInExternalLink = () => getExternalLinksConfig().tradeInExternalLink || undefined;

const getContactUs = () => getConfig().contactUs;
const getEnableNotificationPageHeaderImagePath = () =>
    getCombinedPathAssetPath(getConfig().enableNotificationPageHeaderImagePath);
const getEnableNotificationPageHeaderVideoSrc = () => getConfig().enableNotificationPageHeaderVideoSrc;
const getEnableNotificationPageHeaderVideoThumbnailSrc = () =>
    getConfig().enableNotificationPageHeaderVideoThumbnailSrc;
const getEnableNotificationPageIcons = () => getConfig().enableNotificationPageIcons?.sort((x) => x.order);
const getTopBanner = () => getConfig().topBanner;
const getLoyaltyTopBanner = () => getConfig().loyaltyTopBanner;
const getNoLoyaltyTopBanner = () => getConfig().noLoyaltyTopBanner;
const getLoyaltyNoPointsTopBanner = () => getConfig().loyaltyNoPointsTopBanner;
const getIsLoyaltyButtonsBarEnabled = () => getLoyaltyConfig().isLoyaltyButtonsBarEnabled;
const getLoyaltyButtonsBar = () => getLoyaltyConfig().loyaltyButtonsBar;

const getAutoDeliveryDashboardInactiveBenefitsIcons = () =>
    getConfig().autoDeliveryDashboardInactiveBenefitsIcons?.sort((x) => x.order);
const getAutoDeliveryStatusInactiveBenefitsIcons = () =>
    getConfig().autoDeliveryStatusInactiveBenefitsIcons?.sort((x) => x.order);
const getAutoDeliveryStatusInactiveHowItWorksIcons = () =>
    getConfig().autoDeliveryStatusInactiveHowItWorksIcons?.sort((x) => x.order);
const getAutoDeliveryStatusNoneBenefitsIcons = () =>
    getConfig().autoDeliveryStatusNoneBenefitsIcons?.sort((x) => x.order);
const getAutoDeliveryStatusNoneHowItWorksIcons = () =>
    getConfig().autoDeliveryStatusNoneHowItWorksIcons?.sort((x) => x.order);
const getAutoDeliveryDetailedBannerNone = () => getConfig().autoDeliveryDetailedBannerNone;
const getAutoDeliveryDetailedBannerInactive = () => getConfig().autoDeliveryDetailedBannerInactive;
const getAutoDeliveryOnHoldPayFailedExternalLink = () =>
    getExternalLinksConfig().autoDeliveryOnHoldPayFailedExternalLink;
const getAutoDeliveryOnHoldOutOfStockExternalLink = () =>
    getExternalLinksConfig().autoDeliveryOnHoldOutOfStockExternalLink;
const getAutoDeliveryOnHoldDeliveryFailExternalLink = () =>
    getExternalLinksConfig().autoDeliveryOnHoldDeliveryFailExternalLink;
const getAutoDeliveryOnHoldNoneExternalLink = () => getExternalLinksConfig().autoDeliveryOnHoldNoneExternalLink;
const getAutoDeliveryInactiveExternalLink = () => getExternalLinksConfig().autoDeliveryInactiveExternalLink;
const getAutoDeliveryPaymentPendingExternalLink = () => getExternalLinksConfig().autoDeliveryPaymentPendingExternalLink;
const getAutoDeliveryEditOrderExternalLink = () => getExternalLinksConfig().autoDeliveryEditOrderExternalLink;
const getAutoDeliveryEditAddressExternalLink = () => getExternalLinksConfig().autoDeliveryEditAddressExternalLink;
const getAutoDeliveryOrderSummaryExternalLink = () => getExternalLinksConfig().autoDeliveryOrderSummaryExternalLink;
const getAutoDeliveryTncExternalLink = () => getExternalLinksConfig().autoDeliveryTncExternalLink;
const getAutoDeliveryAsapRescheduleExternalLink = () => getExternalLinksConfig().autoDeliveryAsapRescheduleExternalLink;
const getAutoDeliverySkipGoToWebsiteExternalLink = () =>
    getExternalLinksConfig().autoDeliverySkipGoToWebsiteExternalLink;

const getAmplitudeApiKey = () => getConfig().amplitudeKey;

const getGetStartedPage = () => getPagesConfig().getStarted;

const getLeviaLearnMorePopupIcons = () => getConfig().leviaLearnMorePopupIcons?.sort((x) => x.order);

const getHighlights = () => getConfig().highlights;
const getHighlightsAutoplay = () => getConfig().highlightsAutoplay;

const getYourDevicesPromotionList = () => getConfig().yourDevicesPromotionList || [];
const isYourDevicesSectionEnabled = () => getConfig().isShopYourDevicesSectionEnabled;

const getShopPageTopBanner = () => getConfig().shopPageTopBanner;
const getShopByProductSection = () => getConfig().shopByProductSection;
const getSetUpYourIqosImagePath = () => getConfig().setUpYourIqosImagePath;

const getQuickOrderBanner = () => getConfig().quickOrderBanner;
const getQuickOrderBenefitsIcons = () => getConfig().quickOrderBenefitsIcons?.sort((x) => x.order);
const getQuickOrderHowItWorksIcons = () => getConfig().quickOrderHowItWorksIcons?.sort((x) => x.order);

export default {
    getIsDebug,
    getLogLevel,
    getAwsRegion,
    getAwsLogLevel,
    getAuthLogsUrl,

    getMarketLanguages,

    getLoginEmailRegex,
    getLoginPasswordRegex,
    getRegisterLinkCode,
    getForgotPasswordLinkCode,
    getLearnMoreUrlFormatted,
    getSupportExternalLink,
    getIqosStoreExternalLink,

    getIccApiHeader_a,
    getIccApiUrl,
    getIccNotificationApiUrl,
    getIccApiHeaderAuthorization,
    getIccMarketName,
    getIccAnonymousToken,

    getEnvironment,
    getAppVersion,

    getDeviceSupport,
    getAssetsPath,
    getContentPath,
    getCombinedPathAssetPath,
    getIsWarningIconEnabled,

    getIsRestrictedMarket,
    getIsQureMarket,
    getIsUnregistrationEnabled,
    getIsLoyaltyEnabled,
    getIsWelcomeScreenEnabled,
    getIsLoyaltyApiEnabled,
    getIsLoyaltyProgressBarEnabled,
    getLoyaltyLevels,
    getIsLoyaltyButtonsBarEnabled,
    getLoyaltyButtonsBar,
    getIsAutoDeliveryEnabled,
    getIsTradeInEnabled,
    getRtlLanguageCodes,
    getHomePageForYouSection,
    getHomePageIsForYouSectionSwiperEnabled,
    getHomePageAutoDeliveryBanner,
    getLeviaCheckCompatibilityExternalLink,
    getLeviaLearnMoreExternalLink,
    getFirmwareAvailableCheckCompatibilityExternalLink,
    getFirmwareAvailableLearnMoreExternalLink,
    getSuggestForYouSection,
    getWarningBanners,
    getAdConfig,
    getConsumerProfileExternalLink,
    getMarketWebSiteExternalLink,
    getShopDevicesExternalLink,
    getShopAccessoriesExternalLink,
    getTradeInExternalLink,
    getContactUs,
    getEnableNotificationPageHeaderImagePath,
    getEnableNotificationPageHeaderVideoSrc,
    getEnableNotificationPageHeaderVideoThumbnailSrc,
    getEnableNotificationPageIcons,
    getTopBanner,
    getLoyaltyTopBanner,
    getNoLoyaltyTopBanner,
    getLoyaltyNoPointsTopBanner,

    getAutoDeliveryDashboardInactiveBenefitsIcons,
    getAutoDeliveryStatusInactiveBenefitsIcons,
    getAutoDeliveryStatusInactiveHowItWorksIcons,
    getAutoDeliveryStatusNoneBenefitsIcons,
    getAutoDeliveryStatusNoneHowItWorksIcons,
    getAutoDeliveryDetailedBannerNone,
    getAutoDeliveryDetailedBannerInactive,
    getAutoDeliveryOnHoldPayFailedExternalLink,
    getAutoDeliveryOnHoldOutOfStockExternalLink,
    getAutoDeliveryOnHoldDeliveryFailExternalLink,
    getAutoDeliveryOnHoldNoneExternalLink,
    getAutoDeliveryInactiveExternalLink,
    getAutoDeliveryPaymentPendingExternalLink,
    getAutoDeliveryEditOrderExternalLink,
    getAutoDeliveryEditAddressExternalLink,
    getAutoDeliveryOrderSummaryExternalLink,
    getAutoDeliveryTncExternalLink,
    getAutoDeliveryAsapRescheduleExternalLink,
    getAutoDeliverySkipGoToWebsiteExternalLink,

    getAmplitudeApiKey,
    getGetStartedPage,
    getHighlights,
    getHighlightsAutoplay,
    getLeviaLearnMorePopupIcons,
    getYourDevicesPromotionList,
    isYourDevicesSectionEnabled,
    getShopPageTopBanner,
    getShopByProductSection,
    getSetUpYourIqosImagePath,

    getQuickOrderBanner,
    getQuickOrderBenefitsIcons,
    getQuickOrderHowItWorksIcons,
};
