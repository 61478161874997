import {MapTo} from '@adobe/aem-react-editable-components';

import EditableFormInput from '../../components/Aem/EditableFormInput/EditableFormInput';
import EditableFormInputPassword from '../../components/Aem/EditableFormInputPassword/EditableFormInputPassword';
import EditableNotificationsInbox from '../../components/Aem/EditableNotificationsInbox/EditableNotificationsInbox';
import EditableText from '../../components/Aem/EditableText/EditableText';
import {
    RESOURCE_TYPE_FORM_INPUT,
    RESOURCE_TYPE_FORM_INPUT_PASSWORD,
    RESOURCE_TYPE_NOTIFICATIONS_INBOX,
    RESOURCE_TYPE_TEXT,
} from '../../consts/aem/aemComponents';

const initAemComponents = () => {
    MapTo(RESOURCE_TYPE_TEXT)(EditableText);
    MapTo(RESOURCE_TYPE_NOTIFICATIONS_INBOX)(EditableNotificationsInbox);
    MapTo(RESOURCE_TYPE_FORM_INPUT)(EditableFormInput);
    MapTo(RESOURCE_TYPE_FORM_INPUT_PASSWORD)(EditableFormInputPassword);
};

export default {
    initAemComponents,
};
