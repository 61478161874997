import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ContactUsIcon, GlobeIcon, PreferenceCenterIcon} from '../../components/Icons';
import StickyHeader from '../../components/Layout/StickyHeader';
import {CONSUMER_PROFILE_PAGE_HEADER_TITLE} from '../../consts/localization/localizationKeys';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import amplitudeProfileTracking from '../../services/amplitude/amplitudeProfileTracking';
import appErrorService from '../../services/app/appErrorService';
import homePageNavigationService from '../../services/homePageNavigationService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import appRouterService from '../../services/route/appRouterService';
import routerService from '../../services/route/routerService';
import tncService from '../../services/tncService';
import {selectAttributionNoticeUrl, selectConsumerName, selectPrivacyNoticeUrl} from '../../state/selectors/consumer';
import {showLanguageSelectModalAction} from '../../state/slices/modalSlice';
import ConsumerProfileHeader from './components/ConsumerProfileHeader';
import ConsumerProfileSection from './components/ConsumerProfileSection';
import LogoutButton from './components/LogoutButton';

const ConsumerProfilePage = () => {
    const dispatch = useDispatch();
    const privacyNoticeUrl = useSelector(selectPrivacyNoticeUrl);
    const attributionNoticeUrl = useSelector(selectAttributionNoticeUrl);
    const userName = useSelector(selectConsumerName);
    const localizedStrings = getLocalizedStrings();

    useDocumentTitle('Consumer profile');

    useEffect(() => {
        tncService.getTncData(null, appErrorService.showGlobalError);
    }, []);

    const onPreferenceCenterClick = () => {
        tncService.getTncData(appRouterService.forwardToPreferenceCenterProfilePage, appErrorService.showGlobalError);
    };

    const onContactUsClick = () => {
        appRouterService.forwardToContactUsPage();
    };

    const onTermsOfUseClick = () => {
        amplitudeProfileTracking.trackTermsOfUseClick();
        tncService.openTermsOfUse();
    };

    const onPrivacyNoticeClick = () => {
        amplitudeProfileTracking.trackPrivacyNoticeClick();
        routerService.blankLinkOpen(privacyNoticeUrl);
    };

    const onAttributionNoticeClick = () => {
        amplitudeProfileTracking.trackAttributionNoticeClick();
        routerService.blankLinkOpen(attributionNoticeUrl);
    };

    useEffect(() => {
        amplitudeProfileTracking.trackConsumerProfileScreen();
    }, []);

    return (
        <div className='ica-page w-full overflow-hidden bg-romance pb-8 text-start pt-safe-offset-14'>
            <StickyHeader
                onBack={homePageNavigationService.forwardToSourcePage}
                title={localizedStrings.formatString(localizedStrings[CONSUMER_PROFILE_PAGE_HEADER_TITLE], userName)}
            />
            <div className='px-layout'>
                <ConsumerProfileHeader userName={userName} />
                <ConsumerProfileSection
                    headerItemName='accountSettingsHeader'
                    items={[
                        {
                            icon: <PreferenceCenterIcon />,
                            itemName: 'preferenceCentreButton',
                            onClick: onPreferenceCenterClick,
                        },
                        {
                            icon: <GlobeIcon className='h-8 w-8' />,
                            itemName: 'languageButton',
                            onClick: () => dispatch(showLanguageSelectModalAction()),
                        },
                    ]}
                />
                <ConsumerProfileSection
                    className='mt-12'
                    headerItemName='supportHeader'
                    items={[
                        {
                            icon: <ContactUsIcon />,
                            itemName: 'contactUsButton',
                            onClick: onContactUsClick,
                        },
                    ]}
                />
                <ConsumerProfileSection
                    headerItemName='legalHeader'
                    items={[
                        {
                            itemName: 'termsOfUseButton',
                            onClick: onTermsOfUseClick,
                        },
                        {
                            itemName: 'privacyNoticeButton',
                            onClick: onPrivacyNoticeClick,
                        },
                        {
                            itemName: 'attributionNoticeButton',
                            onClick: onAttributionNoticeClick,
                        },
                    ]}
                />
                <div className='mt-18 flex justify-center'>
                    <LogoutButton />
                </div>
            </div>
        </div>
    );
};

export default ConsumerProfilePage;
