const PRODUCT_COLORS = {
    AMBER: 'E3813B',
    BRIGHT_BLUE: '00C1FF',
    BRIGHT_RED: 'EF0117',
    BRIGHT_TEAL: '1BD9C5',
    BRIGHT_YELLOW: 'F8B80F',
    CORAL: 'E54360',
    FLUORESCENT_RED: 'EB0B00',
    TOURQOISE: '00D1D2',
};

export default PRODUCT_COLORS;
