import React from 'react';

import appConfig from '../../../config/appConfig';
import AMPLITUDE_EVENT_VALUES from '../../../consts/amplitude/amplitudeEventValues';
import {AUTO_DELIVERY_INACTIVE_BUTTON} from '../../../consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '../../../services/amplitude/amplitudeAutoDeliveryTracking';
import AutoDeliveryButton from '../AutoDeliveryButton';

const AutoDeliveryInactiveButton = (props) => {
    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryDashboardButtonClick(AMPLITUDE_EVENT_VALUES.REACTIVATE);
    };

    return (
        <AutoDeliveryButton
            localizationKey={AUTO_DELIVERY_INACTIVE_BUTTON}
            amplitudeEvent={amplitudeEvent}
            externalLinkConfig={appConfig.getAutoDeliveryInactiveExternalLink()}
            {...props}
        />
    );
};

export default AutoDeliveryInactiveButton;
