import cn from 'classnames';
import React from 'react';
import {RemoveScroll} from 'react-remove-scroll';

import AppPopup from './AppPopup';
import styles from './Popup.module.scss';

const PopupRaw = (props) => {
    const {className, overlayClassName, isHidePopup, ...rest} = props;

    return (
        <RemoveScroll enabled={!isHidePopup}>
            <AppPopup
                isOpen
                className={cn(styles.PopupContainer, className)}
                overlayClassName={cn(styles.Overlay, overlayClassName)}
                {...rest}
            />
        </RemoveScroll>
    );
};

export default PopupRaw;
