import {createSlice} from '@reduxjs/toolkit';

import * as loaderNames from '../../consts/loader/loaderNames';

const DEFAULT_LOADER_NAME = 'default';

export const slice = createSlice({
    name: 'loaders',
    initialState: [],
    reducers: {
        showLoader: (state, action) => {
            const payload = typeof action.payload === 'string' ? {name: action.payload} : {...action.payload};

            const {name = DEFAULT_LOADER_NAME, text, isTransparent} = payload;

            state.push({name, text, isTransparent});

            return state;
        },
        hideLoader: (state, action) => {
            const loaderName = action.payload || DEFAULT_LOADER_NAME;

            state = state.filter((e) => e.name !== loaderName);

            return state;
        },
        hideAllLoaders: (state, action) => {
            state = [];

            return state;
        },
    },
});

export const {showLoader, hideLoader, hideAllLoaders} = slice.actions;

export const showDeviceSettingsLoader = () =>
    showLoader({
        name: loaderNames.DEVICE_SETTINGS_DEVICE_SETTINGS_CHANGE_LOADER,
        isTransparent: true,
    });
export const hideDeviceSettingsLoader = () => hideLoader(loaderNames.DEVICE_SETTINGS_DEVICE_SETTINGS_CHANGE_LOADER);

export default slice.reducer;
