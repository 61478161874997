import cn from 'classnames';
import React from 'react';

import DynamicHeightTextarea from '../../Textarea/DynamicHeightTextarea';

export const DynamicTextarea = ({className, text: placeholder, ...rest}) => {
    return (
        <DynamicHeightTextarea
            className={cn(
                'w-full resize-none border-2 border-border text-start placeholder-primary placeholder:truncate placeholder-shown:text-ellipsis focus:placeholder-border',
                className
            )}
            placeholder={placeholder}
            {...rest}
        />
    );
};
