import React, {useEffect} from 'react';

import appConfig from '../../../config/appConfig';
import {AUTO_DELIVERY_DETAILS_NONE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '../../../services/amplitude/amplitudeAutoDeliveryTracking';
import AutoDeliveryDetailsStatusNoneTemplate from './components/AutoDeliveryDetailsStatusNoneTemplate';

const StatusNone = () => {
    const banner = appConfig.getAutoDeliveryDetailedBannerNone();
    const benefitsList = appConfig.getAutoDeliveryStatusNoneBenefitsIcons();
    const howItWorksList = appConfig.getAutoDeliveryStatusNoneHowItWorksIcons();

    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryGetStartedButtonClick();
    };

    useEffect(() => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryGetStartedScreen();
    }, []);

    return (
        <AutoDeliveryDetailsStatusNoneTemplate
            banner={banner}
            headerKey={AUTO_DELIVERY_DETAILS_NONE_HEADER_TITLE}
            benefitsItemName='benefitsNoneTitle'
            howItWorksItemName='howItWorksNoneTitle'
            benefitsList={benefitsList}
            howItWorksList={howItWorksList}
            amplitudeEvent={amplitudeEvent}
        />
    );
};

export default StatusNone;
