import cn from 'classnames';
import React from 'react';

import CloseButton from '../../CloseButton/CloseButton';

const PopupCloseButton = (props) => {
    const {onClick, className} = props;

    return <CloseButton className={cn('absolute right-3.5 top-3.5 z-[1]', className)} onClick={onClick} />;
};

export default PopupCloseButton;
