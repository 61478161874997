import React from 'react';

import Icon from '../Icon/Icon';

const TncDataCollectionIcon = (props) => {
    return (
        <Icon width='65' height='59' fill='none' {...props}>
            <path
                d='M32.3706 9.1875L53.125 13.5625H53.123H53.125V26.8122C53.118 39.1147 46.259 48.4481 32.5481 54.8125L32.499 54.6615L32.4519 54.8125C18.9061 48.5248 12.0483 39.3392 11.8782 27.2555L11.875 26.8122V13.5625H11.877H11.875L32.3706 9.1875Z'
                stroke='#34303D'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M32.5323 13.25L48.8636 16.7805V27.4726C48.7453 37.2523 43.4506 44.7069 32.9795 49.8366L32.5 50.0682V13.257L32.5323 13.25Z'
                fill='#00D1D2'
            />
            <path
                d='M24.9909 31.9007L30.1741 36.6585L42.382 24.2363'
                stroke='#34303D'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default TncDataCollectionIcon;
