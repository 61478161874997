import stringUtils from './stringUtils';

const isExpired = (expirationDate) => expirationDate < Date.now();

const getTimeAgo = (date, props) => {
    const seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(weeks / 4);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
        return props.ageNowText;
    } else if (minutes === 1) {
        return stringUtils.formatString(props.ageMinuteAgoText, minutes);
    } else if (minutes < 60) {
        return stringUtils.formatString(props.ageMinutesAgoText, minutes);
    } else if (hours === 1) {
        return stringUtils.formatString(props.ageHourAgoText, hours);
    } else if (hours < 24) {
        return stringUtils.formatString(props.ageHoursAgoText, hours);
    } else if (days === 1) {
        return stringUtils.formatString(props.ageDayAgoText, days);
    } else if (days < 7) {
        return stringUtils.formatString(props.ageDaysAgoText, days);
    } else if (weeks === 1) {
        return stringUtils.formatString(props.ageWeekAgoText, weeks);
    } else if (weeks < 4) {
        return stringUtils.formatString(props.ageWeeksAgoText, weeks);
    } else if (months === 1) {
        return stringUtils.formatString(props.ageMonthAgoText, months);
    } else if (months < 12) {
        return stringUtils.formatString(props.ageMonthsAgoText, months);
    } else if (years === 1) {
        return stringUtils.formatString(props.ageYearAgoText, years);
    } else {
        return stringUtils.formatString(props.ageYearsAgoText, years);
    }
};

const getNowIsoTs = () => new Date().toISOString();

const getDayAndShortMonth = (date, localeCode) => {
    const options = {day: 'numeric', month: 'short'};

    return date.toLocaleDateString(localeCode, options).replace(',', '');
};

const getDayAndMonth = (date, localeCode) => {
    const options = {day: 'numeric', month: 'long'};

    return date.toLocaleDateString(localeCode, options).replace(',', '');
};

const getWeekAndDayAndMonth = (date, localeCode) => {
    const options = {weekday: 'long', day: 'numeric', month: 'long'};

    return date.toLocaleDateString(localeCode, options).replace(',', '');
};

const getMonth = (date, localeCode) => {
    const options = {month: 'long'};

    return date.toLocaleDateString(localeCode, options);
};

const getDateDiffInDays = (firstDate, secondDate) => {
    const days = Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));

    return days;
};

export default {
    getTimeAgo,
    isExpired,
    getNowIsoTs,
    getDayAndShortMonth,
    getDayAndMonth,
    getWeekAndDayAndMonth,
    getMonth,
    getDateDiffInDays,
};
