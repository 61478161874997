import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';
import amplitude from './amplitude';
import amplitudeDeviceTracking from './amplitudeDeviceTracking';

const trackDevicesPageScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.DEVICE_SECTION_VIEWED);
};

const trackTryYourFirstIqosButtonClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.TRY_YOUR_FIRST_IQOS_BUTTON_CLICKED);
};

const trackAboutMyDeviceScreen = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.ABOUT_MY_DEVICE_SCREEN_VIEWED);
};

const trackTipsAndTutorialsScreen = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.TIPS_AND_TUTORIAL_SCREEN_VIEWED);
};

export default {
    trackDevicesPageScreen,
    trackTryYourFirstIqosButtonClick,
    trackAboutMyDeviceScreen,
    trackTipsAndTutorialsScreen,
};
