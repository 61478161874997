import {useEffect} from 'react';

import useOnVisibilityChange from '../../../hooks/useOnVisibilityChange';
import pushNotificationService from '../../../services/pushNotificationService';

const useUpdateBadges = () => {
    useEffect(() => {
        pushNotificationService.updateAppBadges();
    }, []);
    useOnVisibilityChange(pushNotificationService.updateAppBadges);
};

export default useUpdateBadges;
