import {createSelector} from 'reselect';

const selectModal = (state) => state.modal;

const selectGlobalModals = createSelector(selectModal, (modalState) => modalState.globalModals);
const selectRenderedModals = createSelector(selectModal, (modalState) => modalState.renderedModals);

const makeSelectVisibleGlobalModal = (modalType) =>
    createSelector(selectGlobalModals, (modalState) => modalState.some((modal) => modal.modalType === modalType));

const makeSelectGlobalModal = (modalType) =>
    createSelector(selectGlobalModals, (modalState) => modalState.find((modal) => modal.modalType === modalType));

const makeSelectIsVisibleRenderedModal = (id) =>
    createSelector(selectRenderedModals, (modals) => {
        const lastModalId = modals.length ? modals[modals.length - 1] : null;

        return lastModalId === id;
    });

const selectIsAnyRenderedPopup = createSelector(selectRenderedModals, (modals) => !!modals?.length);

export {
    makeSelectGlobalModal,
    makeSelectIsVisibleRenderedModal,
    makeSelectVisibleGlobalModal,
    selectGlobalModals,
    selectIsAnyRenderedPopup,
};
