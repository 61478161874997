import React from 'react';
import {useSelector} from 'react-redux';

import Banner from '../../../../../components/Banner/Banner';
import PromotedBadge from '../../../../../components/PromotedBadge/PromotedBadge';
import appConfig from '../../../../../config/appConfig';
import amplitudeAutoDeliveryTracking from '../../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import appRouterService from '../../../../../services/route/appRouterService';
import {selectIsAem} from '../../../../../state/selectors/global';
import AutoDeliveryWidgetContainer from './components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from './components/AutoDeliveryWidgetHeader';

const AutoDeliveryStatusNone = ({className}) => {
    const banner = appConfig.getHomePageAutoDeliveryBanner();
    const {imagePath, videoPath, isGradient, titleKey, subTitleKey, descriptionKey} = banner || {};
    const localizedStrings = getLocalizedStrings();
    const isAem = useSelector(selectIsAem);
    const isBannerDataExist = imagePath || videoPath || titleKey || subTitleKey || descriptionKey;

    const onBannerClick = () => {
        amplitudeAutoDeliveryTracking.trackAutoDeliveryBannerClick();
        appRouterService.forwardToAutoDeliveryPage();
    };

    if (!isBannerDataExist && !isAem) return;

    return (
        <AutoDeliveryWidgetContainer className={className}>
            <AutoDeliveryWidgetHeader />
            <Banner
                className='mt-4 h-60 cursor-pointer overflow-hidden rounded-xl bg-primary text-start'
                imagePath={imagePath}
                videoPath={videoPath}
                isGradient={isGradient}
                gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
                onClick={onBannerClick}
            >
                <div className='relative flex h-full flex-col justify-end gap-2 p-4'>
                    <PromotedBadge
                        className='max-w-full whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                        badgeText={localizedStrings[subTitleKey]}
                    />
                    <h2 className='mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-24 text-white'>
                        {localizedStrings[titleKey]}
                    </h2>
                    <p className='line-clamp-3 whitespace-pre-line text-white'>{localizedStrings[descriptionKey]}</p>
                </div>
            </Banner>
        </AutoDeliveryWidgetContainer>
    );
};

export default AutoDeliveryStatusNone;
