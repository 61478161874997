import cn from 'classnames';
import React from 'react';
import {SwiperSlide} from 'swiper/react';

import SwiperCarousel from '../../../../components/Swiper/SwiperCarousel';
import styles from './HomePageSwiperCarousel.module.scss';

const HomePageSwiperCarousel = ({slides, className}) => {
    return (
        <div className={cn(styles.HomePageSwiperCarousel, className)}>
            <SwiperCarousel>
                {slides.map((slide, index) => (
                    <SwiperSlide key={index}>{slide}</SwiperSlide>
                ))}
            </SwiperCarousel>
        </div>
    );
};

export default HomePageSwiperCarousel;
