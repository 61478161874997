import React from 'react';

import Icon from '../Icon/Icon';

const AlertIcon = (props) => {
    return (
        <Icon width='24' height='24' fill='none' {...props}>
            <g clipPath='url(#clip0_2262_47276)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12 5.71875C13.713 5.71875 15.2655 6.41328 16.3983 7.53866C17.5342 8.6672 18.2483 10.2288 18.2801 11.9592L18.2812 17.5312H5.71875V12.0845C5.75104 10.2991 6.45994 8.70952 7.5911 7.5649C8.72453 6.41798 10.2815 5.71875 12 5.71875Z'
                    stroke='#34303D'
                    strokeWidth='1.5'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.1562 17.5317C14.1563 18.2198 13.9109 18.8324 13.509 19.2752C13.1228 19.7007 12.5907 19.9686 12 19.9686C11.4093 19.9686 10.8772 19.7007 10.491 19.2752C10.0891 18.8324 9.84375 18.2198 9.84375 17.545L14.1562 17.5317Z'
                    stroke='#34303D'
                    strokeWidth='1.5'
                />
                <path
                    d='M10.5 3.9375C11 3.8125 11.5 3.75 12 3.75C12.5 3.75 13 3.8125 13.5 3.9375'
                    stroke='#34303D'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                />
                <path d='M4.5 17.5312H19.5' stroke='#34303D' strokeWidth='1.5' strokeLinecap='round' />
            </g>
            <defs>
                <clipPath id='clip0_2262_47276'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </Icon>
    );
};

export default AlertIcon;
