import React from 'react';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import {LeviaIcon} from '../../../../components/Icons';
import {MaxWidthContainer} from '../../../../components/Layout';
import appConfig from '../../../../config/appConfig';
import LeviaLearnMoreItem from './LeviaLearnMoreItem';

const LeviaLearnMoreArticle = () => {
    const list = appConfig.getLeviaLearnMorePopupIcons();
    const isAnyItem = list.some((x) => x.titleKey);

    return (
        <MaxWidthContainer className='flex-1 px-4 pb-4 pt-safe-offset-18'>
            <LeviaIcon className='h-16 w-16' />
            <h1 className='mb-0 mt-4 text-28'>
                <AemComponent component={EditableText} pageName='modals' itemName='leviaTitle' />
            </h1>
            <p>
                <AemComponent component={EditableText} pageName='modals' itemName='leviaDesc' />
            </p>
            {isAnyItem && (
                <p className='mt-8 text-20 font-bold'>
                    <AemComponent component={EditableText} pageName='modals' itemName='leviaAbout' />
                </p>
            )}
            <ul className='mt-4 flex max-w-[356px] flex-col items-start gap-4'>
                {list.map((item, index) => (
                    <LeviaLearnMoreItem key={index} item={item} />
                ))}
            </ul>
        </MaxWidthContainer>
    );
};

export default LeviaLearnMoreArticle;
