import {useState} from 'react';
import {useSelector} from 'react-redux';

import appErrorService from '../../../services/app/appErrorService';
import {selectInboxMessages, selectInboxMessagesNextPageStartKey} from '../../../state/selectors/inbox';
import inboxMessageService from './inboxMessageService';

const useInboxMessages = () => {
    const [isLoading, setIsLoading] = useState();
    const [isError, setIsError] = useState(false);
    const inboxMessages = useSelector(selectInboxMessages);
    const nextPageStartKey = useSelector(selectInboxMessagesNextPageStartKey);
    const hasNextPage = !isError && (nextPageStartKey || inboxMessages === undefined);

    const loadMore = async () => {
        setIsLoading(true);

        try {
            await inboxMessageService.fetchInboxMessages({nextPageStartKey});
        } catch (error) {
            appErrorService.showGlobalError();
            setIsError(true);
        }

        setIsLoading(false);
    };

    return {inboxMessages, loadMore, isLoading, hasNextPage};
};

export default useInboxMessages;
