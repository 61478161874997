import {useEffect} from 'react';

import appConfig from '../../../../config/appConfig';
import {amplitude} from '../../../../services/amplitude';
import useAmplitudeMarket from './useAmplitudeMarket';
import useAmplitudeOptOut from './useAmplitudeOptOut';
import useAmplitudeUserId from './useAmplitudeUserId';

const useAmplitudeInit = () => {
    useEffect(() => {
        const apiKey = appConfig.getAmplitudeApiKey();

        if (!apiKey) return;

        amplitude.initAmplitude(apiKey);
    }, []);

    useAmplitudeOptOut();
    useAmplitudeUserId();
    useAmplitudeMarket();
};

export default useAmplitudeInit;
