import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';
import {selectCurrentProductInfo} from '../../state/selectors/device';
import {getState} from '../../state/store';
import deviceTypesService from '../device/deviceTypesService';
import amplitude from './amplitude';

const trackDeviceEvent = (eventType, serialNumber, deviceType, properties) => {
    const modelName = deviceTypesService.getDeviceModelByType(deviceType);

    amplitude.trackEvent({eventType, serialNumber, modelName, properties});
};

const trackDeviceFromStateEvent = (eventType, properties) => {
    const {deviceSerialNumber, type} = selectCurrentProductInfo(getState());

    trackDeviceEvent(eventType, deviceSerialNumber, type, properties);
};

const trackAddNewDeviceClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.ADD_NEW_DEVICE_CLICKED);
};

const trackPairDeviceClick = (serialNumber, deviceType) => {
    trackDeviceEvent(AMPLITUDE_EVENT_TYPES.PAIR_DEVICE_CLICKED, serialNumber, deviceType);
};

const trackStartWithBluefyClick = () => {
    const device = selectCurrentProductInfo(getState());
    const eventType = AMPLITUDE_EVENT_TYPES.START_WITH_BLUEFY_CLICKED;

    if (device && device.deviceSerialNumber && device.type) {
        trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.START_WITH_BLUEFY_CLICKED);
    } else {
        amplitude.trackWithSource(eventType);
    }
};

const trackRenameDeviceScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.RENAME_MY_DEVICE_VIEWED);
};

const trackRenameDeviceSave = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.RENAME_DEVICE);
};

const trackUnregisterDeviceClick = (deviceSerialNumber, type, success) => {
    trackDeviceEvent(AMPLITUDE_EVENT_TYPES.UNREGISTER_DEVICE_CLICKED, deviceSerialNumber, type, {
        [AMPLITUDE_EVENT_PROPERTIES.SUCCESS]: success,
    });
};

const trackUnregisterHolderClick = (deviceSerialNumber, type, success) => {
    const holderDefaultData = deviceTypesService.getDeviceTypesDataByType(type);
    const modelName = holderDefaultData?.modelName;

    amplitude.trackEvent({
        eventType: AMPLITUDE_EVENT_TYPES.UNREGISTER_DEVICE_CLICKED,
        serialNumber: deviceSerialNumber,
        modelName,
        properties: {
            [AMPLITUDE_EVENT_PROPERTIES.SUCCESS]: success,
        },
    });
};

export default {
    trackAddNewDeviceClick,
    trackDeviceFromStateEvent,
    trackPairDeviceClick,
    trackStartWithBluefyClick,
    trackRenameDeviceScreen,
    trackRenameDeviceSave,
    trackUnregisterDeviceClick,
    trackUnregisterHolderClick,
};
