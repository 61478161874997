import React, {useEffect} from 'react';

import * as advanceDiagnosticScreenTypes from '../../consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import queryParameters from '../../consts/route/queryParameters';
import appRouterService from '../../services/route/appRouterService';
import urlUtils from '../../utils/urlUtils';
import AdvancedDiagnosticChargingIssue from './AdvancedDiagnosticChargingIssue/AdvancedDiagnosticChargingIssue';
import AdvancedDiagnosticCleaningContacts from './AdvancedDiagnosticCleaningContacts/AdvancedDiagnosticCleaningContacts';
import AdvancedDiagnosticDamage from './AdvancedDiagnosticDamage/AdvancedDiagnosticDamage';
import AdvancedDiagnosticEntryPoint from './AdvancedDiagnosticEntryPoint/AdvancedDiagnosticEntryPoint';
import AdvancedDiagnosticFinishScreen from './AdvancedDiagnosticFinishScreen/AdvancedDiagnosticFinishScreen';
import AdvancedDiagnosticFittingConsumables from './AdvancedDiagnosticFittingConsumables/AdvancedDiagnosticFittingConsumables';
import AdvancedDiagnosticFittingConsumablesSolved from './AdvancedDiagnosticFittingConsumables/AdvancedDiagnosticFittingConsumablesSolved';
import AdvancedDiagnosticPostCleaning from './AdvancedDiagnosticPostCleaning/AdvancedDiagnosticPostCleaning';
import AdvancedDiagnosticQuestionnaireMarketScreen from './AdvancedDiagnosticQuestionnaireMarketScreen/AdvancedDiagnosticQuestionnaireMarketScreen';
import AdvancedDiagnosticRemoveStick from './AdvancedDiagnosticRemoveStick/components/AdvancedDiagnosticRemoveStick';
import AdvancedDiagnosticSelectIssue from './AdvancedDiagnosticSelectIssue/AdvancedDiagnosticSelectIssue';
import useRedirectToPhase2 from './hooks/useRedirectToPhase2';

const AdvancedDiagnosticPage = () => {
    const [screenName] = urlUtils.getDataFromUrl(queryParameters.SCREEN);
    const redirectToPhase2 = useRedirectToPhase2();

    useEffect(() => {
        if (screenName === advanceDiagnosticScreenTypes.CLEANING_GUIDE_SCREEN) {
            appRouterService.forwardToCleaningGuide({isAdvancedDiagnostic: true, replace: true});
        } else if (screenName === advanceDiagnosticScreenTypes.PHASE_2_SCREEN) {
            redirectToPhase2();
        }
    }, [screenName]);

    switch (screenName) {
        case advanceDiagnosticScreenTypes.PHASE_1_SCREEN:
            return <AdvancedDiagnosticEntryPoint />;
        case advanceDiagnosticScreenTypes.PHASE_2_SCREEN:
            return null;
        case advanceDiagnosticScreenTypes.DAMAGE_CHECK_SCREEN:
            return <AdvancedDiagnosticDamage />;
        case advanceDiagnosticScreenTypes.SELECT_ISSUE_SCREEN:
            return <AdvancedDiagnosticSelectIssue />;
        case advanceDiagnosticScreenTypes.QUESTIONNAIRE_MARKET_SCREEN:
            return <AdvancedDiagnosticQuestionnaireMarketScreen />;
        case advanceDiagnosticScreenTypes.CHARGING_ISSUE_SCREEN:
            return <AdvancedDiagnosticChargingIssue />;
        case advanceDiagnosticScreenTypes.CLEANING_CONTACTS_SCREEN:
            return <AdvancedDiagnosticCleaningContacts />;
        case advanceDiagnosticScreenTypes.REMOVE_STICK_SCREEN:
            return <AdvancedDiagnosticRemoveStick />;
        case advanceDiagnosticScreenTypes.FITTING_CONSUMABLES_SOLVED:
            return <AdvancedDiagnosticFittingConsumablesSolved />;
        case advanceDiagnosticScreenTypes.FITTING_CONSUMABLES_CHECK_SCREEN:
            return <AdvancedDiagnosticFittingConsumables />;
        case advanceDiagnosticScreenTypes.POST_CLEANING_SCREEN:
            return <AdvancedDiagnosticPostCleaning />;
        case advanceDiagnosticScreenTypes.FINISH_SCREEN:
            return <AdvancedDiagnosticFinishScreen />;
        case advanceDiagnosticScreenTypes.CLEANING_GUIDE_SCREEN:
            return null;
        default:
            return <AdvancedDiagnosticEntryPoint />;
    }
};

export default AdvancedDiagnosticPage;
