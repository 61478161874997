import appConfig from '../../config/appConfig';
import {getMappedInitialLanguageCode} from '../localization/localizationService';

let _isRtlEnabled = undefined;

const isRtlEnabled = () => {
    if (_isRtlEnabled !== undefined) return _isRtlEnabled;

    const localizedLanguage = getMappedInitialLanguageCode();
    const rtlLanguageCodes = appConfig.getRtlLanguageCodes();

    _isRtlEnabled = Array.isArray(rtlLanguageCodes)
        ? !!rtlLanguageCodes.find((l) => l.toLowerCase() === localizedLanguage.toLowerCase())
        : false;

    return _isRtlEnabled;
};

const resetRtlModeCache = () => (_isRtlEnabled = undefined);

const getDir = () => {
    return isRtlEnabled() ? 'rtl' : 'ltr';
};

export default {
    isRtlEnabled,
    resetRtlModeCache,
    getDir,
};
