import React from 'react';

import SensitiveIcon from '../Icon/SensitiveIcon';

const WarningIcon = (props) => {
    return (
        <SensitiveIcon width='24' height='24' {...props}>
            <g fill='none' fillRule='evenodd'>
                <circle fill='#E5B052' transform='rotate(90 12 12)' cx='12' cy='12' r='12' />
                <g transform='rotate(180 6.5 10.5)' fill='#FFF'>
                    <ellipse cx='1.313' cy='1.352' rx='1.313' ry='1.352' />
                    <rect x='.188' y='4.25' width='2.25' height='12.75' rx='1' />
                </g>
            </g>
        </SensitiveIcon>
    );
};

export default WarningIcon;
