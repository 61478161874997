import React from 'react';

import styles from './AppLoader.module.scss';
import AppLoaderContent from './AppLoaderContent';

const AppLoaderRaw = () => {
    return (
        <div className={styles.Overlay}>
            <div className={styles.AppLoaderContainer}>
                <AppLoaderContent loaderText='' />
            </div>
        </div>
    );
};

export default AppLoaderRaw;
