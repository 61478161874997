import ReactLocalizedStrings from 'react-localization';

import log from '../logger/log';

export default class LocalizedStrings extends ReactLocalizedStrings {
    // eslint-disable-next-line no-useless-constructor
    constructor(...args) {
        super(...args);
    }

    formatString(str, ...values) {
        try {
            return super.formatString(str, ...values);
        } catch (e) {
            log.info(`LocalizedStrings.formatString: error: ${e}, str: ${str}, values: [${values}]`);

            return '';
        }
    }
}
