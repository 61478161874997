import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../components/Button';
import appConfig from '../../../config/appConfig';
import useConfigExternalLink from '../../../hooks/useConfigExternalLink';
import amplitudeProfileTracking from '../../../services/amplitude/amplitudeProfileTracking';

const ConsumerProfileHeader = ({userName}) => {
    const consumerProfileExternalLink = appConfig.getConsumerProfileExternalLink();
    const redirectToExternalLink = useConfigExternalLink(consumerProfileExternalLink);
    const onClick = () => {
        amplitudeProfileTracking.trackShowProfileClick();
        redirectToExternalLink();
    };

    return (
        <article>
            {userName && <h1 className='mb-0 text-32'>{userName}</h1>}
            <AppButton className='pt-2 tracking-05' tertiary isExternalLink onClick={onClick}>
                <AemEditableText pageName='profile' itemName='showProfileButton' />
            </AppButton>
        </article>
    );
};

export default ConsumerProfileHeader;
