import React from 'react';

const InputFieldWarning = ({isValid, isEntered, warningNotValid, warningRequiredField}) => {
    return (
        <>
            {(!isValid || !isEntered) && (
                <div className='text-start text-14 leading-[24px] text-error'>
                    {isEntered ? warningNotValid : warningRequiredField}
                </div>
            )}
        </>
    );
};

export default InputFieldWarning;
