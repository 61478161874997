import cn from 'classnames';

import {useGetAutoDeliveryDetails} from '../../../../api/consumerApi';
import SectionLoader from '../../../../components/Loader/AppLoader/SectionLoader';
import {AUTO_DELIVERY_STATUS_TYPES} from '../../../../consts/icc/iccAutoDeliveryStatusTypes';
import AutoDeliveryStatusActive from './HomePageAutoDeliveryWidgets/AutoDeliveryStatusActive';
import AutoDeliveryStatusInactive from './HomePageAutoDeliveryWidgets/AutoDeliveryStatusInactive/AutoDeliveryStatusInactive';
import AutoDeliveryStatusNone from './HomePageAutoDeliveryWidgets/AutoDeliveryStatusNone';
import AutoDeliveryStatusOnHold from './HomePageAutoDeliveryWidgets/AutoDeliveryStatusOnHold';
import AutoDeliveryStatusPaymentPending from './HomePageAutoDeliveryWidgets/AutoDeliveryStatusPaymentPending';
import AutoDeliveryWidgetContainer from './HomePageAutoDeliveryWidgets/components/AutoDeliveryWidgetContainer';

const getAutoDeliveryComponent = (status) => {
    switch (status) {
        case AUTO_DELIVERY_STATUS_TYPES.NONE:
        default:
            return AutoDeliveryStatusNone;
        case AUTO_DELIVERY_STATUS_TYPES.INACTIVE:
            return AutoDeliveryStatusInactive;
        case AUTO_DELIVERY_STATUS_TYPES.ACTIVE:
            return AutoDeliveryStatusActive;
        case AUTO_DELIVERY_STATUS_TYPES.PAYMENT_PENDING:
            return AutoDeliveryStatusPaymentPending;
        case AUTO_DELIVERY_STATUS_TYPES.ON_HOLD:
            return AutoDeliveryStatusOnHold;
    }
};

const HomePageAutoDeliverySection = ({className}) => {
    const {data, isLoading} = useGetAutoDeliveryDetails();

    if (isLoading) {
        return (
            <AutoDeliveryWidgetContainer className={cn('min-h-40', className)}>
                <SectionLoader />
            </AutoDeliveryWidgetContainer>
        );
    }

    if (!data) return null;

    const {
        autoDelivery: {status},
    } = data;

    const AutoDeliveryWidgetComponent = getAutoDeliveryComponent(status);

    if (!AutoDeliveryWidgetComponent) return;

    return <AutoDeliveryWidgetComponent className={className} />;
};

export default HomePageAutoDeliverySection;
