import cn from 'classnames';
import React, {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import Popup from '../../../components/Popup/Popup';
import appConfig from '../../../config/appConfig';
import logState from '../../../services/logger/logState';
import pushNotificationService from '../../../services/pushNotificationService';
import {showConsumerFeedbackGatheringModalAction} from '../../../state/slices/modalSlice';
import {dispatch} from '../../../state/store';
import styles from './Logs.module.css';

const Logs = () => {
    const [isVisibleLogs, setIsVisibleLogs] = useState(false);
    const [logs, setLogs] = useState(null);
    const [key, setKey] = useState(0);

    const onLogsOpen = () => {
        setLogs(logState.getLogState());
        setIsVisibleLogs(true);
    };
    const onLogsClose = () => setIsVisibleLogs(false);

    const clearLogs = () => {
        setLogs(null);
        logState.clearLogState();
        onLogsClose();
    };

    const onInitPushNotificationClick = async () => {
        await pushNotificationService.subscribe();

        setKey(key + 1);
    };

    const subscriptionInfo = pushNotificationService.getSubscriptionInfo();
    const title = appConfig.getAppVersion();

    const showFeedbackModal = () => {
        dispatch(showConsumerFeedbackGatheringModalAction());
    };

    return (
        <>
            <div id='logs' className={styles.LogsIcon} onClick={onLogsOpen}>
                {title}
            </div>
            {isVisibleLogs && (
                <Popup onClose={onLogsClose} overlayClassName={styles.LogsPopupOverlay} className={styles.LogsPopup}>
                    {isVisibleLogs && (
                        <>
                            <div>
                                <button onClick={clearLogs} className={styles.ClearLogsBtn}>
                                    Clear logs
                                </button>
                                <CopyToClipboard text={logs?.map(({log}) => log).join('\n')}>
                                    <div className={styles.ClearLogsBtn}>Copy logs</div>
                                </CopyToClipboard>
                                <button onClick={showFeedbackModal} className={cn(styles.ClearLogsBtn, 'bg-tertiary')}>
                                    Feedback
                                </button>
                            </div>
                            {subscriptionInfo ? (
                                <CopyToClipboard text={JSON.stringify(subscriptionInfo)}>
                                    <div className={styles.ClearLogsBtn}>Copy subscription info</div>
                                </CopyToClipboard>
                            ) : (
                                <button onClick={onInitPushNotificationClick} className={styles.ClearLogsBtn}>
                                    Init push notifications
                                </button>
                            )}
                            {logs?.map(({log}, i) => {
                                return (
                                    <div className={styles.LogContainer} key={i}>
                                        <div className={styles.Log}>{log}</div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </Popup>
            )}
        </>
    );
};

export default Logs;
