import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {amplitude} from '../../../../services/amplitude';
import {selectIsLoggedIn} from '../../../../state/selectors/auth';
import {
    selectIsAggregatedSettingsFetched,
    selectIsAmplitudeEnabled,
} from '../../../../state/selectors/consumerAggregatedSettings';

const useAmplitudeOptOut = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isAggregatedSettingsFetched = useSelector(selectIsAggregatedSettingsFetched);
    const isAmplitudeEnabled = useSelector(selectIsAmplitudeEnabled);

    useEffect(() => {
        // if logged in -> check aggregatedSettings
        if (isLoggedIn) return;

        // disable amplitude after logout
        amplitude.enableAmplitude(false);
    }, [isLoggedIn]);

    useEffect(() => {
        if (!isAggregatedSettingsFetched) return;

        amplitude.enableAmplitude(isAmplitudeEnabled);
    }, [isAggregatedSettingsFetched, isAmplitudeEnabled]);
};

export default useAmplitudeOptOut;
