import React from 'react';

import {isInboxMessageStatusRead} from '../../../../../consts/icc/iccInboxMessageStatusTypes';
import dateTimeUtils from '../../../../../utils/dateTimeUtils';

const InboxNotificationItem = ({subTitle, title, shortText, sendTs, status, ...rest}) => {
    const timeAgo = dateTimeUtils.getTimeAgo(sendTs, rest);
    const isRead = isInboxMessageStatusRead(status);

    return (
        <>
            <article className='my-4 text-start'>
                <div className='flex justify-between'>
                    <div className='flex items-center gap-2'>
                        {!isRead && <div className='h-2 w-2 rounded-full bg-amber' />}
                        <h4 className='mb-0 text-14 text-primary-tins-slate'>{subTitle}</h4>
                    </div>
                    <p className='text-14 font-bold text-primary-tins-slate'>{timeAgo}</p>
                </div>
                <h3 className='text-18'>{title}</h3>
                <p className='mt-1 text-14'>{shortText}</p>
            </article>
        </>
    );
};

export default InboxNotificationItem;
