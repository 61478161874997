import {useSelector} from 'react-redux';

import {selectConsumerProductsMergedInfo} from '../../../state/selectors/consumer';

const useGetDeviceSectionList = () => {
    const products = useSelector(selectConsumerProductsMergedInfo);

    return products;
};

export default useGetDeviceSectionList;
