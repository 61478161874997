import React, {useEffect, useRef} from 'react';

import Textarea from './Textarea';

const DynamicHeightTextarea = ({value, maxHeight = 190, ...rest}) => {
    const ref = useRef();

    useEffect(() => {
        ref.current.style.overflow = 'hidden';
        ref.current.style.height = 0 + 'px';

        const scrollHeight = ref.current.scrollHeight;

        if (scrollHeight <= maxHeight) {
            ref.current.style.height = scrollHeight + 'px';
        } else {
            ref.current.style.height = maxHeight + 'px';
            ref.current.style.overflowY = 'scroll';
        }
    }, [value, ref.current?.scrollHeight]);

    return <Textarea ref={ref} value={value} {...rest} />;
};

export default DynamicHeightTextarea;
