import {createSlice} from '@reduxjs/toolkit';

import storageService from '../../services/storage/storageService';

const getInitialState = () => {
    return {
        codentify: storageService.getSelectedDeviceCodentifyFromLocalStorage(),
        data: undefined,
        holderData: undefined,
    };
};

export const slice = createSlice({
    name: 'device',
    initialState: getInitialState(),
    reducers: {
        setDeviceCodentify: (state, action) => {
            return {
                ...state,
                codentify: action.payload,
                data: undefined,
            };
        },
        setDeviceData: (state, action) => {
            return {
                ...state,
                data: action.payload,
            };
        },
        setHolderData: (state, action) => {
            return {
                ...state,
                holderData: action.payload,
            };
        },
    },
});

export const {setDeviceCodentify, setDeviceData, setHolderData} = slice.actions;

export default slice.reducer;
