import React from 'react';

import Banner from '../../components/Banner/Banner';
import {AppButton} from '../../components/Button';
import CloseButton from '../../components/CloseButton/CloseButton';
import {StickyHeader} from '../../components/Layout';
import appConfig from '../../config/appConfig';
import {QUICK_ORDER_HEADER_TITLE} from '../../consts/localization/localizationKeys';
import useConfigExternalLink from '../../hooks/useConfigExternalLink';
import amplitudeShopPageTracking from '../../services/amplitude/amplitudeShopPageTracking';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import appRouterService from '../../services/route/appRouterService';
import BenefitsSection from './components/BenefitsSection';
import HowItWorksSection from './components/HowItWorksSection';

const QuickOrderPage = () => {
    const onClose = () => {
        appRouterService.forwardToShopPage();
    };

    const banner = appConfig.getQuickOrderBanner();
    const {imagePath, videoPath, isGradient, titleKey, descriptionKey, buttonActionKey, action} = banner || {};
    const localizedStrings = getLocalizedStrings();
    const redirectToExternalLink = useConfigExternalLink(action);
    const isShowButton = buttonActionKey && localizedStrings[buttonActionKey] && redirectToExternalLink;

    const onButtonClick = () => {
        amplitudeShopPageTracking.trackQuickOrderButtonClick();
        redirectToExternalLink();
    };

    return (
        <div className='ica-page w-full overflow-hidden text-start'>
            <StickyHeader
                title={localizedStrings[QUICK_ORDER_HEADER_TITLE]}
                endIcon={<CloseButton onClick={onClose} />}
            />
            <Banner
                className='h-[422px] bg-tertiary'
                imagePath={imagePath}
                videoPath={videoPath}
                isGradient={isGradient}
                gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
            >
                <div className='absolute bottom-4 w-full px-4 text-start text-white'>
                    <h1 className='mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-24 leading-[36px] lg:text-32'>
                        {localizedStrings[titleKey]}
                    </h1>
                    <p
                        className='line-clamp-2 whitespace-pre-line text-14'
                        dangerouslySetInnerHTML={{__html: localizedStrings[descriptionKey]}}
                    />
                    {isShowButton && (
                        <AppButton
                            primary
                            dark
                            className='mt-2 whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                            onClick={onButtonClick}
                        >
                            {localizedStrings[buttonActionKey]}
                        </AppButton>
                    )}
                </div>
            </Banner>
            <BenefitsSection className='hidden px-4 py-8 has-[h2,li_*]:block' />
            <HowItWorksSection className='hidden border-t border-mischka first:border-0 has-[h2,li_*]:block' />
        </div>
    );
};

export default QuickOrderPage;
