import cn from 'classnames';
import React, {useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import AemEditableText from '../../components/Aem/AemEditableText/AemEditableText';
import {DevicesIcon, HomeIcon, MyIqosIcon} from '../../components/Icons';
import ROUTE_PATHS from '../../consts/route/routePaths';
import useWithLocale from '../../hooks/useWithLocale';
import {twx} from '../../utils/tailwindUtils';

const HomePageNavigationBar = () => {
    const [navbarRef, setNavbarRef] = useState(null);

    return (
        <>
            <div style={{height: navbarRef?.offsetHeight}} />
            <div ref={(ref) => setNavbarRef(ref)} className='fixed bottom-0 z-[1] w-full'>
                <div className='border-input-border flex gap-2 border-t border-border bg-white'>
                    <Link className='pl-2' to={ROUTE_PATHS.HOME_PAGE} icon={HomeIcon} itemName='forYouMenuItem' />
                    <Link
                        to={ROUTE_PATHS.SHOP_PAGE}
                        icon={MyIqosIcon}
                        iconClassName='my-1 mr-0.5 ml-[1px]'
                        itemName='shopMenuItem'
                    />
                    <Link className='pr-2' to={ROUTE_PATHS.DEVICES_PAGE} icon={DevicesIcon} itemName='deviceMenuItem' />
                </div>
            </div>
        </>
    );
};

const Link = ({to, icon: Icon, className, iconClassName, itemName}) => {
    const withLocale = useWithLocale();
    const {pathname} = useLocation();
    const isActive = to === pathname;

    return (
        <NavLink
            to={withLocale(to)}
            className={twx('flex w-full flex-col items-center gap-1 pt-3 text-12 pb-safe-or-3', className)}
        >
            <div
                className={cn('flex h-8 w-16 items-center justify-center rounded-2xl', {
                    'bg-tertiary-a30': isActive,
                })}
            >
                <Icon className={twx('text-romance', iconClassName, {'text-tertiary-a30': isActive})} />
            </div>
            <div className={cn(isActive && 'font-bold')}>
                <AemEditableText pageName='home' itemName={itemName} />
            </div>
        </NavLink>
    );
};

export default HomePageNavigationBar;
