import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import loaderService from '../services/loaderService';
import {selectConsumerProductsMergedInfo} from '../state/selectors/consumer';
import {showLoader} from '../state/slices/loadersSlice';

const LOADER_NAME = 'DEVICE_LIST_LOADER';

const useConsumerProductsLoader = () => {
    const dispatch = useDispatch();
    const consumerProducts = useSelector(selectConsumerProductsMergedInfo);

    useEffect(() => {
        if (consumerProducts === undefined) {
            dispatch(showLoader(LOADER_NAME));
        } else {
            loaderService.hideLoaderIfNeeded(LOADER_NAME);
        }

        return () => {
            loaderService.hideLoaderIfNeeded(LOADER_NAME);
        };
    }, [consumerProducts]);
};

export default useConsumerProductsLoader;
