import cn from 'classnames';
import React, {Fragment} from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import Carousel from '../../../../components/Carousel/Carousel';
import useConsumerProductsLoader from '../../../../hooks/useConsumerProductsLoader';
import useRefetchConsumerProducts from '../../../../hooks/useRefetchConsumerProducts';
import useGetDeviceSectionList from './hooks/useGetDeviceSectionList';

const DevicesSection = ({className}) => {
    const devices = useGetDeviceSectionList();
    const isSingleSlide = devices.length === 1;
    const sliderOptions = {
        centerPadding: '0px',
        variableWidth: true,
        centerMode: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
        ],
    };

    useRefetchConsumerProducts();
    useConsumerProductsLoader();

    if (!devices?.length) return null;

    return (
        <div className={cn('bg-romance px-4 py-10', className)}>
            <h1 className='mb-0 text-start text-24'>
                <AemEditableText pageName='shop' itemName='yourdevicestitle' />
            </h1>
            <div className={cn('mt-4', {'justify-start': isSingleSlide})}>
                <Carousel
                    controlArrowClassName='sm:block -translate-y-full'
                    controlArrowRightClassName='lg:end-0'
                    options={sliderOptions}
                    isControlArrowVisible
                    isDotsVisible={false}
                >
                    {devices.map((slide, index) => (
                        <Fragment key={index}>{slide}</Fragment>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default DevicesSection;
