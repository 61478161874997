import React from 'react';

import appConfig from '../../config/appConfig';
import urlUtils from '../../utils/urlUtils';
import AppImage from '../Image/AppImage';

const RemoteIcon = ({imagePath, className}) => {
    if (!imagePath) return null;

    const imageSrc = urlUtils.join(appConfig.getContentPath(), imagePath);

    return <AppImage alt={imageSrc} className={className} src={imageSrc} />;
};

export default RemoteIcon;
