import React from 'react';
import {useParams} from 'react-router-dom';

import ROUTE_PARAMS from '../../consts/route/routeParams';
import InboxViewPage from './InboxView/InboxViewPage';
import NotificationDetailsPage from './NotificationDetails/NotificationDetailsPage';

const InboxPage = () => {
    const {[ROUTE_PARAMS.MESSAGE_ID]: messageId} = useParams();

    return (
        <div className='ica-page w-full'>
            {messageId ? <NotificationDetailsPage messageId={messageId} /> : <InboxViewPage />}
        </div>
    );
};

export default InboxPage;
