import React from 'react';

import Icon from '../Icon/Icon';

const IlumaBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M14.25 41.25c-.701 0-1.451-1.152-2.25-3.455v-25.76c0-1.076.447-2.087 1.342-3.035h4.816c.895.948 1.342 1.96 1.342 3.034v25.76c-.799 2.304-1.549 3.456-2.25 3.456z'
                    stroke='#34303D'
                    strokeWidth='2'
                    strokeLinejoin='round'
                />
                <circle stroke='#00D1D2' fill='#34303D' cx='15.75' cy='23.185' r='1.5' />
                <path
                    d='M35 34v4.75A2.25 2.25 0 0 1 32.75 41h-7a2.25 2.25 0 0 1-2.25-2.25V9.25A2.25 2.25 0 0 1 25.75 7h3.5A5.75 5.75 0 0 1 35 12.75v9.48'
                    stroke='#34303D'
                    strokeWidth='2'
                />
                <g transform='translate(30 21.31)' stroke='#34303D' strokeWidth='1.5'>
                    <circle fill='#00D1D2' cx='6.761' cy='6.761' r='6.011' />
                    <path strokeLinecap='round' strokeLinejoin='round' d='m4.26 7.1 1.414 1.48L9.26 4.896' />
                </g>
            </g>
        </Icon>
    );
};

export default IlumaBlueIcon;
