import React from 'react';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import EditableNotificationItem from '../../../../components/Aem/EditableNotificationItem/EditableNotificationItem';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import {ButtonRaw} from '../../../../components/Button';
import * as sourceTypes from '../../../../consts/sourceTypes';
import appRouterService from '../../../../services/route/appRouterService';

const NotificationDetailsReadNext = ({notificationItem}) => {
    const {messageId} = notificationItem;

    const onClick = () => {
        appRouterService.forwardToNotificationDetailsPage(messageId, sourceTypes.INBOX);
    };

    return (
        <div className='px-layout mt-2 bg-romance py-2'>
            <h3 className='my-4 text-24'>
                <AemComponent component={EditableText} pageName='home' itemName='notificationdetailsreadnext' />
            </h3>
            <ButtonRaw className='w-full' onClick={onClick}>
                <AemComponent
                    component={EditableNotificationItem}
                    pageName='home'
                    itemName='notificationsinbox'
                    {...notificationItem}
                />
            </ButtonRaw>
        </div>
    );
};

export default NotificationDetailsReadNext;
