import {useEffect, useState} from 'react';

const useOnScroll = ({scrollElement = window} = {}) => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const onScroll = () => {
            setScrollY(scrollElement.scrollY || scrollElement.scrollTop || 0);
        };

        scrollElement.addEventListener('scroll', onScroll);

        return () => scrollElement.removeEventListener('scroll', onScroll);
    }, [scrollElement]);

    return scrollY;
};

export default useOnScroll;
