import {makeSelectVisibleGlobalModal} from '../state/selectors/modal';
import {hideModalAction} from '../state/slices/modalSlice';
import {dispatch, getState} from '../state/store';

const hideModal = (modalType) => {
    const modal = makeSelectVisibleGlobalModal(modalType)(getState());

    if (modal) {
        dispatch(hideModalAction(modalType));
    }
};

export default {hideModal};
