import React from 'react';

import Icon from '../Icon/Icon';

const VeevDefaultBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g transform='translate(19 7.3)' fill='none' fillRule='evenodd'>
                <path d='M.889 33.5c-.57 0-.865-6.16-.888-18.479v-3.033l.004-1.603a7.299 7.299 0 0 1 1.553-.19h7.036c.461 0 .98.064 1.554.19l.003 2.422v2.168l-.002.92c-5.007 3.718-1.994 9.04-.12 9.75-.14 5.236-.394 7.855-.765 7.855z' />
                <path
                    d='M1.558 9.934c-.46 0-.979.087-1.553.262C.44 3.399.978 0 1.614 0h6.925c.636 0 1.172 3.399 1.609 10.196-.575-.175-1.093-.262-1.554-.262H1.558zM5.076 10.196H1.558c-.46 0-.979.063-1.553.19C-.042 25.795.252 33.5.889 33.5h4.187m4.952-7.856c-.139 5.237-.393 7.856-.764 7.856H5.076m0-23.304h3.518c.461 0 .98.063 1.554.19.004 2.532.004 4.369 0 5.51'
                    stroke='#34303D'
                    strokeWidth='2'
                    strokeLinejoin='round'
                />
                <rect fill='#34303D' x='4.351' y='3.641' width='1.45' height='4.37' rx='.725' />
                <circle stroke='#34303D' strokeWidth='1.5' fill='#00D1D2' cx='13.762' cy='20.765' r='6.011' />
                <path
                    stroke='#34303D'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m11.26 21.104 1.415 1.48L16.26 18.9'
                />
            </g>
        </Icon>
    );
};

export default VeevDefaultBlueIcon;
