import React from 'react';

import Icon from '../Icon/Icon';

const MailIcon = ({isSingleColor, ...restProps}) => {
    return (
        <Icon width='48' height='48' fill='none' {...restProps}>
            <mask id='983a80za2a' maskUnits='userSpaceOnUse' x='4' y='7' width='41' height='35'>
                <path d='M45 7H4v35h41V7z' fill='#fff' />
            </mask>
            <g mask='url(#983a80za2a)' strokeWidth='2'>
                <path
                    d='M40.744 37.92 29.278 26.453m-10.54-.015L8.746 36.43'
                    stroke={isSingleColor ? '#34303D' : '#00D1D2'}
                    strokeLinecap='round'
                />
                <path
                    d='M40.604 14.88c-2.756 2.757-6.748 6.942-12.26 12.454-2.949 2.95-6.005 2.457-8.6-.139L7.54 14.991l-.006-2.716 33.056.185.014 2.42z'
                    stroke='#34303D'
                    strokeLinejoin='round'
                />
                <path
                    clipRule='evenodd'
                    d='M40.658 38.191h-27.19c-4.217 0-6.05-2.507-6.05-6.174V12.373l33.24.001v25.817z'
                    stroke='#34303D'
                    strokeLinejoin='round'
                />
            </g>
        </Icon>
    );
};

export default MailIcon;
