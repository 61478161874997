import cn from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {useResizeObserver} from 'usehooks-ts';

import {twx} from '../../utils/tailwindUtils.js';
import StickyHeader from './StickyHeader';

const StickyPageLayout = ({
    children,
    headerOptions,
    className,
    noScrollFooterClassName,
    footerClassName,
    contentClassName,
    footer,
}) => {
    const contentContainerRef = useRef();
    const contentRef = useRef();
    const {height} = useResizeObserver({
        ref: contentRef,
    });
    const [isScrollVisible, setIsScrollVisible] = useState(false);

    useEffect(() => {
        const containerHeight = contentContainerRef.current.clientHeight;
        const isScrollVisible = containerHeight < height;

        setIsScrollVisible(isScrollVisible);
    }, [height]);

    return (
        <section className={cn('flex max-h-screen flex-1 flex-col', className)}>
            {headerOptions && <StickyHeader scrollElement={contentContainerRef.current} {...headerOptions} />}
            <div ref={contentContainerRef} className='w-full flex-1 overflow-y-auto overflow-x-hidden'>
                <div ref={contentRef} className={cn('min-h-full', contentClassName)}>
                    {children}
                </div>
            </div>
            {footer && (
                <div
                    className={twx(
                        'w-full px-4 pt-4 pb-safe-offset-4',
                        {'bg-secondary': isScrollVisible},
                        {[noScrollFooterClassName]: !isScrollVisible},
                        footerClassName
                    )}
                >
                    {footer}
                </div>
            )}
        </section>
    );
};

export default StickyPageLayout;
