import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import appRouterService from '../../services/route/appRouterService';
import {selectIsLoggedIn} from '../../state/selectors/auth';
import AppRoute from './AppRoute';

const PrivateRoute = (props) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(() => {
        if (!isLoggedIn) appRouterService.forwardToLoginPage();
    }, [isLoggedIn]);

    if (!isLoggedIn) return null;

    return <AppRoute {...props} />;
};

export default PrivateRoute;
