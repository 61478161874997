import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import {Route} from 'react-router-dom';

import appConfig from '../../config/appConfig';
import domClassNames from '../../consts/app/domClassNames';
import useHistoryScrollTop from '../../hooks/useHistoryScrollTop';
import useRegUnregInterval from '../../hooks/useRegUnregInterval';
import rtlService from '../../services/app/rtlService';
import userBrowserService from '../../services/user/userBrowserService';
import userDeviceService from '../../services/user/userDeviceService';
import {selectIsTncPopupVisible} from '../../state/selectors/tnc';
import LoaderGlobal from '../LoaderGlobal/LoaderGlobal';
import ModalsRoot from '../Modals/ModalsRoot';
import Notifications from '../Notifications/Notifications';
import TermsOfUsePopup from '../TNC/TermsOfUsePopup/TermsOfUsePopup';
import PageWarningBanners from '../WarningBanners/PageWarningBanners';
import Logs from './Logs/Logs';

const AppLayout = (props) => {
    const {component: Component, path} = props;
    const isTncPopupVisible = useSelector(selectIsTncPopupVisible);
    const isTouchSupported = userDeviceService.isTouchSupported();
    const isRTLEnabled = rtlService.isRtlEnabled();

    useHistoryScrollTop();
    useRegUnregInterval();

    return (
        <Route
            path={path}
            render={(matchProps) => (
                <div
                    dir={isRTLEnabled ? 'rtl' : 'ltr'}
                    className={cn(domClassNames.APP_CLASSNAME, {
                        touch: isTouchSupported,
                        'ica-standalone': userBrowserService.isInStandaloneMode(),
                    })}
                >
                    <div className='relative flex min-h-screen flex-col items-center bg-romance font-iqos'>
                        <Notifications />
                        {isTncPopupVisible && <TermsOfUsePopup />}
                        <div
                            className={cn('flex w-full flex-1 flex-col', domClassNames.COMPONENTS_CONTAINER_CLASS_NAME)}
                        >
                            <div className='page-content flex flex-1 justify-center'>
                                <Component {...matchProps} />
                            </div>
                        </div>
                        <PageWarningBanners />
                        <LoaderGlobal />
                        <ModalsRoot />
                        {appConfig.getIsDebug() && <Logs />}
                    </div>
                </div>
            )}
        />
    );
};

export default AppLayout;
