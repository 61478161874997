import cn from 'classnames';
import React from 'react';

const HwContainer = ({className, children, isSeparatorVisible}) => {
    return (
        <article
            className={cn(
                'relative w-full bg-secondary text-center',
                {
                    'after:absolute after:-top-[1px] after:left-4 after:right-4 after:h-[1px] after:bg-mischka after:content-[""]':
                        isSeparatorVisible,
                },
                className
            )}
        >
            {children}
        </article>
    );
};

export default HwContainer;
