import {useEffect} from 'react';

const useOnVisibilityChange = (callback) => {
    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
};

export default useOnVisibilityChange;
