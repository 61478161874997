import {createBrowserHistory} from 'history';

import marketService from './marketService';

let history;

const getHistory = () => {
    if (!history) {
        history = createBrowserHistory({
            basename: marketService.getMarketCode(),
        });
    }

    return history;
};

export default {
    getHistory,
};
