import aemClientService from '../services/aem/aemClientService';

let config;

const initConfig = async (market) => {
    const configData = await aemClientService.initMarketConfig(market);
    const configDataStatic = await aemClientService.initMarketConfigStatic(market);

    config = {...configData, ...configDataStatic};
};

const getConfig = () => config || {};

const getBaseConfig = () => getConfig().baseConfig || {};

const getExternalLinksConfig = () => getConfig().externalLinks || {};

const getLoyaltyConfig = () => getConfig().loyalty || {};

const getPagesConfig = () => getConfig().pages || {};

export {getBaseConfig, getConfig, getExternalLinksConfig, getLoyaltyConfig, getPagesConfig, initConfig};
