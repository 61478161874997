import {useSelector} from 'react-redux';

import topBannerImage from '../../../../../../assets/images/home-page/top-banner.jpg';
import topBannerLoyaltyImage from '../../../../../../assets/images/home-page/top-banner-loyalty.jpg';
import topBannerNoPointsImage from '../../../../../../assets/images/home-page/top-banner-no-points.jpg';
import appConfig from '../../../../../config/appConfig';
import {
    selectIsAnyConsumerProducts,
    selectIsConsumerProductsFetched,
    selectIsLoyaltyMember,
    selectLoyaltyPoints,
} from '../../../../../state/selectors/consumer';
import useLoyaltyButtonsBar from './useLoyaltyButtonsBar';

const useTopBanner = () => {
    const isLoyaltyEnabled = appConfig.getIsLoyaltyEnabled();
    const isLoyaltyApiEnabled = appConfig.getIsLoyaltyApiEnabled();
    const isLoyaltyUser = useSelector(selectIsLoyaltyMember);
    const loyaltyPoints = useSelector(selectLoyaltyPoints);
    const isConsumerProductsFetched = useSelector(selectIsConsumerProductsFetched);
    const isAnyConsumerProducts = useSelector(selectIsAnyConsumerProducts);
    const loyaltyButtons = useLoyaltyButtonsBar();

    const topBanner = appConfig.getTopBanner();
    const loyaltyTopBanner = appConfig.getLoyaltyTopBanner();
    const noLoyaltyTopBanner = appConfig.getNoLoyaltyTopBanner();
    const loyaltyNoPointsTopBanner = appConfig.getLoyaltyNoPointsTopBanner();

    if (!isLoyaltyEnabled || !isLoyaltyApiEnabled) return {topBanner, defaultImage: topBannerImage};

    if (!isLoyaltyUser) return {topBanner: noLoyaltyTopBanner, defaultImage: topBannerImage};

    if (!isConsumerProductsFetched) return {};

    if (loyaltyPoints > 0 && isAnyConsumerProducts)
        return {
            topBanner: loyaltyTopBanner,
            defaultImage: topBannerLoyaltyImage,
            isLoyaltyBanner: true,
            loyaltyButtons,
        };

    return {topBanner: loyaltyNoPointsTopBanner, defaultImage: topBannerNoPointsImage};
};

export default useTopBanner;
