import {useEffect, useState} from 'react';

import queryParameters from '../consts/route/queryParameters';
import marketService from '../services/marketService';
import routerService from '../services/route/routerService';
import urlUtils from '../utils/urlUtils';
import useSearchParams from './useSearchParams';

const useModalRoute = ({modalType, closeModal}) => {
    const [searchParams] = useSearchParams();
    const [isRouteUpdated, setIsRouteUpdated] = useState(false);
    const modalFromUrl = searchParams[queryParameters.MODAL];

    // Update route
    useEffect(() => {
        const search = urlUtils.stringify({
            ...searchParams,
            [queryParameters.MODAL]: modalType,
        });
        const marketName = marketService.getMarketCode();
        const path = urlUtils.getCurrentPathname().replace(`/${marketName}`, '');
        const fullPath = `${path}?${search}`;

        routerService.forwardTo(fullPath);
        setIsRouteUpdated(true);
    }, []);

    // Close modal on browser back button click
    useEffect(() => {
        if (!isRouteUpdated) return;
        if (modalFromUrl) return;

        closeModal();
    }, [modalFromUrl, isRouteUpdated]);

    const onClose = () => {
        closeModal();
        routerService.goBack();
    };

    return {onClose};
};

export default useModalRoute;
