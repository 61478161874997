import cn from 'classnames';
import React from 'react';
import ReactModal from 'react-modal';

import domService from '../../services/domService';
import helpers from '../../utils/helpers';
import styles from './Popup.module.scss';
import PopupCloseButton from './PopupCloseButton/PopupCloseButton';

const AppPopup = (props) => {
    //IA: closeTimeoutMS = 1 is needed to run popup 'close' function
    //    if Popup component is removed from DOM NOT by isOpen property
    const {
        children,
        className,
        closeTimeoutMS = 1,
        shouldReturnFocusAfterClose,
        isCloseVisible,
        onClose,
        closeButtonClass,
        ...restProps
    } = props;
    const isCloseFunction = typeof onClose === 'function';
    const onPopupClose = (action) => {
        if (isCloseFunction) {
            helpers.runFunction(onClose);

            if (action) {
                helpers.runFunction(action);
            }
        }
    };

    return (
        <ReactModal
            parentSelector={domService.getComponentsWrapperElement}
            shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
            className={cn(styles.AppPopup, className)}
            closeTimeoutMS={closeTimeoutMS}
            ariaHideApp={false}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            onRequestClose={onPopupClose}
            {...restProps}
        >
            {isCloseVisible && <PopupCloseButton onClick={() => onClose(false)} className={closeButtonClass} />}
            {children}
        </ReactModal>
    );
};

export default AppPopup;
