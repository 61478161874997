import React, {useEffect} from 'react';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';
import DeviceIcon from '../../../components/DeviceIcon/DeviceIcon';
import {StickyPageLayout} from '../../../components/Layout';
import {AD_ENTRY_POINT_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import amplitudeADTracking from '../../../services/amplitude/amplitudeADTracking';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import appRouterService from '../../../services/route/appRouterService';
import routerService from '../../../services/route/routerService';
import PageWarningBannersContainer from '../../WarningBanners/PageWarningBannersContainer';
import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';

const AdvancedDiagnosticEntryPoint = () => {
    const localizedStrings = getLocalizedStrings();

    const onDiagnosticStart = () => {
        appRouterService.forwardToDamageCheckAD();
    };

    useEffect(() => {
        amplitudeADTracking.trackADEntryPointScreen();
    }, []);

    return (
        <StickyPageLayout
            className='items-center'
            headerOptions={{
                onBack: routerService.goBack,
                title: localizedStrings[AD_ENTRY_POINT_HEADER_TITLE],
            }}
            contentClassName='flex flex-1 flex-col items-center'
            footer={
                <AppButton primary light onClick={onDiagnosticStart} className='m-auto'>
                    <AemComponent component={EditableText} pageName='ad' itemName='entrypointbutton' />
                </AppButton>
            }
        >
            <AdvancedDiagnosticContainer>
                <DeviceIcon mode='diagnostics' className='h-16 w-16' />
                <AdvancedDiagnosticTitle itemName='entrypointtitle' />
                <AdvancedDiagnosticDescription itemName='entrypointdesc' />
            </AdvancedDiagnosticContainer>
            <PageWarningBannersContainer />
        </StickyPageLayout>
    );
};

export default AdvancedDiagnosticEntryPoint;
