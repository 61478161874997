import React from 'react';

import {twx} from '../../utils/tailwindUtils';
import {CancelIcon} from '../Icons';

const CloseButton = (props) => {
    const {onClick, className} = props;

    return (
        <button className={twx('cursor-pointer p-2.5 text-primary', className)} onClick={onClick}>
            <CancelIcon />
        </button>
    );
};

export default CloseButton;
