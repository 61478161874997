import React from 'react';

import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import HomePageForYouContainer from './HomePageForYouContainer/HomePageForYouContainer';

const HomePageCampaignItem = (props) => {
    const {banner, isDarkBannerText, promotedBadgeType, defaultImage} = props;
    const {titleKey, promotionKey, imagePath, videoPath, isGradient, amplitudeTrackingValue, action} = banner;
    const localizedStrings = getLocalizedStrings();
    const PROMOTED_BADGE_ROMANCE = 'romance';

    return (
        <HomePageForYouContainer
            className='bg-tertiary'
            videoClassName='absolute bottom-0 h-auto'
            imagePath={imagePath}
            videoPath={videoPath}
            defaultImage={defaultImage}
            externalLink={action}
            isDarkText={isDarkBannerText}
            isSmallTitle
            titleText={localizedStrings[titleKey]}
            badgeText={localizedStrings[promotionKey]}
            badgeClassName={promotedBadgeType === PROMOTED_BADGE_ROMANCE && 'bg-romance'}
            amplitudeTrackingValue={amplitudeTrackingValue}
            linearGradient={isGradient}
        />
    );
};

export default HomePageCampaignItem;
