import React from 'react';
import {useSelector} from 'react-redux';

import {StickyHeader} from '../../../components/Layout';
import appConfig from '../../../config/appConfig';
import {AD_CLEANING_CONTACTS_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {selectDeviceType} from '../../../state/selectors/device';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import AdvancedDiagnosticCleaningContactsCarousel from './AdvancedDiagnosticCleaningContactsCarousel';

const AdvancedDiagnosticCleaningContacts = () => {
    const deviceType = useSelector(selectDeviceType);
    const diagnosticData = appConfig.getAdConfig(deviceType, 'cleaningGuide');
    const localizedStrings = getLocalizedStrings();

    return (
        <div className='w-full pb-4 mt-safe-or-14'>
            <StickyHeader title={localizedStrings[AD_CLEANING_CONTACTS_HEADER_TITLE]} />
            <AdvancedDiagnosticTitle itemName='cleaningcontactstitle' />
            <AdvancedDiagnosticCleaningContactsCarousel carouselData={diagnosticData} deviceType={deviceType} />
        </div>
    );
};

export default AdvancedDiagnosticCleaningContacts;
