import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import log from '../services/logger/log';
import serviceWorkerService from '../services/serviceWorkerService';
import {setSwMessage} from '../state/slices/globalSlice';

const useSWMessage = () => {
    const dispatch = useDispatch();
    const subscribeOnMessage = async () => {
        try {
            await serviceWorkerService.registerServiceWorker();

            navigator.serviceWorker.addEventListener('message', (event) => {
                log.debug(`The service worker sent me a message: ${event.data}`);
                try {
                    const data = JSON.parse(event.data);

                    dispatch(setSwMessage(data));
                } catch (error) {
                    log.error(`On service worker message error: ${JSON.stringify(error)}`);
                }
            });

            navigator.serviceWorker.startMessages();
            serviceWorkerService.postMessage({type: 'OPENED'});

            log.debug('On service worker post message subscribed');
        } catch (error) {
            log.debug(`useSWMessage, error: ${JSON.stringify(error)}`);
        }
    };

    useEffect(() => {
        subscribeOnMessage();
    }, []);
};

export default useSWMessage;
