import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import ContentList from '../../../components/ContentList/ContentList';
import {MaxWidthContainer} from '../../../components/Layout';
import appConfig from '../../../config/appConfig';

const BenefitsSection = ({className}) => {
    const list = appConfig.getQuickOrderBenefitsIcons();

    return (
        <article className={className}>
            <AemEditableText
                pageName='quick-order'
                itemName='benefitsTitle'
                render={(title) => (title ? <h2 className='mb-0 text-24'>{title}</h2> : null)}
            />
            <MaxWidthContainer className='mt-4 flex-1 first:mt-0'>
                <ContentList list={list} />
            </MaxWidthContainer>
        </article>
    );
};

export default BenefitsSection;
