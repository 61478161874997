import React from 'react';

import Icon from '../Icon/Icon';

const DamageIcon = (props) => {
    return (
        <Icon width='64' height='64' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    stroke='#34303D'
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m20.688 53-10.46-2.655 6.492-3.083L9.312 37l13.157 3M38.64 31.92l7.213-4.718-1.147 10.275 7.118-.295-2.087 7.644 5.695 2.906-10.023 4.47'
                />
                <g transform='rotate(-25 41.82 -14.695)'>
                    <rect fill='#00D1D2' x='12.338' y='2.742' width='5.484' height='26.504' rx='1' />
                    <path
                        d='M5.808 42.618a4.237 4.237 0 0 1-3.005-1.244 4.237 4.237 0 0 1-1.245-3.006V5.5c0-1.174.476-2.236 1.245-3.005A4.237 4.237 0 0 1 5.808 1.25h6.025c1.864 0 3.551.756 4.773 1.977A6.729 6.729 0 0 1 18.583 8v30.868c0 1.036-.42 1.974-1.098 2.652a3.738 3.738 0 0 1-2.652 1.098z'
                        stroke='#34303D'
                        strokeWidth='2.5'
                    />
                    <path
                        d='M13.813 42.645a2.742 2.742 0 0 1-1.475-2.432V4.57a2.742 2.742 0 0 1 2.742-2.742'
                        stroke='#34303D'
                        strokeWidth='2.5'
                    />
                    <path stroke='#34303D' strokeWidth='2.5' strokeLinecap='square' d='M12.795 28.789h5.484' />
                </g>
            </g>
        </Icon>
    );
};

export default DamageIcon;
