import React from 'react';

import Icon from '../Icon/Icon';

const CommentIcon = (props) => {
    return (
        <Icon width='49' height='48' fill='none' {...props}>
            <mask
                id='mask0_2950_554'
                style={{maskType: 'alpha'}}
                maskUnits='userSpaceOnUse'
                x='0'
                y='0'
                width='49'
                height='48'
            >
                <path d='M48.5 0H0.5V48H48.5V0Z' fill='white' />
            </mask>
            <g mask='url(#mask0_2950_554)'>
                <path
                    d='M34.3438 15.2635H14.6562C12.1192 15.2635 10.0625 17.3202 10.0625 19.8572C10.0625 22.3943 12.1192 24.451 14.6562 24.451H34.3438C36.8808 24.451 38.9375 22.3943 38.9375 19.8572C38.9375 17.3202 36.8808 15.2635 34.3438 15.2635Z'
                    fill='#00D1D2'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M38.6342 8C40.2911 8 41.6342 9.34315 41.6342 11V28.8844C41.6342 30.5412 40.2911 31.8844 38.6342 31.8844L36.3388 31.884L36.1778 40.1654L27.1688 31.884L10.3658 31.8844C8.70893 31.8844 7.36578 30.5412 7.36578 28.8844V11C7.36578 9.34315 8.70893 8 10.3658 8H38.6342Z'
                    stroke='#34303D'
                    strokeWidth='2'
                    strokeLinejoin='round'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M31.9094 21.5304C32.8046 21.5304 33.5302 20.7993 33.5302 19.8974C33.5302 18.9956 32.8046 18.2645 31.9094 18.2645C31.0143 18.2645 30.2886 18.9956 30.2886 19.8974C30.2886 20.7993 31.0143 21.5304 31.9094 21.5304ZM24.2685 21.5304C25.1636 21.5304 25.8893 20.7993 25.8893 19.8974C25.8893 18.9956 25.1636 18.2645 24.2685 18.2645C23.3733 18.2645 22.6477 18.9956 22.6477 19.8974C22.6477 20.7993 23.3733 21.5304 24.2685 21.5304ZM16.778 21.5304C17.6284 21.5304 18.3178 20.7993 18.3178 19.8974C18.3178 18.9956 17.6284 18.2645 16.778 18.2645C15.9277 18.2645 15.2383 18.9956 15.2383 19.8974C15.2383 20.7993 15.9277 21.5304 16.778 21.5304Z'
                    fill='#34303D'
                />
            </g>
        </Icon>
    );
};

export default CommentIcon;
