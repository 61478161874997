export const EXTERNAL_SYSTEM_UNAVAILABLE = 'EXTERNAL_SYSTEM_UNAVAILABLE';
export const DEVICE_ALREADY_OWNED = 'DEVICE_ALREADY_OWNED';
export const NOT_FOUND = 'NOT_FOUND';
export const INVALID_CODENTIFYID = 'INVALID_CODENTIFYID';
export const DEVICE_REMOVED_FROM_MASTER_DB = 'DEVICE_REMOVED_FROM_MASTER_DB';
export const INVALID_DEVICE_STATUS = 'INVALID_DEVICE_STATUS';
export const NOT_FOUND_404 = '404_NOT_FOUND';
export const PURCHASE_DATE_INVALID = 'PURCHASE_DATE_INVALID';
export const DEVICE_ALREADY_YOURS = 'DEVICE_ALREADY_YOURS';
export const UNKNOWN_EXTERNAL_ERROR = 'UNKNOWN_EXTERNAL_ERROR';
