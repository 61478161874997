import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    assetIdList: [],
};

export const slice = createSlice({
    name: 'uam',
    initialState,
    reducers: {
        setAssetIdList: (state, action) => {
            const {codentify, assetId, uamApiKey, uamAuthToken, uamApiUrl} = action.payload;
            const {assetIdList} = state;
            const assetIdListNew = assetIdList.filter((x) => x.assetId !== assetId);

            assetIdListNew.push({codentify, assetId, uamApiKey, uamApiUrl, uamAuthToken});

            return {...state, assetIdList: [...assetIdListNew]};
        },
    },
});

export const {setAssetIdList} = slice.actions;

export default slice.reducer;
