export const IMAGE_VIEW_TYPES = {
    HI_RES: 'hi-res',
    LARGE: 'large',
    MEDIUM: 'medium',
    SMALL: 'small',
};

export const getImageByViewType = (images, viewType) => {
    if (!images?.length) return;

    const result = images.filter((image) => image.viewType?.toLowerCase() === viewType);

    return result[0];
};
