import React, {useEffect} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import amplitudeInboxTracking from '../../../services/amplitude/amplitudeInboxTracking';
import homePageNavigationService from '../../../services/homePageNavigationService';
import inboxMessageService from './inboxMessageService';
import InboxView from './InboxView';
import useInboxMessages from './useInboxMessages';

const InboxViewPage = () => {
    const {inboxMessages, loadMore, isLoading, hasNextPage} = useInboxMessages();

    const onDeleteMessage = (messageId) => {
        inboxMessageService.deleteInboxMessage(messageId);
    };

    const onCloseClick = () => {
        homePageNavigationService.forwardToSourcePage();
        inboxMessageService.deleteInboxMessages();
    };

    const [sentryRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage,
        onLoadMore: loadMore,
    });

    useEffect(() => {
        amplitudeInboxTracking.trackInboxScreen();
    }, []);

    return (
        <InboxView
            notifications={inboxMessages}
            sentryRef={sentryRef}
            hasNextPage={hasNextPage}
            onCloseClick={onCloseClick}
            onDeleteMessage={onDeleteMessage}
        />
    );
};

export default InboxViewPage;
