const AMPLITUDE_EVENT_PROPERTIES = {
    ACTION_VALUE: 'action_value',
    AUTODELIVERY_STATUS: 'autodelivery_status',
    BUTTON_TYPE: 'button_type',
    CAMPAIGN_NAME: 'campaign_name',
    IQOS_PRODUCT_DEVICE_ID: 'iqos_product_device_id',
    IQOS_PRODUCT_MODEL_TYPE: 'iqos_product_model_type',
    LEGAL: 'legal',
    MESSAGE: 'message',
    PUSH_SOURCE: 'push_source',
    QUESTIONNAIRE_PAGE: 'Questionnaire_page',
    SOURCE: 'source',
    SUCCESS: 'success',
    TRACKING_VALUE: 'tracking_value',
    ORDER_SUMMARY: 'order_summary',
};

export default AMPLITUDE_EVENT_PROPERTIES;
