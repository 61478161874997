import {selectLanguageCode} from '../../state/selectors/global';
import {getState} from '../../state/store';
import aemClientService from '../aem/aemClientService';
import defaultLocalizations from './default.json';
import languageDataService from './languageDataService';
import LocalizedStrings from './LocalizedStrings';

let localizedStrings;

const initLocalizations = async (marketName, languageCode) => {
    const localization = await aemClientService.getLocalization(marketName, languageCode);
    const localizationsData = {
        default: defaultLocalizations,
        [languageCode]: localization,
    };

    localizedStrings = new LocalizedStrings(localizationsData, {logsEnabled: false});

    if (languageCode) {
        languageDataService.setLanguageCodeToStores(languageCode);
        localizedStrings.setLanguage(languageCode);
    }
};

const getLocalizedLanguage = () => {
    return localizedStrings?.getLanguage();
};

const getLocalizedStrings = () => localizedStrings || {};

const initDefaultLocalizations = (languageCode) => {
    const localizationsData = {
        default: defaultLocalizations,
    };

    localizedStrings = new LocalizedStrings(localizationsData, {logsEnabled: false});

    localizedStrings.setLanguage(languageCode);
};

const getMappedInitialLanguageCode = () => {
    // TODO: Add mapping if needed
    const languageCode = selectLanguageCode(getState());

    return languageCode;
};

export {
    getLocalizedLanguage,
    getLocalizedStrings,
    getMappedInitialLanguageCode,
    initDefaultLocalizations,
    initLocalizations,
};
