import {useSelector} from 'react-redux';

import appConfig from '../config/appConfig';
import languageDataService from '../services/localization/languageDataService';
import {selectLanguageCode} from '../state/selectors/global';

const useLanguageName = () => {
    const marketLanguages = appConfig.getMarketLanguages();
    const languageCode = useSelector(selectLanguageCode);
    const marketLanguage = languageDataService.getMarketLanguageByLanguageCode(marketLanguages, languageCode);
    const {languageTitle} = marketLanguage || {};

    return languageTitle || '';
};

export default useLanguageName;
