import React from 'react';

import Icon from '../Icon/Icon';

const OtherDeviceBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g transform='translate(10.875 6)'>
                    <rect fill='#FFF' x='19.082' y='2.234' width='4.468' height='21.596' rx='1' />
                    <path
                        d='M2 34.745a.997.997 0 0 1-1-1V7.687a.997.997 0 0 1 1-1h5.16c.821 0 1.566.333 2.104.872.54.538.872 1.283.872 2.105v20.91c0 1.571.486 2.965 1.483 4.17z'
                        stroke='#34303D'
                        strokeWidth='2'
                    />
                    <path
                        d='M14.78 34.745a4.486 4.486 0 0 1-3.182-1.318 4.486 4.486 0 0 1-1.318-3.182V5.5c0-1.243.504-2.368 1.318-3.182A4.486 4.486 0 0 1 14.78 1h2.455c1.92 0 3.659.778 4.917 2.037a6.933 6.933 0 0 1 2.037 4.918v22.79a3.987 3.987 0 0 1-1.171 2.828 3.987 3.987 0 0 1-2.829 1.172zM.838 14.149h9.681'
                        stroke='#34303D'
                        strokeWidth='2'
                    />
                    <circle fill='#FFF' cx='5.492' cy='10.612' r='1' />
                    <path
                        d='M20.264 34.7a2.196 2.196 0 0 1-1.182-1.949V4.197c0-1.214.984-2.197 2.197-2.197'
                        stroke='#34303D'
                        strokeWidth='2'
                    />
                    <path stroke='#34303D' strokeWidth='2' strokeLinecap='square' d='M19.455 23.457h4.468' />
                </g>
                <g transform='translate(30 21.31)' stroke='#34303D' strokeWidth='1.5'>
                    <circle fill='#00D1D2' cx='6.761' cy='6.761' r='6.011' />
                    <path strokeLinecap='round' strokeLinejoin='round' d='m4.26 7.1 1.414 1.48L9.26 4.896' />
                </g>
            </g>
        </Icon>
    );
};

export default OtherDeviceBlueIcon;
