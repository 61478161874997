import {createSelector} from 'reselect';

const selectUam = (state) => state.uam;

const makeSelectUamData = (codentify) =>
    createSelector(selectUam, (uamState) => {
        return uamState.assetIdList.find((x) => x.codentify === codentify);
    });

export {makeSelectUamData};
