import {useSelector} from 'react-redux';

import marketService from '../../services/marketService';
import * as pathService from '../../services/route/pathService';
import {selectLanguageCode} from '../../state/selectors/global';

const useAemPath = (pageName, itemName) => {
    const languageCode = useSelector(selectLanguageCode);
    const marketCode = marketService.getMarketCode();
    const aemPath = pathService.getFullAemPath(marketCode, languageCode);

    return {
        pagePath: `${aemPath}/${pageName.toLowerCase()}`,
        itemPath: `root/responsivegrid/${itemName.toLowerCase()}`,
    };
};

export default useAemPath;
