import React from 'react';

import Icon from '../Icon/Icon';

const LocationIcon = (props) => {
    return (
        <Icon width='49' height='48' fill='none' {...props}>
            <path
                d='M24.7493 8C30.5137 8 35.2008 12.7145 35.2008 18.5477C35.2008 20.1958 34.5355 22.4356 33.0626 25.32C31.6001 28.1842 29.3891 31.5932 26.4113 35.5531C26.2939 35.7092 26.1552 35.848 25.9991 35.9653C25.0812 36.6556 23.7776 36.471 23.0874 35.5531L22.2882 36.1542L23.0874 35.5531C20.1096 31.5931 17.8986 28.1842 16.436 25.32C14.9632 22.4356 14.2979 20.1958 14.2979 18.5477C14.2979 12.7145 18.985 8 24.7493 8Z'
                stroke='#34303D'
                strokeWidth='2'
            />
            <circle cx='24.6915' cy='18.0138' r='3.0383' stroke='#34303D' strokeWidth='2' />
            <path
                d='M33.7 34.1277C33.7491 34.1277 33.796 34.1476 33.8301 34.1829L39.2918 39.8446C39.4305 39.9884 39.4264 40.2174 39.2826 40.3561C39.2152 40.4211 39.1251 40.4574 39.0315 40.4574H10.3527C10.153 40.4574 9.99103 40.2955 9.99103 40.0957C9.99103 40.0019 10.0275 39.9118 10.0927 39.8443L15.5658 34.1828C15.5999 34.1476 15.6468 34.1277 15.6958 34.1277'
                stroke='#00D1D2'
                strokeWidth='2'
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default LocationIcon;
