import {selectLanguageCode} from '../state/selectors/global';
import {getState} from '../state/store';
import dateTimeUtils from '../utils/dateTimeUtils';

const getLocalizedDayAndShortMonth = (date) => {
    const languageCode = selectLanguageCode(getState());
    const result = dateTimeUtils.getDayAndShortMonth(new Date(date), languageCode);

    return result;
};

export default {
    getLocalizedDayAndShortMonth,
};
