import React from 'react';

import {useGetInboxStatus} from '../../api/iccNotificationApi';
import {ButtonRaw} from '../../components/Button';
import {AlertIcon} from '../../components/Icons';
import appRouterService from '../../services/route/appRouterService';

const InboxIcon = () => {
    const {data} = useGetInboxStatus();
    const {isUnreadExists} = data || {};

    const onInboxClick = () => {
        appRouterService.forwardToInboxPage();
    };

    return (
        <ButtonRaw onClick={onInboxClick} className='relative'>
            <AlertIcon className='h-6 w-6' />
            {isUnreadExists && <div className='absolute right-0 top-0 h-1.5 w-1.5 rounded-full bg-bright-sun' />}
        </ButtonRaw>
    );
};

export default InboxIcon;
