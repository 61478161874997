import InfoBox from '../../../../components/InfoBox/InfoBox';
import AutoDeliveryStatusTitle from '../../../../views/AutoDelivery/AutoDeliveryStatusTitle';

const EditDeliveryPopupTopSection = ({header, title, subtitle, infoText, icon}) => {
    return (
        <div className='pb-8'>
            <AutoDeliveryStatusTitle title={header} className='text-28' />
            <div className='mt-8 flex items-center gap-2'>
                <div className='shrink-0'>{icon}</div>
                <div>
                    <p className='text-jumbo'>{subtitle}</p>
                    <h4 className='mb-0 text-20 text-primary'>{title}</h4>
                </div>
            </div>
            <InfoBox className='mt-4 tracking-02'>{infoText}</InfoBox>
        </div>
    );
};

export default EditDeliveryPopupTopSection;
