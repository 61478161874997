import dateTimeUtils from '../../utils/dateTimeUtils';
import iccService from '../icc/iccService';
import authDataStoreService from './authDataStoreService';

const getAuthToken = async (isRetry) => {
    const {accessToken, expirationDate, isAnonymous} = authDataStoreService.getAccessTokenData();

    try {
        if (accessToken) {
            const isAuthTokenExpired = dateTimeUtils.isExpired(expirationDate);

            if (isAuthTokenExpired) {
                if (isAnonymous) {
                    await iccService.fetchIccToken(isAnonymous);
                }

                const {accessToken} = isAnonymous
                    ? await authDataStoreService.getAccessTokenData()
                    : await iccService.refreshIccToken();

                return accessToken;
            } else {
                return accessToken;
            }
        } else if (!isRetry) {
            await iccService.fetchIccToken({isAnonymous: !accessToken});

            return await getAuthToken(true);
        }
    } catch (e) {
        return Promise.reject(`getAuthToken error: ${e}`);
    }

    return Promise.reject(`getAuthToken error: return undefined`);
};

export default {
    getAuthToken,
};
