import cn from 'classnames';

import {useGetAutoDeliveryDetails} from '../../../../../../api/consumerApi';
import SectionLoader from '../../../../../../components/Loader/AppLoader/SectionLoader';

const AutoDeliveryWidgetContainer = ({className, children}) => {
    const {isFetching} = useGetAutoDeliveryDetails();

    return (
        <section className={cn('relative bg-romance px-4 py-8 text-start', className)}>
            {children}
            {isFetching && <SectionLoader />}
        </section>
    );
};

export default AutoDeliveryWidgetContainer;
