import React, {useEffect} from 'react';

import Banner from '../../../../components/Banner/Banner';
import {AppButton} from '../../../../components/Button';
import CloseButton from '../../../../components/CloseButton/CloseButton';
import {StickyHeader} from '../../../../components/Layout';
import PromotedBadge from '../../../../components/PromotedBadge/PromotedBadge';
import useConfigExternalLink from '../../../../hooks/useConfigExternalLink';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import routerService from '../../../../services/route/routerService';
import helpers from '../../../../utils/helpers';
import PageWarningBannersContainer from '../../../WarningBanners/PageWarningBannersContainer';
import AutoDeliveryBenefits from './AutoDeliveryBenefits';
import HowItWorksSection from './HowItWorksSection';

const AutoDeliveryDetailsStatusNoneTemplate = ({
    banner,
    headerKey,
    benefitsList,
    howItWorksList,
    amplitudeEvent,
    benefitsItemName,
    howItWorksItemName,
}) => {
    const onClose = () => {
        routerService.goBack();
    };

    const {imagePath, videoPath, isGradient, titleKey, subTitleKey, descriptionKey, buttonActionKey, action} =
        banner || {};
    const localizedStrings = getLocalizedStrings();
    const redirectToExternalLink = useConfigExternalLink(action);
    const isShowButton = buttonActionKey && localizedStrings[buttonActionKey] && redirectToExternalLink;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onButtonClick = () => {
        helpers.runFunction(amplitudeEvent);
        redirectToExternalLink();
    };

    return (
        <div className='ica-page w-full overflow-hidden text-start'>
            <StickyHeader title={localizedStrings[headerKey]} endIcon={<CloseButton onClick={onClose} />} />
            <Banner
                className='h-[422px] bg-tertiary'
                imagePath={imagePath}
                videoPath={videoPath}
                isGradient={isGradient}
                gradient='linear-gradient(180deg, rgba(52, 48, 61, 0) 39.99%, #1C1A21 100%)'
            >
                <div className='absolute bottom-8 w-full px-4 text-start text-white'>
                    <div className='mb-2 flex'>
                        <PromotedBadge
                            className='max-w-full whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                            badgeText={localizedStrings[subTitleKey]}
                        />
                    </div>
                    <h1 className='mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-24 leading-[40px] lg:text-32'>
                        {localizedStrings[titleKey]}
                    </h1>
                    <p
                        className='line-clamp-3 whitespace-pre-line text-14'
                        dangerouslySetInnerHTML={{__html: localizedStrings[descriptionKey]}}
                    />
                    {isShowButton && (
                        <AppButton
                            primary
                            dark
                            className='mt-4 whitespace-nowrap [&>*]:overflow-hidden [&>*]:text-ellipsis'
                            onClick={onButtonClick}
                        >
                            {localizedStrings[buttonActionKey]}
                        </AppButton>
                    )}
                </div>
            </Banner>
            <AutoDeliveryBenefits
                benefitsList={benefitsList}
                itemName={benefitsItemName}
                className='hidden px-4 py-8 has-[h2,li_*]:block'
            />
            <HowItWorksSection
                howItWorksList={howItWorksList}
                itemName={howItWorksItemName}
                className='hidden border-t border-mischka first:border-0 has-[h2,li_*]:block'
            />
            <PageWarningBannersContainer />
        </div>
    );
};

export default AutoDeliveryDetailsStatusNoneTemplate;
