import appConfig from '../../../../../config/appConfig';

let iconsSorted;

const useLoyaltyButtonsBar = () => {
    const isLoyaltyEnabled = appConfig.getIsLoyaltyEnabled();
    const isLoyaltyApiEnabled = appConfig.getIsLoyaltyApiEnabled();
    const isLoyaltyButtonsBarEnabled = appConfig.getIsLoyaltyButtonsBarEnabled();

    if (!isLoyaltyEnabled || !isLoyaltyApiEnabled || !isLoyaltyButtonsBarEnabled) return null;

    if (iconsSorted) return iconsSorted;

    const iconsFromConfig = appConfig.getLoyaltyButtonsBar();
    const iconsWithAction = iconsFromConfig?.filter((i) => i?.action);

    if (!iconsWithAction?.length) return null;

    const iconsWithOrder = iconsWithAction.filter((i) => i?.order != null).sort((a, b) => a?.order - b?.order);
    const iconsWithoutOrder = iconsWithAction.filter((i) => i?.order == null);

    iconsSorted = [...iconsWithOrder, ...iconsWithoutOrder].slice(0, 3);

    return iconsSorted;
};

export default useLoyaltyButtonsBar;
