import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';
import AMPLITUDE_EVENT_VALUES from '../../consts/amplitude/amplitudeEventValues';
import amplitude from './amplitude';

const trackConsumerProfileScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.PROFILE_SCREEN_VIEWED);
};

const trackPreferenceCenterScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.PREFERENCE_CENTER_VIEWED);
};

const trackUpdateYourDataScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.PREFERENCE_CENTER_UPDATE_VIEWED);
};

const trackContactUsScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.CONTACT_US_VIEWED);
};

const trackShowProfileClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.SHOW_PROFILE_CLICKED);
};

const trackTermsOfUseClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.LEGAL_VIEWED, {
        [AMPLITUDE_EVENT_PROPERTIES.LEGAL]: AMPLITUDE_EVENT_VALUES.TERMS_OF_USE,
    });
};

const trackPrivacyNoticeClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.LEGAL_VIEWED, {
        [AMPLITUDE_EVENT_PROPERTIES.LEGAL]: AMPLITUDE_EVENT_VALUES.PRIVACY_NOTICE,
    });
};

const trackAttributionNoticeClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.LEGAL_VIEWED, {
        [AMPLITUDE_EVENT_PROPERTIES.LEGAL]: AMPLITUDE_EVENT_VALUES.ATTRIBUTION_NOTICE,
    });
};

const trackLogOutClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.LOG_OUT);
};

export default {
    trackConsumerProfileScreen,
    trackPreferenceCenterScreen,
    trackUpdateYourDataScreen,
    trackContactUsScreen,
    trackShowProfileClick,
    trackTermsOfUseClick,
    trackPrivacyNoticeClick,
    trackAttributionNoticeClick,
    trackLogOutClick,
};
