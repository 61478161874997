import {useState} from 'react';

import appConfig from '../../../../../config/appConfig';

const useLoginForm = (username, password) => {
    const [loginEmailRegex, loginPasswordRegex] = [appConfig.getLoginEmailRegex(), appConfig.getLoginPasswordRegex()];

    const [isValidCredentials, setIsValidCredentials] = useState({
        isUsernameValid: true,
        isPasswordValid: true,
    });

    const {isUsernameValid, isPasswordValid} = isValidCredentials || {};

    const [isCredentialsEntered, setIsCredentialsEntered] = useState({
        isUsernameEntered: true,
        isPasswordEntered: true,
    });
    const {isUsernameEntered, isPasswordEntered} = isCredentialsEntered || {};

    const validateCredentials = () => {
        const isValidUsername = username.match(loginEmailRegex);
        const isValidPassword = password.match(loginPasswordRegex);
        const isUsernameExist = username;
        const isPasswordExist = password;

        setIsValidCredentials({isUsernameValid: isValidUsername, isPasswordValid: isValidPassword});

        setIsCredentialsEntered({isUsernameEntered: isUsernameExist, isPasswordEntered: isPasswordExist});

        return isValidUsername && isValidPassword && isUsernameExist && isPasswordExist;
    };

    return {isUsernameValid, isPasswordValid, isUsernameEntered, isPasswordEntered, validateCredentials};
};

export default useLoginForm;
