import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import AppButton from '../../../components/Button/AppButton/AppButton';
import {TncIcon} from '../../../components/Icons';
import {StickyPageLayout} from '../../../components/Layout';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import * as tncTypes from '../../../consts/tnc/tncTypes';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import tncService from '../../../services/tncService';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../state/selectors/consumer';
import {hideLoader, showLoader} from '../../../state/slices/loadersSlice';
import {setNotificationFailed} from '../../../state/slices/notificationsSlice';
import {dispatch} from '../../../state/store';
import PageWarningBannersContainer from '../../WarningBanners/PageWarningBannersContainer';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';

const mapStateToProps = createStructuredSelector({
    termsOfUse: makeSelectTncByType(tncTypes.TERMS_OF_USE),
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.TERMS_OF_USE),
});

const TermsOfUsePage = (props) => {
    const {termsOfUse, acceptedTermsOfUse} = props;

    if (!termsOfUse) return null;

    const {description, legals, updateDescription} = termsOfUse;

    if (!legals) return null;

    const onAcceptButtonClick = async () => {
        dispatch(showLoader());

        await tncService.setTnc(legals);

        dispatch(hideLoader());
    };

    const localizedStrings = getLocalizedStrings();

    const onDeclineButtonClick = () => {
        dispatch(setNotificationFailed(localizedStrings[localizationKeys.TERMS_OF_USE_DECLINED_ERROR]));
    };

    const isUpdatedTnc = acceptedTermsOfUse != null;
    const descriptionText = isUpdatedTnc ? updateDescription : description;

    return (
        <StickyPageLayout
            className='items-center'
            headerOptions={{
                title: localizedStrings[localizationKeys.TERMS_OF_USE_HEADER_TITLE],
            }}
            contentClassName='flex flex-1 flex-col items-center pt-safe-offset-14'
            footer={
                <div className='flex flex-col gap-6'>
                    <AppButton className='mt-0' primary light onClick={onAcceptButtonClick}>
                        <AemEditableText pageName='tnc' itemName='termsOfUseAgree' />
                    </AppButton>
                    <AppButton className='mt-0' secondary light onClick={onDeclineButtonClick}>
                        <AemEditableText pageName='tnc' itemName='termsOfUseDecline' />
                    </AppButton>
                </div>
            }
        >
            <div className='mb-4 flex flex-1 flex-col items-center px-4'>
                <TncIcon />
                <h1 className='mt-9 text-28'>
                    {isUpdatedTnc && <AemEditableText pageName='tnc' itemName='termsOfUseTitleUpdate' />}
                    {!isUpdatedTnc && <AemEditableText pageName='tnc' itemName='termsOfUseTitle' />}
                </h1>
                <TermsOfUseDescription className={'mt-4'} innerHtml={descriptionText} tncType={tncTypes.TERMS_OF_USE} />
            </div>
            <PageWarningBannersContainer />
        </StickyPageLayout>
    );
};

export default connect(mapStateToProps)(TermsOfUsePage);
