const onTouchStartEvent = 'ontouchstart';

const isTouchSupported = () => {
    return onTouchStartEvent in window || navigator.maxTouchPoints || navigator.msMaxTouchPoints;
};

const isIOSDevice = () => navigator.userAgent.match(/iOS/i);

export default {
    isTouchSupported,
    isIOSDevice,
};
