import UAParser from 'ua-parser-js';

const getBrowserName = () => {
    const uaParser = new UAParser();

    return uaParser.getBrowser().name;
};

const getOS = () => {
    const uaParser = new UAParser();

    return uaParser.getOS();
};

const getOSShortName = () => {
    const {name} = getOS();

    return name?.toLowerCase().substr(0, 3);
};

const getIsIos = () => {
    const {name} = getOS();

    return name?.toLowerCase() === 'ios';
};

export default {
    getBrowserName,
    getOS,
    getOSShortName,
    getIsIos,
};
