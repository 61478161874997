import LayoutTypes from '../consts/layout/layoutTypes';

const isDesktopLayout = (layout) => layout === LayoutTypes.DESKTOP;
const isSmLayout = (layout) => layout === LayoutTypes.SM;
const isMobileLayout = (layout) => isSmLayout(layout) || layout === LayoutTypes.MOBILE;
const isTabletLayout = (layout) => layout === LayoutTypes.TABLET;

export default {
    isDesktopLayout,
    isMobileLayout,
    isSmLayout,
    isTabletLayout,
};
