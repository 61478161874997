import appConfig from '../../config/appConfig';
import {ICC_INBOX_MESSAGE_STATUS_TYPES} from '../../consts/icc/iccInboxMessageStatusTypes';
import * as iccMethods from '../../consts/icc/iccMethods';
import {deleteInboxMessageAction, readInboxMessageAction, setInboxMessagesAction} from '../../state/slices/inboxSlice';
import stringUtils from '../../utils/stringUtils';
import urlUtils from '../../utils/urlUtils';
import server from '../server/server';
import iccMarketService from './iccMarketService';

const PLATFORM_TYPE = 'WEB';

const getIccApiUrl = (methodName) => urlUtils.join(appConfig.getIccNotificationApiUrl(), methodName);

const pushSubscription = async (subscription) => {
    const data = {
        platform: PLATFORM_TYPE,
        details: {
            web: subscription,
            fcm: null,
        },
    };

    const {
        data: {subscriptionId},
    } = await iccMarketService.callRequest({
        httpMethod: server.post,
        methodUrl: getIccApiUrl(iccMethods.SUBSCRIPTION_PUSH_DATA),
        requestConfig: {data},
        throwException: true,
    });

    return subscriptionId;
};

const deleteSubscription = async (subscriptionId) => {
    const result = await iccMarketService.callRequest({
        httpMethod: server.requestDelete,
        methodUrl: urlUtils.join(getIccApiUrl(iccMethods.SUBSCRIPTION_PUSH_DATA), subscriptionId),
    });

    return result;
};

const getInboxMessages = async ({limit = 10, nextPageStartKey}) => {
    const result = await iccMarketService.callRequest({
        httpMethod: server.get,
        methodUrl: getIccApiUrl(iccMethods.INBOX_MESSAGES),
        requestConfig: {params: {limit, pageStartKey: nextPageStartKey}},
        action: setInboxMessagesAction,
        throwException: true,
    });

    return result;
};

const getSpecificInboxMessage = async (messageId) => {
    const result = await iccMarketService.callRequest({
        httpMethod: server.get,
        methodUrl: getIccApiUrl(stringUtils.formatByName(iccMethods.INBOX_MESSAGE, {messageId})),
        throwException: true,
    });

    return result;
};

const deleteInboxMessage = async (messageId) => {
    const result = await iccMarketService.callRequest({
        httpMethod: server.requestDelete,
        methodUrl: getIccApiUrl(iccMethods.INBOX_MESSAGES),
        requestConfig: {data: {messageIds: [messageId]}},
        action: () => deleteInboxMessageAction(messageId),
        throwException: true,
    });

    return result;
};

const updateInboxMessageStatus = async ({status = ICC_INBOX_MESSAGE_STATUS_TYPES.READ, messageId}) => {
    const result = await iccMarketService.callRequest({
        httpMethod: server.post,
        methodUrl: getIccApiUrl(stringUtils.formatByName(iccMethods.INBOX_UPDATE_MESSAGE_STATUS, {messageId})),
        requestConfig: {data: {status}},
        action: () => readInboxMessageAction(messageId),
        throwException: true,
    });

    return result;
};

export default {
    pushSubscription,
    deleteSubscription,
    getInboxMessages,
    getSpecificInboxMessage,
    deleteInboxMessage,
    updateInboxMessageStatus,
};
