import {EditableComponent} from '@adobe/aem-react-editable-components';
import React from 'react';

import {NotificationsInbox} from './NotificationsInbox';

const EditableNotificationsInbox = ({pagePath, itemPath, ...rest}) => (
    <EditableComponent pagePath={pagePath} itemPath={itemPath}>
        <NotificationsInbox {...rest} />
    </EditableComponent>
);

export default EditableNotificationsInbox;
