import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../components/Button';
import appConfig from '../../../config/appConfig';
import amplitudeADTracking from '../../../services/amplitude/amplitudeADTracking';
import deviceTypesService from '../../../services/device/deviceTypesService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import appRouterService from '../../../services/route/appRouterService';
import rtlService from '../../../services/rtlService';
import SignedImage from '../../System/SignedImage';
import AdvancedDiagnosticCarousel from '../AdvancedDiagnosticCarousel/AdvancedDiagnosticCarousel';
import advanceDiagnosticService from '../advanceDiagnosticService';
import useRedirectToPhase2 from '../hooks/useRedirectToPhase2';
import ADVANCED_DIAGNOSTIC_DAMAGE_DATA from './advancedDiagnosticDamageData';

const AdvancedDiagnosticDamageCarousel = ({carouselData, deviceType}) => {
    const localizedStrings = getLocalizedStrings();
    const isRestrictedMarket = appConfig.getIsRestrictedMarket();
    const isP1V4 = deviceTypesService.isP1V4(deviceType);
    const redirectToPhase2 = useRedirectToPhase2({disabled: !isP1V4});
    const defaultImage = ADVANCED_DIAGNOSTIC_DAMAGE_DATA[deviceType]?.defaultImage;

    const onNextButtonClick = async () => {
        amplitudeADTracking.trackADNoneOfTheAboveClick();

        if (isP1V4) {
            redirectToPhase2();
        } else {
            appRouterService.forwardToSelectIssueAD();
        }
    };

    const onOptionButtonClick = (screenName, errorCode, isHolder, amplitudeQuestionnairePage) => {
        amplitudeADTracking.trackADQuestionnaireSelectClick(amplitudeQuestionnairePage);
        advanceDiagnosticService.setInferenceCodeAndRedirect(errorCode, isHolder, screenName);
    };

    const onAfterChange = () => {
        amplitudeADTracking.trackADQuestionnaireScroll();
    };

    return (
        <>
            <AdvancedDiagnosticCarousel carouselData={carouselData} onAfterChange={onAfterChange}>
                {carouselData.map((option, i) => {
                    const {amplitudeQuestionnairePage, image, title, description, nextStep, errorCode, isHolder} =
                        option;
                    const imagePath = appConfig.getCombinedPathAssetPath(image);

                    return (
                        <div
                            key={i}
                            dir={rtlService.getDir()}
                            className='flex h-full flex-col rounded bg-secondary text-primary md:w-[436px] lg:w-[597px]'
                        >
                            <SignedImage
                                imgClassName='h-[176px] md:h-[244px] lg:h-[335px] object-cover bg-center'
                                isThumbnailAvailable
                                mediaData={imagePath}
                                defaultImage={isRestrictedMarket ? undefined : defaultImage}
                                signIfNull
                            />

                            <div className='mx-auto mt-4 flex h-full max-w-[328px] flex-col justify-between px-6 py-7'>
                                <div>
                                    <h1 className='mb-3 text-20'>{localizedStrings[title]}</h1>
                                    <p>{localizedStrings[description]}</p>
                                </div>
                                <AppButton
                                    secondary
                                    light
                                    onClick={() =>
                                        onOptionButtonClick(nextStep, errorCode, isHolder, amplitudeQuestionnairePage)
                                    }
                                    className='mt-6 sm:min-w-[220px] lg:mt-8'
                                >
                                    <AemEditableText pageName='ad' itemName='damageselectbutton' />
                                </AppButton>
                            </div>
                        </div>
                    );
                })}
            </AdvancedDiagnosticCarousel>
            <div className='px-8'>
                <AppButton secondary light onClick={onNextButtonClick} className='mx-auto mt-6'>
                    <AemEditableText pageName='ad' itemName='damagenonebutton' />
                </AppButton>
            </div>
        </>
    );
};

export default AdvancedDiagnosticDamageCarousel;
