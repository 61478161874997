import 'swiper/css';

import React, {useRef} from 'react';
import {FreeMode} from 'swiper/modules';
import {Swiper} from 'swiper/react';

const SwiperCarousel = ({options, children}) => {
    const sliderRef = useRef(null);
    const builtinOptions = {
        modules: [FreeMode],
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 16,
        slidesOffsetAfter: 16,
        slidesOffsetBefore: 8,
        navigation: false,
        freeMode: {
            enabled: true,
        },
        mousewheel: false,
    };

    const mergedOptions = {...builtinOptions, ...options};

    return (
        <Swiper {...mergedOptions} ref={sliderRef}>
            {children}
        </Swiper>
    );
};

export default SwiperCarousel;
