import React from 'react';

import {getLocalizedStrings} from '../../../services/localization/localizationService';
import HwContainer from './HwContainer';

const HealthWarning = ({titleKey, className, isSeparatorVisible}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <HwContainer className={className} isSeparatorVisible={isSeparatorVisible}>
            <p className='m-8 bg-romance px-8 py-6 text-14 font-bold'>{localizedStrings[titleKey]}</p>
        </HwContainer>
    );
};

export default HealthWarning;
