import {createSlice} from '@reduxjs/toolkit';

import * as notificationTypes from '../../consts/app/notificationTypes';
import helpers from '../../utils/helpers';
export const slice = createSlice({
    name: 'notifications',
    initialState: [],
    reducers: {
        setNotification: (state, action) => {
            const {type, text, autoFocus, customId, isBottom} = action.payload;

            if (state.some((el) => el.text === text && el.isBottom === isBottom)) return state;

            state.push({
                notificationId: customId || helpers.guid(),
                type,
                text,
                autoFocus,
                isBottom,
            });

            return state;
        },
        removeNotification: (state, action) => {
            const notificationId = action.payload;

            state = state.filter((notification) => notification.notificationId !== notificationId);

            return state;
        },
    },
});

export const {setNotification, removeNotification} = slice.actions;

export const setNotificationSuccess = (text, autoFocus, customId) =>
    setNotification({text, type: notificationTypes.SUCCESS, autoFocus, customId});

export const setNotificationFailed = (text, autoFocus, customId) =>
    setNotification({text, type: notificationTypes.FAILED, customId});

export const setNotificationInfo = (text, autoFocus, customId) =>
    setNotification({text, type: notificationTypes.INFO, customId});

export const setNotificationInfoBottom = (text, autoFocus, customId) =>
    setNotification({text, type: notificationTypes.INFO, customId, isBottom: true});

export default slice.reducer;
