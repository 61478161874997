import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {CalendarIcon} from '../../../../components/Icons';
import useDeliveryDate from '../hooks/useDeliveryDate';
import DeliveryAsapInfo from './DeliveryAsapInfo';
import EditDeliveryPopupTopSection from './EditDeliveryPopupTopSection';

const DeliveryAsapSection = () => {
    const {days, asapDeliveryDate, asapDeliveryDay} = useDeliveryDate();

    return (
        <EditDeliveryPopupTopSection
            header={<AemEditableText pageName='auto-delivery' itemName='deliverAsapHeaderTitle' />}
            title={asapDeliveryDate}
            subtitle={
                <AemEditableText pageName='auto-delivery' itemName='deliverAsapHeaderSubTitle' contentList={[days]} />
            }
            infoText={<DeliveryAsapInfo />}
            icon={<CalendarIcon date={asapDeliveryDay} className='text-tertiary' />}
        />
    );
};

export default DeliveryAsapSection;
