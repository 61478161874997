import React from 'react';

import appRouterService from '../../../services/route/appRouterService';
import PreferenceCenterControl from '../PreferenceCenterPage/PreferenceCenterControl';

const PreferenceCenterProfilePage = () => {
    return (
        <PreferenceCenterControl
            backButtonClick={appRouterService.forwardToConsumerProfilePage}
            onApproved={appRouterService.forwardToConsumerProfilePage}
        />
    );
};

export default PreferenceCenterProfilePage;
