import React from 'react';

import AppImage from '../AppImage';
import Image from '../Image';
import Loader from './components/Loader/Loader';

const ProgressiveImage = (props) => {
    const {srcSet, placeholderSrc, isMediaUrlFetched, imgClassName, alt, defaultImageSrcSet, isLoader = false} = props;

    const loader = <Loader imgClassName={imgClassName} isLoader={isLoader} />;
    const unloader = <Image alt={alt} src={defaultImageSrcSet} className={imgClassName} />;

    const getImage = (isSigned, src, placeholder) =>
        isSigned ? (
            <AppImage
                alt={alt}
                className={imgClassName}
                src={src}
                loader={placeholder}
                unloader={unloader}
                defaultImageSrcSet={defaultImageSrcSet}
            />
        ) : (
            loader
        );

    const placeholderImage = getImage(isMediaUrlFetched, placeholderSrc, loader);

    return getImage(isMediaUrlFetched, srcSet, placeholderImage);
};

export default ProgressiveImage;
