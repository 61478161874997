import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import feedbackPopupService from '../services/feedbackPopupService';
import {selectConsumerId} from '../state/selectors/consumer';
import useOnVisibilityChange from './useOnVisibilityChange';

const useFeedbackPopup = () => {
    const consumerId = useSelector(selectConsumerId);

    useEffect(() => {
        if (consumerId) {
            feedbackPopupService.handleAppOpening();
        }
    }, [consumerId]);

    useOnVisibilityChange(feedbackPopupService.handleAppOpening);
};

export default useFeedbackPopup;
