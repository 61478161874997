import {getDeviceTypesData} from '../../consts/device/deviceTypesData';
import * as iccProductsStatusMessageTypes from '../../consts/icc/iccProductsStatusMessageTypes';
import {ICC_PRODUCT_STATUS_TYPES} from '../../consts/icc/iccProductStatusTypes';
import devicePlatformService from '../device/devicePlatformService';
import deviceTypesService from '../device/deviceTypesService';
import marketConfigService from '../marketConfigService';
import mediaService from '../mediaService';
import productHelpers from '../product/productHelpers';

export const aggregatedSettingsMapping = (data) => ({
    allMandatoryTermsApproved: data.AllMandatoryTermsApproved,
    allNonMandatoryTermsReviewed: data.AllNonMandatoryTermsReviewed,
    settingsRefreshTimeoutSec: data.SettingsRefreshTimeoutSec,
    consumerAggregatedSettings: data.ConsumerAggregatedSettings.map(consumerAggregatedSettingsMapping),
    intToken: data.IntToken,
});

const consumerAggregatedSettingsMapping = (data) => ({key: data.Key, value: data.Value?.toLowerCase() === 'true'});

export const tncMapping = (data) => data.map(tncItemMapper);

const tncItemMapper = (data) => ({
    description: data.Description,
    updateDescription: data.UpdateDescription,
    titleDescription: data.TitleDescription,
    groupName: data.GroupName,
    isMandatory: data.IsMandatory,
    legals: data.Legals.map(tncLegalMapper),
    position: data.Position,
    screenId: data.ScreenId,
});

const tncLegalMapper = (data) => ({
    changeList: data.ChangeList,
    code: data.Code,
    description: data.Description,
    externalTermAndConditionID: data.ExternalTermAndConditionID,
    isInformational: data.IsInformational,
    isMandatory: data.IsMandatory,
    isRevoked: data.IsRevoked,
    position: data.Position,
    settings: data.Settings,
    text: data.Text,
    title: data.Title,
    versionNumber: data.VersionNumber,
    webLink: data.WebLink,
    alternativeText: data.AlternativeText,
    updateTs: data.UpdateTs,
});

export const consumerMapping = (data) => ({
    consumerId: data.ConsumerID,
    defaultLocaleCode: data.DefaultLocaleCode,
});

export const externalConsumerMapping = (data) => ({
    firstName: data.Firstname,
    lastName: data.Lastname,
    consumerAggregatedAttributes: data.ConsumerAggregatedAttributes?.map(consumerAggregatedAttributeMapping),
});

const consumerAggregatedAttributeMapping = (data) => ({
    attributeCode: data.AttributeCode,
    value: data.Value,
});

export const consumerProductsMapping = (products) => {
    const mappedProducts = products.map((product) => consumerProductMapping(product));
    const notEmptyProducts = mappedProducts.filter((x) => x);

    return notEmptyProducts;
};

const consumerProductMapping = (data) => {
    const codentify = data.CodentifyID;
    const deviceSerialNumber = data.DeviceSerialNumber;

    if (!deviceSerialNumber) return null;

    const deviceType = devicePlatformService.getDeviceTypeBySerialNumber(deviceSerialNumber);
    const deviceTypeData = getDeviceTypesData()[deviceType];

    if (!deviceTypeData) return null;

    const isDeviceAlreadyYoursStatusMessage = data.StatusMessage === iccProductsStatusMessageTypes.DEVICE_ALREADY_YOURS; //this is only needed to ignore the status message DEVICE_ALREADY_YOURS and not change status to failed
    const transformedDeviceSerialNumber = productHelpers.getTransformedDeviceSerialNumber(deviceSerialNumber);

    return {
        deviceSerialNumber: transformedDeviceSerialNumber,
        type: deviceType,
        codentify,
        productId: data.ProductID,
        pairingStatus: data.PairingStatus,
        productName: data.ProductName,
        parentCodentifyID: data.ParentCodentifyID,
        parentProductID: data.ParentProductID,
        defaultHolderType: deviceTypeData.defaultHolderType,
        status: isDeviceAlreadyYoursStatusMessage ? ICC_PRODUCT_STATUS_TYPES.REGISTERED : data.Status,
        isHolder: deviceTypeData.isHolder,
        purchaseDate: new Date(data.PurchasedOn).toUTCString(),
        statusMessage: data.StatusMessage,
    };
};

export const consumerProductsInfoMapping = (products) => {
    const mappedProducts = products.map(consumerProductInfoMapping);
    const notEmptyProducts = mappedProducts.filter((x) => x);

    return notEmptyProducts;
};

const consumerProductInfoMapping = (product) => {
    const deviceSerialNumber = product?.DeviceSerialNumber;

    if (!deviceSerialNumber) return null;

    const material = getMaterialMapping(product.Material, product.Product);
    const preparedType = productHelpers.getPreparedType(material, deviceSerialNumber);
    const deviceTypeData = getDeviceTypesData()[preparedType];
    const isDeviceSupported = marketConfigService.isDeviceSupported(preparedType);
    const transformedDeviceSerialNumber = productHelpers.getTransformedDeviceSerialNumber(deviceSerialNumber);
    const isP1V41 = deviceTypesService.isP1V41(preparedType);

    return {
        deviceSerialNumber: transformedDeviceSerialNumber,
        mediaId: getMediaId(material, isP1V41),
        material,
        //TODO: remove brandFamily and use material.materialBrandFamily
        brandFamily: material?.materialBrandFamily,
        pairingStatus: product.PairingStatus,
        productName: product.ProductName,
        defaultProductName: deviceTypesService.getProductNameByType(preparedType),
        defaultHolderType: deviceTypeData?.defaultHolderType,
        isDeviceSupported,
        type: preparedType,
        codentifyID: product.CodentifyID,
        //TODO: remove materialColor and use material.materialColor
        materialColor: material?.materialColor,
    };
};

export const consumerProductsStatusMapping = (data) => data.map(deviceRegistrationMapping);

export const deviceRegistrationMapping = (data) => {
    const isDeviceAlreadyYoursStatusMessage = data.StatusMessage === iccProductsStatusMessageTypes.DEVICE_ALREADY_YOURS; //this is only needed to ignore the status message DEVICE_ALREADY_YOURS and not change status to failed
    const transformedDeviceSerialNumber = productHelpers.getTransformedDeviceSerialNumber(data.DeviceSerialNumber);

    return {
        codentify: data.CodentifyID,
        deviceSerialNumber: transformedDeviceSerialNumber,
        productID: data.ProductID,
        parentProductId: data.ParentProductID,
        status: isDeviceAlreadyYoursStatusMessage ? ICC_PRODUCT_STATUS_TYPES.REGISTERED : data.Status,
        statusMessage: isDeviceAlreadyYoursStatusMessage ? null : data.StatusMessage,
    };
};

export const deviceUnregistrationMapping = (data) => {
    const unregistrationData = data[0];

    return {
        codentify: unregistrationData.CodentifyID,
        deviceSerialNumber: unregistrationData.DeviceSerialNumber,
        productID: unregistrationData.ProductID,
        parentProductId: unregistrationData.ParentProductID,
        status: unregistrationData.Status,
        statusMessage: unregistrationData.StatusMessage,
    };
};

const getMaterialMapping = (material, product) => {
    if (!material) return null;

    return {
        materialGroup: material.MaterialGroup,
        materialBrandFamily: material.MaterialBrandFamily,
        materialModelNumber: material.MaterialModelNumber,
        materialColor: material.MaterialColor,
        materialBrandDifferentiator: product?.ProductDifferentiator,
    };
};

const getMediaId = (material, isP1V41) => {
    try {
        const mediaName = productHelpers.getMediaName(material, isP1V41);

        return mediaService.assetMapping(mediaName);
    } catch (e) {
        return null;
    }
};

export const mediaUrlsMapping = (data) =>
    data.map((item) => {
        const expirationDate = new Date(item.ExpirationDate).getTime();

        return {
            mediaId: item.MediaId,
            url: item.Url,
            expirationDate,
        };
    });

export const externalLinkMapping = (data) => ({
    key: data.Key,
    value: data.Value,
    locale: data.Locale,
});
