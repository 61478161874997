const ModalTypes = {
    UWM_UNAVAILABILITY: 0,
    LEVIA_LEAR_MORE: 1,
    BROWSER_NOT_COMPATIBLE: 2,
    CONSUMER_FEEDBACK_GATHERING: 3,
    LANGUAGE_SELECT: 4,
    FIRMWARE_AVAILABLE: 5,
};

export default ModalTypes;
