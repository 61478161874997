import * as qs from 'qs';
import urlJoin from 'url-join';

import arrayUtils from './arrayUtils';

const join = (...params) => urlJoin(params);
const getCurrentPathname = () => window.location.pathname;

const getParameterFromPath = (index) => {
    try {
        const path = getCurrentPathname();
        const pathSplitted = path.split('/');

        return pathSplitted[index + 1];
    } catch (e) {
        return null;
    }
};

const parseQueryString = (query) => qs.parse(query, {ignoreQueryPrefix: true});

const stringify = (obj, options) => qs.stringify(obj, options);

const updateParams = (url, params, splitter = '&') => {
    const paramsArray = arrayUtils.toArray(params);
    const isEntryDataValid = typeof url === 'string' && url && paramsArray.length;

    if (isEntryDataValid) {
        const splittedUrl = url.split(splitter);

        paramsArray.forEach((param) => {
            const {name, value, separator = '='} = param || {};
            const matchedParam = splittedUrl.find((urlParam) => urlParam.split(separator)[0] === name);

            if (matchedParam) {
                const matchedParamSplited = matchedParam.split('=');

                matchedParamSplited.pop();
                matchedParamSplited.push(value);

                const matchedParamJoined = matchedParamSplited.join('=');
                const matchedParamIndex = splittedUrl.indexOf(matchedParam);

                if (matchedParamIndex !== -1) {
                    splittedUrl[matchedParamIndex] = matchedParamJoined;
                }
            } else {
                splittedUrl.push(`${name}=${value}`);
            }
        });
        return splittedUrl.join(splitter);
    } else {
        return url;
    }
};

const getDataFromUrl = (...keys) => {
    const url = window.location.search;
    const parsedUrl = parseQueryString(url);
    const keysList = arrayUtils.toArray(keys);

    return keysList.map((key) => parsedUrl[key]);
};

export default {
    join,
    getCurrentPathname,
    getDataFromUrl,
    getParameterFromPath,
    stringify,
    updateParams,
    parseQueryString,
};
