import imageCleaningCortez from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_Cortez.png';
import imageCleaningCosmos from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_Cosmos.png';
import imageCleaningDelta from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_Delta.png';
import imageCleaningDuo from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_Duo.png';
import imageCleaningDuoOriginals from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_DuoOriginals.png';
import imageCleaningMulti from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_Multi.png';
import imageCleaningOrdinary from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_Ordinary.png';
import imageCleaningOriginalOne from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_OriginalOne.png';
import imageCleaningVeev from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_Veev.png';
import imageCleaningVoyager from '../../../../assets/images/advanced-diagnostics/cleaning-contacts/cleaning_Voyager.png';
import {DEVICE_TYPES} from '../../../consts/device/deviceTypes';

const ADVANCED_DIAGNOSTIC_CLEANING_CONTACTS_DATA = {
    [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: {defaultImage: imageCleaningCortez},
    [DEVICE_TYPES.DEV_TYPE_P1V30M]: {defaultImage: imageCleaningMulti},
    [DEVICE_TYPES.DEV_TYPE_P1V31M]: {defaultImage: imageCleaningOriginalOne},
    [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: {defaultImage: imageCleaningDuo},
    [DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER]: {defaultImage: imageCleaningDuoOriginals},
    [DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER]: {defaultImage: imageCleaningCosmos},
    [DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER]: {defaultImage: imageCleaningVoyager},
    [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: {defaultImage: imageCleaningVeev},
    [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: {defaultImage: imageCleaningOrdinary},
    [DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL]: {defaultImage: imageCleaningDelta},
};

export default ADVANCED_DIAGNOSTIC_CLEANING_CONTACTS_DATA;
