import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {StickyPageLayout} from '../../../components/Layout';
import {PREFERENCE_CENTER_PAGE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import * as tncTypes from '../../../consts/tnc/tncTypes';
import amplitudeProfileTracking from '../../../services/amplitude/amplitudeProfileTracking';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {selectDeviceType} from '../../../state/selectors/device';
import PageWarningBannersContainer from '../../WarningBanners/PageWarningBannersContainer';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';
import DCExpansionPanel from './components/DCExpansionPanel';
import SaveContinueButton from './components/SaveContinueButton';

const PreferenceCenter = (props) => {
    const {description, acceptedLegals, legals, onApproved, isEnableAllAvailable, onBackButtonClick} = props;
    const deviceType = useSelector(selectDeviceType);
    const legalsNotNull = legals.filter((x) => x.settings != null);
    const acceptedLegalsNotNull = acceptedLegals.filter((x) => x.settings != null);
    const legalsSettingsNotNull = [...legalsNotNull, ...acceptedLegalsNotNull].sort((a, b) => a.position - b.position);
    const legalsSettingsNotNullCodes = legalsSettingsNotNull.map(({code}) => code);
    const filteredAcceptedLegals = acceptedLegalsNotNull.filter((x) => !x.isRevoked).map(({code}) => code);
    const [acceptedSettingList, setAcceptedSettingList] = useState(filteredAcceptedLegals);
    const localizedStrings = getLocalizedStrings();

    useEffect(() => {
        amplitudeProfileTracking.trackPreferenceCenterScreen();
    }, []);

    useEffect(() => {
        setAcceptedSettingList(filteredAcceptedLegals);
    }, [acceptedLegals]);

    const onSettingLegalChange = (code, isAccepted) => {
        if (isAccepted) {
            setAcceptedSettingList([...acceptedSettingList, code]);
        } else {
            const isCodeInList = acceptedSettingList.indexOf(code) !== -1;

            if (isCodeInList) {
                const filteredList = acceptedSettingList.filter((el) => el !== code);

                setAcceptedSettingList(filteredList);
            }
        }
    };

    return (
        <StickyPageLayout
            className='items-center'
            headerOptions={{
                onBack: onBackButtonClick,
                title: localizedStrings[PREFERENCE_CENTER_PAGE_HEADER_TITLE],
            }}
            contentClassName='flex flex-1 flex-col items-center'
            footer={
                <div className='flex flex-col gap-6'>
                    <SaveContinueButton
                        deviceType={deviceType}
                        legals={legals}
                        onApproved={onApproved}
                        legalsSettingsNotNull={legalsSettingsNotNull}
                        acceptedSettingList={isEnableAllAvailable ? legalsSettingsNotNullCodes : acceptedSettingList}
                        itemName={isEnableAllAvailable ? 'preferenceCenterEnableAll' : 'preferenceCenterSave'}
                    />
                    {isEnableAllAvailable && (
                        <SaveContinueButton
                            isSecondary
                            deviceType={deviceType}
                            legals={legals}
                            onApproved={onApproved}
                            legalsSettingsNotNull={legalsSettingsNotNull}
                            acceptedSettingList={acceptedSettingList}
                            itemName='preferenceCenterContinue'
                        />
                    )}
                </div>
            }
        >
            <h1 className='mb-3 px-4 mt-safe-offset-14'>
                <AemEditableText pageName='tnc' itemName='preferenceCenterTitle' />
            </h1>
            <TermsOfUseDescription
                className='mb-4 px-4'
                innerHtml={description}
                tncType={tncTypes.WEB_PREFERENCE_CENTER}
            />
            {legalsSettingsNotNull.map(({code, title, text}) => {
                return (
                    <div className='w-full px-4' key={code}>
                        <DCExpansionPanel
                            index={code}
                            title={title}
                            innerText={text}
                            onChange={(value) => onSettingLegalChange(code, value)}
                            isActive={acceptedSettingList.includes(code)}
                        />
                    </div>
                );
            })}
            <PageWarningBannersContainer />
        </StickyPageLayout>
    );
};

export default PreferenceCenter;
