import React from 'react';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import {FirmwareUpdateIcon} from '../../../../components/Icons';
import {MaxWidthContainer} from '../../../../components/Layout';

const FirmwareAvailableArticle = () => {
    return (
        <MaxWidthContainer className='flex-1 px-4 pb-4 pt-safe-offset-18'>
            <FirmwareUpdateIcon />
            <h1 className='mt-4 text-28'>
                <AemComponent component={EditableText} pageName='modals' itemName='fwAvailableTitle' />
            </h1>
            <p>
                <AemComponent component={EditableText} pageName='modals' itemName='fwAvailableDesc' />
            </p>
            <p className='mt-4 text-12 leading-13 tracking-02'>
                <AemComponent component={EditableText} pageName='modals' itemName='fwAvailableFootnote' />
            </p>
        </MaxWidthContainer>
    );
};

export default FirmwareAvailableArticle;
