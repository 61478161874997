import React, {useEffect} from 'react';

import defaultHeaderImage from '../../../assets/images/enable-notification/enable-notification-image.jpg';
import AemComponent from '../../components/Aem/AemComponent/AemComponent';
import AemEditableText from '../../components/Aem/AemEditableText/AemEditableText';
import EditableText from '../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../components/Button';
import RemoteIcon from '../../components/Icon/RemoteIcon';
import {BellIcon} from '../../components/Icons';
import {MaxWidthContainer, StickyPageLayout} from '../../components/Layout';
import PageHeaderMedia from '../../components/PageHeaderMedia/PageHeaderMedia';
import appConfig from '../../config/appConfig';
import amplitudeNotificationTracking from '../../services/amplitude/amplitudeNotificationTracking';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import pushNotificationService from '../../services/pushNotificationService';
import appRouterService from '../../services/route/appRouterService';

const EnableNotificationPage = () => {
    const imageSrc = appConfig.getEnableNotificationPageHeaderImagePath();
    const videoSrc = appConfig.getEnableNotificationPageHeaderVideoSrc();
    const videoThumbnailSrc = appConfig.getEnableNotificationPageHeaderVideoThumbnailSrc();
    const icons = appConfig.getEnableNotificationPageIcons();
    const localizedStrings = getLocalizedStrings();

    const onClick = async () => {
        amplitudeNotificationTracking.trackEnableNotificationScreenNextButton();
        await pushNotificationService.subscribe();
        appRouterService.forwardToHomePage();
    };

    useEffect(() => {
        amplitudeNotificationTracking.trackEnableNotificationScreen();
    }, []);

    return (
        <StickyPageLayout
            className='items-center'
            contentClassName='flex flex-1 flex-col items-center'
            footer={
                <AppButton className='m-auto' primary light onClick={onClick}>
                    <AemComponent
                        component={EditableText}
                        pageName='enable-notification'
                        itemName='enableNotificationButton'
                    />
                </AppButton>
            }
        >
            <PageHeaderMedia
                defaultImage={defaultHeaderImage}
                isVideoAvailable={!!videoThumbnailSrc}
                videoThumbnailSrc={videoThumbnailSrc}
                videoSrc={videoSrc}
                imageSrc={imageSrc}
            />

            <MaxWidthContainer className='flex-1 px-4'>
                <BellIcon className='mt-8 h-16 w-16' />
                <h1 className='mb-0 mt-4 text-28'>
                    <AemEditableText pageName='enable-notification' itemName='enableNotificationTitle' />
                </h1>
                <ul className='mt-16 flex w-full flex-col gap-4 px-4'>
                    {icons?.map((icon, i) => {
                        const {titleKey, imagePath} = icon;
                        const title = localizedStrings[titleKey];

                        if (!title) return null;

                        return (
                            <li key={i} className='flex items-center justify-start gap-2'>
                                <div className='min-w-8'>
                                    <RemoteIcon imagePath={imagePath} />
                                </div>
                                <span className='text-start'>{title}</span>
                            </li>
                        );
                    })}
                </ul>
            </MaxWidthContainer>
        </StickyPageLayout>
    );
};

export default EnableNotificationPage;
