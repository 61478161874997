import React from 'react';

import {Text} from './Text';

export const TextWithContent = (props) => {
    const {render, contentList, ...rest} = props || {};
    //remove render function. Get text only
    const text = Text(rest);
    const textFormatted = getText(text, contentList);

    return typeof render === 'function' ? render(textFormatted) : textFormatted;
};

const getText = (text, contentList) => {
    if (!text || !contentList?.length) {
        return text;
    }

    const textWithInsertedContent = () => {
        return text
            .split(/\{(.*?)\}/)
            .map((part, index) =>
                index % 2 === 0 ? part : <React.Fragment key={index}>{contentList[parseInt(part, 10)]}</React.Fragment>
            );
    };

    return textWithInsertedContent();
};
