import cn from 'classnames';
import React, {forwardRef} from 'react';

import InputComponent from './InputComponent';

// eslint-disable-next-line react/display-name
const RadioButtonChildComponent = forwardRef((props, ref) => {
    const {isDefaultMode, defaultDeviceName, deviceNameText, ...restProps} = props;

    return (
        <div className={cn('relative w-full items-start bg-cararra px-8 py-6')}>
            <div className='text-start'>
                <p className={cn('overflow-hidden text-ellipsis text-12', isDefaultMode ? 'mb-3' : 'mb-4')}>
                    {deviceNameText}
                </p>
                {isDefaultMode ? (
                    <p className='w-full text-20'>{defaultDeviceName}</p>
                ) : (
                    <InputComponent {...restProps} ref={ref} />
                )}
            </div>
        </div>
    );
});

export default RadioButtonChildComponent;
