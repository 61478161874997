import React from 'react';

import Icon from '../Icon/Icon';

const TickCircle = (props) => {
    return (
        <Icon width='32' height='32' {...props}>
            <g fill='none' fillRule='evenodd'>
                <circle cx='16' cy='16' r='12' fill='#00D1D2' />
                <path
                    d='M10.6667 16.7028L13.683 19.8596L21.3334 12'
                    stroke='#34303D'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </Icon>
    );
};

export default TickCircle;
