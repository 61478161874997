import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../components/Button';
import {MailIcon} from '../../../components/Icons';

const ContactUsSendQuestion = ({email}) => {
    if (!email) return null;

    return (
        <div className='flex w-full flex-col items-center'>
            <MailIcon className={'mb-3 min-h-12'} />
            <h3 className='text-24'>
                <AemEditableText pageName='contact-us' itemName='sendQuestionTitle' />
            </h3>
            <p className='mt-4'>
                <AemEditableText pageName='contact-us' itemName='sendQuestionDesc' />
            </p>
            <div className='h-full w-full md:w-auto'>
                <AppButton
                    secondary
                    light
                    className='mx-auto mt-6 md:min-w-[312px]'
                    to={`mailto:${email}`}
                    isExternalLink
                >
                    <AemEditableText pageName='contact-us' itemName='sendQuestionButton' />
                </AppButton>
            </div>
        </div>
    );
};

export default ContactUsSendQuestion;
