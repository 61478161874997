import {setDeviceCodentify} from '../../state/slices/deviceSlice';
import {dispatch} from '../../state/store';
import storageService from '../storage/storageService';

const storeSelectedDeviceCodentify = (codentify) => {
    dispatch(setDeviceCodentify(codentify));
    storageService.setSelectedDeviceCodentifyToLocalStorage(codentify);
};

export default {
    storeSelectedDeviceCodentify,
};
