import * as RadioGroup from '@radix-ui/react-radio-group';
import cn from 'classnames';
import React from 'react';

import {TickCircle} from '../../../../../../components/Icons';
import appConfig from '../../../../../../config/appConfig';
import productHelpers from '../../../../../../services/product/productHelpers';
import DeviceImage from '../../../../../DeviceImage/DeviceImage';
import styles from './DevicesListItem.module.scss';
import UnregisterButton from './UnregisterButton';
import useDeviceListItemSwipe from './useDeviceListItemSwipe';

const DeviceListItem = ({device, isSelected, touchedDeviceId, onDeviceTouch, hideDeviceListPopup}) => {
    const isUnregistrationEnabled = appConfig.getIsUnregistrationEnabled();
    const {codentify} = device || {};
    const name = productHelpers.getProductName(device);
    const {onTouchStart, onTouchMove, onTouchEnd, isEndOfSwipe, cropLeft} = useDeviceListItemSwipe({
        deviceId: codentify,
        touchedDeviceId,
        onDeviceTouch,
        isEnabled: isUnregistrationEnabled,
    });

    return (
        <div className='relative flex w-full'>
            <RadioGroup.Item className='max-w-full flex-1 px-4' value={codentify} id={codentify}>
                <div
                    className='flex items-center gap-2 overflow-x-auto py-4 text-start text-primary'
                    onTouchStart={onTouchStart}
                    onTouchMove={onTouchMove}
                    onTouchEnd={onTouchEnd}
                >
                    <DeviceImage
                        className='h-16 w-16'
                        device={device}
                        holder={device?.holder}
                        imgClassName='absolute'
                        ignoreRestrictedMarket
                        showHolder
                    />
                    <h4 className='mb-0 flex-1 truncate text-16'>{name}</h4>
                    {isSelected && <TickCircle className='shrink-0' />}
                </div>
            </RadioGroup.Item>
            <div
                className={cn(styles.DeleteContainer, {[styles.Animated]: isEndOfSwipe})}
                style={{
                    '--crop-left': `${cropLeft}px`,
                }}
            >
                <UnregisterButton device={device} hideDeviceListPopup={hideDeviceListPopup} />
            </div>
        </div>
    );
};

export default DeviceListItem;
