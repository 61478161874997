import cn from 'classnames';
import React, {useState} from 'react';

import TermsOfUseDescription from '../../containers/TNC/TermsOfUseDescription/TermsOfUseDescription';
import domService from '../../services/domService';
import {ExpansionPanelArrowIcon} from '../Icons';

const ExpansionPanel = ({title, innerText, children, index}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const onKeyPress = (e) => domService.handleBtnKeyPress(e, onExpansionPanelClick);

    const onExpansionPanelClick = () => {
        setIsExpanded(!isExpanded);
    };

    const after =
        'after:content-[""] after:absolute after:h-px after:w-screen after:bg-alto-second after:bottom-0 after:right-1/2 after:translate-x-1/2 md:after:w-full ';

    const arrowClassName = cn('w-3 min-w-[12px] ease-in-out duration-200', {'rotate-180': isExpanded});

    return (
        <div className={cn('relative w-full cursor-pointer select-none md:min-h-18', after)}>
            <div className={'flex items-center justify-between'}>
                <div
                    className={'flex w-full items-center'}
                    onClick={onExpansionPanelClick}
                    aria-expanded={isExpanded}
                    onKeyPress={onKeyPress}
                    aria-label={title}
                    tabIndex={0}
                    role='button'
                >
                    <ExpansionPanelArrowIcon className={arrowClassName} onClick={onExpansionPanelClick} />
                    <p className='w-full px-4 py-6 text-start' id={`${index}`}>
                        {title}
                    </p>
                </div>
                {children}
            </div>
            {isExpanded && innerText && <TermsOfUseDescription innerHtml={innerText} className='pb-6 text-start' />}
        </div>
    );
};

export default ExpansionPanel;
