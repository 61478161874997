import React, {forwardRef} from 'react';

import {twx} from '../../utils/tailwindUtils';

const Input = forwardRef((props, ref) => {
    const {type, className, value, maxLength, minLength, onChange, placeholder, testId, ariaLabel, ariaLabelledby, id} =
        props;
    const inputClassName = twx(
        'block w-full h-12 appearance-none bg-none rounded-[3px] border-solid border-[1px] text-start border-border text-16 bg-white outline-[0] pl-3',
        className
    );

    return (
        <input
            ref={ref}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledby}
            className={inputClassName}
            data-testid={testId}
            id={id}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
        />
    );
});

Input.displayName = 'Input';

export default Input;
