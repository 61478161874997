import {ButtonRaw} from '../../components/Button';
import ModalTypes from '../../consts/app/modalTypes';
import appRouterService from '../../services/route/appRouterService';
import {showModalAction} from '../../state/slices/modalSlice';
import {dispatch} from '../../state/store';

const ModalsPage = () => {
    const showPopup = (el) => {
        const modalType = el.target.value;

        dispatch(showModalAction({modalType}));
    };

    return (
        <section className='flex flex-col gap-2'>
            <ul onClick={showPopup}>
                <li className='cursor-pointer' value={ModalTypes.LEVIA_LEAR_MORE}>
                    LEVIA_LEAR_MORE
                </li>
                <li className='cursor-pointer' value={ModalTypes.FIRMWARE_AVAILABLE}>
                    FIRMWARE_AVAILABLE
                </li>
                <li className='cursor-pointer' value={ModalTypes.BROWSER_NOT_COMPATIBLE}>
                    BROWSER_NOT_COMPATIBLE
                </li>
                <li className='cursor-pointer' value={ModalTypes.UWM_UNAVAILABILITY}>
                    UWM_UNAVAILABILITY
                </li>
                <li className='cursor-pointer' value={ModalTypes.CONSUMER_FEEDBACK_GATHERING}>
                    CONSUMER_FEEDBACK_GATHERING
                </li>
                <li className='cursor-pointer' value={ModalTypes.LANGUAGE_SELECT}>
                    LANGUAGE_SELECT
                </li>
            </ul>
            <div>
                <ButtonRaw onClick={appRouterService.forwardToEnableNotificationPage}>
                    Enable Notification Page
                </ButtonRaw>
            </div>
        </section>
    );
};

export default ModalsPage;
