import React from 'react';

import useLanguageName from '../../../../app/hooks/useLanguageName';
import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {ButtonRaw} from '../../../components/Button';
import {GlobeIcon} from '../../../components/Icons';
import {ContentWrapper, MaxWidthContainer, StickyHeader} from '../../../components/Layout';
import {LOGIN_PAGE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {showLanguageSelectModalAction} from '../../../state/slices/modalSlice';
import {dispatch} from '../../../state/store';

const LoginHeader = () => {
    const languageName = useLanguageName();
    const localizedStrings = getLocalizedStrings();
    const onLanguageClick = () => {
        dispatch(showLanguageSelectModalAction());
    };

    return (
        <ContentWrapper className='gap-[18px] pb-0 pt-safe-offset-14'>
            <StickyHeader
                title={localizedStrings[LOGIN_PAGE_HEADER_TITLE]}
                endIcon={
                    <ButtonRaw className='flex gap-2 self-end' onClick={onLanguageClick}>
                        <span className='text-14 font-bold leading-[24px] underline'>{languageName}</span>
                        <GlobeIcon className='h-6 w-6 stroke-2' />
                    </ButtonRaw>
                }
            />
            <MaxWidthContainer className='gap-[10px]'>
                <h1 className='mb-0 leading-[40px] md:mb-10'>
                    <AemComponent component={EditableText} pageName='login' itemName='title' />
                </h1>
                <AemComponent component={EditableText} pageName='login' itemName='description' />
            </MaxWidthContainer>
        </ContentWrapper>
    );
};

export default LoginHeader;
