const UWM_EVENT_TYPES = {
    UWM_MODULE_BACK_BUTTON_CLICK: 'UWM_MODULE_BACK_BUTTON_CLICK',
    UWM_MODULE_COMPLETE_CLEANING_GUIDE: 'UWM_MODULE_COMPLETE_CLEANING_GUIDE',
    UWM_MODULE_COMPLETE_GUIDE: 'UWM_MODULE_COMPLETE_GUIDE',
    UWM_MODULE_INITIALIZATION_ERROR: 'UWM_MODULE_INITIALIZATION_ERROR',
    UWM_MODULE_LOADED: 'UWM_MODULE_LOADED',
    UWM_MODULE_RESIZE: 'UWM_MODULE_RESIZE',
    UWM_MODULE_DATA_RECEIVED_SUCCESS: 'UWM_MODULE_DATA_RECEIVED_SUCCESS',
    UWM_MODULE_SUPPORT_BTN_CLICK: 'UWM_MODULE_SUPPORT_BTN_CLICK',
};

export default UWM_EVENT_TYPES;
