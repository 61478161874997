import React from 'react';

import ExpansionPanel from '../../../../components/ExpansionPanel/ExpansionPanel';
import SwitchToggle from '../../../../components/SwitchToggle/SwitchToggle';

const DCExpansionPanel = (props) => {
    const {index, title, innerText, onChange, isActive} = props;

    return (
        <ExpansionPanel className='w-full' innerText={innerText} title={title} index={index}>
            <SwitchToggle
                aria-describedby={index}
                labelId={index}
                isActive={isActive}
                onChange={onChange}
                className={'min-w-[48px]'}
            />
        </ExpansionPanel>
    );
};

export default DCExpansionPanel;
