import ROUTE_PARAMS from './routeParams';

const ROUTE_PATHS = {
    ROOT: '/',
    INSTALL_PWA: '/install-pwa',
    LOGIN: '/login',
    TNC: '/tnc',
    TNC_TERMS_OF_USE: '/tnc/terms-of-use',
    TNC_DATA_COLLECTION: '/tnc/data-collection',
    TNC_DATA_PREFERENCE: '/tnc/preference-center',
    HOME_PAGE: '/home',
    SHOP_PAGE: '/shop',
    DEVICES_PAGE: '/devices',
    ENABLE_NOTIFICATION: '/enable-notification',
    QUICK_ORDER: '/quick-order',
    QUICK_START_GUIDE: '/quick-start-guide',
    QUICK_START_GUIDE_PARAMETERIZED: `/quick-start-guide/:${ROUTE_PARAMS.DEVICE_TYPE}?`,
    TIPS_AND_TUTORIALS: '/tips-and-tutorials',
    TIPS_AND_TUTORIALS_PARAMETERIZED: `/tips-and-tutorials/:${ROUTE_PARAMS.DEVICE_TYPE}?`,
    INBOX_PAGE: '/inbox',
    INBOX_PAGE_PARAMETERIZED: `/inbox/:${ROUTE_PARAMS.MESSAGE_ID}?`,
    ADVANCE_DIAGNOSTIC: '/ad',
    ABOUT_DEVICE: '/about-device',
    AUTO_DELIVERY: '/auto-delivery',
    DEVICE_RENAME: '/device-rename',
    CLEAN_TUTORIAL: '/clean-tutorial',
    MODALS: '/modals',
    CONSUMER_PROFILE: '/profile',
    PREFERENCE_CENTER_PROFILE: '/preference-center',
    CONTACT_US: '/contact-us',
    SELECT_YOUR_DEVICE: '/select-device',
    USB_REQUIRED: '/usb-required',
    ERROR_PAGE: '/error',
    WILDCARD: '*',
};

export default ROUTE_PATHS;
