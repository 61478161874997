import React from 'react';

import AutoDeliveryButton from '../AutoDeliveryButton';
import {getAutoDeliveryOnHoldContent} from './autoDeliveryOnHoldService';

const AutoDeliveryOnHoldButton = ({className, statusUpdateReason, amplitudeEvent}) => {
    const {button, amplitudeTrackingValue, externalLinkConfig} = getAutoDeliveryOnHoldContent(statusUpdateReason);

    return (
        <AutoDeliveryButton
            sandy
            className={className}
            text={button}
            amplitudeEvent={() => amplitudeEvent(amplitudeTrackingValue)}
            externalLinkConfig={externalLinkConfig}
        />
    );
};

export default AutoDeliveryOnHoldButton;
