import * as iccMethods from '../../consts/icc/iccMethods';
import server from '../server/server';
import iccMarketService from './iccMarketService';

const JSON_CONTENT_TYPE = {
    'Content-Type': 'application/json',
};

const getPreSignedURL = async () => {
    const result = await iccMarketService.callRequest({
        httpMethod: server.get,
        methodUrl: iccMarketService.getIccMarketApiUrl(iccMethods.FEEDBACK_PRE_SIGNED_URL),
        throwException: true,
    });

    return result;
};

const uploadConsumerFeedback = async (data) => {
    const preSignedUrl = await getPreSignedURL();

    const result = await iccMarketService.callRequest({
        httpMethod: server.put,
        methodUrl: preSignedUrl.Url,
        requestConfig: {
            data,
            headers: JSON_CONTENT_TYPE,
        },
        throwException: true,
    });

    return result;
};

export default {
    uploadConsumerFeedback,
};
