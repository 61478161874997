import React from 'react';

import Icon from '../Icon/Icon';

const IlumaAboutBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g transform='translate(12 7)' fill='none' fillRule='evenodd'>
                <path
                    d='M2.25 34.25c-.701 0-1.451-1.152-2.25-3.455V5.035C0 3.958.447 2.947 1.342 2h4.816C7.053 2.948 7.5 3.96 7.5 5.034v25.76c-.799 2.304-1.549 3.456-2.25 3.456z'
                    stroke='#34303D'
                    strokeWidth='2'
                    strokeLinejoin='round'
                />
                <circle stroke='#00D1D2' fill='#34303D' cx='3.75' cy='16.185' r='1.5' />
                <path
                    d='M23 27v4.75A2.25 2.25 0 0 1 20.75 34h-7a2.25 2.25 0 0 1-2.25-2.25V2.25A2.25 2.25 0 0 1 13.75 0h3.5A5.75 5.75 0 0 1 23 5.75v9.48'
                    stroke='#34303D'
                    strokeWidth='2'
                />
                <g transform='translate(18 14.31)'>
                    <circle stroke='#34303D' strokeWidth='1.5' fill='#00D1D2' cx='6.761' cy='6.761' r='6.011' />
                    <g transform='translate(5.98 3.316)' fill='#34303D'>
                        <circle cx='.778' cy='.765' r='1' />
                        <rect y='2.295' width='1.556' height='4.591' rx='.778' />
                    </g>
                </g>
            </g>
        </Icon>
    );
};

export default IlumaAboutBlueIcon;
