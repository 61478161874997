import domClassNames from '../consts/app/domClassNames';

const getHtmlElement = () => document.documentElement;
const getElementBySelector = (selector) => document.querySelector(selector);
const getAllElementsBySelector = (selector) => document.querySelectorAll(selector);
const getRoot = () => document.getElementById('root');
const getComponentsWrapperElement = () => getElementBySelector(`.${domClassNames.COMPONENTS_CONTAINER_CLASS_NAME}`);
const setFocusToDomElement = (domElement) => domElement?.focus();
const handleBtnKeyPress = (event, postFunc) => {
    if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
        event.preventDefault();
        postFunc(event);
    }
};
const handleCheckboxKeyPress = (event, postFunc) => {
    if (event.key === ' ' || event.key === 'Spacebar') {
        event.preventDefault();
        postFunc(event);
    }
};

export default {
    getAllElementsBySelector,
    getComponentsWrapperElement,
    getElementBySelector,
    getHtmlElement,
    getRoot,
    setFocusToDomElement,
    handleBtnKeyPress,
    handleCheckboxKeyPress,
};
