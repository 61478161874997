import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {AppButton} from '../../components/Button';
import {ErrorIcon} from '../../components/Icons';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/localization/localizationKeys';
import useConfigExternalLink from '../../hooks/useConfigExternalLink';
import languageDataService from '../../services/localization/languageDataService';
import {getLocalizedStrings, initDefaultLocalizations} from '../../services/localization/localizationService';
import appRouterService from '../../services/route/appRouterService';
import {selectLanguageCode} from '../../state/selectors/global';
import {setLanguageCode} from '../../state/slices/globalSlice';
import {hideAllLoaders} from '../../state/slices/loadersSlice';
import {hideAllModalsAction} from '../../state/slices/modalSlice';

const ErrorHandlingPage = () => {
    const dispatch = useDispatch();
    const languageCode = useSelector(selectLanguageCode);
    const localizedStrings = getLocalizedStrings();

    const supportExternalLink = appConfig.getSupportExternalLink();
    const redirectToSupport = useConfigExternalLink(supportExternalLink);

    const redirectToRoot = () => {
        appRouterService.redirectToRootPage();
    };

    useEffect(() => {
        dispatch(hideAllModalsAction());
        dispatch(hideAllLoaders());
    }, []);

    useEffect(() => {
        if (languageCode) return;

        const defaultLanguageCode = languageDataService.getDefaultLanguageCode();

        initDefaultLocalizations(defaultLanguageCode);
        dispatch(setLanguageCode(defaultLanguageCode));
    }, [languageCode]);

    return (
        <div className='flex min-h-full flex-col items-center p-8 pt-safe-offset-8'>
            <ErrorIcon className='mb-5 h-6 w-6 sm:h-8 sm:w-8' />
            <div className='flex-1'>
                <h1 className='mb-4 lg:mb-9'>{localizedStrings[localizationKeys.GLOBAL_ERROR_TITLE_TEXT]}</h1>
                <p className='mb-[272px] sm:mb-9'>
                    {localizedStrings[localizationKeys.GLOBAL_ERROR_WITH_RETRY_DESCRIPTION_TEXT]}
                </p>
            </div>
            <AppButton primary light className='mt-0' onClick={redirectToRoot}>
                {localizedStrings[localizationKeys.GLOBAL_ERROR_TRY_AGAIN_BUTTON_TEXT]}
            </AppButton>
            {supportExternalLink && (
                <AppButton secondary light className='mt-4' onClick={redirectToSupport}>
                    {localizedStrings[localizationKeys.GLOBAL_ERROR_CONTACT_US_BUTTON_TEXT]}
                </AppButton>
            )}
        </div>
    );
};

export default ErrorHandlingPage;
