import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    signedMediaList: [],
    ssoLinks: {},
};

export const slice = createSlice({
    name: 'media',
    initialState,
    reducers: {
        addSignedMedia: (state, action) => {
            const data = action.payload;
            const signedMediaListFromState = state.signedMediaList;
            const filteredSignedMediaListFromState = signedMediaListFromState.filter(
                ({mediaId}) => !data.some((item) => mediaId === item.mediaId)
            );

            return {
                ...state,
                signedMediaList: [...filteredSignedMediaListFromState, ...data],
            };
        },
        addSsoLink: (state, action) => {
            const {ssoTemplate, link, expirationDate, status} = action.payload;

            return {
                ...state,
                ssoLinks: {
                    ...state.ssoLinks,
                    [ssoTemplate]: {link, expirationDate, status},
                },
            };
        },
    },
});

export const {addSignedMedia, addSsoLink} = slice.actions;

export default slice.reducer;
