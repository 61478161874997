import React from 'react';

import HomePageCarousel from './HomePageCarousel';
import HomePageSwiperCarousel from './HomePageSwiperCarousel';

const HomePageCarouselContainer = ({slides, className, isSwiper}) => {
    return (
        <>
            {isSwiper ? (
                <HomePageSwiperCarousel slides={slides} className={className} />
            ) : (
                <HomePageCarousel slides={slides} className={className} />
            )}
        </>
    );
};

export default HomePageCarouselContainer;
