import React from 'react';

import Icon from '../Icon/Icon';

const TroubleshootOtherDeviceBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g stroke='#34303D' strokeWidth='2'>
                    <path
                        d='M22.24 38.456a4.486 4.486 0 0 1-2.579-2.283 4.486 4.486 0 0 1-.21-3.437l7.488-21.747a4.486 4.486 0 0 1 2.282-2.58 4.486 4.486 0 0 1 3.438-.21l1.953.673a6.546 6.546 0 0 1 3.764 3.33 6.546 6.546 0 0 1 .307 5.016l-6.978 20.266a3.987 3.987 0 0 1-2.028 2.293 3.987 3.987 0 0 1-3.056.187z'
                        fill='#FFFDFB'
                    />
                    <path d='M26.964 39.994a2.041 2.041 0 0 1-.49-2.09l8.672-25.184c.368-1.07 1.567-1.627 2.676-1.245' />
                    <path strokeLinecap='square' d='m29.67 29.711 4.019 1.384' />
                </g>
                <path
                    d='M33.723 10.886c-.32.128-.6.54-.84 1.237-.24.696-1.71 4.969-4.414 12.818'
                    stroke='#00D1D2'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                />
                <g transform='translate(9 6)'>
                    <rect stroke='#34303D' strokeWidth='2' fill='#FFFDFB' x='1' y='1' width='19' height='34' rx='3' />
                    <path
                        stroke='#00D1D2'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M4.5 4.673h12'
                    />
                    <circle fill='#00D1D2' cx='10.5' cy='18' r='7.5' />
                    <path
                        d='M10.813 12a1 1 0 0 1 .996.909l.074.807c.348.113.677.266.983.455.017-.016.039-.036.061-.054l.48-.404a1 1 0 0 1 1.35.06l.231.23a1 1 0 0 1 .062 1.347l-.402.482c-.041.05-.086.094-.135.134.106.205.196.422.269.646l.805.07a1 1 0 0 1 .913.997v.634a1 1 0 0 1-.909.996l-.807.074a4.476 4.476 0 0 1-.385.866 1 1 0 0 1 .08.083l.403.48a1 1 0 0 1-.06 1.35l-.23.23a1 1 0 0 1-1.346.062l-.483-.401a1.008 1.008 0 0 1-.106-.103 4.46 4.46 0 0 1-.77.332l-.07.805a1 1 0 0 1-.996.913h-.634a1 1 0 0 1-.996-.909l-.074-.807a4.471 4.471 0 0 1-.65-.268l-.065.074a1 1 0 0 1-.064.059l-.48.403a1 1 0 0 1-1.35-.06l-.23-.23a1 1 0 0 1-.062-1.346l.401-.483.058-.061a4.479 4.479 0 0 1-.457-.984l-.805-.07a1 1 0 0 1-.913-.997v-.634a1 1 0 0 1 .909-.996l.807-.074c.087-.27.199-.527.333-.772a1 1 0 0 1-.102-.102l-.404-.481a1 1 0 0 1 .06-1.35l.23-.23a1 1 0 0 1 1.347-.062l.482.402.086.081c.271-.157.56-.287.864-.385l.07-.805A1 1 0 0 1 10.18 12h.634zm-.313 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z'
                        fill='#34303D'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default TroubleshootOtherDeviceBlueIcon;
