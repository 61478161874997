import {createSelector} from 'reselect';

import * as aggregatedSettingsTypes from '../../consts/tnc/aggregatedSettingTypes';

const selectConsumer = (state) => state.consumer;

const selectAggregatedSettings = createSelector(selectConsumer, (state) => state.aggregatedSettings);

const selectIsAggregatedSettingsFetched = createSelector(
    selectAggregatedSettings,
    (aggregatedSettings) => aggregatedSettings !== undefined
);

const selectConsumerAggregatedSettings = createSelector(
    selectAggregatedSettings,
    (aggregatedSettings) => aggregatedSettings?.consumerAggregatedSettings
);

const makeConsumerAggregatedSetting = (key) =>
    createSelector(selectConsumerAggregatedSettings, (cas) => cas?.find((item) => item.key === key)?.value);

const selectIsAmplitudeEnabled = makeConsumerAggregatedSetting(aggregatedSettingsTypes.ENABLE_AMPLITUDE);

const selectIsTncApproved = createSelector(
    selectAggregatedSettings,
    (aggregatedSettings) =>
        aggregatedSettings?.allNonMandatoryTermsReviewed && aggregatedSettings?.allMandatoryTermsApproved
);

export {selectAggregatedSettings, selectIsAggregatedSettingsFetched, selectIsAmplitudeEnabled, selectIsTncApproved};
