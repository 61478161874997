export const AUTO_DELIVERY_STATUS_TYPES = {
    NONE: 'NONE',
    ACTIVE: 'ACTIVE',
    ON_HOLD: 'ON_HOLD',
    INACTIVE: 'INACTIVE',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
};

export const AUTO_DELIVERY_ORDER_STATUS_TYPES = {
    EXPECTED: 'EXPECTED',
    PROCESSED: 'PROCESSED',
    ON_ITS_WAY: 'ON_ITS_WAY',
};

export const AUTO_DELIVERY_UPDATE_REASON_STATUS_TYPES = {
    PAY_FAIL: 'PAY_FAIL',
    OUT_OF_STOCK: 'OUT_OF_STOCK',
    DELIVERY_FAIL: 'DELIVERY_FAIL',
};

export const validateAutoDeliveryOrderStatus = (status) => {
    if (!status) return;

    const result = !!AUTO_DELIVERY_ORDER_STATUS_TYPES[status];

    return result;
};
