import cn from 'classnames';
import React from 'react';

import PopupRaw from './PopupRaw';

const PopupCentered = (props) => {
    const {className, ...rest} = props;

    return <PopupRaw className={cn('top-1/2 mx-8 h-min w-auto -translate-y-1/2 p-6', className)} {...rest} />;
};

export default PopupCentered;
