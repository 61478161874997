import cn from 'classnames';
import React, {Fragment} from 'react';

import Carousel from '../../../../components/Carousel/Carousel';
import styles from './HomePageCarousel.module.scss';

const HomePageCarousel = ({slides, className}) => {
    const isSingleSlide = slides.length === 1;
    const sliderOptions = {
        centerPadding: '0px',
        variableWidth: true,
        centerMode: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className={cn(styles.HomePageCarousel, {[styles.HomePageSingleSlide]: isSingleSlide}, className)}>
            <Carousel
                ariaLabelRight='Scroll right'
                ariaLabelLeft='Scroll left'
                controlArrowClassName='sm:block -translate-y-full'
                controlArrowRightClassName='lg:end-0'
                options={sliderOptions}
                isControlArrowVisible
                isDotsVisible={false}
            >
                {slides.map((slide, index) => (
                    <Fragment key={index}>{slide}</Fragment>
                ))}
            </Carousel>
        </div>
    );
};

export default HomePageCarousel;
