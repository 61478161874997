import {ICC_SSO_AD_DEEPLINK_TEMPLATE} from '../../../consts/icc/iccSsoDeeplinkTemplates';
import useExternalLink from '../../../hooks/useExternalLink';

const useRedirectToPhase2 = ({disabled} = {}) => {
    const {redirect: redirectToPhase2} = useExternalLink({
        ssoTemplate: ICC_SSO_AD_DEEPLINK_TEMPLATE,
        openInBluefy: true,
        disabled,
    });

    return redirectToPhase2;
};

export default useRedirectToPhase2;
