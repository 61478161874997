import React from 'react';

import Icon from '../Icon/Icon';

const ArrowIcon = (props) => {
    return (
        <Icon width='24' height='24' fill='none' {...props}>
            <path d='M9 4L17 12L9 20' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' />
        </Icon>
    );
};

export default ArrowIcon;
