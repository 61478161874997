export const authMapping = (responseData) => {
    const RESPONSE_DELAY_MS = 10 * 1000;
    const expirationDate = Date.now() + responseData.expires_in * 1000 - RESPONSE_DELAY_MS;

    return {
        accessToken: responseData.access_token,
        refreshToken: responseData.refresh_token,
        expirationDate,
    };
};

const DEFAULT_EXPIRATION_TIME_MS = 5 * 60_000;

export const loginTokenMapping = (responseData) => {
    const expiryDate = new Date(responseData.ExpiryDate);
    const now = new Date();
    const isExpiryDateValid = expiryDate > now;
    const expirationDate = isExpiryDateValid ? expiryDate.getTime() : now.getTime() + DEFAULT_EXPIRATION_TIME_MS;

    return {
        url: responseData.CampaignUrlPersonal,
        expirationDate,
    };
};

export const authUamMapping = (responseData, codentify) => {
    return {
        uamAuthToken: responseData.AuthToken,
        uamApiUrl: responseData.UamUrl,
        uamApiKey: responseData.UamApiKey,
        assetId: responseData.Assets?.[0]?.assetId,
        codentify,
    };
};
