import {useSelector} from 'react-redux';

import appConfig from '../../../../../config/appConfig';
import AMPLITUDE_EVENT_VALUES from '../../../../../consts/amplitude/amplitudeEventValues';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getDefaultDeviceData} from '../../../../../services/device/deviceData';
import {selectConsumerProductsMergedInfo} from '../../../../../state/selectors/consumer';
import arrayUtils from '../../../../../utils/arrayUtils';
import DeviceItem from '../components/DeviceItem';

const mergeAndSortArrays = (array, arrayWithOrder) => {
    return arrayUtils.sortArrayWithOrderField(
        array.map((x) => {
            const item = arrayWithOrder.find((y) => y.deviceType === x || y.type === x);

            return {deviceType: x, order: item?.order, promotedTitleKey: item?.promotedTitleKey};
        })
    );
};

const useGetDeviceSectionList = () => {
    const yourDevicesPromotionList = appConfig.getYourDevicesPromotionList();
    const yourDevicePromotionTypes = yourDevicesPromotionList.map((x) => x.deviceType);
    const deviceSupportList = getDefaultDeviceData();

    const products = useSelector(selectConsumerProductsMergedInfo) || [];
    const mergedProductTypes = [...new Set(products.map((item) => item.type))];

    const productsInList = mergedProductTypes.filter((x) => yourDevicePromotionTypes.includes(x));
    const productInListSorted = mergeAndSortArrays(productsInList, yourDevicesPromotionList);
    const productsNotInList = mergedProductTypes.filter((x) => !yourDevicePromotionTypes.includes(x));
    const productsNotInListSorted = mergeAndSortArrays(productsNotInList, deviceSupportList);

    const shopDevicesExternalLink = appConfig.getShopDevicesExternalLink();
    const shopAccessoriesExternalLink = appConfig.getShopAccessoriesExternalLink();
    const tradeInExternalLink = appConfig.getTradeInExternalLink();

    const getDeviceData = (deviceType) => {
        const deviceDefaultData = deviceSupportList.find((x) => x.type === deviceType);
        const {fullType, name, images} = deviceDefaultData || {};

        return {type: fullType, defaultProductName: name, image: images};
    };

    const getDevicesInList = () => {
        return productInListSorted.map((device) => {
            return (
                <DeviceItem
                    device={getDeviceData(device.deviceType)}
                    promotedTitleKey={device.promotedTitleKey}
                    externalLinkData={shopAccessoriesExternalLink}
                    actionLocalizationKey={localizationKeys.SHOP_YOUR_DEVICES_SHOP_ACCESSORIES}
                    amplitudeButtonType={AMPLITUDE_EVENT_VALUES.SHOP_ACCESSORIES}
                />
            );
        });
    };

    const getPromotedDevices = () => {
        return arrayUtils.sortArrayWithOrderField(yourDevicesPromotionList).map((device) => {
            return (
                <DeviceItem
                    device={getDeviceData(device.deviceType)}
                    promotedTitleKey={device.promotedTitleKey}
                    externalLinkData={shopDevicesExternalLink}
                    actionLocalizationKey={localizationKeys.SHOP_YOUR_DEVICES_SHOP_DEVICES}
                    amplitudeButtonType={AMPLITUDE_EVENT_VALUES.SHOP_DEVICE}
                />
            );
        });
    };

    const getTradeInList = () => {
        const tradeInItems = productsNotInListSorted.map((device) => {
            const deviceType = device.deviceType;
            const isTradeInEnabled = appConfig.getIsTradeInEnabled(deviceType);

            if (!isTradeInEnabled) return null;

            return (
                <DeviceItem
                    device={getDeviceData(deviceType)}
                    externalLinkData={tradeInExternalLink}
                    actionLocalizationKey={localizationKeys.SHOP_YOUR_DEVICES_TRADE_IN}
                    amplitudeButtonType={AMPLITUDE_EVENT_VALUES.TRADE_IN}
                />
            );
        });

        return arrayUtils.filterEmptyObjects(tradeInItems);
    };

    const tradeInList = getTradeInList();

    if (tradeInList?.length) {
        if (productsInList?.length) {
            return [...getDevicesInList(), ...getTradeInList()];
        } else {
            return [...getPromotedDevices(), ...getTradeInList()];
        }
    }

    if (productsInList?.length) {
        return getDevicesInList();
    }

    return getPromotedDevices();
};

export default useGetDeviceSectionList;
