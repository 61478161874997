import React from 'react';

import {AUTO_DELIVERY_ACTIVE_TITLE} from '../../../consts/localization/localizationKeys';
import appDateTimeService from '../../../services/appDateTimeService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import AutoDeliveryStatusTitle from '../AutoDeliveryStatusTitle';

const AutoDeliveryActiveTitle = ({className, nextDeliveryDate, maxNextDeliveryDate}) => {
    const localizedStrings = getLocalizedStrings();
    const text = localizedStrings.formatString(
        localizedStrings[AUTO_DELIVERY_ACTIVE_TITLE],
        appDateTimeService.getLocalizedDayAndShortMonth(nextDeliveryDate),
        appDateTimeService.getLocalizedDayAndShortMonth(maxNextDeliveryDate)
    );

    return <AutoDeliveryStatusTitle className={className} title={text} />;
};

export default AutoDeliveryActiveTitle;
