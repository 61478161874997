import React from 'react';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';
import {WarningIcon} from '../../../components/Icons';
import {PopupFullPage} from '../../../components/Popup';
import ModalTypes from '../../../consts/app/modalTypes';
import appRouterService from '../../../services/route/appRouterService';
import {hideModalAction} from '../../../state/slices/modalSlice';
import {dispatch} from '../../../state/store';

const UWMUnavailabilityPopup = ({refreshAction}) => {
    const onCancelClick = () => {
        dispatch(hideModalAction(ModalTypes.UWM_UNAVAILABILITY));

        appRouterService.forwardToHomePage();
    };

    return (
        <PopupFullPage>
            <div className='flex flex-col items-center justify-center px-8 pb-8 pt-18'>
                <WarningIcon className='mb-4 h-6 w-6 lg:mb-6 lg:h-8 lg:w-8' />
                <h1>
                    <AemComponent component={EditableText} pageName='modals' itemName='uwmunavailabletitle' />
                </h1>
                <p>
                    <span className='whitespace-pre-line'>
                        <AemComponent component={EditableText} pageName='modals' itemName='uwmunavailabledesc' />
                    </span>
                </p>
                <AppButton primary light onClick={refreshAction}>
                    <AemComponent component={EditableText} pageName='modals' itemName='uwmunavailablerefreshbutton' />
                </AppButton>
                <AppButton secondary light className={'mt-4'} onClick={onCancelClick}>
                    <AemComponent component={EditableText} pageName='modals' itemName='uwmunavailablenotnowbutton' />
                </AppButton>
            </div>
        </PopupFullPage>
    );
};

export default UWMUnavailabilityPopup;
