import {useSelector} from 'react-redux';

import {selectIsMobileLayout} from '../../state/selectors/global';
import Popup from './Popup';
import PopupStickedWithSwipeClose from './PopupStickedWithSwipeClose';

const PopupMobileSticked = (props) => {
    const isMobileLayout = useSelector(selectIsMobileLayout);
    const Component = isMobileLayout ? PopupStickedWithSwipeClose : Popup;

    return <Component {...props} />;
};

export default PopupMobileSticked;
