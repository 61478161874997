import React from 'react';

import routerService from '../../services/route/routerService.js';
import {twx} from '../../utils/tailwindUtils.js';
import {ButtonRaw} from '../Button/index.js';
import {ArrowLeftIcon} from '../Icons/index.js';

const BackButton = (props) => {
    const {className, onClick = routerService.goBack, alt, ...restProps} = props;
    const altResult = alt || 'Back';

    return (
        <ButtonRaw
            onClick={onClick}
            className={twx('flex h-12 w-12 items-center justify-center', className)}
            {...restProps}
        >
            <ArrowLeftIcon className='rtl:rotate-180' ariaLabel={altResult} />
        </ButtonRaw>
    );
};

export default BackButton;
