import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';
import amplitude from './amplitude';

const trackHomePageScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.HOME_SCREEN_VIEWED);
};

const trackHomePageTopBannerButtonClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.HOME_PAGE_TOP_BANNER_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.TRACKING_VALUE]: trackingValue,
    });
};

const trackLoyaltyButtonsBarClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.LOYALTY_BUTTONS_BAR_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.TRACKING_VALUE]: trackingValue,
    });
};

const trackHighlightBannerClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.HIGHLIGHT_BANNER_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.TRACKING_VALUE]: trackingValue,
    });
};

const trackForYouTileOpened = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.FOR_YOU_TILE_OPENED, {
        [AMPLITUDE_EVENT_PROPERTIES.TRACKING_VALUE]: trackingValue,
    });
};

const trackSuggestForYou = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.SUGGESTED_FOR_YOU_LINK_OPENED, {
        [AMPLITUDE_EVENT_PROPERTIES.TRACKING_VALUE]: trackingValue,
    });
};

export default {
    trackHomePageScreen,
    trackHomePageTopBannerButtonClick,
    trackLoyaltyButtonsBarClick,
    trackHighlightBannerClick,
    trackForYouTileOpened,
    trackSuggestForYou,
};
