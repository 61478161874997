import React from 'react';

import {useGetAutoDeliveryDetailsExtended} from '../../api/consumerApi';
import AppLoader from '../../components/Loader/AppLoader/AppLoader';
import {AUTO_DELIVERY_STATUS_TYPES} from '../../consts/icc/iccAutoDeliveryStatusTypes';
import appRouterService from '../../services/route/appRouterService';
import StatusActive from './AutoDeliveryDetailsPages/StatusActive/StatusActive';
import StatusInactive from './AutoDeliveryDetailsPages/StatusInactive';
import StatusNone from './AutoDeliveryDetailsPages/StatusNone';
import StatusOnHold from './AutoDeliveryDetailsPages/StatusOnHold';
import StatusPaymentPending from './AutoDeliveryDetailsPages/StatusPaymentPending';

const getAutoDeliveryDetailsComponent = (status) => {
    switch (status) {
        case AUTO_DELIVERY_STATUS_TYPES.NONE:
        default:
            return StatusNone;
        case AUTO_DELIVERY_STATUS_TYPES.INACTIVE:
            return StatusInactive;
        case AUTO_DELIVERY_STATUS_TYPES.ACTIVE:
            return StatusActive;
        case AUTO_DELIVERY_STATUS_TYPES.PAYMENT_PENDING:
            return StatusPaymentPending;
        case AUTO_DELIVERY_STATUS_TYPES.ON_HOLD:
            return StatusOnHold;
    }
};

const AutoDeliveryPage = () => {
    const {data, isLoading, isError} = useGetAutoDeliveryDetailsExtended();

    if (isLoading) return <AppLoader />;
    if (isError) {
        appRouterService.replaceToErrorPage();
        return null;
    }

    const {
        autoDelivery: {status},
    } = data;

    const AutoDeliveryDetailsComponent = getAutoDeliveryDetailsComponent(status);

    if (!AutoDeliveryDetailsComponent) return;

    return <AutoDeliveryDetailsComponent />;
};

export default AutoDeliveryPage;
