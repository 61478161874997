import {EditableComponent} from '@adobe/aem-react-editable-components';
import React from 'react';

import {FormInput} from './FormInput';

const EditableFormInput = (props) => (
    <EditableComponent {...props}>
        <FormInput {...props} />
    </EditableComponent>
);

export default EditableFormInput;
