import defaultImage_DEV_TYPE_P1_ARIANE from '../../../assets/images/devices/default/DEV_TYPE_P1_ARIANE.png';
import defaultImage_DEV_TYPE_P1V30_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V30_CHARGER.png';
import defaultImage_DEV_TYPE_P1V30_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_P1V30_HOLDER.png';
import defaultImage_DEV_TYPE_P1V30M from '../../../assets/images/devices/default/DEV_TYPE_P1V30M.png';
import defaultImage_DEV_TYPE_P1V31_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V31_CHARGER.png';
import defaultImage_DEV_TYPE_P1V31_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_P1V31_HOLDER.png';
import defaultImage_DEV_TYPE_P1V31_ORIGINALS_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V31_ORIGINALS_CHARGER.png';
import defaultImage_DEV_TYPE_P1V31_ORIGINALS_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_P1V31_ORIGINALS_HOLDER.png';
import defaultImage_DEV_TYPE_P1V31M from '../../../assets/images/devices/default/DEV_TYPE_P1V31M.png';
import defaultImage_DEV_TYPE_P1V40C_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V40C_CHARGER.png';
import defaultImage_DEV_TYPE_P1V40C_HOLDER_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V40C_HOLDER_CONTROL.png';
import defaultImage_DEV_TYPE_P1V40D_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V40D_CONTROL.png';
import defaultImage_DEV_TYPE_P1V40V_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V40V_CHARGER.png';
import defaultImage_DEV_TYPE_P1V40V_HOLDER_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V40V_HOLDER_CONTROL.png';
import defaultImage_DEV_TYPE_P1V41J_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V41J_CONTROL.png';
import defaultImage_DEV_TYPE_P1V41M_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V41M_CHARGER.png';
import defaultImage_DEV_TYPE_P1V41M_HOLDER_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V41M_HOLDER_CONTROL.png';
import defaultImage_DEV_TYPE_P1V41P_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V41P_CHARGER.png';
import defaultImage_DEV_TYPE_P1V41P_HOLDER_CONTROL from '../../../assets/images/devices/default/DEV_TYPE_P1V41P_HOLDER_CONTROL.png';
import defaultImage_DEV_TYPE_P4_M3_G2_iqos_title from '../../../assets/images/devices/default/DEV_TYPE_P4_M3_G2_IQOS_TITLE.png';
import defaultImage_DEV_TYPE_V24_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_V24_CHARGER.png';
import defaultImage_DEV_TYPE_V24_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_V24_HOLDER.png';
import defaultImage_DEV_TYPE_V24P_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_V24P_CHARGER.png';
import defaultImage_DEV_TYPE_V24P_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_V24P_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V30_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V30_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V30M_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V30M_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V31_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V31_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V31_ORIGINALS_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V31_ORIGINALS_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V31M_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V31M_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_P1V40D_CONTROL from '../../../assets/images/devices/singleHolder/DEV_TYPE_P1V40D_CONTROL.png';
import defaultSingleHolder_DEV_TYPE_V24_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_V24_HOLDER.png';
import defaultSingleHolder_DEV_TYPE_V24P_HOLDER from '../../../assets/images/devices/singleHolder/DEV_TYPE_V24P_HOLDER.png';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import * as localizationKeys from '../localization/localizationKeys';
import {DEVICE_TYPES} from './deviceTypes';

let data;

const getDeviceTypesData = () => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();

        data = {
            [DEVICE_TYPES.DEV_TYPE_UNKNOWN]: null,
            [DEVICE_TYPES.DEV_TYPE_V24_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24_CHARGER,
                description: 'IQOS 2.4 Charger',
                name: 'IQOS 2.4 Charger',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_V24_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_V24_HOLDER,
                modelName: 'IQOS 2.4+ Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_V24_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_V24_HOLDER,
                description: 'IQOS 2.4 Holder',
                isHolder: true,
                name: 'IQOS 2.4 Holder',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_V24P_HOLDER,
                modelName: 'IQOS 2.4 Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24P_CHARGER,
                description: 'IQOS 2.4 Plus Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORDINARY_NAME],
                deviceModel: 'IQOS 2.4 Plus',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_V24P_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_V24P_HOLDER,
                modelName: 'IQOS 2.4 Plus Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_V24P_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24P_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_V24P_HOLDER,
                description: 'IQOS 2.4 Plus Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_ORDINARY_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_V24P_HOLDER,
                modelName: 'IQOS 2.4 Plus Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30_CHARGER,
                description: 'IQOS 3.0 Charger (Cortez)',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_CORTEZ_NAME],
                deviceModel: 'IQOS 3.0',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_P1V30_HOLDER,
                modelName: 'IQOS 3.0 Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V30_HOLDER,
                description: 'IQOS 3.0 Holder (Cortez)',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_CORTEZ_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V30_HOLDER,
                modelName: 'IQOS 3.0 Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30M]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30M,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V30M_HOLDER,
                description: 'IQOS 3.0 Multi',
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_VESPUCCI_NAME],
                deviceModel: 'IQOS 3.0 Multi',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V30M,
                modelName: 'IQOS MULTI',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31M]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31M,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V31M_HOLDER,
                description: 'IQOS Original One',
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORIGINALS_ONE_NAME],
                deviceModel: 'IQOS Original One',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V31M,
                modelName: 'IQOS ORIGINALS MONO',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31_CHARGER,
                description: 'IQOS 3.1 Charger (Soyuz)',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_SOYUZ_NAME],
                deviceModel: 'IQOS 3.1',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER,
                modelName: 'IQOS 3.1 Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V31_HOLDER,
                description: 'IQOS 3.1 Holder (Soyuz)',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_SOYUZ_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER,
                modelName: 'IQOS 3.1 Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31_ORIGINALS_CHARGER,
                description: 'IQOS Original Duo Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORIGINALS_DUO_NAME],
                deviceModel: 'IQOS Original Duo',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_HOLDER,
                modelName: 'IQOS ORIGINALS DUO Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31_ORIGINALS_HOLDER,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V31_ORIGINALS_HOLDER,
                description: 'IQOS Original Duo Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_ORIGINALS_DUO_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_HOLDER,
                modelName: 'IQOS ORIGINALS DUO Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_P4_M3_G1]: {
                description: 'IQOS P4 gen. 1 (Mesh)',
                isMono: true,
                name: 'Mesh',
                deviceModel: 'IQOS VEEV',
                type: DEVICE_TYPES.DEV_TYPE_P4,
                fullType: DEVICE_TYPES.DEV_TYPE_P4_M3_G1,
                modelName: 'IQOS VEEV',
            },
            [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P4_M3_G2_iqos_title,
                description: 'IQOS P4 gen. 2 (Titan)/(VEEV)',
                isHolder: false,
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_TITAN_NAME],
                deviceModel: 'IQOS VEEV',
                type: DEVICE_TYPES.DEV_TYPE_P4,
                fullType: DEVICE_TYPES.DEV_TYPE_P4_M3_G2,
                modelName: 'IQOS VEEV',
            },
            [DEVICE_TYPES.DEV_TYPE_P1_ARIANE]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1_ARIANE,
                description: 'P1 Ariane (Mono)',
                isHolder: false,
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ARIANE_NAME],
                deviceModel: 'BONDS by IQOS',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                fullType: DEVICE_TYPES.DEV_TYPE_P1_ARIANE,
                modelName: 'ARIANE',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40V_CHARGER,
                description: 'IQOS P1V40 Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40_NAME],
                deviceModel: 'IQOS Iluma Prime',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_P1V40V_HOLDER_CONTROL,
                modelName: 'IQOS ILUMA PRIME Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40V_HOLDER_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40V_HOLDER_CONTROL,
                description: 'IQOS P1V40 Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_P1V40_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40V_HOLDER_CONTROL,
                modelName: 'IQOS ILUMA PRIME Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40C_CHARGER,
                description: 'IQOS P1V40C Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40M_NAME],
                deviceModel: 'IQOS Iluma Mid',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_P1V40C_HOLDER_CONTROL,
                modelName: 'IQOS ILUMA MID Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40C_HOLDER_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40C_HOLDER_CONTROL,
                description: 'IQOS P1V40C Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_P1V40M_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40C_HOLDER_CONTROL,
                modelName: 'IQOS ILUMA MID Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V40D_CONTROL,
                defaultSingleHolder: defaultSingleHolder_DEV_TYPE_P1V40D_CONTROL,
                description: 'IQOS P1V40 (Delta)',
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V40D_NAME],
                deviceModel: 'IQOS Iluma One',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL,
                modelName: 'IQOS ILUMA ONE',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V41P_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V41P_CHARGER,
                description: 'IQOS P1V41P Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V41P_NAME],
                deviceModel: 'IQOS Iluma i Prime',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V41P_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_P1V41P_HOLDER_CONTROL,
                modelName: 'IQOS ILUMA i PRIME Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V41P_HOLDER_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V41P_HOLDER_CONTROL,
                description: 'IQOS P1V41P Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_P1V41P_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V41P_HOLDER_CONTROL,
                modelName: 'IQOS ILUMA i PRIME Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V41M_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V41M_CHARGER,
                description: 'IQOS P1V41M Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V41M_NAME],
                deviceModel: 'IQOS Iluma i Mid',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V41M_CHARGER,
                defaultHolderType: DEVICE_TYPES.DEV_TYPE_P1V41M_HOLDER_CONTROL,
                modelName: 'IQOS ILUMA i MID Charger',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V41M_HOLDER_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V41M_HOLDER_CONTROL,
                description: 'IQOS P1V41M Holder',
                isHolder: true,
                name: localizedStrings[localizationKeys.ABOUT_DEVICE_P1V41M_HOLDER_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V41M_HOLDER_CONTROL,
                modelName: 'IQOS ILUMA i MID Holder',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V41J_CONTROL]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V41J_CONTROL,
                description: 'IQOS P1V41J Charger',
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_P1V41J_NAME],
                deviceModel: 'IQOS Iluma i One',
                type: DEVICE_TYPES.DEV_TYPE_P1_V4,
                fullType: DEVICE_TYPES.DEV_TYPE_P1V41J_CONTROL,
                modelName: 'IQOS ILUMA i ONE',
            },
        };
    }

    return data;
};

export {getDeviceTypesData};
