import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import domService from '../../../services/domService';
import tncService from '../../../services/tncService';
import {makeSelectTncLegalsByType, selectTncLegals} from '../../../state/selectors/consumer';
import {setTncPopupCode} from '../../../state/slices/tncSlice';
import {dispatch} from '../../../state/store';
import styles from './TermsOfUseDescription.module.scss';

const TermsOfUseDescription = (props) => {
    const {innerHtml, className, tncType} = props;
    const legals = useSelector(selectTncLegals);
    const tncLegals = useSelector(makeSelectTncLegalsByType(tncType));
    const tncLinkClass = styles.TncLink;

    const onReadButtonClick = (e) => {
        const {target} = e;
        const tncCode = tncService.getTncCodeFromTarget(target, tncLinkClass);

        if (tncCode) {
            dispatch(setTncPopupCode(tncCode));
        }
    };

    const textParsed = tncService.getParsedDescription(innerHtml, tncLinkClass, legals, tncLegals);

    const onKeyPress = (e) => domService.handleBtnKeyPress(e, onReadButtonClick);

    return (
        <p
            className={cn('whitespace-pre-line', className)}
            dangerouslySetInnerHTML={{__html: textParsed}}
            onClick={onReadButtonClick}
            onKeyPress={onKeyPress}
        />
    );
};

export default TermsOfUseDescription;
