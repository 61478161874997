import {fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import appConfig from '../config/appConfig';
import authDataService from '../services/auth/authDataService';
import iccResponseErrorCheckService from '../services/icc/iccResponseErrorCheckService';
import {getMappedInitialLanguageCode} from '../services/localization/localizationService';
import log from '../services/logger/log';
import urlUtils from '../utils/urlUtils';

export const getHeaders = async (headers) => {
    const authToken = await authDataService.getAuthToken();

    if (!authToken) return headers;

    const appVersion = appConfig.getAppVersion();
    const languageCode = getMappedInitialLanguageCode()?.toUpperCase();

    headers.set('Authorization', 'Bearer ' + authToken);
    headers.set('v', appVersion);
    headers.set('l', languageCode);

    return headers;
};

export const getBaseQuery = (baseUrlGetter) => {
    const baseQuery = async (args, api, extraOptions) => {
        const baseUrl = baseUrlGetter();
        const url = urlUtils.join(baseUrl, args);
        const rawBaseQuery = fetchBaseQuery({
            baseUrl,
            prepareHeaders: getHeaders,
        });

        log.debug(`Request has started. Url: ${url}`);

        const result = await rawBaseQuery(args, api, extraOptions);
        const {error} = result || {};

        if (error) {
            const requestData = result?.meta?.request;
            const {method} = requestData || {};

            log.error(`requestApi: url: ${url}; requestConfig: {}, method: ${method}; error: ${JSON.stringify(error)}`);
            await iccResponseErrorCheckService({status: error?.status, error});

            return baseQuery(args, api, extraOptions);
        }

        log.info(`Request has successful response. Url: ${url}`);

        return result;
    };

    return baseQuery;
};

export const getRefetchOnFocus = () => process.env.REACT_APP_ENV !== 'dev';
