import React, {useEffect} from 'react';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';
import SupportAndStoreTemplate from '../../../components/SupportAndStoreTemplate/SupportAndStoreTemplate';
import * as advanceDiagnosticIconTypes from '../../../consts/advanceDiagnostic/advanceDiagnosticIconTypes';
import amplitudeADTracking from '../../../services/amplitude/amplitudeADTracking';
import appRouterService from '../../../services/route/appRouterService';
import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticIcon from '../components/AdvancedDiagnosticIcon';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';

const AdvancedDiagnosticFittingConsumablesSolved = () => {
    useEffect(() => {
        amplitudeADTracking.trackADInitialQuestionnaireScreen();
    }, []);

    return (
        <AdvancedDiagnosticContainer isDeviceHero>
            <AdvancedDiagnosticIcon type={advanceDiagnosticIconTypes.SUCCESS} />
            <div className='px-8'>
                <AdvancedDiagnosticTitle itemName='consumablessolvedtitle' />
                <AdvancedDiagnosticDescription itemName='consumablessolveddesc' />
            </div>
            <div className='mt-10 w-full px-8 md:w-auto'>
                <AppButton primary light onClick={appRouterService.forwardToDevicesPage} className='mb-4 mt-0'>
                    <AemComponent component={EditableText} pageName='ad' itemName='consumablessolvedbutton' />
                </AppButton>
            </div>
            <SupportAndStoreTemplate className='mb-4 mt-0 md:mb-6 md:flex-col lg:flex-row' />
        </AdvancedDiagnosticContainer>
    );
};

export default AdvancedDiagnosticFittingConsumablesSolved;
