import {ICC_SSO_GUIDE_DEEPLINK_TEMPLATE} from '../../../consts/icc/iccSsoDeeplinkTemplates';
import useExternalLink from '../../../hooks/useExternalLink';
import marketConfigService from '../../../services/marketConfigService';
import appRouterService from '../../../services/route/appRouterService';
import uaUtils from '../../../utils/uaUtils';

const useOnConnectClick = (deviceType) => {
    const {redirect: redirectToWebAppGuidePage} = useExternalLink({
        ssoTemplate: ICC_SSO_GUIDE_DEEPLINK_TEMPLATE,
        openInBluefy: true,
        ssoParams: {
            deviceType,
        },
    });

    const {isBleDisabled} = marketConfigService.getDeviceData(deviceType) || {};
    const isIos = uaUtils.getIsIos();

    const onConnectClick = () => {
        if (isBleDisabled && isIos) {
            appRouterService.forwardToUsbRequiredPage(deviceType);
        } else {
            redirectToWebAppGuidePage();
        }
    };

    return {onConnectClick};
};

export default useOnConnectClick;
