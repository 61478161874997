import React from 'react';

import Icon from '../Icon/Icon';

const SelectedIcon = ({props}) => {
    return (
        <Icon width='24' height='24' fill='none' {...props}>
            <g>
                <path
                    id='Path4'
                    d='M4 13.0543L8.5244 17.7895L20 6'
                    stroke='#34303D'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
        </Icon>
    );
};

export default SelectedIcon;
