import cn from 'classnames';
import React, {useId} from 'react';

import helpers from '../../utils/helpers';
import {SelectedIcon} from '../Icons';

const SelectList = ({className, data = [], selectedValue, onChange}) => {
    const name = useId();

    const onItemChange = (e) => {
        helpers.runFunction(onChange, e.target.value);
    };

    return (
        <div className={cn('flex w-full flex-col', className)}>
            {data.map((item) => {
                const {title, value} = item;
                const isChecked = selectedValue === value;

                return (
                    <div key={value} className='flex w-full justify-between border-b border-border py-5'>
                        <label className='grow cursor-pointer px-4 text-left text-16 md:px-4'>
                            <input
                                className='hidden'
                                type='radio'
                                name={name}
                                value={value}
                                checked={isChecked}
                                onChange={onItemChange}
                            />
                            {title}
                        </label>
                        {isChecked && (
                            <div className='mr-5'>
                                <SelectedIcon />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default SelectList;
