import React from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {selectConsumerName} from '../../../state/selectors/consumer';

const ShopPageHeader = () => {
    const userName = useSelector(selectConsumerName);

    return (
        <article className='bg-romance px-4 pb-4 mt-safe-offset-14'>
            <h1 className='mb-2 text-32'>
                <AemEditableText pageName='shop' itemName='title' />
            </h1>
            <p>
                <AemEditableText pageName='shop' itemName='subtitle' /> {userName}
            </p>
        </article>
    );
};

export default ShopPageHeader;
