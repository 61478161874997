import React from 'react';

import Icon from '../Icon/Icon';

const StarIcon = (props) => {
    return (
        <Icon width='48' height='48' stroke='#B8B7BB' fill='transparent' {...props}>
            <path
                d='M23.5352 33.3237C23.8267 33.1745 24.1733 33.1745 24.4648 33.3237L33.9002 38.1533L32.1023 27.947C32.0445 27.6193 32.1555 27.2847 32.3982 27.0544L40 19.8399L29.4798 18.3515C29.1518 18.3051 28.8677 18.1028 28.7192 17.81L24 8.5L19.2808 17.81C19.1323 18.1028 18.8482 18.3051 18.5202 18.3515L8 19.8399L15.6018 27.0544C15.8445 27.2847 15.9555 27.6193 15.8977 27.947L14.0998 38.1533L23.5352 33.3237Z'
                strokeWidth='1.5'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default StarIcon;
