import appConfig from '../../config/appConfig';
import appRouterService from '../../services/route/appRouterService';
import {dispatch} from '../../state/store';
import {setDiagnosticFlowDataResult} from './state/advancedDiagnosticSlice';

const isEnabled = (type) => {
    const adConfig = appConfig.getAdConfig(type);

    return !!adConfig?.device;
};

const setInferenceCodeAndRedirect = (inferenceCode, isHolder, screenName, isQuestionnaireThirdStep) => {
    const currentInferenceCode = isHolder
        ? {
              holderInferenceCode: inferenceCode,
              holderInferenceCode_L1: inferenceCode,
          }
        : {
              deviceInferenceCode: inferenceCode,
              deviceInferenceCode_L1: inferenceCode,
          };

    dispatch(setDiagnosticFlowDataResult({isQuestionnaireThirdStep, ...currentInferenceCode}));
    appRouterService.forwardToAdvancedDiagnosticScreen({screenName});
};

export default {
    isEnabled,
    setInferenceCodeAndRedirect,
};
