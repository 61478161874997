import {useEffect} from 'react';

import {useGetAutoDeliveryDetailsExtended} from '../../../api/consumerApi';
import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {StickyHeader} from '../../../components/Layout';
import AMPLITUDE_EVENT_VALUES from '../../../consts/amplitude/amplitudeEventValues';
import amplitudeAutodeliveryTracking from '../../../services/amplitude/amplitudeAutoDeliveryTracking';
import routerService from '../../../services/route/routerService';
import AutoDeliveryActiveProgress from '../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryPaymentPendingButton from '../../../views/AutoDelivery/AutoDeliveryPaymentPending/AutoDeliveryPaymentPendingButton';
import AutoDeliveryStatusTitle from '../../../views/AutoDelivery/AutoDeliveryStatusTitle';
import PageWarningBannersContainer from '../../WarningBanners/PageWarningBannersContainer';
import AutoDeliveryDetailsFooter from './components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from './components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from './components/AutoDeliveryOrderAddress';

const StatusPaymentPending = () => {
    const {
        data: {
            autoDelivery: {status},
            order: {deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();

    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderButtonClick(
            AMPLITUDE_EVENT_VALUES.CHECK_ORDER_STATUS,
            AMPLITUDE_EVENT_VALUES.PAYMENT_PENDING
        );
    };

    useEffect(() => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryOrderScreen(AMPLITUDE_EVENT_VALUES.PAYMENT_PENDING);
    }, []);

    return (
        <div className='w-full text-start'>
            <StickyHeader
                onBack={routerService.goBack}
                title={<AemEditableText pageName='auto-delivery' itemName='autoDeliveryPaymentPendingTitle' />}
            />
            <div className='p-4 pt-safe-offset-14'>
                <AutoDeliveryStatusTitle
                    className='text-28'
                    title={<AemEditableText pageName='auto-delivery' itemName='autoDeliveryPaymentPendingTitle' />}
                />
                <AutoDeliveryActiveProgress status={status} className='mt-8' />
                <AutoDeliveryPaymentPendingButton className='mt-8' amplitudeEvent={amplitudeEvent} />
            </div>
            <AutoDeliveryOrder className='border-t-8 border-secondary' products={products} />
            <AutoDeliveryOrderAddress className='border-t-8 border-secondary' address={deliveryAddress} />
            <AutoDeliveryDetailsFooter className='border-t-8 border-secondary px-4 py-[34px]' />
            <PageWarningBannersContainer />
        </div>
    );
};

export default StatusPaymentPending;
