import React from 'react';

import AppLoaderRaw from '../../components/Loader/AppLoader/AppLoaderRaw';

const SplashScreen = () => {
    return (
        <div className='h-screen w-screen'>
            <AppLoaderRaw />
        </div>
    );
};

export default SplashScreen;
