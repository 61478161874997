import React from 'react';

import Icon from '../Icon/Icon';

const BellIcon = (props) => {
    return (
        <Icon width='64' height='64' fill='transparent' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M32 15.25C36.568 15.25 40.708 17.1021 43.7287 20.1031C46.7579 23.1125 48.662 27.2769 48.747 31.8911L48.7498 46.75H15.25V32.2254C15.3361 27.4642 17.2265 23.2254 20.2429 20.1731C23.2654 17.1146 27.4174 15.25 32 15.25Z'
                stroke='#34303D'
                strokeWidth='2.5'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M37.7499 46.7515C37.75 48.5864 37.0958 50.2199 36.0241 51.4007C34.9943 52.5355 33.5751 53.2499 32 53.2499C30.4249 53.2499 29.0057 52.5355 27.9759 51.4007C26.9042 50.2199 26.25 48.5864 26.25 46.7869L37.7499 46.7515Z'
                fill='#00D1D2'
                stroke='#34303D'
                strokeWidth='2.5'
            />
            <path
                d='M28 10.5C29.3333 10.1667 30.6667 10 32 10C33.3333 10 34.6667 10.1667 36 10.5'
                stroke='#34303D'
                strokeWidth='2.5'
                strokeLinecap='round'
            />
            <path d='M12 46.75H52' stroke='#34303D' strokeWidth='2.5' strokeLinecap='round' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M44.9964 31.6846L45 31.987V43.25L32 43.249L32.0002 19C39.1202 19.2497 44.8333 24.8072 44.9964 31.6846Z'
                fill='#00D1D2'
            />
        </Icon>
    );
};

export default BellIcon;
