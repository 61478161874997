import {useSelector} from 'react-redux';

import PRODUCT_COLORS from '../consts/product/productColors';
import deviceTypesService from '../services/device/deviceTypesService';
import {selectDeviceType} from '../state/selectors/device';

const useDevicePrimaryColor = (currentDeviceType, isVideoContent) => {
    const deviceTypeFromState = useSelector(selectDeviceType);
    const deviceType = currentDeviceType || deviceTypeFromState;
    const isP1V4 = deviceTypesService.isP1V4(deviceType);
    const isP1V41 = deviceTypesService.isP1V41(deviceType);
    const isJuno = deviceTypesService.isJuno(deviceType);

    if (isJuno && isVideoContent) {
        return PRODUCT_COLORS.BRIGHT_TEAL;
    } else if (isP1V41) {
        return PRODUCT_COLORS.TOURQOISE;
    } else if (isP1V4) {
        return PRODUCT_COLORS.BRIGHT_TEAL;
    }

    const isAriane = deviceTypesService.isAriane(deviceType);

    if (isAriane) {
        return PRODUCT_COLORS.FLUORESCENT_RED;
    }

    return PRODUCT_COLORS.TOURQOISE;
};

export default useDevicePrimaryColor;
