import React from 'react';

import AemComponent from '../../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../../components/Aem/EditableText/EditableText';
import appConfig from '../../../../config/appConfig';
import HomePageCarouselContainer from '../HomePageCarouselContainer/HomePageCarouselContainer';
import useGetForYouSectionList from './hooks/useGetForYouSectionList';

const HomePageForYouSection = ({selectedDevice}) => {
    const forYouSectionList = useGetForYouSectionList(selectedDevice);
    const isForYouSectionSwiperEnabled = appConfig.getHomePageIsForYouSectionSwiperEnabled();

    if (!forYouSectionList.length) return null;

    return (
        <div className='bg-romance pb-8 pl-2 pt-8'>
            <h1 className='mb-4 pl-2 pr-8 text-start text-24 lg:text-42'>
                <AemComponent component={EditableText} pageName='home' itemName='foryoutitle' />
            </h1>
            <HomePageCarouselContainer slides={forYouSectionList} isSwiper={isForYouSectionSwiperEnabled} />
        </div>
    );
};

export default HomePageForYouSection;
