import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../components/Button';
import {SupportServiceIcon} from '../../../components/Icons';

const ContactUsCallUs = ({callUs}) => {
    const doesAnyWorkingDaysExist = callUs.workingDays.some((d) => d.title && d.hours);

    if (!callUs.phoneNumber) return null;

    const {phoneNumber, workingDays} = callUs;

    return (
        <div className='flex w-full flex-col items-center'>
            <SupportServiceIcon className={'mb-3 min-h-12'} />
            <h3 className='text-24'>
                <AemEditableText pageName='contact-us' itemName='callUsTitle' />
            </h3>
            <p className='mb-6 mt-4'>
                <AemEditableText pageName='contact-us' itemName='callUsDesc' contentList={[phoneNumber]} />
            </p>
            {doesAnyWorkingDaysExist && (
                <ul className='w-full rounded-[4px] border border-border md:w-auto md:min-w-[460px]'>
                    <li className='flex justify-between border-b border-border px-4 py-6 font-bold last:border-b-0'>
                        <AemEditableText pageName='contact-us' itemName='callUsPhoneHours' />
                    </li>
                    {workingDays.map(({title, hours}) => (
                        <ScheduleItem title={title} hours={hours} />
                    ))}
                </ul>
            )}

            <div className='h-full w-full md:w-auto'>
                <AppButton
                    primary
                    light
                    className='mb-4 mt-10 md:min-w-[312px]'
                    to={`tel:${phoneNumber}`}
                    isExternalLink
                >
                    <AemEditableText pageName='contact-us' itemName='callUsButton' />
                </AppButton>
            </div>
        </div>
    );
};

const ScheduleItem = ({title, hours}) => {
    if (!(title && hours)) return null;

    return (
        <li className='flex justify-between border-b border-border px-4 py-6 last:border-b-0'>
            <span>{title}</span>
            <span>{hours}</span>
        </li>
    );
};

export default ContactUsCallUs;
