import cn from 'classnames';
import React from 'react';

import Link from '../Link/Link';
import styles from './Button.module.scss';

const ButtonRaw = (props) => {
    const {className, disabled, to, onClick, onDisableClick, children, ...restProps} = props;
    const btnClassName = cn(styles.ButtonRaw, className, {
        'ica-disabled': disabled,
        'cursor-default': disabled && !onDisableClick,
    });

    const eventProps = disabled
        ? {
              onClick: onDisableClick,
          }
        : {
              to,
              onClick,
          };

    return (
        <Link {...restProps} {...eventProps} disabled={disabled} className={btnClassName}>
            {children}
        </Link>
    );
};

export default ButtonRaw;
