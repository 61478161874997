import imageDamageCortez from '../../../../assets/images/advanced-diagnostics/damage-check/damage_Cortez.jpg';
import imageDamageCosmos from '../../../../assets/images/advanced-diagnostics/damage-check/damage_Cosmos.png';
import imageDamageDelta from '../../../../assets/images/advanced-diagnostics/damage-check/damage_Delta.png';
import imageDamageDuo from '../../../../assets/images/advanced-diagnostics/damage-check/damage_Duo.jpg';
import imageDamageDuoOriginals from '../../../../assets/images/advanced-diagnostics/damage-check/damage_DuoOriginals.jpg';
import imageDamageMulti from '../../../../assets/images/advanced-diagnostics/damage-check/damage_Multi.jpg';
import imageDamageOrdinary from '../../../../assets/images/advanced-diagnostics/damage-check/damage_Ordinary.jpg';
import imageDamageOriginalOne from '../../../../assets/images/advanced-diagnostics/damage-check/damage_OriginalOne.jpg';
import imageDamageVeev from '../../../../assets/images/advanced-diagnostics/damage-check/damage_Veev.jpg';
import imageDamageVoyager from '../../../../assets/images/advanced-diagnostics/damage-check/damage_Voyager.png';
import {DEVICE_TYPES} from '../../../consts/device/deviceTypes';

const ADVANCED_DIAGNOSTIC_DAMAGE_DATA = {
    [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: {defaultImage: imageDamageCortez},
    [DEVICE_TYPES.DEV_TYPE_P1V30M]: {defaultImage: imageDamageMulti},
    [DEVICE_TYPES.DEV_TYPE_P1V31M]: {defaultImage: imageDamageOriginalOne},
    [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: {defaultImage: imageDamageDuo},
    [DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER]: {defaultImage: imageDamageDuoOriginals},
    [DEVICE_TYPES.DEV_TYPE_P1V40C_CHARGER]: {defaultImage: imageDamageCosmos},
    [DEVICE_TYPES.DEV_TYPE_P1V40V_CHARGER]: {defaultImage: imageDamageVoyager},
    [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: {defaultImage: imageDamageVeev},
    [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: {defaultImage: imageDamageOrdinary},
    [DEVICE_TYPES.DEV_TYPE_P1V40D_CONTROL]: {defaultImage: imageDamageDelta},
};

export default ADVANCED_DIAGNOSTIC_DAMAGE_DATA;
