import {makeSelectIsLoaderVisible} from '../state/selectors/loaders';
import {hideLoader} from '../state/slices/loadersSlice';
import {dispatch, getState} from '../state/store';

const hideLoaderIfNeeded = (name) => {
    const isLoaderVisible = makeSelectIsLoaderVisible(name)(getState());

    if (!isLoaderVisible) return;

    dispatch(hideLoader(name));
};

export default {hideLoaderIfNeeded};
