import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';
import amplitude from './amplitude';

const trackShopPageScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.SHOP_SECTION_VIEWED);
};

const trackQuickOrderBannerClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.QUICK_ORDER_BANNER_CLICKED);
};

const trackQuickOrderButtonClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.QUICK_ORDER_BUTTON_CLICKED);
};

const trackYourDeviceButtonClick = (buttonType, modelName) => {
    if (!buttonType || !modelName) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.YOUR_DEVICES_BUTTON_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.BUTTON_TYPE]: buttonType,
        [AMPLITUDE_EVENT_PROPERTIES.IQOS_PRODUCT_MODEL_TYPE]: modelName,
    });
};

const trackShopByProductBannerClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.SHOP_BY_PRODUCT_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.TRACKING_VALUE]: trackingValue,
    });
};

export default {
    trackShopPageScreen,
    trackQuickOrderBannerClick,
    trackQuickOrderButtonClick,
    trackYourDeviceButtonClick,
    trackShopByProductBannerClick,
};
