import React from 'react';

import Icon from '../Icon/Icon';

const ErrorBigIcon = (props) => {
    return (
        <Icon width='65' height='64' {...props}>
            <g>
                <circle cx='32.5' cy='32' r='24' transform='rotate(90 32.5 32)' fill='#C73636' />
                <circle cx='32.5' cy='43.6001' r='2' fill='#FFFDFB' />
                <path d='M32.5 35.4011V20.4678' stroke='#FFFDFB' strokeWidth='1.5' strokeLinecap='round' />
            </g>
        </Icon>
    );
};

export default ErrorBigIcon;
