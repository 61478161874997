import cn from 'classnames';
import React from 'react';

import ButtonRaw from '../../Button/ButtonRaw';
import {ArrowIcon} from '../../Icons';
import styles from './CarouselArrow.module.scss';

const CarouselArrow = (props) => {
    const {onClick, customClassName, testId, ariaLabel, isVisible} = props;

    if (!isVisible) return null;

    return (
        <ButtonRaw
            ariaLabel={ariaLabel}
            testId={testId}
            onClick={onClick}
            className={cn(styles.Arrow, customClassName)}
        >
            <ArrowIcon />
        </ButtonRaw>
    );
};

export default CarouselArrow;
