import React, {useEffect} from 'react';

import useConsumerProductsLoader from '../../hooks/useConsumerProductsLoader';
import useRefetchConsumerProducts from '../../hooks/useRefetchConsumerProducts';
import amplitudeDevicesPageTracking from '../../services/amplitude/amplitudeDevicesPageTracking';
import MyDevicesView from './components/MyDevicesView/MyDevicesView';
import NoDevicesView from './components/NoDevicesView/NoDevicesView';
import useGetDeviceSectionList from './hooks/useGetDeviceSectionList';

const DevicesPage = () => {
    const deviceList = useGetDeviceSectionList();
    const isDeviceListEmpty = !deviceList?.length;

    useEffect(() => {
        amplitudeDevicesPageTracking.trackDevicesPageScreen();
    }, []);

    useRefetchConsumerProducts();
    useConsumerProductsLoader();

    return (
        <div className='ica-page w-full overflow-hidden bg-secondary text-start'>
            {isDeviceListEmpty ? <NoDevicesView /> : <MyDevicesView />}
        </div>
    );
};

export default DevicesPage;
