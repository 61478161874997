import React from 'react';

import Icon from '../Icon/Icon';

const ClockIcon = (props) => {
    return (
        <Icon width='64' height='64' fill='none' {...props}>
            <circle cx='32.0488' cy='32.0485' r='22.1047' stroke='#34303D' strokeWidth='2.5' />
            <path
                d='M31.9839 22.2593V35.2831L38.2658 29.0012'
                stroke='#34303D'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <circle cx='32' cy='15' r='2' fill='#00D1D2' />
            <circle cx='32' cy='49' r='2' fill='#00D1D2' />
            <circle cx='49' cy='32' r='2' fill='#00D1D2' />
            <circle cx='15' cy='32' r='2' fill='#00D1D2' />
        </Icon>
    );
};

export default ClockIcon;
