import cn from 'classnames';
import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {InfoIcon} from '../../../components/Icons';

const FirmwareVersionWarning = ({className}) => {
    return (
        <div className={cn('flex w-full items-start justify-between rounded-xl bg-secondary p-4', className)}>
            <div className='flex items-center gap-2 text-start'>
                <InfoIcon className='shrink-0' />
                <div>
                    <h4 className='mb-0 text-16 leading-[24px]'>
                        <AemEditableText pageName='about-device' itemName='firmwareWarningTitle' />
                    </h4>
                    <p className='text-14 leading-[20px]'>
                        <AemEditableText pageName='about-device' itemName='firmwareWarningDescription' />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FirmwareVersionWarning;
