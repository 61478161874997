import cn from 'classnames';
import React from 'react';

import styles from './Button.module.scss';
import ButtonRaw from './ButtonRaw';

const Button = ({showUnderline, children, arrowClassName, className, ...props}) => {
    const btnClassName = cn(styles.Button, className, {
        [styles.Underline]: showUnderline,
    });

    return (
        <ButtonRaw {...props} className={btnClassName}>
            {children}
        </ButtonRaw>
    );
};

export default Button;
