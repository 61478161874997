import {useEffect} from 'react';

const useHideNotification = (props) => {
    const {displayTime, hideNotification} = props;

    useEffect(() => {
        let notificationTimeout;

        if (displayTime) {
            notificationTimeout = setTimeout(() => {
                hideNotification();
            }, displayTime);
        }

        return () => {
            clearTimeout(notificationTimeout);
        };
    }, []);
};

export default useHideNotification;
