import cn from 'classnames';
import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import ContentList from '../../../components/ContentList/ContentList';
import {MaxWidthContainer} from '../../../components/Layout';
import appConfig from '../../../config/appConfig';

const HowItWorksSection = ({className}) => {
    const list = appConfig.getQuickOrderHowItWorksIcons();

    return (
        <article className={cn('mx-4 bg-romance py-8', className)}>
            <AemEditableText
                pageName='quick-order'
                itemName='howItWorksTitle'
                render={(title) => (title ? <h2 className='mb-0 text-24'>{title}</h2> : null)}
            />
            <MaxWidthContainer className='mt-4 flex-1 first:mt-0'>
                <ContentList list={list} className='[&>*]:gap-x-2' />
            </MaxWidthContainer>
        </article>
    );
};

export default HowItWorksSection;
