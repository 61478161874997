import React from 'react';

import {ButtonRaw} from '../../../../components/Button';
import {ArrowLeftIcon} from '../../../../components/Icons';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import appRouterService from '../../../../services/route/appRouterService';
import styles from './AdvancedDiagnosticIssueList.module.scss';

const AdvancedDiagnosticIssueList = ({issuesData}) => {
    const localizedStrings = getLocalizedStrings();

    const onClick = (nextStep) => {
        appRouterService.forwardToAdvancedDiagnosticScreen({screenName: nextStep});
    };

    return (
        <div className='mt-10 flex w-full flex-col'>
            {issuesData?.map((issue, index) => {
                const {title, nextStep} = issue;
                const titleText = localizedStrings[title];

                if (!titleText) return null;

                return (
                    <div key={index} className='mb-4 w-full'>
                        <ButtonRaw onClick={() => onClick(nextStep)} className={styles.AdvancedDiagnosticIssueList}>
                            {titleText}
                            <div className='pl-5 rtl:pl-0 rtl:pr-5'>
                                <ArrowLeftIcon className='rotate-180 rtl:rotate-0' />
                            </div>
                        </ButtonRaw>
                    </div>
                );
            })}
        </div>
    );
};

export default AdvancedDiagnosticIssueList;
