import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import iccService from './iccService';

const iccResponseErrorCheckService = async ({status, error}) => {
    const isAuthError = status === httpErrorsTypes.NOT_AUTHORIZED;

    if (isAuthError) {
        return iccService.refreshIccTokenIfAllowed();
    } else {
        throw error;
    }
};

export default iccResponseErrorCheckService;
