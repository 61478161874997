import React, {forwardRef} from 'react';

import arrayUtils from '../../utils/arrayUtils';

const Image = forwardRef((props, ref) => {
    const {src, className, onTransitionEnd, alt = '', isSrcDelayed, width, height, ariaHidden, onError} = props;

    if (!src?.length && !isSrcDelayed) return null;

    const imgUrls = arrayUtils.toArray(src);
    const srcSet = imgUrls
        .filter((image, i) => {
            return i !== 0;
        })
        .map((image, i) => {
            return `${image} ${i + 2}x`;
        })
        .join(',');

    return (
        // IA: Image elements should have explicit width and height
        // https://web.dev/optimize-cls/?utm_source=lighthouse&utm_medium=devtools#images-without-dimensions
        <img
            alt={alt}
            aria-hidden={ariaHidden}
            className={className}
            height={height}
            onError={onError}
            onTransitionEnd={onTransitionEnd}
            ref={ref}
            src={imgUrls[0]}
            srcSet={srcSet}
            width={width}
        />
    );
});

export default Image;
