import {logoutClearData} from '../../state/slices/globalSlice';
import {dispatch} from '../../state/store';
import iccService from '../icc/iccService';
import appRouterService from '../route/appRouterService';
import storageService from '../storage/storageService';
import authDataStoreService from './authDataStoreService';

const login = async ({password, username}) => {
    authDataStoreService.setCredentials({username, password});

    try {
        await iccService.fetchIccToken();
    } catch (e) {
        authDataStoreService.clearCredentials();
        throw e;
    }
};

const logout = () => {
    storageService.removeAuthDataFromStorage();
    dispatch(logoutClearData());

    appRouterService.forwardToLoginPage();
};

export default {
    logout,
    login,
};
