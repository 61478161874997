import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';

const DevicesPageHeader = () => {
    return (
        <article className='pb-4 mt-safe-offset-14'>
            <h1 className='mb-4 text-32'>
                <AemEditableText pageName='devices' itemName='setupyouriqos' />
            </h1>
            <p>
                <AemEditableText pageName='devices' itemName='connectyouriqosdesc' />
            </p>
        </article>
    );
};

export default DevicesPageHeader;
