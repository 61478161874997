import {Identify, identify, init, setOptOut, setUserId, track} from '@amplitude/analytics-browser';

import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_VALUES from '../../consts/amplitude/amplitudeEventValues';
import AMPLITUDE_USER_PROPERTIES from '../../consts/amplitude/amplitudeUserProperties';
import * as sourceTypes from '../../consts/sourceTypes';
import cryptoUtils from '../../utils/cryptoUtils';
import userBrowserService from '../user/userBrowserService';

const initAmplitude = (apiKey) => {
    init(apiKey, undefined, {
        serverZone: 'EU',
        appVersion: process.env.REACT_APP_VERSION,
        // Disable tracking while TNC is not fetched
        optOut: true,
    });
};

const setAmplitudeUserId = (userId) => {
    setUserId(userId);
};

const setMarket = (marketName) => {
    const identifyObj = new Identify();

    identifyObj.set(AMPLITUDE_USER_PROPERTIES.MARKET, marketName);

    identify(identifyObj);
};

const enableAmplitude = (isEnabled) => {
    setOptOut(!isEnabled);
};

const getPushSource = (source) => {
    switch (source) {
        case sourceTypes.HOMEPAGE:
            return AMPLITUDE_EVENT_VALUES.HOMEPAGE;
        case sourceTypes.INBOX:
            return AMPLITUDE_EVENT_VALUES.INBOX;
        default:
            return null;
    }
};

const trackEvent = ({eventType, serialNumber, modelName, properties}) => {
    trackWithSource(eventType, {
        [AMPLITUDE_EVENT_PROPERTIES.IQOS_PRODUCT_MODEL_TYPE]: modelName,
        [AMPLITUDE_EVENT_PROPERTIES.IQOS_PRODUCT_DEVICE_ID]: cryptoUtils.hashMD5(serialNumber),
        ...properties,
    });
};

const trackWithSource = (eventType, properties) => {
    const source = userBrowserService.isInStandaloneMode()
        ? AMPLITUDE_EVENT_VALUES.PWA
        : AMPLITUDE_EVENT_VALUES.BROWSER;

    track(eventType, {
        ...properties,
        [AMPLITUDE_EVENT_PROPERTIES.SOURCE]: source,
    });
};

export default {
    initAmplitude,
    setAmplitudeUserId,
    setMarket,
    getPushSource,
    enableAmplitude,
    trackEvent,
    trackWithSource,
};
