import iccConsumerFeedback from '../../../services/icc/iccConsumerFeedback';
import cryptoUtils from '../../../utils/cryptoUtils';
import dateTimeUtils from '../../../utils/dateTimeUtils';

const ENJOY_THE_APP = 'Enjoy the app?';

const getUidHash = (uid) => {
    return cryptoUtils.hashMD5(uid);
};

const getCreateTs = () => {
    return dateTimeUtils.getNowIsoTs();
};

const prepareFeedbackData = (data, consumerId) => {
    const result = {
        ...data,
        surveyName: ENJOY_THE_APP,
        uidHash: getUidHash(consumerId),
        createTs: getCreateTs(),
    };

    return JSON.stringify(result);
};

const uploadFeedbackData = async ({data, consumerId, onSuccess, onError}) => {
    try {
        const feedbackData = prepareFeedbackData(data, consumerId);

        await iccConsumerFeedback.uploadConsumerFeedback(feedbackData);
        onSuccess();
    } catch (error) {
        onError();
    }
};

export default {
    uploadFeedbackData,
};
