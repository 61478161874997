import cn from 'classnames';
import React from 'react';
import {Link as ReactLink} from 'react-router-dom';

const Link = (props) => {
    const {
        className,
        isExternalLink,
        value,
        to,
        onClick,
        children,
        isTargetBlank,
        testId,
        ariaLabel,
        disabled,
        ariaPressed,
        tabIndex,
        dangerouslySetInnerHTML,
        renderAs,
    } = props;
    const Component = to ? ReactLink : renderAs || 'button';
    const target = isTargetBlank ? '_blank' : null;

    return isExternalLink ? (
        <a
            className={className}
            href={to}
            onClick={onClick}
            rel={isTargetBlank ? 'noopener noreferrer' : null}
            target={target}
            data-testid={testId}
            aria-label={ariaLabel}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        >
            {children || value}
        </a>
    ) : (
        <Component
            className={cn(className)}
            onClick={onClick}
            rel={isTargetBlank ? 'noopener noreferrer' : null}
            target={target}
            to={to}
            data-testid={testId}
            aria-label={ariaLabel}
            aria-disabled={disabled}
            aria-pressed={ariaPressed}
            tabIndex={tabIndex}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        >
            {children || value}
        </Component>
    );
};

Link.defaultProps = {
    className: '',
    isExternalLink: false,
    isTargetBlank: false,
};

export default Link;
