import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '../../../../components/Button';
import appConfig from '../../../../config/appConfig';
import useConfigExternalLink from '../../../../hooks/useConfigExternalLink';

const AutoDeliveryEditAddressButton = () => {
    const autoDeliveryEditAddressExternalLink = appConfig.getAutoDeliveryEditAddressExternalLink();
    const redirectToExternalLink = useConfigExternalLink(autoDeliveryEditAddressExternalLink);

    if (!redirectToExternalLink) return;

    return (
        <ButtonRaw className='shrink-0 text-16 font-bold text-scarpa-flow' onClick={redirectToExternalLink}>
            <AemEditableText pageName='auto-delivery' itemName='changeAddressButton' />
        </ButtonRaw>
    );
};

export default AutoDeliveryEditAddressButton;
