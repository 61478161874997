import * as RadioGroup from '@radix-ui/react-radio-group';
import cn from 'classnames';
import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import deviceStateService from '../../../../../services/device/deviceStateService';
import productHelpers from '../../../../../services/product/productHelpers';
import {selectConsumerProductsMergedInfo} from '../../../../../state/selectors/consumer';
import {selectDeviceCodentify} from '../../../../../state/selectors/device';
import DeviceListItem from './DevicesListItem/DevicesListItem';

const DevicesList = ({className, onClose}) => {
    const devices = useSelector(selectConsumerProductsMergedInfo);
    const selectedDeviceCodentify = useSelector(selectDeviceCodentify);
    const [touchedDeviceId, setTouchedDeviceId] = useState();
    // We don't want to sort devices when another device is selected
    const sortedDevices = useMemo(
        () =>
            productHelpers.promoteProductByCodentify({
                products: devices,
                codentify: selectedDeviceCodentify,
            }),
        [devices]
    );

    const onChange = (codentify) => {
        deviceStateService.storeSelectedDeviceCodentify(codentify);
        onClose();
    };

    return (
        <RadioGroup.Root
            onValueChange={onChange}
            className={cn('flex flex-col overflow-auto overflow-x-hidden', className)}
            required
        >
            {sortedDevices.map((device, index) => {
                const {codentify} = device;

                return (
                    <React.Fragment key={codentify}>
                        <DeviceListItem
                            device={device}
                            isSelected={codentify === selectedDeviceCodentify}
                            touchedDeviceId={touchedDeviceId}
                            onDeviceTouch={(deviceId) => setTouchedDeviceId(deviceId)}
                            hideDeviceListPopup={onClose}
                        />
                        {index < sortedDevices.length - 1 && <hr className='mx-4 h-px shrink-0 bg-border' />}
                    </React.Fragment>
                );
            })}
        </RadioGroup.Root>
    );
};

export default DevicesList;
