const getBackgroundColorStyle = (color) => (color ? {backgroundColor: `#${color}`} : undefined);

const getBackgroundImageStyle = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'right',
});

export default {
    getBackgroundColorStyle,
    getBackgroundImageStyle,
};
