import React from 'react';

import appConfig from '../../config/appConfig';
import AemModalTypes from '../../consts/aem/aemModalTypes';
import WarningBanners from './WarningBanners';

const ModalWarningBanners = ({modalType}) => {
    if (!modalType) return;

    const warningBanners = getModalWarningBanners(modalType);

    if (!warningBanners) return null;

    return <WarningBanners warningBanners={warningBanners} />;
};

const getModalWarningBanners = (aemModalType) => {
    const warningBanners = appConfig.getWarningBanners();
    const warningBannersForModals = warningBanners?.filter((b) =>
        b.pages?.some((page) => page === AemModalTypes[aemModalType])
    );

    return warningBannersForModals;
};

export default ModalWarningBanners;
