import React from 'react';

import deviceAssetService from '../../services/device/deviceAssetService';
import {twx} from '../../utils/tailwindUtils';
import ProductImage from './ProductImage';

const DeviceImage = (props) => {
    const {className, imgClassName, device, holder, altMainImg, altHolderImg, isHolderSingle, showHolder} = props;
    const holderMediaIdList =
        (holder?.type || showHolder) && deviceAssetService.getHolderMediaId(device, holder, isHolderSingle);
    const holderType = holder?.type ? holder.type : device?.defaultHolderType;

    return (
        <div className={twx('relative', className)}>
            {!isHolderSingle && device && (
                <ProductImage
                    alt={altMainImg}
                    key={device.mediaId}
                    mediaIdList={device.mediaId}
                    type={device.type}
                    imgClassName={imgClassName}
                />
            )}
            {(holder?.type || showHolder) && (
                <ProductImage
                    isHolderSingle={isHolderSingle}
                    alt={altHolderImg}
                    key={holderMediaIdList}
                    mediaIdList={holderMediaIdList}
                    type={holderType}
                    imgClassName={imgClassName}
                />
            )}
        </div>
    );
};

export default DeviceImage;
