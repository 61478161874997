import appConfig from '../../config/appConfig';
import helpers from '../../utils/helpers';
import uaUtils from '../../utils/uaUtils';
import storageService from '../storage/storageService';

let _appInstanceId;

const getAppInstanceId = () => {
    if (_appInstanceId) return _appInstanceId;

    let appInstanceId = storageService.getAppInstanceIdFromStorage();

    if (isValidAppInstanceId(appInstanceId)) return returnAppInstanceId(appInstanceId);

    appInstanceId = generateAppInstanceId();
    return returnAppInstanceId(appInstanceId);
};

const returnAppInstanceId = (appInstanceId) => {
    _appInstanceId = appInstanceId;

    return appInstanceId;
};

const isValidAppInstanceId = (appInstanceId) => {
    const appCode = getApplicationCode();

    return appInstanceId && appInstanceId.startsWith(`${appCode}-`);
};

const generateAppInstanceId = () => {
    const appCode = getApplicationCode();
    const osShortName = uaUtils.getOSShortName();

    const appInstanceId = `${appCode}-${osShortName}_${helpers.guid()}`;

    storageService.setAppInstanceIdToStorage(appInstanceId);

    return appInstanceId;
};

const getApplicationCode = () => {
    //TODO: remove PWA if icc have changed app code
    const appCode = appConfig.getIccApiHeader_a() + '-PWA';

    return appCode;
};

export default {
    getAppInstanceId,
};
