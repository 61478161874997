import React from 'react';
import {useDispatch} from 'react-redux';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../components/Button';
import amplitudeProfileTracking from '../../../services/amplitude/amplitudeProfileTracking';
import authService from '../../../services/auth/authService';
import pushNotificationService from '../../../services/pushNotificationService';
import {showLoader} from '../../../state/slices/loadersSlice';

const LogoutButton = ({className}) => {
    const dispatch = useDispatch();

    const onLogoutClick = async () => {
        const LOADER_NAME = 'LOGOUT_LOADER';

        amplitudeProfileTracking.trackLogOutClick();
        dispatch(showLoader(LOADER_NAME));

        await pushNotificationService.unsubscribeAndDeleteSubscription();
        authService.logout();
    };

    return (
        <AppButton className={className} onClick={onLogoutClick} tertiary>
            <AemEditableText pageName='profile' itemName='logoutButton' />
        </AppButton>
    );
};

export default LogoutButton;
