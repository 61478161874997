import {selectIsLoggedIn} from '../state/selectors/auth';
import {selectIsConsumerDataFetched} from '../state/selectors/consumer';
import {selectIsAem} from '../state/selectors/global';
import {getState} from '../state/store';
import authService from './auth/authService';
import iccMarketService from './icc/iccMarketService';
import languageDataService from './localization/languageDataService';
import log from './logger/log';
import marketConfigService from './marketConfigService';
import appRouterService from './route/appRouterService';

const onAppStart = async () => {
    try {
        const state = getState();
        const isLoggedIn = selectIsLoggedIn(state);

        if (isLoggedIn) {
            await fetchConsumerData();

            if (appRouterService.isModalsPage()) {
                appRouterService.forwardToModalsPage();
            } else {
                const isAem = selectIsAem(state);

                if (isAem) {
                    appRouterService.forwardToTncPage();
                }
            }

            return;
        }

        if (marketConfigService.isInstallPwaEnabled()) {
            appRouterService.forwardToInstallPwaPage();

            return;
        }
    } catch (error) {
        log.info(`onAppStart: error: : ${JSON.stringify(error)}`);
    }

    appRouterService.forwardToLoginPage();
};

const fetchConsumerData = async () => {
    try {
        const isConsumerDataFetched = selectIsConsumerDataFetched(getState());

        if (isConsumerDataFetched) return;

        await iccMarketService.getExternalConsumerProfile();
        await iccMarketService.getConsumerProfile();
        await iccMarketService.getConsumerAggregatedSettings();

        await languageDataService.checkAndSetConsumerLanguageCode();
    } catch (error) {
        authService.logout();
        throw error;
    }
};

export default {
    onAppStart,
    fetchConsumerData,
};
