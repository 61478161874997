import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {amplitude} from '../../../../services/amplitude';
import {selectConsumerId} from '../../../../state/selectors/consumer';
import cryptoUtils from '../../../../utils/cryptoUtils';

const useAmplitudeUserId = () => {
    const consumerId = useSelector(selectConsumerId);

    useEffect(() => {
        let hashedConsumerId;

        if (consumerId) {
            hashedConsumerId = cryptoUtils.hashMD5(consumerId);
        }

        amplitude.setAmplitudeUserId(hashedConsumerId);
    }, [consumerId]);
};

export default useAmplitudeUserId;
