import React from 'react';

import {BellIcon} from '../../../../components/Icons';

const InboxEmpty = ({emptyInboxText}) => {
    return (
        <div className='flex flex-col items-center gap-4 py-36'>
            <BellIcon />
            <h4 className='text-center'>{emptyInboxText}</h4>
        </div>
    );
};

export default InboxEmpty;
