import cn from 'classnames';
import React from 'react';

import AppPopup from '../../Popup/AppPopup';
import styles from './AppLoader.module.scss';
import AppLoaderContent from './AppLoaderContent';

const AppLoader = ({loaderText, isTransparent}) => {
    const overlayClassName = cn(styles.Overlay, {[styles.Transparent]: isTransparent});

    return (
        <AppPopup
            isOpen
            className={styles.AppLoaderContainer}
            overlayClassName={overlayClassName}
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            {!isTransparent && <AppLoaderContent loaderText={loaderText} />}
        </AppPopup>
    );
};

export default AppLoader;
