import React from 'react';

import Icon from '../Icon/Icon';

const ComputerBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g transform='translate(6.75 7.5)' fill='none' fillRule='evenodd'>
                <rect stroke='#34303D' strokeWidth='1.95' x='.975' y='.975' width='32.55' height='25.226' rx='1.95' />
                <path d='m12.814 33 1.464-6.794h5.944L21.686 33h-8.872z' fill='#34303D' />
                <rect fill='#00D3CF' x='3.943' y='3.882' width='26.614' height='19.412' rx='1.95' />
            </g>
        </Icon>
    );
};

export default ComputerBlueIcon;
