import {createSelector} from 'reselect';

import {APP_SECTION_TYPES, EXTERNAL_LINK_TYPES} from '../../consts/externalLinkTypes';
import layoutService from '../../services/layoutService';

const selectGlobal = (state) => state.global;

const selectIsAem = createSelector(selectGlobal, (globalState) => globalState.isAem);

const selectIsAppLoading = createSelector(selectGlobal, (globalState) => globalState.isAppLoading);

const selectIsConfigInitialized = createSelector(selectGlobal, (globalState) => globalState.isConfigInitialized);

const selectLayoutType = createSelector(selectGlobal, (globalState) => globalState.layout);

const selectIsMobileLayout = createSelector(selectLayoutType, (layout) => layoutService.isMobileLayout(layout));

const selectIsDesktopLayout = createSelector(selectLayoutType, (layout) => layoutService.isDesktopLayout(layout));

const selectIsSmLayout = createSelector(selectLayoutType, (layout) => layoutService.isSmLayout(layout));

const selectLanguageCode = createSelector(selectGlobal, (globalState) => globalState.languageCode);

const selectSwMessage = createSelector(selectGlobal, (globalState) => globalState.swMessage);

const selectIsRedirectionRequiredAfterNotificationClick = createSelector(
    selectSwMessage,
    (swMessage) =>
        swMessage?.actionTypeCode === EXTERNAL_LINK_TYPES.SECTION_LINK &&
        swMessage?.appSection === APP_SECTION_TYPES.DETAILS
);

const selectHwContainerIds = createSelector(selectGlobal, (globalState) => globalState.hwContainerIds);
const selectIsWelcomeMode = createSelector(selectGlobal, (globalState) => globalState.isWelcomeMode);
const selectIsWelcomeAnimationStarted = createSelector(
    selectGlobal,
    (globalState) => globalState.isWelcomeAnimationStarted
);

export {
    selectHwContainerIds,
    selectIsAem,
    selectIsAppLoading,
    selectIsConfigInitialized,
    selectIsDesktopLayout,
    selectIsMobileLayout,
    selectIsRedirectionRequiredAfterNotificationClick,
    selectIsSmLayout,
    selectIsWelcomeAnimationStarted,
    selectIsWelcomeMode,
    selectLanguageCode,
    selectLayoutType,
    selectSwMessage,
};
