import {dispatch} from '../../state/store';
import arrayUtils from '../../utils/arrayUtils';
import stringUtils from '../../utils/stringUtils';
import server from '../server/server';

export default class UamBaseClient {
    tokenRefreshInProgress = {};

    setTokenRefreshInProgress = (codentify, value) => {
        this.tokenRefreshInProgress[codentify] = value;
    };

    isTokenRefreshInProgress = (codentify) => {
        return this.tokenRefreshInProgress[codentify];
    };

    //abstract methods
    getHeaders = async () => {};
    errorCheck = async () => {};
    getRequestParams = async () => {};
    getApiUrl = () => {};

    getMethodUrl = ({methodName, requestConfig}) => {
        const args = requestConfig?.args;
        let methodUrl = methodName;

        if (args) {
            const params = arrayUtils.toArray(args);

            methodUrl = stringUtils.formatString(methodName, ...params);
        }
        return methodUrl;
    };

    callGet = (options) => {
        options.methodUrl = this.getMethodUrl(options);
        options.httpMethod = server.get;
        return this.callRequest(options);
    };

    callPost = (options) => {
        options.methodUrl = this.getMethodUrl(options);
        options.httpMethod = server.post;
        return this.callRequest(options);
    };

    callPut = (options) => {
        options.methodUrl = this.getMethodUrl(options);
        options.httpMethod = server.put;
        return this.callRequest(options);
    };

    callRequest = async (params) => {
        try {
            const response = await this.callMethod(params);

            if (!response) return null;

            const {action, mapper} = params;
            let {data} = response;

            if (mapper) {
                data = mapper(data);
            }

            if (action) {
                dispatch(action(data));
            } else {
                return data;
            }
        } catch (e) {
            return null;
        }
    };

    callMethod = async ({httpMethod, methodUrl, requestConfig, ignoreErrorCodes, codentify}) => {
        const url = this.getApiUrl(methodUrl, codentify);
        const headers = requestConfig?.headers ? requestConfig.headers : await this.getHeaders(codentify);
        const params = requestConfig?.params ? requestConfig?.params : {};
        const data = requestConfig?.data ? requestConfig.data : {};

        try {
            const response = await httpMethod(url, {headers, params, data});

            return response;
        } catch (error) {
            return await this.errorCheck({
                error,
                onSuccess: async ({refreshedAuthToken}) => {
                    if (!refreshedAuthToken) return;

                    const uamParams = await this.getRequestParams({
                        headers,
                        params,
                        data,
                        codentify,
                        refreshedAuthToken,
                    });

                    if (!uamParams) return;

                    return await httpMethod(url, uamParams);
                },
                ignoreErrorCodes,
                codentify,
            });
        }
    };
}
