import React from 'react';

import Icon from '../Icon/Icon';

const HomeIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.5 2C12.9752 2 13.4493 2.16149 13.844 2.48446L19.6622 7.24782C19.9231 7.46152 20.1341 7.73463 20.2793 8.04645C20.4245 8.35827 20.4999 8.70062 20.5 9.04753V19.7148C20.5 20.3455 20.2556 20.9173 19.861 21.3307C19.4663 21.7441 18.9204 22 18.3182 22H6.68182C6.39524 22.0004 6.11142 21.9415 5.84666 21.8266C5.58189 21.7117 5.34141 21.5432 5.13903 21.3307C4.93613 21.1187 4.77522 20.8668 4.66556 20.5895C4.55589 20.3122 4.49963 20.015 4.5 19.7148V9.04753C4.5 8.34471 4.80934 7.68048 5.33782 7.24782L11.156 2.48446C11.5397 2.1701 12.0129 1.99952 12.5 2V2Z'
                stroke='#232029'
                strokeWidth='1.5'
                fill='currentColor'
            />
        </Icon>
    );
};

export default HomeIcon;
