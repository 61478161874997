import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';
import amplitude from './amplitude';

const trackInboxScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.INBOX_SCREEN_VIEWED);
};

const trackNotificationDetailedScreen = (source, campaignName) => {
    const pushSource = amplitude.getPushSource(source);

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.INBOX_MESSAGE_OPENED, {
        [AMPLITUDE_EVENT_PROPERTIES.PUSH_SOURCE]: pushSource,
        [AMPLITUDE_EVENT_PROPERTIES.CAMPAIGN_NAME]: campaignName,
    });
};

const trackInboxMessageButtonClicked = (source, campaignName) => {
    const pushSource = amplitude.getPushSource(source);

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.INBOX_MESSAGE_BUTTON_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.PUSH_SOURCE]: pushSource,
        [AMPLITUDE_EVENT_PROPERTIES.CAMPAIGN_NAME]: campaignName,
    });
};

const trackActionScreen = (source) => {
    const pushSource = amplitude.getPushSource(source);

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.ACTION_SCREEN_VIEWED, {
        [AMPLITUDE_EVENT_PROPERTIES.PUSH_SOURCE]: pushSource,
    });
};

export default {
    trackInboxScreen,
    trackNotificationDetailedScreen,
    trackInboxMessageButtonClicked,
    trackActionScreen,
};
