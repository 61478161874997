import React from 'react';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import StickyHeader from '../../../components/Layout/StickyHeader';
import {PopupFullPage} from '../../../components/Popup';
import SelectList from '../../../components/SelectList/SelectList';
import appConfig from '../../../config/appConfig';
import {LANGUAGE_SELECT_PAGE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import useLanguageChanger from '../../../hooks/useLanguageChanger';
import useModalRoute from '../../../hooks/useModalRoute';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';

const LanguageSelectPopup = ({modalType}) => {
    const localizedStrings = getLocalizedStrings();
    const marketLanguages = appConfig.getMarketLanguages();
    const selectListData = marketLanguages.map((marketLanguage) => {
        const {languageTitle, languageCode} = marketLanguage;

        return {title: languageTitle, value: languageCode};
    });

    const {currentLanguageCode, changeLanguage} = useLanguageChanger();

    const closeModal = () => modalService.hideModal(modalType);

    const {onClose} = useModalRoute({modalType, closeModal});

    return (
        <PopupFullPage>
            <div className='relative mb-8 flex h-full w-full flex-col items-center'>
                <StickyHeader onBack={onClose} title={localizedStrings[LANGUAGE_SELECT_PAGE_HEADER_TITLE]} />
                <h2 className='min-h-12 text-center mt-safe-offset-14'>
                    <AemEditableText pageName='modals' itemName='languageselecttitle' />
                </h2>
                <SelectList
                    className='mt-10'
                    selectedValue={currentLanguageCode}
                    data={selectListData}
                    onChange={changeLanguage}
                />
            </div>
        </PopupFullPage>
    );
};

export default LanguageSelectPopup;
