import React from 'react';

import {useGetAutoDeliveryDetails} from '../../../../../api/consumerApi';
import AMPLITUDE_EVENT_VALUES from '../../../../../consts/amplitude/amplitudeEventValues';
import {AUTO_DELIVERY_VIEW_ORDER_LINK} from '../../../../../consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '../../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import AutoDeliveryActiveProgress from '../../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryPaymentPendingButton from '../../../../../views/AutoDelivery/AutoDeliveryPaymentPending/AutoDeliveryPaymentPendingButton';
import AutoDeliveryPaymentPendingTitle from '../../../../../views/AutoDelivery/AutoDeliveryPaymentPending/AutoDeliveryPaymentPendingTitle';
import AutoDeliveryWidgetContainer from './components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from './components/AutoDeliveryWidgetHeader';

const AutoDeliveryStatusPaymentPending = ({className}) => {
    const {
        data: {
            autoDelivery: {status},
        },
    } = useGetAutoDeliveryDetails();

    const amplitudeEvent = () => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryDashboardButtonClick(AMPLITUDE_EVENT_VALUES.CHECK_ORDER_STATUS);
    };

    return (
        <AutoDeliveryWidgetContainer className={className}>
            <AutoDeliveryWidgetHeader btnLocalizationKey={AUTO_DELIVERY_VIEW_ORDER_LINK} />
            <AutoDeliveryPaymentPendingTitle className='mt-6' />
            <AutoDeliveryActiveProgress status={status} className='mt-4' />
            <AutoDeliveryPaymentPendingButton amplitudeEvent={amplitudeEvent} className='mt-8' />
        </AutoDeliveryWidgetContainer>
    );
};

export default AutoDeliveryStatusPaymentPending;
