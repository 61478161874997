import {createSelector} from 'reselect';

const selectLoaders = (state) => state.loaders;

const makeSelectIsLoaderVisible = (loaderName) =>
    createSelector(selectLoaders, (loaders) => {
        const loader = loaders?.filter(({name}) => name === loaderName);

        return loader?.length;
    });

export {makeSelectIsLoaderVisible, selectLoaders};
