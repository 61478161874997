import {useSelector} from 'react-redux';

import {useGetAutoDeliveryDetailsExtended} from '../../../../api/consumerApi';
import {selectLanguageCode} from '../../../../state/selectors/global';
import dateTimeUtils from '../../../../utils/dateTimeUtils';

const useDeliveryDate = () => {
    const languageCode = useSelector(selectLanguageCode);
    const {
        data: {
            autoDelivery: {asapDeliveryDate, nextDeliveryDate, isSkipDeliveryAvailable},
        },
    } = useGetAutoDeliveryDetailsExtended();

    const now = new Date();
    const asapDelivery = new Date(asapDeliveryDate);
    const nextDelivery = new Date(nextDeliveryDate);

    const skipDate = new Date(nextDelivery);

    skipDate.setDate(skipDate.getDate() + 30);

    return {
        startDeliveryDay: nextDelivery.getDate(),
        startDeliveryDate: dateTimeUtils.getDayAndMonth(nextDelivery, languageCode),
        days: dateTimeUtils.getDateDiffInDays(now, asapDelivery),
        asapDeliveryDay: asapDelivery.getDate(),
        asapDeliveryMonth: dateTimeUtils.getMonth(asapDelivery, languageCode),
        asapDeliveryDate: dateTimeUtils.getWeekAndDayAndMonth(asapDelivery, languageCode),
        skipDateShort: dateTimeUtils.getDayAndShortMonth(skipDate, languageCode),
        skipDate: dateTimeUtils.getDayAndMonth(skipDate, languageCode),
        isSkipDeliveryAvailable,
        asapDeliveryDateRaw: asapDeliveryDate,
    };
};

export default useDeliveryDate;
