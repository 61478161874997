import cn from 'classnames';
import React from 'react';

import loaderImage from '../../../../assets/images/loading_primary.png';
import ButtonRaw from '../ButtonRaw';
import styles from './AppButton.module.scss';

const AppButton = (props) => {
    const {
        className,
        primary,
        secondary,
        tertiary,
        light,
        dark,
        colored,
        sandy,
        disabled,
        loading,
        children,
        to,
        ...rest
    } = props;
    const classes = cn(
        {[styles.Button]: !tertiary},
        {
            [styles.Loading]: loading,
            [styles.PrimaryLight]: primary && light && !disabled,
            [styles.SecondaryLight]: secondary && light && !disabled,
            [styles.PrimaryDark]: primary && dark && !disabled,
            [styles.SecondaryDark]: secondary && dark && !disabled,
            [styles.Light]: light && !disabled,
            [styles.Dark]: dark && !disabled,
            [styles.Tertiary]: tertiary && !disabled,
            [styles.Active]: !disabled && !tertiary && (primary || secondary || sandy),
            [styles.Disabled]: disabled,
            [styles.PrimaryColored]: primary && colored && !disabled,
            [styles.SecondaryColored]: secondary && colored && !disabled,
            [styles.SecondaryColoredLight]: secondary && colored && light && !disabled,
            [styles.Sandy]: sandy,
        },
        className
    );

    return (
        <ButtonRaw className={classes} disabled={disabled || loading} to={to} {...rest}>
            <div className={cn({'opacity-0': loading})}>{children}</div>
            {loading && <img alt='' className={styles.Loader} src={loaderImage} />}
        </ButtonRaw>
    );
};

export default AppButton;
