import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    diagnosticFlowDataResult: undefined,
};

export const slice = createSlice({
    name: 'advancedDiagnostic',
    initialState,
    reducers: {
        setDiagnosticFlowDataResult: (state, action) => {
            return {
                ...state,
                diagnosticFlowDataResult: action.payload,
            };
        },
    },
});

export const {setDiagnosticFlowDataResult} = slice.actions;

export default slice.reducer;
