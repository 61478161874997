import {selectConsumerId} from '../state/selectors/consumer';
import {showConsumerFeedbackGatheringModalAction} from '../state/slices/modalSlice';
import {dispatch, getState} from '../state/store';
import storageService from './storage/storageService';

const getFeedback = () => {
    const consumerId = selectConsumerId(getState());

    if (!consumerId) return null;

    const feedback = storageService.getFeedbackFromLocalStorage() || {};

    return feedback[consumerId] || {};
};

const setFeedback = (consumerFeedback) => {
    const consumerId = selectConsumerId(getState());

    if (!consumerId) return null;

    const feedback = storageService.getFeedbackFromLocalStorage() || {};

    feedback[consumerId] = consumerFeedback;
    storageService.setFeedbackToLocalStorage(feedback);
};

const setFeedbackStartTrackingDate = () => {
    const DAYS_TO_START_TRACKING = 29;
    const startTrackingDate = new Date();

    startTrackingDate.setHours(0, 0, 0, 0);
    startTrackingDate.setDate(startTrackingDate.getDate() + DAYS_TO_START_TRACKING);
    setFeedback({startTrackingDate});
};

const setDoNotShowAnymore = () => {
    setFeedback({doNotShowAnymore: true});
};

const setShowDate = () => {
    const DAYS_TO_FEEDBACK_SHOW = 1;
    const showDate = new Date();
    const feedback = getFeedback() || {};

    showDate.setHours(0, 0, 0, 0);
    showDate.setDate(showDate.getDate() + DAYS_TO_FEEDBACK_SHOW);

    feedback.showDate = showDate;
    setFeedback(feedback);
};

const handleAppOpening = () => {
    const feedback = getFeedback();

    if (!feedback) return null;

    if (feedback.doNotShowAnymore) return null;

    const todaysDate = new Date().setHours(0, 0, 0, 0);
    const startTrackingDateString = feedback.startTrackingDate;

    if (startTrackingDateString) {
        const startTrackingDate = new Date(startTrackingDateString).setHours(0, 0, 0, 0);

        if (todaysDate < startTrackingDate) return null;
    }

    const showDateString = feedback.showDate;

    if (showDateString) {
        const showDate = new Date(showDateString).setHours(0, 0, 0, 0);

        if (todaysDate < showDate) return null;
        if (todaysDate === showDate) {
            dispatch(showConsumerFeedbackGatheringModalAction());
            return null;
        }
    }

    setShowDate();
};

const handleFeedbackClosing = () => {
    const feedback = getFeedback();

    if (feedback.startTrackingDate) {
        setDoNotShowAnymore();
    } else {
        setFeedbackStartTrackingDate();
    }
};

export default {
    setDoNotShowAnymore,
    handleAppOpening,
    handleFeedbackClosing,
};
