import cn from 'classnames';
import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import ContentList from '../../../../components/ContentList/ContentList';
import {MaxWidthContainer} from '../../../../components/Layout';

const HowItWorksSection = ({className, howItWorksList, itemName}) => {
    return (
        <article className={cn('mx-4 bg-romance py-8', className)}>
            <AemEditableText
                pageName='auto-delivery'
                itemName={itemName}
                render={(title) => (title ? <h2 className='mb-0 text-24'>{title}</h2> : null)}
            />
            <MaxWidthContainer className='mt-4 flex-1 first:mt-0'>
                <ContentList list={howItWorksList} />
            </MaxWidthContainer>
        </article>
    );
};

export default HowItWorksSection;
