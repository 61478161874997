const isAnyKeys = (obj) => !!Object.keys(obj).length;
const objMap = (obj, func) => Object.keys(obj).map((name) => func(obj[name], name));
const objectKeysToLowerCase = (input) => {
    if (typeof input !== 'object') return input;
    if (Array.isArray(input)) return input.map(objectKeysToLowerCase);
    return Object.keys(input).reduce(function (newObj, key) {
        const val = input[key];
        const newVal = typeof val === 'object' && val !== null ? objectKeysToLowerCase(val) : val;
        const keyName = key.charAt(0).toLowerCase() + key.slice(1);

        newObj[keyName] = newVal;
        return newObj;
    }, {});
};

export default {
    isAnyKeys,
    objMap,
    objectKeysToLowerCase,
};
