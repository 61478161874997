import React from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';

import {selectHwContainerIds} from '../../state/selectors/global';
import useGetPageWarningBanners from './hooks/useGetPageWarningBanners';
import WarningBanners from './WarningBanners';

const PageWarningBanners = () => {
    const warningBanners = useGetPageWarningBanners();
    const hwContainerIds = useSelector(selectHwContainerIds);

    if (!warningBanners) return null;

    if (!hwContainerIds?.length) {
        return <WarningBanners warningBanners={warningBanners} className='page-content' />;
    }

    return hwContainerIds.map((hwContainerId) => {
        const hwContainer = document.getElementById(hwContainerId);

        return createPortal(<WarningBanners warningBanners={warningBanners} />, hwContainer);
    });
};

export default PageWarningBanners;
