import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../components/Button';
import {ArrowLeftIcon, CalendarIcon} from '../../../../components/Icons';
import PopupStickedWithSwipeClose from '../../../../components/Popup/PopupStickedWithSwipeClose';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import useDeliveryDate from '../hooks/useDeliveryDate';
import DeliveryAsapInfo from './DeliveryAsapInfo';

const DeliveryAsapConfirmPopup = ({onClose, onConfirm}) => {
    const {startDeliveryDay, asapDeliveryDay} = useDeliveryDate();

    return (
        <PopupStickedWithSwipeClose onClose={onClose}>
            <div className='text-start'>
                <h2 className='mb-0 text-28'>
                    <AemEditableText pageName='auto-delivery' itemName='deliveryAsapConfirmPopupTitle' />
                </h2>
                <DeliveryAsapInfo className='mt-8' />
                <div className='mt-8 flex items-center justify-between'>
                    <div className='flex items-center gap-2'>
                        <CalendarIcon className='text-french-gray' />
                        <p>
                            <AemEditableText
                                pageName='auto-delivery'
                                itemName='deliveryAsapConfirmPopupStartDate'
                                contentList={[startDeliveryDay]}
                            />
                        </p>
                    </div>
                    <ArrowLeftIcon className='mx-2 rotate-180' />
                    <div className='flex items-center gap-2'>
                        <CalendarIcon className='text-tertiary' />
                        <p>
                            <AemEditableText
                                pageName='auto-delivery'
                                itemName='deliveryAsapConfirmPopupEndDate'
                                contentList={[asapDeliveryDay]}
                            />
                        </p>
                    </div>
                </div>
                <AutoDeliveryDetailsFooter className='mt-8'>
                    <AppButton primary light className='mt-0' onClick={onConfirm}>
                        <AemEditableText pageName='auto-delivery' itemName='deliveryAsapConfirmPopupButton' />
                    </AppButton>
                </AutoDeliveryDetailsFooter>
            </div>
        </PopupStickedWithSwipeClose>
    );
};

export default DeliveryAsapConfirmPopup;
