import appConfig from '../../../config/appConfig';
import AMPLITUDE_EVENT_VALUES from '../../../consts/amplitude/amplitudeEventValues';
import {AUTO_DELIVERY_UPDATE_REASON_STATUS_TYPES} from '../../../consts/icc/iccAutoDeliveryStatusTypes';
import {
    AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_BUTTON,
    AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_DESCRIPTION,
    AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_SUBTITLE,
    AUTO_DELIVERY_ON_HOLD_NONE_BUTTON,
    AUTO_DELIVERY_ON_HOLD_NONE_DESCRIPTION,
    AUTO_DELIVERY_ON_HOLD_NONE_SUBTITLE,
    AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_BUTTON,
    AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_DESCRIPTION,
    AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_SUBTITLE,
    AUTO_DELIVERY_ON_HOLD_PAY_FAILED_BUTTON,
    AUTO_DELIVERY_ON_HOLD_PAY_FAILED_DESCRIPTION,
    AUTO_DELIVERY_ON_HOLD_PAY_FAILED_SUBTITLE,
} from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';

const getIssueDescription = (status) => {
    switch (status?.toUpperCase()) {
        case AUTO_DELIVERY_UPDATE_REASON_STATUS_TYPES.PAY_FAIL:
            return {
                titleKey: AUTO_DELIVERY_ON_HOLD_PAY_FAILED_SUBTITLE,
                descriptionKey: AUTO_DELIVERY_ON_HOLD_PAY_FAILED_DESCRIPTION,
                buttonKey: AUTO_DELIVERY_ON_HOLD_PAY_FAILED_BUTTON,
                amplitudeTrackingValue: AMPLITUDE_EVENT_VALUES.REACTIVATE,
                externalLinkConfig: appConfig.getAutoDeliveryOnHoldPayFailedExternalLink(),
            };
        case AUTO_DELIVERY_UPDATE_REASON_STATUS_TYPES.OUT_OF_STOCK:
            return {
                titleKey: AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_SUBTITLE,
                descriptionKey: AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_DESCRIPTION,
                buttonKey: AUTO_DELIVERY_ON_HOLD_OUT_OF_STOCK_BUTTON,
                amplitudeTrackingValue: AMPLITUDE_EVENT_VALUES.REACTIVATE,
                externalLinkConfig: appConfig.getAutoDeliveryOnHoldOutOfStockExternalLink(),
            };
        case AUTO_DELIVERY_UPDATE_REASON_STATUS_TYPES.DELIVERY_FAIL:
            return {
                titleKey: AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_SUBTITLE,
                descriptionKey: AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_DESCRIPTION,
                buttonKey: AUTO_DELIVERY_ON_HOLD_DELIVERY_FAIL_BUTTON,
                amplitudeTrackingValue: AMPLITUDE_EVENT_VALUES.REACTIVATE,
                externalLinkConfig: appConfig.getAutoDeliveryOnHoldDeliveryFailExternalLink(),
            };
        default:
            return {
                titleKey: AUTO_DELIVERY_ON_HOLD_NONE_SUBTITLE,
                descriptionKey: AUTO_DELIVERY_ON_HOLD_NONE_DESCRIPTION,
                buttonKey: AUTO_DELIVERY_ON_HOLD_NONE_BUTTON,
                amplitudeTrackingValue: AMPLITUDE_EVENT_VALUES.REACTIVATE,
                externalLinkConfig: appConfig.getAutoDeliveryOnHoldNoneExternalLink(),
            };
    }
};

export const getAutoDeliveryOnHoldContent = (statusUpdateReason) => {
    const {titleKey, descriptionKey, buttonKey, amplitudeTrackingValue, externalLinkConfig} =
        getIssueDescription(statusUpdateReason) || {};
    const localizedStrings = getLocalizedStrings();

    return {
        title: titleKey && localizedStrings[titleKey],
        description: descriptionKey && localizedStrings[descriptionKey],
        button: buttonKey && localizedStrings[buttonKey],
        amplitudeTrackingValue,
        externalLinkConfig,
    };
};
