import React from 'react';

import {AppButton, ButtonRaw} from '../../../../../components/Button';
import {ArrowIcon} from '../../../../../components/Icons';
import PopupStickedWithSwipeClose from '../../../../../components/Popup/PopupStickedWithSwipeClose';
import * as localizationKeys from '../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import appRouterService from '../../../../../services/route/appRouterService';
import DevicesList from './DevicesList';
import MissingDeviceNotification from './MissingDeviceNotification';

const DevicesListPopup = ({onClose}) => {
    const localizedStrings = getLocalizedStrings();

    const onAddDeviceClick = () => {
        appRouterService.forwardToSelectYourDevicePage();
    };

    return (
        <PopupStickedWithSwipeClose
            className='flex max-h-[calc(100vh-56px)] flex-col overflow-hidden px-4 pb-safe-offset-8'
            swipeAreaClassName='py-4'
            onClose={onClose}
            isSwipeDashVisible={false}
        >
            <div className='flex items-center justify-between'>
                <h2 className='mb-0 text-24'>{localizedStrings[localizationKeys.MY_DEVICES_LIST_TITLE]}</h2>
                <ButtonRaw onClick={onClose} className='shrink-0'>
                    <ArrowIcon className='rotate-90' />
                </ButtonRaw>
            </div>
            <MissingDeviceNotification className='mt-8' />
            <DevicesList className='-mx-4 mt-4' onClose={onClose} />
            <AppButton primary light className='mx-auto mt-8 shrink-0 md:min-w-[312px]' onClick={onAddDeviceClick}>
                {localizedStrings[localizationKeys.MY_DEVICES_LIST_ADD_NEW_BUTTON_TEXT]}
            </AppButton>
        </PopupStickedWithSwipeClose>
    );
};

export default DevicesListPopup;
