import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import productService from '../services/product/productService';
import {selectConsumerProductsToRegUnreg} from '../state/selectors/consumer';
import {removeAllConsumerProductsFromRegUnreg} from '../state/slices/consumerSlice';
import {dispatch} from '../state/store';

const REQUEST_TIMEOUT_LIST_SEC = [1, 2, 3, 3, 3, 3, 15, 15, 15, 30 * 60, 60 * 60];

let registrationTimeout;

const useRegUnregInterval = () => {
    const products = useSelector(selectConsumerProductsToRegUnreg);
    const productsLength = products?.length || 0;
    const [isSchedulerStarted, setIsSchedulerStarted] = useState(false);
    const [currentRequest, setCurrentRequest] = useState(0);

    const startScheduler = () => {
        setIsSchedulerStarted(true);
    };

    const stopScheduler = () => {
        setIsSchedulerStarted(false);
    };

    useEffect(() => {
        if (productsLength) {
            startScheduler();
        } else {
            stopScheduler();
        }
    }, [productsLength]);

    useEffect(() => {
        return () => stopScheduler();
    }, []);

    const timeoutFunction = async () => {
        await productService.fetchProductsStatusAndCheckThem(products);
        setCurrentRequest(currentRequest + 1);
    };

    useEffect(() => {
        if (isSchedulerStarted) {
            if (currentRequest >= REQUEST_TIMEOUT_LIST_SEC.length) {
                stopScheduler();
                dispatch(removeAllConsumerProductsFromRegUnreg());
                return;
            }

            registrationTimeout = setTimeout(timeoutFunction, REQUEST_TIMEOUT_LIST_SEC[currentRequest] * 1000);
        } else {
            clearTimeout(registrationTimeout);
            setCurrentRequest(0);
        }
    }, [isSchedulerStarted, currentRequest]);

    return [stopScheduler];
};

export default useRegUnregInterval;
