import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import iccConsumerProducts from '../../../services/icc/iccConsumerProducts';
import {selectIsLoggedIn} from '../../../state/selectors/auth';

const useFetchConsumerProducts = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);

    useEffect(() => {
        if (!isLoggedIn) return;

        iccConsumerProducts.getDetailedProducts();
    }, [isLoggedIn]);
};

export default useFetchConsumerProducts;
