import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';

const isNotAuthorized = (status) => status === httpErrorsTypes.NOT_AUTHORIZED;
const isBadRequest = (status) => status === httpErrorsTypes.BAD_REQUEST;
const isServiceUnavailable = (status) => status === httpErrorsTypes.SERVICE_UNAVAILABLE;

export default {
    isNotAuthorized,
    isBadRequest,
    isServiceUnavailable,
};
