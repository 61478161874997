import React from 'react';

import {twx} from '../../../utils/tailwindUtils';

const SIZES = {
    SMALL: 's',
    MEDIUM: 'm',
    LARGE: 'l',
    XLARGE: 'xl',
    XXLARGE: 'xxl',
};

const MaxWidthContainer = (props) => {
    const {className, children, size = SIZES.SMALL} = props;

    return (
        <div
            className={twx(
                'flex flex-col items-center',
                {
                    'max-w-[536px]': size === SIZES.SMALL,
                    'max-w-[724px]': size === SIZES.MEDIUM,
                    'max-w-[1280px]': size === SIZES.LARGE,
                    'max-w-[1488px]': size === SIZES.XLARGE,
                    'max-w-[1608px]': size === SIZES.XXLARGE,
                },
                className
            )}
        >
            {children}
        </div>
    );
};

export default MaxWidthContainer;
