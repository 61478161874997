import cn from 'classnames';
import React from 'react';

import styles from './Container.module.scss';

const Container = (props) => {
    const {className, children, isFloatHeight} = props;

    return <div className={cn(styles.Container, className, {[styles.Float]: isFloatHeight})}>{children}</div>;
};

export default Container;
