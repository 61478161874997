import React from 'react';
import {useSelector} from 'react-redux';

import {selectIsAnyRenderedPopup} from '../../state/selectors/modal';
import PopupRaw from './PopupRaw';
import useOneModalOnPageAtATime from './useOneModalOnPageAtATime';

const Popup = (props) => {
    const {allowDisplayWithOtherPopups, ...rest} = props;
    const isVisible = useOneModalOnPageAtATime({allowDisplayWithOtherPopups});
    const isAnyRenderedPopup = useSelector(selectIsAnyRenderedPopup);
    const isHidePopup = allowDisplayWithOtherPopups && isAnyRenderedPopup;
    const zIndex = isHidePopup ? '-z-10' : '';

    if (!isVisible) return null;

    return <PopupRaw className={zIndex} overlayClassName={zIndex} isHidePopup={isHidePopup} {...rest} />;
};

export default Popup;
