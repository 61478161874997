import React from 'react';

import Icon from '../Icon/Icon';

const SocialChannelTelegramIcon = (props) => {
    return (
        <Icon width='63' height='44' fill='none' {...props}>
            <rect x='0.800049' width='62.6' height='44' rx='4' fill='#2AABEE' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M26.46 21.4252C29.9582 19.9011 32.2909 18.8963 33.4581 18.4108C36.7906 17.0247 37.4831 16.7839 37.9344 16.776C38.0337 16.7742 38.2557 16.7988 38.3994 16.9155C38.5208 17.014 38.5542 17.1471 38.5702 17.2405C38.5862 17.3339 38.6061 17.5466 38.5903 17.7129C38.4097 19.6103 37.6283 24.215 37.2307 26.3402C37.0625 27.2395 36.7313 27.541 36.4107 27.5705C35.7138 27.6347 35.1846 27.11 34.5097 26.6676C33.4535 25.9752 32.8569 25.5443 31.8317 24.8687C30.6469 24.0879 31.415 23.6588 32.0902 22.9575C32.2669 22.774 35.3372 19.9812 35.3967 19.7279C35.4041 19.6962 35.411 19.5781 35.3408 19.5157C35.2707 19.4534 35.1671 19.4747 35.0924 19.4917C34.9865 19.5157 33.2992 20.6309 30.0307 22.8372C29.5518 23.1661 29.118 23.3263 28.7294 23.3179C28.3009 23.3087 27.4767 23.0757 26.864 22.8765C26.1125 22.6322 25.5152 22.5031 25.5672 22.0882C25.5943 21.8721 25.8919 21.6511 26.46 21.4252Z'
                fill='white'
            />
        </Icon>
    );
};

export default SocialChannelTelegramIcon;
