import React from 'react';

import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import HomePageForYouContainer from './HomePageForYouContainer/HomePageForYouContainer';

const HomePageLeviaItem = (props) => {
    const {banner, isDarkBannerText, promotedBadgeType, isGradient, defaultImage} = props;
    const {titleKey, descriptionKey, promotionKey, imagePath, amplitudeTrackingValue, action} = banner;
    const localizedStrings = getLocalizedStrings();

    return (
        <HomePageForYouContainer
            externalLink={action}
            imagePath={imagePath}
            defaultImage={defaultImage}
            isDarkText={isDarkBannerText}
            promotedBadgeType={promotedBadgeType}
            badgeText={localizedStrings[promotionKey]}
            titleText={localizedStrings[titleKey]}
            descriptionText={localizedStrings[descriptionKey]}
            amplitudeTrackingValue={amplitudeTrackingValue}
            linearGradient={isGradient}
            className='flex-col-reverse'
        />
    );
};

export default HomePageLeviaItem;
