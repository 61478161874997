import {EditableComponent} from '@adobe/aem-react-editable-components';
import React from 'react';

import {Text, textIsEmpty} from './Text';
import {TextWithContent} from './TextWithContent';

const EditConfig = {
    emptyLabel: 'Text',
    isEmpty: textIsEmpty,
};

const EditableText = ({contentList, ...rest}) => (
    <EditableComponent config={EditConfig} {...rest}>
        {contentList ? <TextWithContent contentList={contentList} {...rest} /> : <Text {...rest} />}
    </EditableComponent>
);

export default EditableText;
