import React from 'react';

import Icon from '../Icon/Icon';

const SuccessIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <g fill='none' fillRule='evenodd'>
                <circle fill='#57760C' transform='rotate(90 12 12)' cx='12' cy='12' r='12' />
                <path
                    d='m5.912 13.133 3.25 3.402 8.243-8.47'
                    fillRule='nonzero'
                    stroke='#FFF'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='1.5'
                />
            </g>
        </Icon>
    );
};

export default SuccessIcon;
