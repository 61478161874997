import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {ButtonRaw} from '../../../../components/Button';
import appConfig from '../../../../config/appConfig';
import useConfigExternalLink from '../../../../hooks/useConfigExternalLink';

const AutoDeliveryEditOrderButton = () => {
    const autoDeliveryEditOrderExternalLink = appConfig.getAutoDeliveryEditOrderExternalLink();
    const redirectToExternalLink = useConfigExternalLink(autoDeliveryEditOrderExternalLink);

    if (!redirectToExternalLink) return;

    return (
        <ButtonRaw className='shrink-0 text-16 font-bold text-scarpa-flow' onClick={redirectToExternalLink}>
            <AemEditableText pageName='auto-delivery' itemName='editOrderButton' />
        </ButtonRaw>
    );
};

export default AutoDeliveryEditOrderButton;
