import React from 'react';

import Icon from '../Icon/Icon';

const SupportServiceIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M36.909 40.274a.91.91 0 0 1-.909-.91v-3.01a3.98 3.98 0 0 0-1.172-2.83A3.975 3.975 0 0 0 32 32.354H16a3.976 3.976 0 0 0-2.829 1.17A3.98 3.98 0 0 0 12 36.354v3.01a.91.91 0 1 1-1.818 0v-3.01c0-1.555.605-3.014 1.704-4.114A5.777 5.777 0 0 1 16 30.536h16c1.554 0 3.015.604 4.114 1.704a5.779 5.779 0 0 1 1.704 4.113v3.012a.91.91 0 0 1-.91.91zM24 12.81c-4.023 0-7.295 3.274-7.295 7.298 0 4.02 3.272 7.294 7.295 7.294s7.296-3.274 7.296-7.294c0-4.024-3.273-7.298-7.296-7.298zm0 16.411c-5.025 0-9.113-4.088-9.113-9.113 0-5.026 4.088-9.114 9.113-9.114 5.026 0 9.114 4.088 9.114 9.114 0 5.025-4.088 9.113-9.114 9.113z'
                fill='#232029'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M31.143 37.528h-3.035a.91.91 0 0 1 0-1.82h3.035a.91.91 0 0 1 0 1.82zM23.963 25.488a5.589 5.589 0 0 1-5.11-3.334.727.727 0 0 1 1.33-.585 4.132 4.132 0 0 0 3.78 2.464 4.15 4.15 0 0 0 3.865-2.676.725.725 0 0 1 .937-.425c.376.14.566.56.425.935a5.61 5.61 0 0 1-5.227 3.62z'
                fill='#00D1D2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M41.09 40.64H6.91a.91.91 0 0 1 0-1.82H41.09a.91.91 0 0 1 0 1.82z'
                fill='#232029'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.501 23.636h-.893c-1.525 0-1.927-.775-1.927-1.562v-2.901c0-.817.368-1.562 1.927-1.562h.893v6.025z'
                fill='#00D1D2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.592 18.52c-1.003.003-1.003.318-1.003.653v2.901c0 .275 0 .65 1.003.655V18.52zm1.818 6.026h-1.802c-2.632 0-2.837-1.892-2.837-2.472v-2.901c0-.742.277-2.472 2.837-2.472h1.802v7.845z'
                fill='#232029'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M35.499 23.636h.894c1.524 0 1.927-.775 1.927-1.562v-2.901c0-.817-.368-1.562-1.927-1.562h-.894v6.025z'
                fill='#00D1D2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M36.408 18.52v4.209c1.003-.005 1.003-.38 1.003-.655v-2.901c0-.335 0-.65-1.003-.653zm-.015 6.026H34.59V16.7h1.803c2.56 0 2.836 1.73 2.836 2.472v2.901c0 .58-.204 2.471-2.836 2.471z'
                fill='#232029'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='m30.848 29.801-.572-1.724c2.378-.788 4.313-3.239 4.313-5.463V19.55c0-5.643-4.59-10.233-10.231-10.233h-.717C18 9.317 13.41 13.907 13.41 19.55h-1.818c0-6.645 5.405-12.05 12.05-12.05h.716c6.644 0 12.05 5.405 12.05 12.05v3.064c0 2.999-2.443 6.157-5.56 7.187z'
                fill='#232029'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M30.1 30.026c.983 0 1.78-.767 1.78-1.715 0-.947-.796-1.716-1.78-1.716s-1.78.77-1.78 1.716c0 .948.796 1.715 1.78 1.715z'
                fill='#00D1D2'
            />
        </Icon>
    );
};

export default SupportServiceIcon;
