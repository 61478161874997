import React from 'react';

import Icon from '../Icon/Icon';

const PlusOneDayIcon = (props) => {
    return (
        <Icon width='65' height='64' fill='none' {...props}>
            <path
                d='M9.75 23.25H55.25V52.75H15.5C12.3244 52.75 9.75 50.1756 9.75 47V23.25Z'
                stroke='#34303D'
                strokeWidth='2.5'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.53 27.2002H51.53V49.1002H17.53C15.3209 49.1002 13.53 47.3093 13.53 45.1002V27.2002Z'
                fill='#00D1D2'
            />
            <rect x='9.75' y='14.0117' width='45.5' height='9.5' stroke='#34303D' strokeWidth='2.5' />
            <rect x='19' y='9' width='1' height='10' rx='0.5' fill='#34303D' stroke='#34303D' />
            <rect x='32' y='9' width='1' height='10' rx='0.5' fill='#34303D' stroke='#34303D' />
            <rect x='45' y='9' width='1' height='10' rx='0.5' fill='#34303D' stroke='#34303D' />
            <path
                d='M27.719 36.996V34.582H25.781V36.996H23.384V38.9H25.781V41.314H27.719V38.9H30.116V36.996H27.719ZM31.476 33.528V35.721L34.706 34.259V44H37.086V31.896H35.165L31.476 33.528Z'
                fill='#34303D'
            />
        </Icon>
    );
};

export default PlusOneDayIcon;
