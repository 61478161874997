import {DEVICE_TYPES} from '../../consts/device/deviceTypes';
import devicePlatformService from '../device/devicePlatformService';
import deviceTypesService from '../device/deviceTypesService';

const getVariableName = (variableObj) => Object.keys(variableObj)[0];

const getIdentificatorObject = (variableObj) => {
    const identificatorName = getVariableName(variableObj);
    const identificatorValue = variableObj[identificatorName];

    return {
        identificatorName,
        identificatorValue,
    };
};

const getProductIdentificator = (product) => {
    const {codentify, deviceSerialNumber} = product;
    const identificatorObject = deviceSerialNumber
        ? getIdentificatorObject({deviceSerialNumber})
        : getIdentificatorObject({codentify});

    return identificatorObject;
};

const getValidProducts = (products) => {
    return products?.filter((x) => !!x) || [];
};

const someProduct = ({products, deviceSerialNumber, codentify}) => {
    const {identificatorName, identificatorValue} = getProductIdentificator({
        deviceSerialNumber,
        codentify,
    });

    return getValidProducts(products).some((product) => product[identificatorName] === identificatorValue);
};

const getNewProductsListWithUpdatedItem = ({products, deviceSerialNumber, codentify, data}) => {
    const isProductExist = someProduct({
        products,
        deviceSerialNumber,
        codentify,
    });

    let newList = products?.length ? [...products] : [];

    if (isProductExist) {
        newList = newList.map((product) => {
            if (product.deviceSerialNumber === deviceSerialNumber || product.codentify === codentify) {
                return {...product, ...data};
            }

            return product;
        });
    } else {
        newList.push({
            deviceSerialNumber,
            codentify,
            ...data,
        });
    }

    return newList;
};

const getTransformedDeviceSerialNumber = (deviceSerialNumber) => {
    if (deviceSerialNumber) {
        const upperCaseSerialNumber = deviceSerialNumber.toUpperCase();
        const isDusnFormat = upperCaseSerialNumber.startsWith('0X');

        if (isDusnFormat) {
            const tranformedSerialNumber =
                '0' + upperCaseSerialNumber.charAt(1).toLowerCase() + upperCaseSerialNumber.slice(2);

            return tranformedSerialNumber;
        }
    }

    return deviceSerialNumber;
};

const isOriginalCodentifyValid = (codentify) => {
    return codentify && !codentify.includes('*');
};

const promoteProductByCodentify = ({products, codentify}) => {
    const index = products.findIndex((product) => product.codentify === codentify);

    if (index !== -1) {
        const [product] = products.splice(index, 1);

        products.unshift(product);
    }

    return products;
};

const getProductName = (device) => {
    const {productName, defaultProductName} = device || {};
    const name = productName || defaultProductName || '';

    return name;
};

const getPreparedType = (material, deviceSerialNumber) => {
    const type = devicePlatformService.getDeviceTypeBySerialNumber(deviceSerialNumber);
    const isOriginalDuoDevice = deviceTypesService.isOriginalsDuoDeviceByCodeOrMaterial({
        material: {materialBrandFamily: material?.materialBrandFamily},
    });
    let preparedType = type;

    if (DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER === type && isOriginalDuoDevice) {
        preparedType = DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_CHARGER;
    } else if (DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER === type && isOriginalDuoDevice) {
        preparedType = DEVICE_TYPES.DEV_TYPE_P1V31_ORIGINALS_HOLDER;
    }

    return preparedType;
};

const getMediaName = (material, isP1V41) => {
    const color = isP1V41 ? material.materialBrandDifferentiator || material.materialColor : material.materialColor;
    const mediaIdParts = [material.materialGroup, material.materialBrandFamily, material.materialModelNumber, color];

    return mediaIdParts.join('_');
};

export default {
    getProductName,
    someProduct,
    getNewProductsListWithUpdatedItem,
    getTransformedDeviceSerialNumber,
    isOriginalCodentifyValid,
    promoteProductByCodentify,
    getPreparedType,
    getMediaName,
};
