import {createSelector} from 'reselect';

const selectTnc = (state) => state.tnc;

const selectTncPopupCodesList = createSelector(selectTnc, (state) => state.tncPopupCodesList);

const selectIsTncPopupVisible = createSelector(
    selectTncPopupCodesList,
    (tncPopupCodesList) => tncPopupCodesList?.length > 0
);

export {selectIsTncPopupVisible, selectTncPopupCodesList};
