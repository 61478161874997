import uaUtils from '../../utils/uaUtils';

const SAFARI_MOBILE_NAME = 'Mobile Safari';
const CHROME_NAME = 'Chrome';
const EDGE_NAME = 'Edge';
const SAFARI_NAME = 'Safari';

const isIosChromeBrowser = () => uaUtils.getBrowserName() === CHROME_NAME && uaUtils.getIsIos();

const isIosSafariBrowser = () => uaUtils.getBrowserName() === SAFARI_MOBILE_NAME && uaUtils.getIsIos();

const isBluefyBrowser = () => !!navigator.userAgent.match(/Bluefy/i);

const isChromeBrowser = () => uaUtils.getBrowserName() === CHROME_NAME;

const isEdgeBrowser = () => uaUtils.getBrowserName() === EDGE_NAME;

const isSafariBrowser = () => uaUtils.getBrowserName() === SAFARI_NAME;

const getUserAgent = () => navigator.userAgent;

const isInStandaloneMode = () => {
    if (window.navigator.standalone) {
        return true;
    }

    if (window.matchMedia('(display-mode: standalone)').matches) {
        return true;
    }

    return false;
};

export default {
    getUserAgent,
    isBluefyBrowser,
    isInStandaloneMode,
    isIosSafariBrowser,
    isIosChromeBrowser,
    isChromeBrowser,
    isEdgeBrowser,
    isSafariBrowser,
};
