import cn from 'classnames';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import appConfig from '../../config/appConfig';
import {HOME_PAGE_HEADER_TITLE} from '../../consts/localization/localizationKeys';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import amplitudeHomePageTracking from '../../services/amplitude/amplitudeHomePageTracking';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '../../services/homePageNavigationService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {selectIsWelcomeAnimationStarted} from '../../state/selectors/global';
import {twx} from '../../utils/tailwindUtils';
import HomePageNavigationBar from '../HomePageNavigationBar/HomePageNavigationBar';
import HomePageTopNavigation from '../HomePageTopNavigation/HomePageTopNavigation';
import PageWarningBannersContainer from '../WarningBanners/PageWarningBannersContainer';
import HomePageAutoDeliverySection from './components/HomePageAutoDeliverySection/HomePageAutoDeliverySection';
import HomePageForYouSection from './components/HomePageForYouSection/HomePageForYouSection';
import HomePageHighlights from './components/HomePageHighlights/HomePageHighlights';
import HomePageTopBanner from './components/HomePageTopBanner/HomePageTopBanner';
import useIsWelcomeMode from './components/useIsWelcomeMode';

const HomePage = () => {
    const {pageAnimationClassName, topNavigationAnimationClassName} = useWelcomeAnimation();
    const localizedStrings = getLocalizedStrings();
    const isAutoDeliveryEnabled = appConfig.getIsAutoDeliveryEnabled();

    useDocumentTitle('Home page');

    useEffect(() => {
        amplitudeHomePageTracking.trackHomePageScreen();
    }, []);

    return (
        <div className={cn('ica-page w-full overflow-hidden bg-secondary', pageAnimationClassName)}>
            <HomePageTopNavigation
                title={localizedStrings[HOME_PAGE_HEADER_TITLE]}
                source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.HOME}
                className={topNavigationAnimationClassName}
            />
            <HomePageTopBanner />
            {isAutoDeliveryEnabled && <HomePageAutoDeliverySection className='mb-2' />}
            <HomePageHighlights className='mb-2' />
            <HomePageForYouSection />
            <PageWarningBannersContainer />
            <HomePageNavigationBar />
        </div>
    );
};

export default HomePage;

const useWelcomeAnimation = () => {
    const isAnimationStarted = useSelector(selectIsWelcomeAnimationStarted);
    const isWelcomeMode = useIsWelcomeMode();

    return {
        pageAnimationClassName: {'animate-fade-in': isWelcomeMode},
        topNavigationAnimationClassName: twx({
            'opacity-0 transition-opacity duration-500': isWelcomeMode,
            'opacity-100': isAnimationStarted,
        }),
    };
};
