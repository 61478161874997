import React from 'react';

import Icon from '../Icon/Icon';

const InputVisibleEyeIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <g transform='translate(3 5.225)' stroke='#34303D' strokeWidth='1.5' fill='none' fillRule='evenodd'>
                <circle cx='9' cy='6.638' r='3.75' />
                <path d='M8.998.75c2.135 0 3.98.765 5.387 1.794C16.12 3.81 17.25 5.487 17.25 6.58c0 1.094-1.131 2.77-2.865 4.038-1.407 1.028-3.252 1.793-5.387 1.793-2.138 0-3.985-.764-5.392-1.793C1.877 9.354.75 7.682.75 6.58c0-1.1 1.127-2.773 2.856-4.037C5.013 1.514 6.86.75 8.998.75z' />
            </g>
        </Icon>
    );
};

export default InputVisibleEyeIcon;
