import React from 'react';

import InfoBox from '../../../../../../components/InfoBox/InfoBox';
import {
    AUTO_DELIVERY_INACTIVE_DESCRIPTION,
    AUTO_DELIVERY_INACTIVE_TITLE,
    AUTO_DELIVERY_LEARN_MORE_LINK,
} from '../../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import AutoDeliveryInactiveButton from '../../../../../../views/AutoDelivery/AutoDeliveryInactive/AutoDeliveryInactiveButton';
import AutoDeliveryWidgetContainer from '../components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from '../components/AutoDeliveryWidgetHeader';
import AutoDeliveryStatusInactiveBenefits from './AutoDeliveryStatusInactiveBenefits';

const AutoDeliveryStatusInactive = ({className}) => {
    const localizedStrings = getLocalizedStrings();
    const title = localizedStrings[AUTO_DELIVERY_INACTIVE_TITLE];
    const description = localizedStrings[AUTO_DELIVERY_INACTIVE_DESCRIPTION];

    return (
        <AutoDeliveryWidgetContainer className={className}>
            <AutoDeliveryWidgetHeader btnLocalizationKey={AUTO_DELIVERY_LEARN_MORE_LINK} />
            <InfoBox className='mt-6 tracking-04'>
                {title && <h4 className='mt-0'>{title}</h4>}
                {description && <div className='mt-2'>{description}</div>}
                <AutoDeliveryStatusInactiveBenefits className='mt-8' />
            </InfoBox>
            <AutoDeliveryInactiveButton primary light className='mt-8' />
        </AutoDeliveryWidgetContainer>
    );
};

export default AutoDeliveryStatusInactive;
