import React, {useEffect} from 'react';

import {ButtonRaw} from '../../components/Button';
import {UserIcon} from '../../components/Icons';
import {StickyHeader} from '../../components/Layout';
import homePageNavigationService from '../../services/homePageNavigationService';
import appRouterService from '../../services/route/appRouterService';
import InboxIcon from './InboxIcon';

const HomePageTopNavigation = ({source, title, className}) => {
    const onConsumerProfileClick = () => {
        appRouterService.forwardToConsumerProfilePage();
    };

    useEffect(() => {
        homePageNavigationService.setSource(source);
    }, [source]);

    return (
        <StickyHeader
            className={className}
            title={title}
            endIcon={
                <div className='flex gap-2 rounded-full bg-secondary p-1'>
                    <InboxIcon />
                    <ButtonRaw onClick={onConsumerProfileClick}>
                        <UserIcon className='h-6 w-6' />
                    </ButtonRaw>
                </div>
            }
        />
    );
};

export default HomePageTopNavigation;
