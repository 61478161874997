import React from 'react';

import Icon from '../Icon/Icon';

const HelpBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M26.943 37.2c4.416-1.46 8.121-5.945 8.121-10.404v-5.041c0-6.095-4.958-11.035-11.074-11.035s-11.074 4.94-11.074 11.035v7.995m11.456 9.657c1.265 0 2.29-.987 2.29-2.206 0-1.219-1.025-2.207-2.29-2.207-1.266 0-2.292.988-2.292 2.207 0 1.219 1.026 2.206 2.292 2.206z'
                    stroke='#34303D'
                    strokeWidth='2'
                />
                <path
                    d='M13 24v4.667h-1.267C9.57 28.667 9 27.464 9 26.247V24h4zM36 24v4.667h1.267c2.162 0 2.733-1.203 2.733-2.42V24h-4z'
                    fill='#00D1D2'
                />
                <path
                    d='M12.916 29.325h-1.47c-2.509 0-3.171-1.277-3.171-2.57V21.98c0-1.342.606-2.57 3.17-2.57h1.47v9.914z'
                    stroke='#34303D'
                    strokeWidth='2'
                    fill='#00D1D2'
                    strokeLinecap='round'
                />
                <path d='M38.28 24.32v1.654c0 .89-.243 1.77-1.161 1.77h-.539V24.32h1.7z' fill='#00D1D2' />
                <path
                    d='M35.064 29.325h1.47c2.508 0 3.17-1.277 3.17-2.57V21.98c0-1.342-.605-2.57-3.17-2.57h-1.47v9.914z'
                    stroke='#34303D'
                    strokeWidth='2'
                    fill='#00D1D2'
                    strokeLinecap='round'
                />
                <path d='m11.419 24.32.001 3.423h-.539c-.918 0-1.161-.879-1.161-1.769V24.32h1.699z' fill='#00D1D2' />
                <path
                    d='M15.87 10.47c2.054-1.524 4.783-2.72 8.13-2.72 3.392 0 6.073 1.154 8.131 2.72'
                    stroke='#00D1D2'
                    strokeWidth='2'
                    strokeLinecap='round'
                />
                <path d='M0 0h48v48H0z' />
            </g>
        </Icon>
    );
};

export default HelpBlueIcon;
