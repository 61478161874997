import React from 'react';

import {useGetAutoDeliveryDetails} from '../../../../../api/consumerApi';
import {AUTO_DELIVERY_VIEW_ORDER_LINK} from '../../../../../consts/localization/localizationKeys';
import amplitudeAutodeliveryTracking from '../../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import AutoDeliveryActiveProgress from '../../../../../views/AutoDelivery/AutoDeliveryActive/AutoDeliveryActiveProgress';
import AutoDeliveryOnHoldButton from '../../../../../views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldButton';
import AutoDeliveryOnHoldInfoBox from '../../../../../views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldInfoBox';
import AutoDeliveryOnHoldSubtitle from '../../../../../views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldSubtitle';
import AutoDeliveryOnHoldTitle from '../../../../../views/AutoDelivery/AutoDeliveryOnHold/AutoDeliveryOnHoldTitle';
import AutoDeliveryWidgetContainer from './components/AutoDeliveryWidgetContainer';
import AutoDeliveryWidgetHeader from './components/AutoDeliveryWidgetHeader';

const AutoDeliveryStatusOnHold = ({className}) => {
    const {
        data: {
            autoDelivery: {statusUpdateReason, status},
            order: {status: orderStatus},
        },
    } = useGetAutoDeliveryDetails();

    const amplitudeEvent = (trackingValue) => {
        amplitudeAutodeliveryTracking.trackAutoDeliveryDashboardButtonClick(trackingValue);
    };

    return (
        <AutoDeliveryWidgetContainer className={className}>
            <AutoDeliveryWidgetHeader btnLocalizationKey={AUTO_DELIVERY_VIEW_ORDER_LINK} />
            <AutoDeliveryOnHoldInfoBox statusUpdateReason={statusUpdateReason} className='mt-4' />
            <AutoDeliveryOnHoldSubtitle statusUpdateReason={statusUpdateReason} className='mt-4' />
            <AutoDeliveryOnHoldTitle />
            <AutoDeliveryActiveProgress status={status} orderStatus={orderStatus} className='mt-4' />
            <AutoDeliveryOnHoldButton
                statusUpdateReason={statusUpdateReason}
                amplitudeEvent={amplitudeEvent}
                className='mt-4'
            />
        </AutoDeliveryWidgetContainer>
    );
};

export default AutoDeliveryStatusOnHold;
