import React from 'react';

import Icon from '../Icon/Icon';

const LocationBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g transform='translate(12.798 7)'>
                    <path
                        d='M11.394 3.255a8.32 8.32 0 0 1 8.319 8.32c0 2.911-2.506 7.74-7.517 14.485a.996.996 0 0 1-.802.404V14.051a3.038 3.038 0 1 0 0-6.076v-4.72z'
                        fill='#00D1D2'
                    />
                    <path
                        d='M11.451 1c2.886 0 5.497 1.18 7.388 3.086a10.563 10.563 0 0 1 3.064 7.462c0 3.65-3 9.307-8.79 17.005a2.08 2.08 0 0 1-3.323 0C4 20.855 1 15.2 1 11.548c0-2.914 1.17-5.552 3.064-7.462A10.372 10.372 0 0 1 11.451 1z'
                        stroke='#34303D'
                        strokeWidth='2'
                    />
                    <circle stroke='#34303D' strokeWidth='2' cx='11.394' cy='11.014' r='3.038' />
                </g>
                <path
                    d='M33.2 34.128c.05 0 .096.02.13.055l5.462 5.662a.362.362 0 0 1-.26.612H9.851a.362.362 0 0 1-.26-.613l5.474-5.661a.18.18 0 0 1 .13-.055'
                    stroke='#00D1D2'
                    strokeWidth='2'
                    strokeLinecap='round'
                />
            </g>
        </Icon>
    );
};

export default LocationBlueIcon;
