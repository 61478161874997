import * as uamMethods from '../../consts/uam/uamMethods';
import {makeSelectUamData} from '../../state/selectors/uam';
import {setDeviceData, setHolderData} from '../../state/slices/deviceSlice';
import {dispatch, getState} from '../../state/store';
import helpers from '../../utils/helpers';
import iccService from '../icc/iccService';
import UamClient from './uamClient';

const getUamInfo = async ({codentify, onError, isHolder}) => {
    try {
        const {assetId} = makeSelectUamData(codentify)(getState()) || {};

        if (assetId) {
            return await getAssets(assetId, codentify, isHolder);
        }

        const responseData = await iccService.fetchUamToken({codentify});
        const fetchedAssetId = responseData?.assetId;

        return await getAssets(fetchedAssetId, codentify, isHolder);
    } catch {
        helpers.runFunction(onError);
    }
};

const getAssets = async (assetId, codentify, isHolder) => {
    if (!assetId) {
        throw new Error('No Asset ID is provided.');
    }

    const deviceInfos = await new UamClient().callGet({
        methodName: uamMethods.GET_DEVICE_INFO,
        requestConfig: {args: assetId},
        codentify,
    });

    const deviceData = deviceInfos?.length ? deviceInfos[0]?.device : null;

    if (!deviceData) {
        throw new Error('No Device Data in Uam info.');
    }

    const mappedDeviceData = mapAssetsToDeviceData(deviceData);

    if (isHolder) {
        dispatch(setHolderData(mappedDeviceData));
    } else {
        dispatch(setDeviceData(mappedDeviceData));
    }

    return mappedDeviceData;
};

const mapAssetsToDeviceData = (deviceData) => {
    const {firmwareVersion} = deviceData;

    return {firmwareVersion};
};

export default {
    getUamInfo,
};
