import React from 'react';

import useGetSpecificSourceWarningBanners from './hooks/useGetSpecificSourceWarningBanners';
import WarningBanners from './WarningBanners';

const SpecificSourceWarningBanners = ({source}) => {
    const warningBanners = useGetSpecificSourceWarningBanners(source);

    return (
        <div className='mt-auto flex w-full flex-col'>
            <WarningBanners warningBanners={warningBanners} />
        </div>
    );
};

export default SpecificSourceWarningBanners;
