import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';
import amplitudeDeviceTracking from './amplitudeDeviceTracking';

const trackADEntryPointScreen = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.DIAGNOSE_SCREEN_VIEWED);
};

const trackADInitialQuestionnaireScreen = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.AD_INITIAL_QUESTIONNAIRE_VIEWED);
};

const trackADNoneOfTheAboveClick = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.NONE_OF_THE_ABOVE_BUTTON_CLICKED);
};

const trackADQuestionnaireSelectClick = (questionnairePage) => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.QUESTIONNAIRE_SELECT_BUTTON_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.QUESTIONNAIRE_PAGE]: questionnairePage,
    });
};

const trackADQuestionnaireScroll = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.QUESTIONNAIRE_SCROLLED);
};

const trackADCleanMyDeviceClick = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.CLEAN_MY_DEVICE_BUTTON_CLICKED);
};

const trackADRemoveStickInstructionScroll = () => {
    amplitudeDeviceTracking.trackDeviceFromStateEvent(AMPLITUDE_EVENT_TYPES.REMOVE_STICK_INSTRUCTIONS_SCROLLED);
};

export default {
    trackADEntryPointScreen,
    trackADInitialQuestionnaireScreen,
    trackADNoneOfTheAboveClick,
    trackADQuestionnaireSelectClick,
    trackADQuestionnaireScroll,
    trackADCleanMyDeviceClick,
    trackADRemoveStickInstructionScroll,
};
