import React from 'react';

import Icon from '../Icon/Icon';

const TncIcon = (props) => {
    return (
        <Icon width='37' height='48' fill='none' {...props}>
            <circle cx='12' cy='19.5' r='6.5' stroke='#34303D' strokeWidth='2' />
            <circle cx='11.9999' cy='19.4999' r='4.19255' fill='#00D1D2' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.13849 19.4949C9.34663 19.2874 9.68276 19.2852 9.89351 19.4901L10.2699 19.8559C10.6621 20.2371 11.2878 20.2325 11.6743 19.8455L14.0664 17.451C14.2768 17.2404 14.6158 17.2331 14.835 17.4345C15.0678 17.6484 15.0754 18.0131 14.8517 18.2364L11.6861 21.3974C11.2948 21.7881 10.6606 21.7871 10.2706 21.395L9.13694 20.2555C8.92749 20.045 8.92818 19.7046 9.13849 19.4949Z'
                fill='#34303D'
            />
            <circle cx='12' cy='36.5' r='6.5' stroke='#34303D' strokeWidth='2' />
            <circle cx='11.9999' cy='36.4999' r='4.19255' fill='#00D1D2' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.13849 36.4949C9.34663 36.2874 9.68276 36.2852 9.89351 36.4901L10.2699 36.8559C10.6621 37.2371 11.2878 37.2325 11.6743 36.8455L14.0664 34.451C14.2768 34.2404 14.6158 34.2331 14.835 34.4345C15.0678 34.6484 15.0754 35.0131 14.8517 35.2364L11.6861 38.3974C11.2948 38.7881 10.6606 38.7871 10.2706 38.395L9.13694 37.2555C8.92749 37.045 8.92818 36.7046 9.13849 36.4949Z'
                fill='#34303D'
            />
            <rect x='1.5' y='9' width='34' height='38' rx='2' stroke='#34303D' strokeWidth='2' strokeLinejoin='round' />
            <path
                d='M27.5 9C28.0523 9 28.5 8.55228 28.5 8V7C28.5 5.89543 27.6046 5 26.5 5H10.5C9.39543 5 8.5 5.89543 8.5 7V8C8.5 8.55228 8.94772 9 9.5 9H27.5Z'
                fill='#00D1D2'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinejoin='round'
            />
            <path
                d='M23.5 34C23.2239 34 23 34.2239 23 34.5C23 34.7761 23.2239 35 23.5 35H30.5C30.7761 35 31 34.7761 31 34.5C31 34.2239 30.7761 34 30.5 34H23.5Z'
                stroke='#34303D'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M23.5 17C23.2239 17 23 17.2239 23 17.5C23 17.7761 23.2239 18 23.5 18H30.5C30.7761 18 31 17.7761 31 17.5C31 17.2239 30.7761 17 30.5 17H23.5Z'
                stroke='#34303D'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M23.5 38C23.2239 38 23 38.2239 23 38.5C23 38.7761 23.2239 39 23.5 39H30.5C30.7761 39 31 38.7761 31 38.5C31 38.2239 30.7761 38 30.5 38H23.5Z'
                stroke='#34303D'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M23.5 21C23.2239 21 23 21.2239 23 21.5C23 21.7761 23.2239 22 23.5 22H30.5C30.7761 22 31 21.7761 31 21.5C31 21.2239 30.7761 21 30.5 21H23.5Z'
                stroke='#34303D'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18.5 5.00982C19.1745 5.00982 19.8347 4.87457 20.3632 4.60904C20.8332 4.37286 21.5 3.86266 21.5 3.00491C21.5 2.14716 20.8332 1.63696 20.3632 1.40078C19.8347 1.13525 19.1745 1 18.5 1C17.8255 1 17.1653 1.13525 16.6368 1.40078C16.1668 1.63696 15.5 2.14716 15.5 3.00491C15.5 3.86266 16.1668 4.37286 16.6368 4.60904C17.1653 4.87457 17.8255 5.00982 18.5 5.00982Z'
                stroke='#34303D'
                strokeWidth='2'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default TncIcon;
