export const PHASE_1_SCREEN = 'PHASE_1_SCREEN';
export const PHASE_2_SCREEN = 'PHASE_2_SCREEN';
export const DAMAGE_CHECK_SCREEN = 'DAMAGE_CHECK_SCREEN';
export const REMOVE_STICK_SCREEN = 'REMOVE_STICK_SCREEN';
export const FITTING_CONSUMABLES_CHECK_SCREEN = 'FITTING_CONSUMABLES_CHECK_SCREEN';
export const FITTING_CONSUMABLES_SOLVED = 'FITTING_CONSUMABLES_SOLVED';
export const SELECT_ISSUE_SCREEN = 'SELECT_ISSUE_SCREEN';
export const QUESTIONNAIRE_MARKET_SCREEN = 'QUESTIONNAIRE_MARKET_SCREEN';
export const CLEANING_GUIDE_SCREEN = 'CLEANING_GUIDE_SCREEN';
export const POST_CLEANING_SCREEN = 'POST_CLEANING_SCREEN';
export const CLEANING_CONTACTS_SCREEN = 'CLEANING_CONTACTS_SCREEN';
export const CHARGING_ISSUE_SCREEN = 'CHARGING_ISSUE_SCREEN';
export const FINISH_SCREEN = 'FINISH_SCREEN';
