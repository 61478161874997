import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    tncPopupCodesList: [],
};

export const slice = createSlice({
    name: 'tnc',
    initialState,
    reducers: {
        setTncPopupCode: (state, action) => {
            const code = action.payload;
            const {tncPopupCodesList} = state;

            return {
                ...state,
                tncPopupCodesList: [...tncPopupCodesList, code],
            };
        },
        removeLastTncPopupCode: (state, action) => {
            const {tncPopupCodesList} = state;
            const newTncPopupCodesList = tncPopupCodesList.slice(0, -1);

            return {
                ...state,
                tncPopupCodesList: newTncPopupCodesList,
            };
        },
    },
});

export const {setTncPopupCode, removeLastTncPopupCode} = slice.actions;

export default slice.reducer;
