import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import AppButton from '../../../components/Button/AppButton/AppButton';
import Image from '../../../components/Image/Image';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {selectIsSmLayout} from '../../../state/selectors/global';
import useOnConnectClick from '../../DevicesPage/hooks/useOnConnectClick';

const SelectDeviceItem = (props) => {
    const {image, name, type, isLast} = props;
    const isRestrictedMarket = appConfig.getIsRestrictedMarket();
    const localizedStrings = getLocalizedStrings();
    const isSmLayout = useSelector(selectIsSmLayout);
    const {onConnectClick} = useOnConnectClick(type);

    return isRestrictedMarket && isSmLayout ? (
        <li className={cn('border-t border-border', {'border-b': isLast})}>
            <AppButton
                onClick={onConnectClick}
                className='w-full max-w-none rounded-none px-8 py-6'
                containerClassName='justify-between'
            >
                {name}
            </AppButton>
        </li>
    ) : (
        <li
            className={cn('flex w-full flex-col items-center gap-5 bg-secondary px-8 pb-8 pt-11 lg:max-w-[296px]', {
                'p-8': isRestrictedMarket,
            })}
        >
            {!isRestrictedMarket && (
                <div className='px-[14px]'>
                    <Image className='h-auto w-full' src={image} alt={name} ariaHidden />
                </div>
            )}
            {isRestrictedMarket && <h3 className='self-start text-16 text-primary'>{name}</h3>}
            <AppButton secondary light onClick={onConnectClick} className='mt-0 sm:min-w-[220px]'>
                {isRestrictedMarket
                    ? localizedStrings[localizationKeys.DEVICE_LIST_CHOOSER_RESTRICTED_MARKET_BUTTON]
                    : name}
            </AppButton>
        </li>
    );
};

export default SelectDeviceItem;
