import React from 'react';

import Icon from '../Icon/Icon';

const TroubleshootIlumaBlueIcon = (props) => {
    return (
        <Icon width='48' height='48' {...props}>
            <g fill='none' fillRule='evenodd'>
                <g transform='rotate(20 -9.647 97.187)'>
                    <path
                        d='M5.538 0H1.652C.551.926 0 1.914 0 2.964v7.504M3.462 0h3.886C8.449.926 9 1.914 9 2.964v25.161c-.702 1.412-1.22 2.304-1.553 2.676-.418.466-.823.699-1.216.699'
                        stroke='#34303D'
                        strokeWidth='2'
                        strokeLinejoin='round'
                    />
                    <path
                        d='M2.74 2.043c-.26.226-.39.707-.39 1.443v13.557'
                        stroke='#00D1D2'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                    />
                    <circle stroke='#00D1D2' fill='#34303D' cx='5.364' cy='12.642' r='1.375' />
                </g>
                <g transform='translate(9 6)'>
                    <rect stroke='#34303D' strokeWidth='2' x='1' y='1' width='19' height='34' rx='3' />
                    <path
                        stroke='#00D1D2'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M4.5 4.673h12'
                    />
                    <circle fill='#00D1D2' cx='10.5' cy='18' r='7.5' />
                    <path
                        d='M10.813 12a1 1 0 0 1 .996.909l.074.807c.348.113.677.266.983.455.017-.016.039-.036.061-.054l.48-.404a1 1 0 0 1 1.35.06l.231.23a1 1 0 0 1 .062 1.347l-.402.482c-.041.05-.086.094-.135.134.106.205.196.422.269.646l.805.07a1 1 0 0 1 .913.997v.634a1 1 0 0 1-.909.996l-.807.074a4.476 4.476 0 0 1-.385.866 1 1 0 0 1 .08.083l.403.48a1 1 0 0 1-.06 1.35l-.23.23a1 1 0 0 1-1.346.062l-.483-.401a1.008 1.008 0 0 1-.106-.103 4.46 4.46 0 0 1-.77.332l-.07.805a1 1 0 0 1-.996.913h-.634a1 1 0 0 1-.996-.909l-.074-.807a4.471 4.471 0 0 1-.65-.268l-.065.074a1 1 0 0 1-.064.059l-.48.403a1 1 0 0 1-1.35-.06l-.23-.23a1 1 0 0 1-.062-1.346l.401-.483.058-.061a4.479 4.479 0 0 1-.457-.984l-.805-.07a1 1 0 0 1-.913-.997v-.634a1 1 0 0 1 .909-.996l.807-.074c.087-.27.199-.527.333-.772a1 1 0 0 1-.102-.102l-.404-.481a1 1 0 0 1 .06-1.35l.23-.23a1 1 0 0 1 1.347-.062l.482.402.086.081c.271-.157.56-.287.864-.385l.07-.805A1 1 0 0 1 10.18 12h.634zm-.313 3a3 3 0 1 0 0 6 3 3 0 0 0 0-6z'
                        fill='#34303D'
                    />
                </g>
            </g>
        </Icon>
    );
};

export default TroubleshootIlumaBlueIcon;
