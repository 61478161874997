import React from 'react';
import {useSelector} from 'react-redux';

import AemEditableText from '../../components/Aem/AemEditableText/AemEditableText';
import AppButton from '../../components/Button/AppButton';
import {ComputerBlueIcon} from '../../components/Icons';
import queryParameters from '../../consts/route/queryParameters';
import deviceTypesService from '../../services/device/deviceTypesService';
import appRouterService from '../../services/route/appRouterService';
import {selectDeviceType} from '../../state/selectors/device';
import urlUtils from '../../utils/urlUtils';

const UsbRequiredPage = () => {
    const [deviceTypeFromQuery] = urlUtils.getDataFromUrl(queryParameters.DEVICE_TYPE);
    const deviceTypeFromState = useSelector(selectDeviceType);
    const deviceType = deviceTypeFromQuery || deviceTypeFromState;
    const deviceName = deviceTypesService.getDeviceModelByType(deviceType);

    const onConnectAnotherClick = () => {
        appRouterService.forwardToSelectYourDevicePage();
    };

    return (
        <section className='flex h-screen flex-1 flex-col'>
            <div className='flex flex-1 flex-col items-center gap-4 px-4 pb-8 pt-safe-offset-4'>
                <ComputerBlueIcon className='h-16 w-16' />
                <h4 className='mb-0 px-6 leading-[40px]'>
                    <AemEditableText
                        pageName='usb-required'
                        itemName='usbRequiredTitle'
                        contentList={deviceName ? [deviceName] : undefined}
                    />
                </h4>
                <p>
                    <AemEditableText pageName='usb-required' itemName='usbRequiredDescription' />
                </p>
            </div>
            <div className='w-full px-4 pt-4 pb-safe-offset-8'>
                <AppButton tertiary onClick={onConnectAnotherClick}>
                    <AemEditableText pageName='usb-required' itemName='usbRequiredConnectButton' />
                </AppButton>
            </div>
        </section>
    );
};

export default UsbRequiredPage;
