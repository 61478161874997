import React, {useEffect} from 'react';

import helpers from '../../../utils/helpers';
import Video from '../Video';

const GuideVideo = (props) => {
    const {
        autoplay = true,
        loop,
        content = {},
        index = 0,
        src,
        thumbnailSrc,
        isVideoPaused,
        isVideoPlay,
        className,
        containerClassName,
        controlsClassName,
        controlAriaLabel,
        onError,
        isAnimationFrameAllowed = true,
        children,
        onEnded,
        onLoadedMetadata,
        isControlsVisible = true,
    } = props || {};
    const currentContent = content[index];
    const {videoStartTime = 0, videoStopTime, isControlsAvailable} = currentContent || {};
    const videoRef = React.createRef();
    let isRequestAnimationFrameAllowed = isAnimationFrameAllowed;
    let req;

    const getIsVideoPaused = () => {
        const video = videoRef.current;
        const isVideoStopTime = typeof videoStopTime === 'number' && video.currentTime >= videoStopTime;
        const isVideoEnded = video.currentTime >= video.duration;

        return isVideoStopTime || isVideoEnded;
    };

    useEffect(() => {
        handleVideoUpdate();
    }, [videoRef]);

    useEffect(() => {
        if (isVideoPaused) {
            videoRef.current?.pause();
            isRequestAnimationFrameAllowed = false;
        }
    }, [isVideoPaused]);

    useEffect(() => {
        if (isVideoPlay && videoRef.current) {
            isRequestAnimationFrameAllowed = true;
            videoRef.current.currentTime = videoStartTime;
            videoRef.current.play().catch(() => {});
        }
    }, [isVideoPlay]);

    useEffect(() => {
        if (videoRef.current) {
            onLoadedData();
        }

        return () => {
            cancelAnimationFrame(req);
        };
    }, [index, videoRef.current]);

    const onLoadedData = () => {
        videoRef.current.currentTime = videoStartTime;
        videoRef.current.play().catch(() => {});

        handleVideoUpdate();
    };

    const handleVideoUpdate = () => {
        if (isRequestAnimationFrameAllowed) {
            const video = videoRef.current;

            if (videoRef.current) {
                const isVideoPaused = getIsVideoPaused();

                if (isVideoPaused) {
                    video.pause();
                } else {
                    video.play().catch(() => {});
                    req = requestAnimationFrame(() => handleVideoUpdate());
                }
            }
        }
    };

    const handleLoadedMetadata = () => {
        videoRef.current.currentTime = videoStartTime;
        helpers.runFunction(onLoadedMetadata, videoRef.current);
    };

    const videoOnCanPlay = () => handleVideoUpdate();

    const onControlsClick = () => {
        cancelAnimationFrame(req);
        const video = videoRef.current;

        if (video.paused) {
            const isVideoPaused = getIsVideoPaused();

            if (isVideoPaused) {
                video.currentTime = videoStartTime;
            }
            isRequestAnimationFrameAllowed = true;
            req = requestAnimationFrame(() => handleVideoUpdate());
        } else {
            video.pause();
            isRequestAnimationFrameAllowed = false;
        }
    };

    return (
        <Video
            autoplay={autoplay}
            loop={loop}
            className={className}
            containerClassName={containerClassName}
            controlsClassName={controlsClassName}
            controlAriaLabel={controlAriaLabel}
            isControlsVisible={isControlsVisible && isControlsAvailable}
            onLoadedMetadata={handleLoadedMetadata}
            muted
            onTimeUpdate={handleVideoUpdate}
            onCanPlay={videoOnCanPlay}
            onControlsClickCustom={onControlsClick}
            playsinline
            thumbnailSrc={thumbnailSrc}
            onError={onError}
            ref={videoRef}
            src={src}
            onEnded={onEnded}
        >
            {children}
        </Video>
    );
};

export default GuideVideo;
