import {createSlice} from '@reduxjs/toolkit';

import {ICC_INBOX_MESSAGE_STATUS_TYPES} from '../../consts/icc/iccInboxMessageStatusTypes';

const initialState = {
    messages: undefined,
    nextPageStartKey: undefined,
};

export const slice = createSlice({
    name: 'inbox',
    initialState,
    reducers: {
        setInboxMessagesAction: (state, action) => {
            const {messages, nextPageStartKey} = action.payload;
            const newMessageIds = new Set(messages.map((e) => e.messageId));
            const newState = (state.messages || []).filter((a) => !newMessageIds.has(a.messageId)).concat(messages);

            return {
                ...state,
                messages: newState,
                nextPageStartKey,
            };
        },
        readInboxMessageAction: (state, action) => {
            const messageId = action.payload;
            const messages = state.messages.map((message) => {
                if (message.messageId === messageId) {
                    return {
                        ...message,
                        status: ICC_INBOX_MESSAGE_STATUS_TYPES.READ,
                    };
                }
                return message;
            });

            return {
                ...state,
                messages,
            };
        },
        deleteInboxMessageAction: (state, action) => {
            const messageId = action.payload;
            const messages = state.messages.filter((message) => message.messageId !== messageId);

            return {
                ...state,
                messages,
            };
        },
        deleteInboxMessagesAction: (state, action) => {
            return {
                ...state,
                messages: undefined,
                nextPageStartKey: undefined,
            };
        },
    },
});

export const {setInboxMessagesAction, readInboxMessageAction, deleteInboxMessageAction, deleteInboxMessagesAction} =
    slice.actions;

export default slice.reducer;
