import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import {getLocalizedStrings} from '../../services/localization/localizationService';
import {selectIsAem} from '../../state/selectors/global';
import RemoteIcon from '../Icon/RemoteIcon';

const ContentList = ({list, className}) => {
    const isAem = useSelector(selectIsAem);
    const localizedStrings = getLocalizedStrings();

    return (
        <ul className={cn('flex w-full flex-col gap-4', className)}>
            {list?.map(({titleKey, descriptionKey, imagePath}) => {
                const title = localizedStrings[titleKey];
                const description = localizedStrings[descriptionKey];

                return (
                    <ItemTitle
                        key={titleKey}
                        title={title}
                        description={description}
                        imagePath={imagePath}
                        isAem={isAem}
                    />
                );
            })}
        </ul>
    );
};

const ItemTitle = ({title, description, imagePath, isAem}) => {
    return (
        <li className={cn({grid: !isAem}, {'gap-x-4': description}, 'grid-cols-[48px_1fr] gap-x-2 empty:hidden')}>
            {title && (
                <>
                    <div className='row-span-2 min-h-12 min-w-12'>
                        <RemoteIcon imagePath={imagePath} />
                    </div>
                    <p className={cn({'font-bold': description}, {'row-span-2': !description}, 'content-center')}>
                        {title}
                    </p>
                </>
            )}
            {description && <p>{description}</p>}
        </li>
    );
};

export default ContentList;
