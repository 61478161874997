import {createSelector} from 'reselect';

const selectNotifications = (state) => state.notifications;

const selectNotificationsBottom = createSelector(selectNotifications, (notifications) =>
    notifications?.filter((x) => x.isBottom)
);
const selectNotificationsTop = createSelector(selectNotifications, (notifications) =>
    notifications?.filter((x) => !x.isBottom)
);

export {selectNotificationsBottom, selectNotificationsTop};
