import React, {useLayoutEffect, useState} from 'react';

import domService from '../../services/domService';
import helpers from '../../utils/helpers';
import AppPopup from './AppPopup';

const PopupFullPage = (props) => {
    const {className, contentRef, ...restProps} = props;
    const [myRef, setMyRef] = useState(null);

    const onRefChange = (node) => {
        setMyRef(node);
        if (contentRef) contentRef(node);
    };

    useLayoutEffect(() => {
        if (myRef) {
            const rootEl = domService.getRoot();
            const heightValue = myRef.offsetTop - rootEl.offsetTop;

            window.scrollTo(0, heightValue);
        }
    }, [myRef]);

    return (
        <AppPopup
            bodyOpenClassName='modal__Body--open'
            overlayClassName='block relative bg-none'
            isOpen
            {...restProps}
            className={className}
            contentRef={onRefChange}
            onAfterClose={() => {
                helpers.scrollTop();
            }}
        />
    );
};

export default PopupFullPage;
