import {useState} from 'react';

export const AUTO_DELIVERY_EDIT_POPUP_TYPES = {
    ASAP: 'asap',
    SKIP: 'skip',
};

const useEditDeliveryPopup = () => {
    const [editPopupType, setEditPopupType] = useState();
    const openAsapDeliveryPopup = () => setEditPopupType(AUTO_DELIVERY_EDIT_POPUP_TYPES.ASAP);
    const openSkipDeliveryPopup = () => setEditPopupType(AUTO_DELIVERY_EDIT_POPUP_TYPES.SKIP);
    const closePopup = () => setEditPopupType(undefined);

    return {editPopupType, openAsapDeliveryPopup, openSkipDeliveryPopup, closePopup};
};

export default useEditDeliveryPopup;
