import {useEffect} from 'react';

import {amplitude} from '../../../../services/amplitude';
import marketService from '../../../../services/marketService';

const useAmplitudeMarket = () => {
    useEffect(() => {
        const marketName = marketService.getMarketCode();

        amplitude.setMarket(marketName);
    }, []);
};

export default useAmplitudeMarket;
