import React from 'react';

import {AppButton} from '../../../components/Button';
import appRouterService from '../../../services/route/appRouterService';

const NotNowButtonAD = (props) => {
    //TODO: uncomment if need to save error codes
    /* eslint-disable no-unused-vars */
    const {
        screenName,
        isTertiary,
        customClassName,
        inferenceCode,
        inferenceCode_L1,
        holderInferenceCode,
        holderInferenceCode_L1,
        isHolder,
        isQuestionnaireThirdStep,
        children,
    } = props;
    /* eslint-enable no-unused-vars */

    const buttonClassName = isTertiary ? '' : 'mt-0 min-w-[312px]';

    const onNotNowButtonClick = () => {
        //TODO: uncomment if need to save error codes
        /*if (inferenceCode && holderInferenceCode) {
            storageService.setFinishAdvancedDiagnosticsDataToLocalStorage({
                inferenceCode,
                inferenceCode_L1: inferenceCode_L1 || inferenceCode,
                screenName,
            });
            storageService.setFinishAdvancedDiagnosticsDataToLocalStorage({
                inferenceCode: holderInferenceCode,
                inferenceCode_L1: holderInferenceCode_L1 || holderInferenceCode,
                screenName,
                isHolder: true,
            });
        } else {
            storageService.setFinishAdvancedDiagnosticsDataToLocalStorage({
                inferenceCode: inferenceCode || holderInferenceCode,
                inferenceCode_L1: inferenceCode_L1 || holderInferenceCode_L1 || inferenceCode || holderInferenceCode,
                screenName: advanceDiagnosticScreenTypes[screenName],
                isQuestionnaireThirdStep,
                isHolder,
            });
        }*/

        appRouterService.forwardToDevicesPage();
    };

    return (
        <AppButton
            secondary={!isTertiary}
            light={!isTertiary}
            tertiary={isTertiary}
            onClick={onNotNowButtonClick}
            className={customClassName || buttonClassName}
        >
            {children}
        </AppButton>
    );
};

export default NotNowButtonAD;
