import React from 'react';

import Icon from '../Icon/Icon';

const RoundedCancelIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <g fill='none' fillRule='evenodd'>
                <circle fill='#7B7881' cx='12' cy='12' r='12' />
                <g stroke='#FFF' strokeLinecap='round' strokeWidth='1.5'>
                    <path d='m16.093 7.91-8.18 8.18M7.913 7.91l8.179 8.18' />
                </g>
            </g>
        </Icon>
    );
};

export default RoundedCancelIcon;
