import {useGetAutoDeliveryDetailsExtended} from '../../../../api/consumerApi';
import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import CloseButton from '../../../../components/CloseButton/CloseButton';
import {StickyPageLayout} from '../../../../components/Layout';
import {PopupFullPage} from '../../../../components/Popup';
import PageWarningBannersContainer from '../../../WarningBanners/PageWarningBannersContainer';
import AutoDeliveryDetailsFooter from '../components/AutoDeliveryDetailsFooter';
import AutoDeliveryOrder from '../components/AutoDeliveryOrder';
import AutoDeliveryOrderAddress from '../components/AutoDeliveryOrderAddress';
import AutoDeliveryOrderSummary from '../components/AutoDeliveryOrderSummary';
import {AUTO_DELIVERY_EDIT_POPUP_TYPES} from '../StatusActive/useEditDeliveryPopup';
import ConfirmAsapButton from './ConfirmAsapButton';
import ConfirmSkipButton from './ConfirmSkipButton';
import DeliveryAsapSection from './DeliveryAsapSection';
import DeliverySkipSection from './DeliverySkipSection';

const EditDeliveryPopup = ({editPopupType, onClose}) => {
    const {
        data: {
            autoDelivery: {isSkipDeliveryAvailable},
            order: {price, deliveryAddress, products},
        },
    } = useGetAutoDeliveryDetailsExtended();

    const isAsap = editPopupType === AUTO_DELIVERY_EDIT_POPUP_TYPES.ASAP;
    const isSkip = editPopupType === AUTO_DELIVERY_EDIT_POPUP_TYPES.SKIP;
    const isShowSkipButton = isSkip && isSkipDeliveryAvailable;

    return (
        <PopupFullPage className='fixed left-0 right-0 z-20 flex h-screen flex-col bg-romance'>
            <StickyPageLayout
                contentClassName='flex-1 text-start'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: isAsap ? (
                        <AemEditableText pageName='auto-delivery' itemName='deliverAsapHeaderTitle' />
                    ) : (
                        <AemEditableText pageName='auto-delivery' itemName='skipDeliveryConfirmHeaderTitle' />
                    ),
                }}
                footer={
                    <AutoDeliveryDetailsFooter>
                        {isAsap && <ConfirmAsapButton closePopup={onClose} />}
                        {isShowSkipButton && <ConfirmSkipButton closePopup={onClose} />}
                    </AutoDeliveryDetailsFooter>
                }
                noScrollFooterClassName='border-t border-mischka pt-4'
                footerClassName='bg-romance border-t-[1px] border-french-gray'
            >
                <>
                    <div className='p-4 pt-safe-offset-14'>
                        {isAsap && <DeliveryAsapSection />}
                        {isSkip && <DeliverySkipSection />}
                        <AutoDeliveryOrder className='border-t border-mischka px-0' products={products} isEditAllowed />
                        <AutoDeliveryOrderSummary className='border-t border-mischka px-0' price={price} />
                        <AutoDeliveryOrderAddress
                            className='border-t border-mischka px-0'
                            address={deliveryAddress}
                            isEditAllowed
                        />
                    </div>
                    <PageWarningBannersContainer />
                </>
            </StickyPageLayout>
        </PopupFullPage>
    );
};

export default EditDeliveryPopup;
