import React from 'react';

import Icon from '../Icon/Icon';

const UserIcon = (props) => {
    return (
        <Icon width='24' height='24' fill='none' {...props}>
            <circle cx='12' cy='12' r='12' fill='#00D1D2' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M14.6666 13.6667C15.0808 13.6667 15.4558 13.8346 15.7272 14.1061C15.9987 14.3775 16.1666 14.7525 16.1666 15.1667V16.7917C16.1666 17.0219 16.0733 17.2302 15.9225 17.381C15.7717 17.5318 15.5634 17.6251 15.3333 17.6251H8.66659C8.43647 17.6251 8.22813 17.5318 8.07733 17.381C7.92653 17.2302 7.83325 17.0219 7.83325 16.7917V15.1667C7.83325 14.7525 8.00115 14.3775 8.27259 14.1061C8.54404 13.8346 8.91904 13.6667 9.33325 13.6667H14.6666Z'
                stroke='black'
                strokeWidth='0.999258'
            />
            <path
                d='M12.0002 11.8333C13.4729 11.8333 14.6668 10.6394 14.6668 9.16667C14.6668 7.69391 13.4729 6.5 12.0002 6.5C10.5274 6.5 9.3335 7.69391 9.3335 9.16667C9.3335 10.6394 10.5274 11.8333 12.0002 11.8333Z'
                stroke='black'
                strokeWidth='0.999258'
            />
        </Icon>
    );
};

export default UserIcon;
