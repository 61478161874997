import React from 'react';

import styles from './AppLoader.module.scss';

const AppLoaderContent = ({loaderText, className}) => {
    return (
        <div className={className}>
            <div className={styles.AppLoader}>
                <div />
                <div />
                <div />
                <div />
            </div>
            {loaderText !== undefined && <div className={styles.LoaderMessage}>{loaderText}</div>}
        </div>
    );
};

export default AppLoaderContent;
