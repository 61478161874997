import React from 'react';
import {useSelector} from 'react-redux';

import AemComponent from '../../../components/Aem/AemComponent/AemComponent';
import EditableText from '../../../components/Aem/EditableText/EditableText';
import {AppButton} from '../../../components/Button';
import * as advanceDiagnosticIconTypes from '../../../consts/advanceDiagnostic/advanceDiagnosticIconTypes';
import * as advanceDiagnosticScreenTypes from '../../../consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import amplitudeADTracking from '../../../services/amplitude/amplitudeADTracking';
import appRouterService from '../../../services/route/appRouterService';
import AdvancedDiagnosticContainer from '../components/AdvancedDiagnosticContainer';
import AdvancedDiagnosticDescription from '../components/AdvancedDiagnosticDescription';
import AdvancedDiagnosticIcon from '../components/AdvancedDiagnosticIcon';
import AdvancedDiagnosticTitle from '../components/AdvancedDiagnosticTitle';
import NotNowButtonAD from '../components/NotNowButtonAD';
import {selectDiagnosticFlowDataResult} from '../state/advancedDiagnosticSelectors';

const AdvancedDiagnosticChargingIssue = () => {
    const diagnosticResult = useSelector(selectDiagnosticFlowDataResult);
    const {deviceInferenceCode, holderInferenceCode} = diagnosticResult || {};
    const inferenceCode = deviceInferenceCode ? deviceInferenceCode : holderInferenceCode;
    const isHolder = inferenceCode === holderInferenceCode;
    const onCleanMyDeviceButtonClick = () => {
        amplitudeADTracking.trackADCleanMyDeviceClick();
        appRouterService.forwardToCleaningContactsScreenAD();
    };

    return (
        <AdvancedDiagnosticContainer isDeviceHero>
            <AdvancedDiagnosticIcon type={advanceDiagnosticIconTypes.ERROR} />
            <div className='px-8'>
                <AdvancedDiagnosticTitle itemName='chargingissuetitle' />
                <AdvancedDiagnosticDescription itemName='chargingissuedesc' />
                <div className='mt-10 grid w-full grid-cols-1 justify-items-center gap-4'>
                    <AppButton primary light className='mt-0 min-w-[312px]' onClick={onCleanMyDeviceButtonClick}>
                        <AemComponent component={EditableText} pageName='ad' itemName='chargingissuebutton' />
                    </AppButton>
                    <NotNowButtonAD
                        isHolder={isHolder}
                        inferenceCode={inferenceCode}
                        screenName={advanceDiagnosticScreenTypes.CHARGING_ISSUE_SCREEN}
                    >
                        <AemComponent component={EditableText} pageName='ad' itemName='chargingissuenotnowbutton' />
                    </NotNowButtonAD>
                </div>
            </div>
        </AdvancedDiagnosticContainer>
    );
};

export default AdvancedDiagnosticChargingIssue;
