const getSuitableHolderAsset = (chargerAsset) => {
    const deviceAssets = SUITABLE_HOLDER_ASSETS.find((a) => a.chargerAsset === chargerAsset);

    return deviceAssets?.holderAsset;
};

const SUITABLE_HOLDER_ASSETS = [
    {
        chargerAsset: 'D0201_III_C1502_ASG',
        holderAsset: 'D0101_III_C1402_ASG',
    },
    {
        chargerAsset: 'D0201_III_M0001_BREEZEB',
        holderAsset: 'D0101_III_C1402_BREEZEB',
    },
    {
        chargerAsset: 'D0201_III_M0001_DGV',
        holderAsset: 'D0101_III_C1402_DGV',
    },
    {
        chargerAsset: 'D0201_III_M0001_DIGITAL',
        holderAsset: 'D0101_III_C1402_DIGITAL',
    },
    {
        chargerAsset: 'D0201_III_C1502_GAR',
        holderAsset: 'D0101_III_C1402_GAR',
    },
    {
        chargerAsset: 'D0201_III_M0001_LEAFGRE',
        holderAsset: 'D0101_III_C1402_LEAFGRE',
    },
    {
        chargerAsset: 'D0201_III_M0001_MGB',
        holderAsset: 'D0101_III_C1402_MGB',
    },
    {
        chargerAsset: 'D0201_III_M0001_MIDNIGH3',
        holderAsset: 'D0101_III_C1402_MIDNIGH3',
    },
    {
        chargerAsset: 'D0201_III_C1502_PAG',
        holderAsset: 'D0101_III_C1402_PAG',
    },
    {
        chargerAsset: 'D0201_III_C1502_PBB',
        holderAsset: 'D0101_III_C1402_PBB',
    },
    {
        chargerAsset: 'D0201_III_C1502_PMB',
        holderAsset: 'D0101_III_C1402_PMB',
    },
    {
        chargerAsset: 'D0201_III_C1502_PRIMEGA',
        holderAsset: 'D0101_III_C1402_PRIMEGA',
    },
    {
        chargerAsset: 'D0201_III_M0001_VIVIDTE',
        holderAsset: 'D0101_III_C1402_VIVIDTE',
    },
    {
        chargerAsset: 'D0201_III_M0001_VTE',
        holderAsset: 'D0101_III_C1402_VTE',
    },
    {
        chargerAsset: 'D0201_IO_A1505_IPL',
        holderAsset: 'D0101_IO_A1406_IPL',
    },
    {
        chargerAsset: 'D0201_IO_A1505_SCT',
        holderAsset: 'D0101_IO_A1406_SCT',
    },
    {
        chargerAsset: 'D0201_IO_A1505_SLT',
        holderAsset: 'D0101_IO_A1406_SLT',
    },
    {
        chargerAsset: 'D0201_IO_A1505_SLV',
        holderAsset: 'D0101_IO_A1406_SLV',
    },
    {
        chargerAsset: 'D0201_IO_A1505_TUR',
        holderAsset: 'D0101_IO_A1406_TUR',
    },
    {
        chargerAsset: 'D0201_IQO1_M0001_ABL',
        holderAsset: 'D0101_IQO1_C1402_ABL',
    },
    {
        chargerAsset: 'D0201_IQO1_C1502_BZT',
        holderAsset: 'D0101_IQO1_C1402_BZT',
    },
    {
        chargerAsset: 'D0201_IQO1_C1502_GKK',
        holderAsset: 'D0101_IQO1_C1402_GKK',
    },
    {
        chargerAsset: 'D0201_IQO1_C1502_JAG',
        holderAsset: 'D0101_IQO1_C1402_JAG',
    },
    {
        chargerAsset: 'D0201_IQO1_M0001_MOSSGRE',
        holderAsset: 'D0101_IQO1_C1402_MOSSGRE',
    },
    {
        chargerAsset: 'D0201_IQO1_M0001_NEON',
        holderAsset: 'D0101_IQO1_C1402_NEON',
    },
    {
        chargerAsset: 'D0201_IQO1_C1502_NEP',
        holderAsset: 'D0101_IQO1_C1402_NEP',
    },
    {
        chargerAsset: 'D0201_IQO1_C1502_OBL',
        holderAsset: 'D0101_IQO1_C1402_OBL',
    },
    {
        chargerAsset: 'D0201_IQO1_M0001_PEB',
        holderAsset: 'D0101_IQO1_C1402_PEB',
    },
    {
        chargerAsset: 'D0201_IQO1_M0001_PEBBLEG',
        holderAsset: 'D0101_IQO1_C1402_PEBBLEG',
    },
    {
        chargerAsset: 'D0201_IQO1_M0001_PEG',
        holderAsset: 'D0101_IQO1_C1402_PEG',
    },
    {
        chargerAsset: 'D0201_IQO1_C1502_PRIMENE',
        holderAsset: 'D0101_IQO1_C1402_PRIMENE',
    },
    {
        chargerAsset: 'D0201_IQO1_M0001_SUR',
        holderAsset: 'D0101_IQO1_C1402_SUR',
    },
    {
        chargerAsset: 'D0201_IQO1_C1502_TER',
        holderAsset: 'D0101_IQO1_C1402_TER',
    },
    {
        chargerAsset: 'D0201_IQO1_C1502_TUR',
        holderAsset: 'D0101_IQO1_C1402_TUR',
    },
    {
        chargerAsset: 'D0201_IQOS_A1502_BLU',
        holderAsset: 'D0101_IQOS_A1402_BLU',
    },
    {
        chargerAsset: 'D0201_IQOS_A1502_HOP',
        holderAsset: 'D0101_IQOS_A1402_HOP',
    },
    {
        chargerAsset: 'D0201_IQOS_A1502_NAV',
        holderAsset: 'D0101_IQOS_A1402_NAV',
    },
    {
        chargerAsset: 'D0201_IQOS_A1502_PNK',
        holderAsset: 'D0101_IQOS_A1402_PNK',
    },
    {
        chargerAsset: 'D0201_IQOS_A1502_RED',
        holderAsset: 'D0101_IQOS_A1402_RED',
    },
    {
        chargerAsset: 'D0201_IQOS_A1502_SRB',
        holderAsset: 'D0101_IQOS_A1402_SRB',
    },
    {
        chargerAsset: 'D0201_IQOS_A1502_WHT',
        holderAsset: 'D0101_IQOS_A1402_WHT',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_BLU',
        holderAsset: 'D0101_IQOS_A1403_BLU',
    },
    {
        chargerAsset: 'D0201_IQOS_D0201_CAM',
        holderAsset: 'D0101_IQOS_A1403_CAM',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_MSR',
        holderAsset: 'D0101_IQOS_A1403_MSR',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_NAV',
        holderAsset: 'D0101_IQOS_A1403_NAV',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_PNK',
        holderAsset: 'D0101_IQOS_A1403_PNK',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_RED',
        holderAsset: 'D0101_IQOS_A1403_RED',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_RUB',
        holderAsset: 'D0101_IQOS_A1403_RUB',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_SLT',
        holderAsset: 'D0101_IQOS_A1403_SLT',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_SRB',
        holderAsset: 'D0101_IQOS_A1403_SRB',
    },
    {
        chargerAsset: 'D0201_IQOS_A1503_WHT',
        holderAsset: 'D0101_IQOS_A1403_WHT',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_BLU',
        holderAsset: 'D0101_IQOS_A1404_BLU',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_BRG',
        holderAsset: 'D0101_IQOS_A1404_BRG',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_GOL',
        holderAsset: 'D0101_IQOS_A1404_GOL',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_GRY',
        holderAsset: 'D0101_IQOS_A1404_GRY',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_RED',
        holderAsset: 'D0101_IQOS_A1404_RED',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_SRB',
        holderAsset: 'D0101_IQOS_A1404_SRB',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_VEG',
        holderAsset: 'D0101_IQOS_A1404_VEG',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_WWH',
        holderAsset: 'D0101_IQOS_A1404_WWH',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_BLU',
        holderAsset: 'D0101_IQOS_A1406_BLU',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_COO',
        holderAsset: 'D0101_IQOS_A1406_COO',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_COP',
        holderAsset: 'D0101_IQOS_A1406_COP',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_FRE',
        holderAsset: 'D0101_IQOS_A1406_FRE',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_GOL',
        holderAsset: 'D0101_IQOS_A1406_GOL',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_GRA',
        holderAsset: 'D0101_IQOS_A1406_GRA',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_GRY',
        holderAsset: 'D0101_IQOS_A1406_GRY',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_LUT',
        holderAsset: 'D0101_IQOS_A1406_LUT',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_MOS',
        holderAsset: 'D0101_IQOS_A1406_MOS',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_RRD',
        holderAsset: 'D0101_IQOS_A1406_RRD',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_SRB',
        holderAsset: 'D0101_IQOS_A1406_SRB',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_TWE',
        holderAsset: 'D0101_IQOS_A1406_TWE',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_VEG',
        holderAsset: 'D0101_IQOS_A1406_VEG',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_WHT',
        holderAsset: 'D0101_IQOS_A1406_WHT',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_WRC',
        holderAsset: 'D0101_IQOS_A1406_WRC',
    },
    {
        chargerAsset: 'D0201_IQOS_A1505_WWH',
        holderAsset: 'D0101_IQOS_A1406_WWH',
    },
    {
        chargerAsset: 'D0201_IQOS_A1504_WHT',
        holderAsset: 'D0201_IQOS_A1404_WHT',
    },
    {
        chargerAsset: 'M0302_IO_A1505_IPL',
        holderAsset: 'M0301_IO_A1406_IPL',
    },
    {
        chargerAsset: 'M0302_IO_A1505_SCT',
        holderAsset: 'M0301_IO_A1406_SCT',
    },
    {
        chargerAsset: 'M0302_IO_A1505_SLT',
        holderAsset: 'M0301_IO_A1406_SLT',
    },
    {
        chargerAsset: 'M0302_IO_A1505_SLV',
        holderAsset: 'M0301_IO_A1406_SLV',
    },
    {
        chargerAsset: 'M0302_IO_A1505_TUR',
        holderAsset: 'M0301_IO_A1406_TUR',
    },
];

export {getSuitableHolderAsset};
