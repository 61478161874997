import cn from 'classnames';

import loaderImage from '../../../../assets/images/loading_primary.png';
import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import styles from '../../../components/Button/AppButton/AppButton.module.scss';
import {getDefaultDeviceDataByType} from '../../../services/device/deviceData';

const DeviceInfoBlock = (props) => {
    const {node, nodeData, holderDefaultData, modelLabelKey, codentifyLabelKey, firmwareLabelKey, loading} = props;
    const {codentify, type, isHolder} = node;
    const {firmwareVersion} = nodeData || {};
    const displayedCodentify = codentify ? codentify : '--';
    const displayedFirmwareVersion = firmwareVersion ? firmwareVersion : '--';

    const defaultDeviceData = getDefaultDeviceDataByType(type);
    const productName = isHolder ? holderDefaultData?.modelName : defaultDeviceData?.name;

    return (
        <div className='border-b border-mischka first:pt-0 last:border-0'>
            <div className={cn('mb-2 text-14 leading-[20px] md:mb-4 lg:mb-2', {'mt-4 lg:mt-0': isHolder})}>
                <AemEditableText pageName='about-device' itemName={modelLabelKey} />
            </div>
            <h6 className='mb-8 text-18 md:mb-2 lg:mb-6'>{productName}</h6>
            <div className='mb-8 flex flex-col gap-6'>
                <div className='flex gap-[14px]'>
                    <span className='w-[38%] break-words text-black'>
                        <AemEditableText pageName='about-device' itemName={codentifyLabelKey} />
                    </span>
                    <span className='flex-1'>{displayedCodentify}</span>
                </div>
                <div className='flex gap-[14px]'>
                    <span className='w-[38%] break-words text-black'>
                        <AemEditableText pageName='about-device' itemName={firmwareLabelKey} />
                    </span>
                    <FirmwareVersionBlock version={displayedFirmwareVersion} loading={loading} />
                </div>
            </div>
        </div>
    );
};

const FirmwareVersionBlock = ({version, loading}) => {
    return (
        <div className='relative'>
            <span className={cn({'opacity-0': loading}, 'flex-1')}>{version}</span>
            {loading && (
                <div className='absolute left-0 top-0 min-h-6 min-w-[14px]'>
                    <img alt='' className={cn([styles.Loader])} src={loaderImage} />
                </div>
            )}
        </div>
    );
};

export default DeviceInfoBlock;
