import {getSuitableHolderAsset} from '../../consts/device/deviceAssetsData';
import {selectConsumerProductsInfo} from '../../state/selectors/consumer';
import {getState} from '../../state/store';
import mediaService from '../mediaService';
import productHelpers from '../product/productHelpers';
import deviceTypesService from './deviceTypesService';

const getDeviceAssetsList = (assets) => {
    const mediaNames = assets.filter((mediaId) => mediaId);
    const mediaThumbnails = mediaNames.map((mediaId) => mediaService.getThumbnailName(mediaId));
    const result = [...mediaNames, ...mediaThumbnails];

    return result;
};

const getHolderMediaId = (device, holder, isHolderSingle) => {
    if (isHolderSingle) {
        return holder.singleHolderMediaId;
    }
    if (holder?.mediaId) {
        return holder.mediaId;
    }
    if (device?.mediaId) {
        return getSuitableHolderMediaId(device);
    }

    return null;
};

const getSuitableHolderMediaId = (device) => {
    const productsInfo = selectConsumerProductsInfo(getState());
    const product = productsInfo.find((p) => p.codentifyID === device.codentify);

    if (product) {
        const chargerAsset = getMediaNameByProduct(product);

        if (chargerAsset) {
            const suitableDeviceAsset = getSuitableHolderAsset(chargerAsset);

            if (suitableDeviceAsset) {
                return mediaService.assetMapping(suitableDeviceAsset);
            }
        }
    }

    return null;
};

const getMediaNameByProduct = (product) => {
    const {deviceSerialNumber, material} = product || {};

    if (!deviceSerialNumber) return null;

    const preparedType = productHelpers.getPreparedType(material, deviceSerialNumber);
    const isP1V41 = deviceTypesService.isP1V41(preparedType);

    return productHelpers.getMediaName(material, isP1V41);
};

export default {
    getHolderMediaId,
    getDeviceAssetsList,
    getSuitableHolderMediaId,
};
