import React from 'react';

import {ErrorIcon, SuccessIcon, WarningIcon} from '../../../components/Icons';
import * as advanceDiagnosticIconTypes from '../../../consts/advanceDiagnostic/advanceDiagnosticIconTypes';

const AdvancedDiagnosticIcon = (props) => {
    const {type, className} = props;

    const getIcon = () => {
        switch (type) {
            case advanceDiagnosticIconTypes.ERROR:
                return ErrorIcon;
            case advanceDiagnosticIconTypes.WARNING:
                return WarningIcon;
            case advanceDiagnosticIconTypes.SUCCESS:
                return SuccessIcon;
            default:
                return ErrorIcon;
        }
    };

    const Icon = getIcon();

    return Icon ? <Icon className={className || 'h-9 w-9 md:h-12 md:w-12'} /> : null;
};

export default AdvancedDiagnosticIcon;
