import React from 'react';

import Icon from '../Icon/Icon';

const CancelSmallIcon = (props) => {
    return (
        <Icon width='16' height='16' {...props}>
            <path d='M12.6769 3.32645L3.32906 12.6743' stroke='#232029' strokeLinecap='round' />
            <path d='M3.32909 3.32645L12.6769 12.6743' stroke='#232029' strokeLinecap='round' />
        </Icon>
    );
};

export default CancelSmallIcon;
