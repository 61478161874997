import React from 'react';

import {ButtonRaw} from '../../../components/Button';
import {BinIcon} from '../../../components/Icons';
import {StickyHeader} from '../../../components/Layout';
import {NOTIFICATION_DETAILS_PAGE_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import inboxMessageService from '../InboxView/inboxMessageService';
import NotificationDetailsMessage from './components/NotificationDetailsMessage';
import NotificationDetailsReadNext from './components/NotificationDetailsReadNext';

const NotificationDetails = ({detailedMessage, nextMessage, onBackButtonClick}) => {
    const localizedStrings = getLocalizedStrings();

    const onDeleteMessage = () => {
        inboxMessageService.deleteInboxMessage(detailedMessage.messageId);
        // TODO: add confirmation popup or some handler
        onBackButtonClick();
    };

    return (
        <div className='flex flex-col bg-secondary text-start'>
            <StickyHeader
                onBack={onBackButtonClick}
                endIcon={
                    <ButtonRaw onClick={onDeleteMessage}>
                        <BinIcon />
                    </ButtonRaw>
                }
                title={localizedStrings.formatString(
                    localizedStrings[NOTIFICATION_DETAILS_PAGE_HEADER_TITLE],
                    detailedMessage?.title
                )}
            />
            <NotificationDetailsMessage
                className='px-layout grow bg-romance pb-2 pt-safe-offset-14'
                {...detailedMessage}
            />
            {nextMessage && <NotificationDetailsReadNext notificationItem={nextMessage} />}
        </div>
    );
};

export default NotificationDetails;
