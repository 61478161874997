import appConfig from '../config/appConfig';
import {selectSignedMediaList} from '../state/selectors/media';
import {addSignedMedia} from '../state/slices/mediaSlice';
import {dispatch, getState} from '../state/store';
import arrayUtils from '../utils/arrayUtils';
import stringUtils from '../utils/stringUtils';
import iccMarketService from './icc/iccMarketService';

const getThumbnailNameList = (mediaData) => {
    const mediaIdList = arrayUtils.toArray(mediaData);
    const thumbnailNameList = mediaIdList.map((mediaId) => getThumbnailName(mediaId));

    return thumbnailNameList;
};

const getThumbnailName = (mediaId) => {
    const THUMBNAIL_NAME_PREFIX = '_50x50';
    const thumbnailName = mediaId ? mediaId.replace(/(\.[\w\d_-]+)$/i, `${THUMBNAIL_NAME_PREFIX}$1`) : undefined;

    return thumbnailName;
};

const getSignedMediaUrl = async (mediaId) => {
    const data = await iccMarketService.getMediaUrl({mediaNameList: mediaId});

    if (data) {
        dispatch(addSignedMedia(data));
    }

    return data;
};

const getSignedMediaList = () => selectSignedMediaList(getState());

const assetMapping = (mediaName) => mediaIdMapping(mediaName, appConfig.getAssetsPath());
const mediaIdMapping = (mediaName, assetsPath) => stringUtils.formatString(assetsPath, mediaName);

export default {
    getSignedMediaUrl,
    getThumbnailNameList,
    getSignedMediaList,
    getThumbnailName,
    assetMapping,
};
