import React from 'react';
import {useSelector} from 'react-redux';

import appConfig from '../../../../../config/appConfig';
import deviceTypesService from '../../../../../services/device/deviceTypesService';
import {selectConsumerProductsMergedInfo} from '../../../../../state/selectors/consumer';
import HomePageCampaignItem from '../components/HomePageCampaignItem';
import HomePageLearnHowToUseItem from '../components/HomePageLearnHowToUseItem';
import HomePageLeviaItem from '../components/HomePageLeviaItem';
import HomePageTextBottomItem from '../components/HomePageTextBottomItem';
import HomePageTextTopItem from '../components/HomePageTextTopItem';
import HomePageTradeInItem from '../components/HomePageTradeInItem';

const BANNER_TEXT_BOTTOM = 'bannerTextBottom';
const BANNER_TEXT_TOP = 'bannerTextTop';
const BANNER_LEVIA = 'bennerLevia';
const BANNER_UWM = 'bannerUwm';
const BANNER_TRADE_IN = 'bannerTradeIn';
const BANNER_CAMPAIGN = 'bannerCampaign';

const useGetForYouSectionList = () => {
    const forYouSection = appConfig.getHomePageForYouSection() || [];
    const products = useSelector(selectConsumerProductsMergedInfo) || [];
    const list = [];

    const pushItem = ({isEnabled, component: Component, properties}) => {
        if (isEnabled) {
            list.push(<Component {...properties} />);
        }
    };

    for (const forYouProperties of forYouSection) {
        const {banner, type, device, action} = forYouProperties;
        const externalLink = banner?.action;
        const {externalLinkCode, ssoTemplate} = externalLink || {};

        switch (type) {
            case BANNER_TRADE_IN:
                pushItem({
                    component: HomePageTradeInItem,
                    isEnabled:
                        (externalLinkCode || ssoTemplate) && products.some((p) => deviceTypesService.isP1(p.type)),
                    properties: {...forYouProperties},
                });

                break;
            case BANNER_LEVIA:
                pushItem({
                    component: HomePageLeviaItem,
                    isEnabled:
                        (externalLinkCode || ssoTemplate) && products.some((p) => deviceTypesService.isP1V4(p.type)),
                    properties: {...forYouProperties},
                });

                break;
            case BANNER_TEXT_TOP:
                pushItem({
                    component: HomePageTextTopItem,
                    isEnabled: externalLinkCode || ssoTemplate,
                    properties: {...forYouProperties},
                });

                break;
            case BANNER_UWM:
                const {deviceType} = device || {};
                const item = {
                    component: HomePageLearnHowToUseItem,
                    isEnabled: action && products.some((p) => p.type === deviceType),
                    properties: {
                        ...forYouProperties,
                        deviceType,
                    },
                };

                pushItem(item);

                break;
            case BANNER_TEXT_BOTTOM:
                pushItem({
                    component: HomePageTextBottomItem,
                    isEnabled: externalLinkCode || ssoTemplate,
                    properties: {...forYouProperties},
                });
                break;
            case BANNER_CAMPAIGN:
                pushItem({
                    component: HomePageCampaignItem,
                    isEnabled: externalLinkCode || ssoTemplate,
                    properties: {...forYouProperties},
                });
                break;
            default:
                break;
        }
    }

    const listWithOrder = list
        .filter((item) => item?.props?.banner?.order)
        .sort((a, b) => a.props.banner.order - b.props.banner.order);
    const listWithoutOrder = list.filter((item) => !item?.props?.banner?.order);

    return [...listWithOrder, ...listWithoutOrder];
};

export default useGetForYouSectionList;
