import {useState} from 'react';

const useDevicesListPopup = () => {
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const openPopup = () => setIsPopupOpened(true);
    const closePopup = () => setIsPopupOpened(false);

    return {isPopupOpened, openPopup, closePopup};
};

export default useDevicesListPopup;
