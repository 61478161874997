import {
    SocialChannelFacebookIcon,
    SocialChannelInstagramIcon,
    SocialChannelTelegramIcon,
    SocialChannelTwitterIcon,
    SocialChannelYoutubeIcon,
} from '../../../components/Icons';
import appConfig from '../../../config/appConfig';
import {getLocalizedStrings} from '../../../services/localization/localizationService';

const useGetContactUs = () => {
    const contactUs = appConfig.getContactUs();
    const localizedStrings = getLocalizedStrings();

    if (!contactUs) return null;

    const {
        imagePath,
        socialChannelsTwitterAction,
        socialChannelsFacebookAction,
        socialChannelsYoutubeAction,
        socialChannelsTelegramAction,
        socialChannelsInstagramAction,
        sendQuestionEmail,
        comeVisitUsNearestStoreAction,
        callUsPhoneNumber,
        weekdaysTitle,
        weekdaysHours,
        saturdayTitle,
        saturdayHours,
        sundayTitle,
        sundayHours,
    } = contactUs;

    const socialChannels = [
        {
            icon: <SocialChannelTwitterIcon />,
            action: socialChannelsTwitterAction,
        },
        {
            icon: <SocialChannelFacebookIcon />,
            action: socialChannelsFacebookAction,
        },
        {
            icon: <SocialChannelYoutubeIcon />,
            action: socialChannelsYoutubeAction,
        },
        {
            icon: <SocialChannelTelegramIcon />,
            action: socialChannelsTelegramAction,
        },
        {
            icon: <SocialChannelInstagramIcon />,
            action: socialChannelsInstagramAction,
        },
    ];

    const callUs = {
        phoneNumber: callUsPhoneNumber,
        workingDays: [
            {
                title: localizedStrings[weekdaysTitle],
                hours: localizedStrings[weekdaysHours],
            },
            {
                title: localizedStrings[saturdayTitle],
                hours: localizedStrings[saturdayHours],
            },
            {
                title: localizedStrings[sundayTitle],
                hours: localizedStrings[sundayHours],
            },
        ],
    };

    const imagePathCombined = appConfig.getCombinedPathAssetPath(imagePath);

    return {
        imagePath: imagePathCombined,
        socialChannels: socialChannels.filter((c) => c.action),
        email: sendQuestionEmail,
        nearestStoreAction: comeVisitUsNearestStoreAction,
        callUs,
    };
};

export default useGetContactUs;
