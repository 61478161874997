import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'slick-carousel/slick/slick.css';
import './assets/css/global.scss';
import './assets/css/index.scss';
import './assets/css/layout.css';
import './assets/css/objects.scss';

import {ModelManager} from '@adobe/aem-spa-page-model-manager';
import {setupListeners} from '@reduxjs/toolkit/query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';

import appConfig from './app/config/appConfig';
import {initConfig} from './app/config/config';
import App from './app/containers/App/App';
import ErrorHandlingPage from './app/containers/ErrorHandlingPage/ErrorHandlingPage';
import aemService from './app/services/aem/aemService';
import languageDataService from './app/services/localization/languageDataService';
import log from './app/services/logger/log';
import marketService from './app/services/marketService';
import * as pathService from './app/services/route/pathService';
import {setIsAem} from './app/state/slices/globalSlice';
import {addReduxLoggerIfEnabled, configureStore, dispatch} from './app/state/store';
import helpers from './app/utils/helpers';
import {setupManifest} from './manifest';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const start = async () => {
    const timeOut = parseInt(localStorage.getItem('startTimeout') || 0);

    if (timeOut) await helpers.timeout(timeOut);

    const store = configureStore();

    try {
        const isMarketCodeValid = marketService.validateAndInitiateMarketCode();

        if (!isMarketCodeValid) {
            throw new Error(`MarketCode is not valid, location: ${window.location}`);
        }

        setupListeners(store.dispatch);

        await initConfig(marketService.getMarketCode());

        log.setLevel(appConfig.getLogLevel(), appConfig.getAwsLogLevel());
        setupManifest();

        await addReduxLoggerIfEnabled();

        aemService.initAemComponents();

        const isInitialized = await languageDataService.initLanguage();

        if (!isInitialized) return;

        // Initialize the ModelManager before invoking root.render(..).
        await initAem();

        render(store, App);
    } catch (e) {
        log.error(e);
        render(store, ErrorHandlingPage);
    }
};

const initAem = async () => {
    const isAem = pathService.isAemPath();

    dispatch(setIsAem(isAem));

    await ModelManager.initialize(`${pathService.getFullAemPath()}/home`);
};

const render = (store, Component) => {
    const isAem = pathService.isAemPath();
    const root = ReactDOM.createRoot(document.getElementById('root'));

    if (isAem) {
        document.body.classList.add('aem-container');
    }

    root.render(
        <Provider store={store}>
            <Component />
        </Provider>
    );
};

start();

serviceWorkerRegistration.register();
