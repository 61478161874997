import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import ROUTE_PATHS from '../../consts/route/routePaths';
import * as tncTypes from '../../consts/tnc/tncTypes';
import useWithLocale from '../../hooks/useWithLocale';
import appRouterService from '../../services/route/appRouterService';
import tncService from '../../services/tncService';
import {
    makeSelectAcceptedTncByType,
    selectConsumerAcceptedTnc,
    selectConsumerTnc,
} from '../../state/selectors/consumer';
import {selectAggregatedSettings} from '../../state/selectors/consumerAggregatedSettings';
import {selectTncPopupCodesList} from '../../state/selectors/tnc';
import DataCollectionPage from './DataCollectionPage/DataCollectionPage';
import PreferenceCenterPage from './PreferenceCenterPage/PreferenceCenterPage';
import TermsOfUsePage from './TermsOfUsePage/TermsOfUsePage';

const mapStateToProps = createStructuredSelector({
    tncPopupCodesList: selectTncPopupCodesList,
    aggregatedSettings: selectAggregatedSettings,
    termsOfUse: selectConsumerTnc,
    acceptedTermsOfUse: selectConsumerAcceptedTnc,
    acceptedTncPrefCenter: makeSelectAcceptedTncByType(tncTypes.WEB_PREFERENCE_CENTER),
});

const TNC = (props) => {
    const withLocale = useWithLocale();
    const {tncPopupCodesList, aggregatedSettings, termsOfUse, acceptedTermsOfUse, acceptedTncPrefCenter} = props;
    const isAggregatedSettingsLoaded = !!aggregatedSettings;
    const {allNonMandatoryTermsReviewed, allMandatoryTermsApproved} = aggregatedSettings || {};
    const isShowTnc = !tncPopupCodesList?.length && isAggregatedSettingsLoaded;
    const isUpdatedTnc = acceptedTncPrefCenter != null;

    useEffect(() => {
        if (!termsOfUse || !acceptedTermsOfUse) return;

        if (!allMandatoryTermsApproved) {
            appRouterService.forwardToTNCTermsOfUsePage();
        } else if (!allNonMandatoryTermsReviewed) {
            if (isUpdatedTnc) {
                appRouterService.forwardToTNCDataCollectionPage();
            } else {
                appRouterService.forwardToTNCPreferenceCenterPage();
            }
        }
    }, [termsOfUse, acceptedTermsOfUse, allMandatoryTermsApproved, allNonMandatoryTermsReviewed, isUpdatedTnc]);

    useEffect(() => {
        if (!isAggregatedSettingsLoaded) return;

        if (!allMandatoryTermsApproved || !allNonMandatoryTermsReviewed) {
            tncService.getTncData(null, appRouterService.forwardToErrorPage);
        } else {
            appRouterService.forwardToEnableNotificationPageIfNeeded();
        }
    }, [isAggregatedSettingsLoaded, allMandatoryTermsApproved, allNonMandatoryTermsReviewed]);

    return (
        isShowTnc && (
            <>
                <Route path={withLocale(ROUTE_PATHS.TNC_TERMS_OF_USE)} component={TermsOfUsePage} />
                <Route path={withLocale(ROUTE_PATHS.TNC_DATA_COLLECTION)} component={DataCollectionPage} />
                <Route path={withLocale(ROUTE_PATHS.TNC_DATA_PREFERENCE)} component={PreferenceCenterPage} />
            </>
        )
    );
};

export default connect(mapStateToProps)(TNC);
