import {React, useEffect} from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {ClockIcon} from '../../../../components/Icons';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import appConfig from '../../../../config/appConfig';
import amplitudeAutoDeliveryTracking from '../../../../services/amplitude/amplitudeAutoDeliveryTracking';
import AutoDeliveryButton from '../../../../views/AutoDelivery/AutoDeliveryButton';
import AutoDeliveryStatusTitle from '../../../../views/AutoDelivery/AutoDeliveryStatusTitle';
import useDeliveryDate from '../hooks/useDeliveryDate';
import useOrderSummaryTracking from '../hooks/useOrderSummaryTracking';
import EditDeliveryPopupTopSection from './EditDeliveryPopupTopSection';

const DeliverySkipSection = () => {
    const externalLinkConfig = appConfig.getAutoDeliverySkipGoToWebsiteExternalLink();
    const {skipDateShort, isSkipDeliveryAvailable} = useDeliveryDate();
    const orderSummaryTrackingValue = useOrderSummaryTracking();

    useEffect(() => {
        if (!isSkipDeliveryAvailable) {
            amplitudeAutoDeliveryTracking.trackAutoDeliverySkipDeliveryNotAvailable(orderSummaryTrackingValue);
        }
    }, [isSkipDeliveryAvailable]);

    return isSkipDeliveryAvailable ? (
        <EditDeliveryPopupTopSection
            header={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryConfirmHeaderTitle' />}
            title={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryConfirmTitle' />}
            subtitle={
                <AemEditableText
                    pageName='auto-delivery'
                    itemName='skipDeliveryConfirmSubTitle'
                    contentList={[skipDateShort]}
                />
            }
            infoText={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryConfirmDescription' />}
            icon={<ClockIcon />}
        />
    ) : (
        <div className='pb-8'>
            <AutoDeliveryStatusTitle
                title={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryConfirmHeaderTitle' />}
                className='text-28'
            />
            <InfoBox className='mt-8 tracking-02'>
                <b>
                    <AemEditableText pageName='auto-delivery' itemName='skipDeliveryCantBeSkippedTitle' />
                </b>
                <div className='mt-2'>
                    <AemEditableText pageName='auto-delivery' itemName='skipDeliveryCantBeSkippedDescription' />
                </div>
                <AutoDeliveryButton
                    sandy
                    className='mt-4'
                    text={<AemEditableText pageName='auto-delivery' itemName='skipDeliveryCantBeSkippedButton' />}
                    externalLinkConfig={externalLinkConfig}
                />
            </InfoBox>
        </div>
    );
};

export default DeliverySkipSection;
