import AEMHeadless from '@adobe/aem-headless-client-js';

import log from '../logger/log';
import server from '../server/server';

const GRAPHQL_ENDPOINT = 'pwa-app';

let aemClient = null;

const getAemClient = () => {
    if (!aemClient) {
        aemClient = new AEMHeadless({});
    }
    return aemClient;
};

const initMarketConfig = async (marketName) => {
    try {
        let result;

        if (
            process.env.REACT_APP_ENV === 'dev' ||
            process.env.REACT_APP_ENV === 'poc' ||
            process.env.REACT_APP_ENV === 'stg' ||
            process.env.REACT_APP_ENV === 'pp'
        ) {
            const path = `/content/dam/pwa-app/${marketName}/market-config/${process.env.REACT_APP_ENV}/marketConfig`;

            result = await getAemClient().runPersistedQuery(`${GRAPHQL_ENDPOINT}/get-market-config`, {
                path,
            });
        } else {
            const request = await server.get(`/content/${GRAPHQL_ENDPOINT}/${marketName}/config.json`);

            result = request?.data;
        }

        const marketConfig = result?.data?.marketconfigByPath?.item;

        if (!marketConfig) throw new Error('Market config is not initialized');

        return marketConfig;
    } catch (e) {
        log.error(`initMarketConfig error: ${e}`);
        throw e;
    }
};

const initMarketConfigStatic = async (marketName) => {
    try {
        let result;

        if (
            process.env.REACT_APP_ENV === 'dev' ||
            process.env.REACT_APP_ENV === 'poc' ||
            process.env.REACT_APP_ENV === 'stg' ||
            process.env.REACT_APP_ENV === 'pp'
        ) {
            const path = `/content/dam/pwa-app/${marketName}/market-config/${process.env.REACT_APP_ENV}/market-config-static`;

            result = await getAemClient().runPersistedQuery(`${GRAPHQL_ENDPOINT}/get-market-config-static`, {
                path,
            });
        } else {
            const request = await server.get(`/content/${GRAPHQL_ENDPOINT}/${marketName}/staticConfig.json`);

            result = request?.data;
        }

        const marketConfig = result?.data?.marketconfigstaticByPath?.item;

        if (!marketConfig) throw new Error('Market config static is not initialized');

        return marketConfig;
    } catch (e) {
        log.error(`initMarketConfigStatic error: ${e}`);
        throw e;
    }
};

const getLocalization = async (marketName, languageCode) => {
    try {
        let result;

        if (
            process.env.REACT_APP_ENV === 'dev' ||
            process.env.REACT_APP_ENV === 'poc' ||
            process.env.REACT_APP_ENV === 'stg' ||
            process.env.REACT_APP_ENV === 'pp'
        ) {
            result = await getAemClient().runPersistedQuery(`${GRAPHQL_ENDPOINT}/get-localization`, {
                path: `/content/dam/pwa-app/${marketName}/${languageCode}/localization`,
            });
        } else {
            const request = await server.get(
                `/content/${GRAPHQL_ENDPOINT}/${marketName}/${languageCode}/localization.json`
            );

            result = request?.data;
        }

        const localization = result?.data?.localizationByPath?.item;

        if (!localization) throw new Error('Localization is not initialized');

        let data = {};

        Object.keys(localization).forEach(function (key, index) {
            data = {...data, ...localization[key]};
        });

        return data;
    } catch (e) {
        log.error(`getLocalization error: ${e}`);
        throw e;
    }
};

export default {
    getLocalization,
    initMarketConfig,
    initMarketConfigStatic,
};
