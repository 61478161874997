import React from 'react';
import {useSelector} from 'react-redux';

import AppLoader from '../../components/Loader/AppLoader/AppLoader';
import {selectLoaders} from '../../state/selectors/loaders';

const LoaderGlobal = () => {
    const loader = useLoaderData();

    if (!loader) return null;

    const {text, isTransparent} = loader;

    return <AppLoader loaderText={text} isTransparent={isTransparent} />;
};

export default LoaderGlobal;

const useLoaderData = () => {
    const loaders = useSelector(selectLoaders);
    // non transparent loader is more prioritized than transparent
    // so if loadersLength>1, and last loader is transparent,
    // than return last non transparent loader
    const loadersLength = loaders.length;

    if (!loadersLength) return;

    const lastLoader = loaders[loadersLength - 1];

    if (lastLoader.isTransparent && loadersLength > 1) {
        const nonTransparentLoader = [...loaders].reverse().find(({isTransparent}) => !isTransparent);

        return nonTransparentLoader || lastLoader;
    } else {
        return lastLoader;
    }
};
