import React from 'react';

import Icon from '../Icon/Icon';

const ArrowLeftIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <g stroke='currentColor' strokeWidth='1.5' fill='none' fillRule='evenodd'>
                <path strokeLinecap='round' d='M9.23 6.5 4 11.73l5.23 5.232' />
                <path d='M5.325 11.731h15' strokeLinecap='square' />
            </g>
        </Icon>
    );
};

export default ArrowLeftIcon;
