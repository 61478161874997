import React from 'react';

import {AppButton} from '../../components/Button';
import RemoteIcon from '../../components/Icon/RemoteIcon';
import {MaxWidthContainer} from '../../components/Layout';
import PageHeaderMedia from '../../components/PageHeaderMedia/PageHeaderMedia';
import appConfig from '../../config/appConfig';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketConfigService from '../../services/marketConfigService';
import appRouterService from '../../services/route/appRouterService';
import InstallPwaPageContent from './components/InstallPwaPageContent';

const InstallPwaPage = () => {
    const localizedStrings = getLocalizedStrings();
    const currentGetStartedPage = marketConfigService.getGetStartedPage();

    const {page, icons, skipButton} = currentGetStartedPage || {};
    const {titleKey, descriptionKey, imagePath, videoPath, videoThumbnailPath} = page || {};

    const imageSrc = appConfig.getCombinedPathAssetPath(imagePath);

    const onClick = () => {
        appRouterService.forwardToLoginPage();
    };

    return (
        <div className='flex h-full w-full flex-col items-center lg:mx-8 lg:grid lg:max-w-[1024px] lg:auto-rows-[1fr_min-content] lg:grid-cols-[1fr_1fr] lg:gap-x-8 lg:self-center'>
            <div className='min-h-svh w-full'>
                <PageHeaderMedia
                    className='lg:order-1 lg:row-span-2 lg:min-h-[370px]'
                    autoPlay
                    loop
                    playsInline
                    isVideoAvailable={!!videoThumbnailPath}
                    videoClassName='lg:rounded-[24px]'
                    imgClassName='lg:object-none'
                    videoThumbnailSrc={videoThumbnailPath}
                    videoSrc={videoPath}
                    imageSrc={imageSrc}
                    content={[{isControlsAvailable: false}]}
                />
                <div>
                    <MaxWidthContainer className='m-8 flex grow flex-col items-center lg:m-0 lg:mb-8 lg:items-start lg:self-end'>
                        <InstallPwaPageContent titleKey={titleKey} descriptionKey={descriptionKey} icons={icons} />
                    </MaxWidthContainer>
                </div>
            </div>

            <AppButton
                className='order-2 m-0 py-[10px] text-14 underline shadow-none lg:self-start lg:px-8 lg:py-0 lg:text-16 lg:no-underline'
                dark
                onClick={onClick}
            >
                {localizedStrings[skipButton?.titleKey]}
                <div className='ml-3 hidden lg:inline'>
                    <RemoteIcon className='inline fill-primary' imagePath={skipButton?.imagePath} />
                </div>
            </AppButton>
        </div>
    );
};

export default InstallPwaPage;
