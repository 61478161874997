import {AUTO_DELIVERY_DETAILS, AUTO_DELIVERY_DETAILS_EXTENDED} from '../consts/icc/iccMethods';
import {dispatch} from '../state/store';
import objUtils from '../utils/objUtils';
import {getRefetchOnFocus} from './apiUtils';
import {iccApi} from './iccApi';

const consumerApi = iccApi.injectEndpoints({
    endpoints: (builder) => ({
        getAutoDeliveryDetails: builder.query({
            query: () => AUTO_DELIVERY_DETAILS,
            transformResponse: objUtils.objectKeysToLowerCase,
        }),
        getAutoDeliveryDetailsExtended: builder.query({
            query: () => AUTO_DELIVERY_DETAILS_EXTENDED,
            transformResponse: objUtils.objectKeysToLowerCase,
        }),
    }),
});

const POLLING_INTERVAL = 30 * 60 * 1000;

export const useGetAutoDeliveryDetails = () =>
    consumerApi.useGetAutoDeliveryDetailsQuery(null, {
        pollingInterval: POLLING_INTERVAL,
        refetchOnFocus: getRefetchOnFocus(),
    });

export const useGetAutoDeliveryDetailsExtended = () =>
    consumerApi.useGetAutoDeliveryDetailsExtendedQuery(null, {
        pollingInterval: POLLING_INTERVAL,
        refetchOnFocus: getRefetchOnFocus(),
    });

export const updateAutoDeliveryDetails = (responseData) => {
    const assignDetails = (details) => {
        Object.assign(details, {
            autoDelivery: objUtils.objectKeysToLowerCase(responseData.AutoDelivery),
        });
    };

    dispatch(consumerApi.util.updateQueryData('getAutoDeliveryDetails', null, assignDetails));
    dispatch(consumerApi.util.updateQueryData('getAutoDeliveryDetailsExtended', null, assignDetails));
};
