import {createSelector} from 'reselect';

const selectInbox = (state) => state.inbox;

const selectInboxMessages = createSelector(selectInbox, (data) => data.messages);
const selectInboxMessagesNextPageStartKey = createSelector(selectInbox, (data) => data.nextPageStartKey);

const makeSelectInboxMessage = (messageId) =>
    createSelector(selectInboxMessages, (messages) => {
        return messages?.find((message) => message.messageId === messageId);
    });

const makeSelectNextInboxMessage = (messageId) =>
    createSelector(selectInboxMessages, (messages) => {
        const index = messages?.findIndex((message) => message.messageId === messageId);

        if (index !== -1 && index < messages?.length - 1) {
            return messages[index + 1];
        } else {
            return null;
        }
    });

export {makeSelectInboxMessage, makeSelectNextInboxMessage, selectInboxMessages, selectInboxMessagesNextPageStartKey};
