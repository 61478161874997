import {useMemo} from 'react';

import appConfig from '../../../config/appConfig';
import storageService from '../../../services/storage/storageService';

const useIsWelcomeMode = () => {
    const result = useMemo(() => {
        const isWelcomeScreenEnabled = appConfig.getIsWelcomeScreenEnabled();
        const hasSeenWelcomeScreen = storageService.getHasSeenWelcomeScreen();

        return isWelcomeScreenEnabled && !hasSeenWelcomeScreen;
    }, []);

    return result;
};

export default useIsWelcomeMode;
