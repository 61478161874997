import React from 'react';

import Icon from '../Icon/Icon';

const DevicesIcon = (props) => {
    return (
        <Icon width='24' height='24' {...props}>
            <g>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.73674 22C7.44295 22.0004 7.15198 21.9437 6.88055 21.8334C6.60913 21.7231 6.36259 21.5612 6.15512 21.357C5.94711 21.1534 5.78215 20.9115 5.66973 20.6451C5.5573 20.3787 5.49962 20.0932 5.5 19.8049V6.14632C5.49974 6.05016 5.51885 5.9549 5.55622 5.86601C5.5936 5.77711 5.64851 5.69635 5.71779 5.62835C5.78708 5.56036 5.86937 5.50647 5.95995 5.46979C6.05053 5.43311 6.1476 5.41436 6.24558 5.41461H9.2279C10.2578 5.41461 11.1893 5.82437 11.8643 6.48583C12.2109 6.82524 12.4858 7.22855 12.6732 7.67257C12.8605 8.1166 12.9565 8.59258 12.9558 9.07315V22H7.73674Z'
                    stroke='#272A33'
                    strokeWidth='1.5'
                    fill='currentColor'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.2868 22C12.9932 22.0002 12.7024 21.9436 12.4312 21.8332C12.1599 21.7229 11.9135 21.5611 11.7062 21.3571C11.4978 21.1536 11.3325 20.9117 11.2197 20.6454C11.1069 20.379 11.049 20.0933 11.0491 19.8049V2.34146L15.274 2C16.4411 2 17.4968 2.46439 18.2623 3.21463C18.6552 3.5992 18.9668 4.05617 19.1792 4.55929C19.3916 5.06241 19.5006 5.60175 19.5 6.14634V21.7561L13.2868 22Z'
                    stroke='#272A33'
                    strokeWidth='1.5'
                    fill='currentColor'
                />
            </g>
        </Icon>
    );
};

export default DevicesIcon;
