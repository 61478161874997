import {useGetAutoDeliveryDetailsExtended} from '../../../../api/consumerApi';
import AMPLITUDE_EVENT_VALUES from '../../../../consts/amplitude/amplitudeEventValues';

const useOrderSummaryTracking = () => {
    const {
        data: {
            order: {price},
        },
    } = useGetAutoDeliveryDetailsExtended();

    if (!price) {
        return null;
    }

    const {delivery, total, subTotal} = price || {};
    const orderSummary =
        delivery !== null && total !== null && subTotal !== null
            ? AMPLITUDE_EVENT_VALUES.PRICES_INFO
            : AMPLITUDE_EVENT_VALUES.LINK;

    return orderSummary;
};

export default useOrderSummaryTracking;
