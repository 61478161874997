import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import {AppButton} from '../../../../components/Button';
import appConfig from '../../../../config/appConfig';
import useConfigExternalLink from '../../../../hooks/useConfigExternalLink';
import amplitudeDevicesPageTracking from '../../../../services/amplitude/amplitudeDevicesPageTracking';
import appRouterService from '../../../../services/route/appRouterService';

const DevicePageButtons = () => {
    const marketWebSiteExternalLink = appConfig.getMarketWebSiteExternalLink();

    const onAddDeviceClick = () => {
        appRouterService.forwardToSelectYourDevicePage();
    };

    return (
        <div className='flex w-full flex-col md:w-auto'>
            <AppButton primary light className='mx-auto mt-4 md:min-w-[312px]' onClick={onAddDeviceClick}>
                <AemEditableText pageName='devices' itemName='addyouriqos' />
            </AppButton>
            {marketWebSiteExternalLink && <TryYourFirstIqosButton externalLink={marketWebSiteExternalLink} />}
        </div>
    );
};

const TryYourFirstIqosButton = ({externalLink}) => {
    const redirectToExternalLink = useConfigExternalLink(externalLink);

    const onTryYourFirstIqosClick = () => {
        amplitudeDevicesPageTracking.trackTryYourFirstIqosButtonClick();
        redirectToExternalLink();
    };

    return (
        <AppButton
            secondary
            light
            className='mx-auto mt-4 md:min-w-[312px]'
            onClick={onTryYourFirstIqosClick}
            isExternalLink
        >
            <AemEditableText pageName='devices' itemName='tryyourfirstiqos' />
        </AppButton>
    );
};

export default DevicePageButtons;
