import {useLocation} from 'react-router-dom';

import appConfig from '../../../config/appConfig';

const useGetPageWarningBanners = () => {
    const location = useLocation();
    const warningBanners = appConfig.getWarningBanners();

    const result = warningBanners?.filter((b) => b.pages?.some((page) => location.pathname.startsWith(page)));

    return result;
};

export default useGetPageWarningBanners;
