import cn from 'classnames';
import React from 'react';

import ContentList from '../../../../../../components/ContentList/ContentList';
import appConfig from '../../../../../../config/appConfig';
import {AUTO_DELIVERY_INACTIVE_BENEFITS_LIST_TITLE} from '../../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';

const AutoDeliveryStatusInactiveBenefits = ({className}) => {
    const localizedStrings = getLocalizedStrings();
    const list = appConfig.getAutoDeliveryDashboardInactiveBenefitsIcons();
    const title = localizedStrings[AUTO_DELIVERY_INACTIVE_BENEFITS_LIST_TITLE];

    return (
        <article className={cn('empty:hidden', className)}>
            {title && <h2 className='mb-0 text-24'>{title}</h2>}
            <ContentList list={list} className='mt-4' />
        </article>
    );
};

export default AutoDeliveryStatusInactiveBenefits;
