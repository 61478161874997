import cn from 'classnames';
import React, {useEffect} from 'react';

import appConfig from '../../config/appConfig';
import {SHOP_PAGE_HEADER_TITLE} from '../../consts/localization/localizationKeys';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import amplitudeShopPageTracking from '../../services/amplitude/amplitudeShopPageTracking';
import {HOME_PAGE_NAVIGATION_SOURCE_TYPES} from '../../services/homePageNavigationService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import HomePageNavigationBar from '../HomePageNavigationBar/HomePageNavigationBar';
import HomePageTopNavigation from '../HomePageTopNavigation/HomePageTopNavigation';
import PageWarningBannersContainer from '../WarningBanners/PageWarningBannersContainer';
import DevicesSection from './components/DeviceSection/DevicesSection';
import ShopByProductSection from './components/ShopByProductSection';
import ShopPageHeader from './components/ShopPageHeader';
import YourUsualSection from './components/YourUsualSection';
import styles from './ShopPage.module.scss';

const ShopPage = () => {
    const localizedStrings = getLocalizedStrings();
    const isYourDevicesSectionEnabled = appConfig.isYourDevicesSectionEnabled();

    // TODO: add localization
    useDocumentTitle('Iqos Shop page');

    useEffect(() => {
        amplitudeShopPageTracking.trackShopPageScreen();
    }, []);

    return (
        <div className={cn(styles.ShopPage, 'ica-page w-full overflow-hidden text-start')}>
            <HomePageTopNavigation
                title={localizedStrings[SHOP_PAGE_HEADER_TITLE]}
                source={HOME_PAGE_NAVIGATION_SOURCE_TYPES.SHOP}
            />
            <ShopPageHeader />
            <YourUsualSection className='border-t-8 border-secondary' />
            {isYourDevicesSectionEnabled && <DevicesSection className='border-t-8 border-secondary' />}
            <ShopByProductSection className='border-t-8 border-secondary' />
            <PageWarningBannersContainer />
            <HomePageNavigationBar />
        </div>
    );
};

export default ShopPage;
