import React from 'react';

import {getLocalizedStrings} from '../../../services/localization/localizationService';
import HwContainer from './HwContainer';

const HealthWarningExtended = ({titleKey, descriptionKey, className, isSeparatorVisible}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <HwContainer className={className} isSeparatorVisible={isSeparatorVisible}>
            <div className='flex flex-col gap-4 p-4 pb-8 text-16'>
                <p className='leading-[24px]'>{localizedStrings[titleKey]}</p>
                <div className='whitespace-pre-line border-2 border-black bg-romance px-4 py-6 font-bold text-primary'>
                    {localizedStrings[descriptionKey]}
                </div>
            </div>
        </HwContainer>
    );
};

export default HealthWarningExtended;
