import {APP_SECTION_TYPES, EXTERNAL_LINK_TYPES} from '../consts/externalLinkTypes';
import log from '../services/logger/log';
import {showFirmwareAvailableModalAction, showLeviaLearnMoreModalAction} from '../state/slices/modalSlice';
import {dispatch} from '../state/store';
import useExternalLink from './useExternalLink';

const useConfigExternalLink = (props) => {
    const {url, externalLinkCode, ssoTemplate, linkType, source} = props || {};
    const isSso = linkType === EXTERNAL_LINK_TYPES.SSO_LINK || linkType === EXTERNAL_LINK_TYPES.SSO_LINK_IN_APP;

    const {redirect: redirectToExternalLink} = useExternalLink({
        url: isSso ? undefined : url,
        externalLinkCode: isSso ? undefined : externalLinkCode,
        ssoTemplate: isSso ? ssoTemplate : undefined,
        openInBluefy: linkType === EXTERNAL_LINK_TYPES.URL_IN_APP || linkType === EXTERNAL_LINK_TYPES.SSO_LINK_IN_APP,
    });

    switch (linkType) {
        case EXTERNAL_LINK_TYPES.SECTION_LINK:
            return handleSectionLink({url, source});
        case EXTERNAL_LINK_TYPES.URL:
        case EXTERNAL_LINK_TYPES.URL_IN_APP:
        case EXTERNAL_LINK_TYPES.SSO_LINK:
        case EXTERNAL_LINK_TYPES.SSO_LINK_IN_APP:
            return redirectToExternalLink;
        default:
            break;
    }
};

const handleSectionLink = ({url, source}) => {
    switch (url) {
        case APP_SECTION_TYPES.FIRMWARE_AVAILABLE:
            return () => dispatch(showFirmwareAvailableModalAction({source}));
        case APP_SECTION_TYPES.LEVIA:
            return () => dispatch(showLeviaLearnMoreModalAction({source}));
        default:
            return () => {
                log.info(`useConfigExternalLink, sectionLink is empty or not supported, actionLink: ${url}`);
            };
    }
};

export default useConfigExternalLink;
