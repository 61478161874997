import cn from 'classnames';
import React, {useRef, useState} from 'react';
import {RemoveScroll} from 'react-remove-scroll';

import {twx} from '../../utils/tailwindUtils';
import AppPopup from './AppPopup';
import styles from './Popup.module.scss';
import useOneModalOnPageAtATime from './useOneModalOnPageAtATime';

const PopupStickedWithSwipeClose = ({
    className,
    swipeAreaClassName,
    children,
    onClose,
    isSwipeDashVisible = true,
    ...rest
}) => {
    const isVisible = useOneModalOnPageAtATime();
    const [isPopupClosing, setIsPopupClosing] = useState(false);
    const timeoutRef = useRef();

    if (!isVisible) return null;

    let touchYStart = 0;
    let touchYEnd = 0;

    const onTouchStart = (e) => {
        touchYStart = e.changedTouches[0].pageY;
    };

    const onTouchMove = (e) => {
        touchYEnd = e.changedTouches[0].pageY;

        const delta = touchYEnd - touchYStart;
        const SWIPE_DELTA_TO_START_CLOSING = 30;

        if (delta >= SWIPE_DELTA_TO_START_CLOSING && onClose) {
            onPopupClose();
        }
    };

    const onPopupClose = () => {
        if (timeoutRef.current) return;

        setIsPopupClosing(true);
        timeoutRef.current = setTimeout(() => {
            onClose();
        }, 700);
    };

    return (
        <RemoveScroll>
            <AppPopup
                isOpen
                className={twx(
                    styles.PopupContainer,
                    'rounded-t-3xl px-4 pt-0 pb-safe-offset-4',
                    {[styles.PopupOnClose]: isPopupClosing},
                    className
                )}
                overlayClassName={cn(styles.Overlay, {[styles.OverlayOnPopupClose]: isPopupClosing})}
                onClose={onClose}
                {...rest}
                isCloseVisible={false}
            >
                <div className={twx('py-6', swipeAreaClassName)} onTouchMove={onTouchMove} onTouchStart={onTouchStart}>
                    {isSwipeDashVisible && <div className='mx-auto h-1 w-8 rounded-2xl bg-primary-tins-slate' />}
                </div>
                {children}
            </AppPopup>
        </RemoveScroll>
    );
};

export default PopupStickedWithSwipeClose;
