import {EditableComponent} from '@adobe/aem-react-editable-components';
import React from 'react';

import {NotificationItem} from './NotificationItem';

const EditableNotificationItem = ({pagePath, itemPath, ...rest}) => (
    <EditableComponent pagePath={pagePath} itemPath={itemPath}>
        <NotificationItem {...rest} />
    </EditableComponent>
);

export default EditableNotificationItem;
