import ButtonRaw from '../../../../../../components/Button/ButtonRaw';
import {ArrowIcon} from '../../../../../../components/Icons';
import {AUTO_DELIVERY_TITLE} from '../../../../../../consts/localization/localizationKeys';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import appRouterService from '../../../../../../services/route/appRouterService';

const AutoDeliveryWidgetHeader = ({btnLocalizationKey}) => {
    const localizedStrings = getLocalizedStrings();
    const onClick = () => {
        appRouterService.forwardToAutoDeliveryPage();
    };

    return (
        <div className='flex items-center justify-between gap-2'>
            <h2 className='mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-24'>
                {localizedStrings[AUTO_DELIVERY_TITLE]}
            </h2>
            {btnLocalizationKey && (
                <ButtonRaw className='flex shrink-0 gap-2 tracking-05 text-scarpa-flow' onClick={onClick}>
                    <span className='flex-1'>{localizedStrings[btnLocalizationKey]}</span>
                    <ArrowIcon />
                </ButtonRaw>
            )}
        </div>
    );
};

export default AutoDeliveryWidgetHeader;
