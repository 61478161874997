import React, {useEffect} from 'react';

import CloseButton from '../../../components/CloseButton/CloseButton';
import {StickyPageLayout} from '../../../components/Layout';
import {PopupFullPage} from '../../../components/Popup';
import {LEVIA_POPUP_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import amplitudeInboxTracking from '../../../services/amplitude/amplitudeInboxTracking';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import modalService from '../../../services/modalService';
import ModalWarningBanners from '../../WarningBanners/ModalWarningBanners';
import LeviaLearnMoreArticle from './LeviaLearnMoreArticle/LeviaLearnMoreArticle';
import LeviaLearnMoreButtons from './LeviaLearnMoreArticle/LeviaLearnMoreButtons';

const LeviaLearnMorePopup = ({modalType, source}) => {
    const onClose = () => modalService.hideModal(modalType);
    const localizedStrings = getLocalizedStrings();

    useEffect(() => {
        amplitudeInboxTracking.trackActionScreen(source);
    }, []);

    return (
        <PopupFullPage className='fixed left-0 right-0 z-20 flex h-screen flex-col bg-romance'>
            <StickyPageLayout
                className='items-center'
                headerOptions={{
                    endIcon: <CloseButton onClick={onClose} />,
                    title: localizedStrings[LEVIA_POPUP_HEADER_TITLE],
                }}
                contentClassName='flex flex-1 flex-col items-center'
                footer={<LeviaLearnMoreButtons onCancel={onClose} />}
            >
                <LeviaLearnMoreArticle />
                <ModalWarningBanners modalType={modalType} />
            </StickyPageLayout>
        </PopupFullPage>
    );
};

export default LeviaLearnMorePopup;
