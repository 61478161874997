import cn from 'classnames';
import React from 'react';

import LoaderCentered from './LoaderCentered';

const SectionLoader = ({className}) => (
    <div className={cn('absolute left-0 top-0 z-[2] h-full w-full bg-white bg-opacity-75', className)}>
        <LoaderCentered />
    </div>
);

export default SectionLoader;
