import {useState} from 'react';

const useHighlightsAutoplay = (sliderRef, highlights, autoplay, currentIndex) => {
    const [slidesDuration, setSlidesDuration] = useState({});

    const moveToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    const onLoadedMetadata = (metadata, slideIndex) => {
        if (!metadata) return null;

        setSlidesDuration({[slideIndex]: metadata.duration, ...slidesDuration});
    };

    const onVideoError = (slideIndex) => {
        setSlidesDuration({[slideIndex]: highlights[currentIndex]?.duration, ...slidesDuration});
    };

    const animationDuration = () => {
        if (autoplay) {
            if (highlights[currentIndex].banner.videoPath) {
                if (slidesDuration[currentIndex]) {
                    return slidesDuration[currentIndex];
                } else {
                    return 0;
                }
            } else {
                return highlights[currentIndex]?.duration;
            }
        }
    };

    return {animationDuration: animationDuration(), moveToNextSlide, onLoadedMetadata, onVideoError};
};

export default useHighlightsAutoplay;
