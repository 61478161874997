import {useSelector} from 'react-redux';

import appErrorService from '../services/app/appErrorService';
import iccMarketService from '../services/icc/iccMarketService';
import {initLocalizations} from '../services/localization/localizationService';
import marketService from '../services/marketService';
import appRouterService from '../services/route/appRouterService';
import {selectIsLoggedIn} from '../state/selectors/auth';
import {selectIsAem, selectLanguageCode} from '../state/selectors/global';

const useLanguageChanger = () => {
    const currentLanguageCode = useSelector(selectLanguageCode);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isAem = useSelector(selectIsAem);

    const changeLanguage = async (languageCode) => {
        try {
            if (isLoggedIn) {
                await iccMarketService.setConsumerLanguageCode({languageCode, throwException: true});
            }

            await initLocalizations(marketService.getMarketCode(), languageCode);

            if (isAem) {
                appRouterService.forwardToRootPage();
            }
        } catch (e) {
            appErrorService.showGlobalError();
        }
    };

    return {currentLanguageCode, changeLanguage};
};

export default useLanguageChanger;
