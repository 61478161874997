import React from 'react';

import Icon from '../Icon/Icon';

const LeviaIcon = (props) => {
    return (
        <Icon width='48' height='48' fill='none' {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M25.915 10.194v6.413h1.408s.687 6.052.687 8.014v13.07c5.813-1.75 10.048-7.139 10.048-13.524 0-7.126-5.283-13.006-12.143-13.973zM9.8 24.167c0 6.385 4.236 11.775 10.048 13.524v-13.07c0-1.962.687-8.014.687-8.014h1.409v-6.413C15.084 11.161 9.8 17.041 9.8 24.167z'
                fill='#00D1D2'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M24 42.127c-9.925 0-18-8.076-18-18C6 16.573 10.768 9.77 17.863 7.197a.97.97 0 0 1 .66 1.821C12.19 11.315 7.935 17.387 7.935 24.126c0 8.858 7.207 16.064 16.065 16.064s16.065-7.206 16.065-16.064c0-6.739-4.255-12.81-10.588-15.107a.97.97 0 0 1 .66-1.821C37.233 9.771 42 16.574 42 24.126c0 9.925-8.075 18-18 18z'
                fill='#232029'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M28.01 41.442a.774.774 0 0 1-.774-.775V24.62c0-1.336-.334-4.681-.533-6.554a.765.765 0 0 0-.761-.688h-4.025a.765.765 0 0 0-.762.688c-.199 1.873-.532 5.218-.532 6.554v16.046a.774.774 0 1 1-1.549 0V24.62c0-1.408.34-4.812.541-6.718a2.31 2.31 0 0 1 2.302-2.07h4.025a2.31 2.31 0 0 1 2.301 2.07c.202 1.906.541 5.31.541 6.718v16.046a.774.774 0 0 1-.774.775z'
                fill='#232029'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.718 15.833h2.423V7.547h-2.423v8.286zm3.197 1.546h-3.97a.774.774 0 0 1-.775-.772V6.772c0-.426.346-.772.774-.772h3.97c.428 0 .775.346.775.772v9.835a.774.774 0 0 1-.774.773z'
                fill='#232029'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='m26.538 35.557-4.278 1.75a.35.35 0 0 1-.456-.193l-.533-1.306a.348.348 0 0 1 .19-.454l4.28-1.75a.35.35 0 0 1 .455.193l.533 1.306a.347.347 0 0 1-.191.454z'
                fill='#00D1D2'
            />
        </Icon>
    );
};

export default LeviaIcon;
