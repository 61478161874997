import {createSlice} from '@reduxjs/toolkit';

import storageService from '../../services/storage/storageService';

const getInitialState = () => {
    const {accessToken, expirationDate, refreshToken, isAnonymous} = storageService.getAuthDataFromStorage() || {};

    return {
        accessToken,
        expirationDate,
        refreshToken,
        isAnonymous,
    };
};

export const slice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        setAuthData: (state, action) => {
            const {accessToken, refreshToken, expirationDate, isAnonymous} = action.payload;

            return {
                ...state,
                accessToken,
                refreshToken,
                expirationDate,
                isAnonymous,
            };
        },
    },
});

export const {setAuthData} = slice.actions;

export default slice.reducer;
