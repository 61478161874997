import {ICC_PRODUCT_STATUS_TYPES} from '../../consts/icc/iccProductStatusTypes';
import {DEVICE_UNREGISTERED_NOTIFICATION_TEXT} from '../../consts/localization/localizationKeys';
import {makeSelectConsumerProductToRegUnreg} from '../../state/selectors/consumer';
import {
    addConsumerProductToRegUnreg,
    removeConsumerProduct,
    removeConsumerProductFromRegUnreg,
} from '../../state/slices/consumerSlice';
import {setNotificationInfoBottom} from '../../state/slices/notificationsSlice';
import {dispatch, getState} from '../../state/store';
import iccMarketService from '../icc/iccMarketService';
import {getLocalizedStrings} from '../localization/localizationService';
import log from '../logger/log';

const iccUnregistration = async ({productId, codentify}) => {
    log.info(`Start unregistration process for codentify: ${codentify}.`);

    const productIdentify = productId ? {productId} : {codentifyId: codentify};
    const response = await iccMarketService.removeConsumerProduct({data: productIdentify});

    if (response) {
        dispatch(removeConsumerProduct(codentify));
        dispatch(addConsumerProductToRegUnreg({codentify}));
        return true;
    }

    return false;
};

const unregisterConsumerProduct = async (device) => {
    try {
        const state = getState();
        const {productId, codentify, holder} = device || {};
        const {productId: holderProductId, codentify: holderCodentify} = holder || {};
        const productStatus = makeSelectConsumerProductToRegUnreg(codentify)(state);
        const holderProductStatus = makeSelectConsumerProductToRegUnreg(codentify)(state);
        const isNeedChargerUnreg = codentify && !productStatus;
        const isNeedHolderUnreg = holderCodentify && !holderProductStatus;

        const unregHolder = async () => {
            if (!isNeedHolderUnreg) return;
            const result = await iccUnregistration({productId: holderProductId, codentify: holderCodentify});

            return result;
        };

        if (isNeedChargerUnreg) {
            //first unreg charger then start holder unreg
            const isChargerRequestSent = await iccUnregistration({productId, codentify});
            const unregResult = {
                chargerResult: isChargerRequestSent,
            };

            if (isChargerRequestSent) {
                unregResult.holderResult = await unregHolder();
            }
            return unregResult;
        } else {
            const result = await unregHolder();

            return {holderResult: result};
        }
    } catch (error) {
        log.info(`End unregistration process. Error: ${error}.`);
        return false;
    }
};

const fetchProductsStatusAndCheckThem = async (products) => {
    if (!products?.length) return;

    const response = await iccMarketService.getConsumerProductsStatus();

    if (!response?.length) return;

    let isNeedCallAsync = false;
    let isNeedShowMessage = false;

    products.forEach((product) => {
        const {isReg, codentify} = product;
        const productResponse = response.find((x) => x.codentify === codentify);
        const {status} = productResponse || {};
        const isRegSuccess = isReg && isStatusRegistered(status);
        const isUnregSuccess = !isReg && isStatusUnregistered(status);
        const isFailed = isStatusRegisterFailed(status);

        if (isRegSuccess || isUnregSuccess || isFailed) {
            isNeedShowMessage = isUnregSuccess;
            isNeedCallAsync = true;
            dispatch(removeConsumerProductFromRegUnreg(codentify));
        }
    });

    const showUnregMessage = () => {
        if (isNeedShowMessage) {
            const localizedStrings = getLocalizedStrings();

            dispatch(setNotificationInfoBottom(localizedStrings[DEVICE_UNREGISTERED_NOTIFICATION_TEXT]));
        }
    };

    if (isNeedCallAsync) {
        await iccMarketService.getConsumerProducts();

        showUnregMessage();
    }
};

const isStatusRegistered = (status) => status === ICC_PRODUCT_STATUS_TYPES.REGISTERED;

const isStatusRegistering = (status) => status === ICC_PRODUCT_STATUS_TYPES.REGISTERING;

const isStatusUnregistered = (status) => status === undefined || status === ICC_PRODUCT_STATUS_TYPES.UNREGISTERED;

const isStatusUnregistering = (status) => status === ICC_PRODUCT_STATUS_TYPES.UNREGISTERING;

const isStatusRegisterFailed = (status) => status === ICC_PRODUCT_STATUS_TYPES.FAILED;

const proceedDeviceStatus = (statusFunction, status) => statusFunction(status);

const getIsRegistered = (status) => proceedDeviceStatus(isStatusRegistered, status);

const getIsRegistering = (status) => proceedDeviceStatus(isStatusRegistering, status);

const getIsUnregistered = (status) => proceedDeviceStatus(isStatusUnregistered, status);

const getIsRegisterFailed = (status) => proceedDeviceStatus(isStatusRegisterFailed, status);

const getIsUnregistering = (status) => proceedDeviceStatus(isStatusUnregistering, status);

export default {
    unregisterConsumerProduct,
    fetchProductsStatusAndCheckThem,
    getIsRegistered,
    getIsRegistering,
    getIsUnregistered,
    getIsRegisterFailed,
    getIsUnregistering,
};
