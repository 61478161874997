import React from 'react';

import Icon from '../Icon/Icon';

const CheckmarkIcon = (props) => {
    return (
        <Icon width='14' height='11' fill='none' {...props}>
            <path
                d='M0.912 6.133L4.162 9.535L12.405 1.065'
                stroke='#34303D'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default CheckmarkIcon;
