import React from 'react';

import Icon from '../Icon/Icon';

const RegularCleaningIcon = (props) => {
    return (
        <Icon width='64' height='64' {...props}>
            <g fill='none' fillRule='evenodd'>
                <path
                    d='M49.613 42.553a1.295 1.295 0 0 1-1.205-1.779l3.981-9.95a3.655 3.655 0 0 0-2.033-4.744l-15-6.003a3.656 3.656 0 0 0-4.746 2.032L23.94 38.782a2.373 2.373 0 0 1-2.212 1.499h-6.41a1.299 1.299 0 0 1 0-2.598h6.265L28.2 21.146a6.254 6.254 0 0 1 8.12-3.48l15 6.003c3.198 1.281 4.759 4.924 3.48 8.12l-3.982 9.95c-.202.507-.69.814-1.205.814'
                    fill='#272830'
                />
                <path
                    d='M23.86 34.65H12.65a1.296 1.296 0 1 1 0-2.594h11.21a1.296 1.296 0 1 1 0 2.594M26.988 28.968H17.61a1.299 1.299 0 0 1 0-2.597h9.377a1.298 1.298 0 0 1 0 2.597M24.187 10.594a2.797 2.797 0 0 0-2.792 2.793 2.797 2.797 0 0 0 2.792 2.794 2.797 2.797 0 0 0 2.793-2.794 2.797 2.797 0 0 0-2.793-2.793zm0 8.18a5.393 5.393 0 0 1-5.388-5.387A5.393 5.393 0 0 1 24.187 8a5.393 5.393 0 0 1 5.39 5.387 5.393 5.393 0 0 1-5.39 5.388z'
                    fill='#272830'
                />
                <path d='M15.92 19.614a2.813 2.813 0 0 1-2.811 2.813 2.81 2.81 0 1 1 2.81-2.814' fill='#00D1D2' />
                <path
                    d='M13.109 18.102c-.834 0-1.513.68-1.513 1.512 0 .835.679 1.515 1.513 1.515.834 0 1.512-.68 1.512-1.515 0-.832-.678-1.512-1.512-1.512zm0 5.62A4.113 4.113 0 0 1 9 19.615a4.113 4.113 0 0 1 4.109-4.106 4.112 4.112 0 0 1 4.108 4.106 4.113 4.113 0 0 1-4.108 4.109z'
                    fill='#272830'
                />
                <path
                    d='m22.643 50.782 8.815-20.914c.36-.805 1.551-1.688 2.346-1.306.766.369 1.306 1.367 1.022 2.202l1.018-2.997a1.771 1.771 0 0 1 2.34-.879 1.938 1.938 0 0 1 1.026 2.388l.502-1.492a2.149 2.149 0 0 1 3.93 1.742l-.756 1.711-.586 1.543c.377-.991 1.268-1.245 2.127-.866a1.737 1.737 0 0 1 .936 2.174l-3.17 8.844a.733.733 0 0 0 .932.94l2.124-.734a2.207 2.207 0 0 1 2.019 3.873l-6.88 4.988c-1.099.798-2.458 2.702-2.853 4.001'
                    fill='#00D1D2'
                />
            </g>
        </Icon>
    );
};

export default RegularCleaningIcon;
