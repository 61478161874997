const getCountryNameByCountryCode = (countryCode, languageCode) => {
    if (!countryCode) return;

    try {
        const regionNames = new Intl.DisplayNames([languageCode], {type: 'region'});

        return regionNames.of(countryCode);
    } catch (error) {
        return;
    }
};

export default {getCountryNameByCountryCode};
