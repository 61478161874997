import cn from 'classnames';
import React from 'react';

import Icon from '../Icon/Icon';

const CalendarIcon = ({date, className, ...rest}) => {
    return (
        <Icon width='64' height='64' fill='none' className={cn('font-bold', className)} {...rest}>
            <path
                d='M9.25 23.25H54.75V52.75H15C11.8244 52.75 9.25 50.1756 9.25 47V23.25Z'
                stroke='#34303D'
                strokeWidth='2.5'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.03 27.2002H51.03V49.1002H17.03C14.8209 49.1002 13.03 47.3093 13.03 45.1002V27.2002Z'
                fill='currentColor'
            />
            <rect x='9.25' y='14.0117' width='45.5' height='9.5' stroke='#34303D' strokeWidth='2.5' />
            <rect x='18.5' y='9' width='1' height='10' rx='0.5' fill='#34303D' stroke='#34303D' />
            <rect x='31.5' y='9' width='1' height='10' rx='0.5' fill='#34303D' stroke='#34303D' />
            <rect x='44.5' y='9' width='1' height='10' rx='0.5' fill='#34303D' stroke='#34303D' />
            {date && (
                <text x='31' y='39' textAnchor='middle' alignmentBaseline='middle' fill='black'>
                    {date}
                </text>
            )}
        </Icon>
    );
};

export default CalendarIcon;
