import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as tncTypes from '../../../consts/tnc/tncTypes';
import appRouterService from '../../../services/route/appRouterService';
import {makeSelectAcceptedTncByType} from '../../../state/selectors/consumer';
import PreferenceCenterControl from './PreferenceCenterControl';

const mapStateToProps = createStructuredSelector({
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.WEB_PREFERENCE_CENTER),
});

const PreferenceCenterPage = (props) => {
    const {acceptedTermsOfUse} = props;
    const isUpdatedTnc = acceptedTermsOfUse != null;

    return (
        <PreferenceCenterControl
            onApproved={appRouterService.forwardToEnableNotificationPageIfNeeded}
            backButtonClick={isUpdatedTnc ? appRouterService.forwardToTNCDataCollectionPage : undefined}
            isEnableAllAvailable
        />
    );
};

export default connect(mapStateToProps)(PreferenceCenterPage);
