import AMPLITUDE_EVENT_PROPERTIES from '../../consts/amplitude/amplitudeEventProperties';
import AMPLITUDE_EVENT_TYPES from '../../consts/amplitude/amplitudeEventTypes';
import amplitude from './amplitude';

const trackAutoDeliveryBannerClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.AUTODELIVERY_BANNER_CLICKED);
};

const trackAutoDeliveryDashboardButtonClick = (trackingValue) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.AUTODELIVERY_DASHBOARD_BUTTON_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.TRACKING_VALUE]: trackingValue,
    });
};

const trackAutoDeliveryGetStartedScreen = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.AUTODELIVERY_GET_STARTED_SCREEN_VIEWED);
};

const trackAutoDeliveryGetStartedButtonClick = () => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.AUTODELIVERY_GET_STARTED_BUTTON_CLICKED);
};

const trackAutoDeliveryOrderScreen = (autoDeliveryStatus, orderSummary = null) => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.ORDER_SCREEN_VIEWED, {
        [AMPLITUDE_EVENT_PROPERTIES.AUTODELIVERY_STATUS]: autoDeliveryStatus,
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliveryOrderSummaryLinkClick = (autoDeliveryStatus) => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.ORDER_SUMMARY_LINK_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.AUTODELIVERY_STATUS]: autoDeliveryStatus,
    });
};

const trackAutoDeliveryDeliverAsapStarted = (orderSummary) => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.DELIVER_ASAP_STARTED, {
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliveryDeliverAsapCompleted = ({success, message, orderSummary}) => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.DELIVER_ASAP_COMPLETED, {
        [AMPLITUDE_EVENT_PROPERTIES.SUCCESS]: success,
        [AMPLITUDE_EVENT_PROPERTIES.MESSAGE]: message,
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliveryOrderButtonClick = (trackingValue, autoDeliveryStatus) => {
    if (!trackingValue) return;

    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.ORDER_BUTTON_CLICKED, {
        [AMPLITUDE_EVENT_PROPERTIES.TRACKING_VALUE]: trackingValue,
        [AMPLITUDE_EVENT_PROPERTIES.AUTODELIVERY_STATUS]: autoDeliveryStatus,
    });
};

const trackAutoDeliverySkipDeliveryNotAvailable = (orderSummary) => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.SKIP_DELIVER_NOT_AVAILABLE, {
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliverySkipDeliveryStarted = (orderSummary) => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.SKIP_DELIVERY_STARTED, {
        ...getOrderSummaryProperties(orderSummary),
    });
};

const trackAutoDeliverySkipDeliveryCompleted = ({success, message, orderSummary}) => {
    amplitude.trackWithSource(AMPLITUDE_EVENT_TYPES.SKIP_DELIVERY_COMPLETED, {
        [AMPLITUDE_EVENT_PROPERTIES.SUCCESS]: success,
        [AMPLITUDE_EVENT_PROPERTIES.MESSAGE]: message,
        ...getOrderSummaryProperties(orderSummary),
    });
};

const getOrderSummaryProperties = (orderSummary) => {
    return orderSummary ? {[AMPLITUDE_EVENT_PROPERTIES.ORDER_SUMMARY]: orderSummary} : {};
};

export default {
    trackAutoDeliveryBannerClick,
    trackAutoDeliveryDashboardButtonClick,
    trackAutoDeliveryGetStartedScreen,
    trackAutoDeliveryGetStartedButtonClick,
    trackAutoDeliveryOrderScreen,
    trackAutoDeliveryOrderSummaryLinkClick,
    trackAutoDeliveryDeliverAsapStarted,
    trackAutoDeliveryDeliverAsapCompleted,
    trackAutoDeliveryOrderButtonClick,
    trackAutoDeliverySkipDeliveryNotAvailable,
    trackAutoDeliverySkipDeliveryStarted,
    trackAutoDeliverySkipDeliveryCompleted,
};
