import {createSelector} from 'reselect';

const selectMedia = (state) => state.media;

const selectSignedMediaList = createSelector(selectMedia, (state) => state.signedMediaList);
const selectSsoLinks = createSelector(selectMedia, (state) => state.ssoLinks);

const makeSelectSignedMediaListItem = (mediaIdList) =>
    createSelector(selectSignedMediaList, (signedMediaList) => {
        const signedDataSet = signedMediaList.filter((signedItem) =>
            mediaIdList.find((mediaItem) => signedItem.mediaId === mediaItem)
        );

        return signedDataSet.length > 0 ? signedDataSet : undefined;
    });

const makeSelectSsoLink = (ssoTemplate) =>
    createSelector(selectSsoLinks, (ssoLinks) => {
        const ssoLink = ssoLinks[ssoTemplate];

        return ssoLink || {};
    });

export {makeSelectSignedMediaListItem, makeSelectSsoLink, selectSignedMediaList};
