import cn from 'classnames';
import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import Image from '../../../../components/Image/Image';
import {getImageByViewType, IMAGE_VIEW_TYPES} from '../../../../consts/icc/imageViewTypes';
import {twx} from '../../../../utils/tailwindUtils';
import AutoDeliveryEditOrderButton from './AutoDeliveryEditOrderButton';

const AutoDeliveryOrder = ({className, isEditAllowed, products}) => {
    if (!products?.length) return;

    return (
        <div className={twx('px-4 pb-4 pt-10', className)}>
            <div className='flex items-center justify-between gap-2'>
                <h3 className='mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-24 font-bold'>
                    <AemEditableText pageName='auto-delivery' itemName='yourOrderTitle' />
                </h3>
                {isEditAllowed && <AutoDeliveryEditOrderButton />}
            </div>
            <ul>
                {products.map((product, i) => {
                    return <ProductListItem product={product} key={i} />;
                })}
            </ul>
        </div>
    );
};

export default AutoDeliveryOrder;

const ProductListItem = ({product}) => {
    const {name, images, quantity, isOutOfStock} = product;
    const {link} = getImageByViewType(images, IMAGE_VIEW_TYPES.SMALL) || {};

    return (
        <li
            className={cn('flex items-center gap-2 border-b border-mischka py-4 text-black last:border-none', {
                'opacity-40': isOutOfStock,
            })}
        >
            <Image src={link} className='h-16 w-16 rounded-md bg-secondary object-contain' />
            <div className='flex-1'>{name}</div>
            <div className='rounded bg-secondary px-2 py-1 font-bold'>x{quantity}</div>
        </li>
    );
};
