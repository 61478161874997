import {EditableComponent} from '@adobe/aem-react-editable-components';
import React from 'react';

import {DynamicTextarea} from './DynamicTextarea';

const EditableDynamicTextarea = (props) => (
    <EditableComponent {...props}>
        <DynamicTextarea {...props} />
    </EditableComponent>
);

export default EditableDynamicTextarea;
