import React from 'react';
import {Img} from 'react-image';

import arrayUtils from '../../utils/arrayUtils';

const AppImage = ({src, alt = '', defaultImageSrcSet, ...restProps}) => {
    const srcSet = arrayUtils.toArray(src, defaultImageSrcSet);

    return <Img alt={alt} {...restProps} src={srcSet} />;
};

export default AppImage;
