import {useEffect} from 'react';

import ModalTypes from '../consts/app/modalTypes';
import routerService from '../services/route/routerService';
import {hideModalAction, showUWMUnavailabilityModalAction} from '../state/slices/modalSlice';
import {dispatch} from '../state/store';

const UWM_UNAVAILABILITY_TIMEOUT_IN_SEC = 30 * 1000;

let uwmTimer;

export const useUWMUnavailabilityHandler = ({forceRefresh, isModuleDataLoaded, refreshAction, isError}) => {
    const location = routerService.getCurrent();

    const hideModal = (uwmTimer) => {
        if (uwmTimer) {
            clearTimeout(uwmTimer);
        }
        dispatch(hideModalAction(ModalTypes.UWM_UNAVAILABILITY));
    };

    useEffect(() => {
        if (isModuleDataLoaded) {
            hideModal(uwmTimer);
            return;
        }

        clearTimeout(uwmTimer);

        if (isError) {
            dispatch(showUWMUnavailabilityModalAction({refreshAction}));
            return;
        }

        uwmTimer = setTimeout(() => {
            dispatch(showUWMUnavailabilityModalAction({refreshAction}));
        }, UWM_UNAVAILABILITY_TIMEOUT_IN_SEC);

        return () => {
            hideModal(uwmTimer);
        };
    }, [location, isModuleDataLoaded, isError, forceRefresh]);
};
