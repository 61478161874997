import React from 'react';

import Icon from '../Icon/Icon';

const ContactUsIcon = (props) => {
    return (
        <Icon width='32' height='32' fill='none' {...props}>
            <path
                d='M17.9623 24.1755C20.9061 23.2016 23.3761 20.2118 23.3761 17.2387C23.3761 16.7879 23.3761 14.2337 23.3761 13.8781C23.3761 9.81524 20.0707 6.52167 15.9933 6.52167C11.9159 6.52167 8.61047 9.81524 8.61047 13.8781V19.2086M16.2479 25.6467C17.0915 25.6467 17.7753 24.988 17.7753 24.1755C17.7753 23.363 17.0915 22.7044 16.2479 22.7044C15.4043 22.7044 14.7204 23.363 14.7204 24.1755C14.7204 24.988 15.4043 25.6467 16.2479 25.6467Z'
                stroke='#34303D'
                strokeWidth='1.5'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.61047 18.925H7.63032C5.95825 18.925 5.51672 18.0734 5.51672 17.2114V14.0292C5.51672 13.1344 5.92052 12.3156 7.63032 12.3156H8.61047V18.925Z'
                stroke='#34303D'
                strokeWidth='1.5'
                strokeLinecap='round'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M23.3761 18.925H24.3563C26.0283 18.925 26.4698 18.0734 26.4698 17.2114V14.0292C26.4698 13.1344 26.0661 12.3156 24.3563 12.3156H23.3761V18.925Z'
                stroke='#34303D'
                strokeWidth='1.5'
                strokeLinecap='round'
            />
        </Icon>
    );
};

export default ContactUsIcon;
