import {useEffect} from 'react';
import {useSelector} from 'react-redux';

import deviceStateService from '../../../services/device/deviceStateService';
import {selectConsumerProductsMergedInfo} from '../../../state/selectors/consumer';
import {selectCurrentProductInfo} from '../../../state/selectors/device';

const useInitSelectedDeviceCodentify = () => {
    const device = useSelector(selectCurrentProductInfo);
    const devices = useSelector(selectConsumerProductsMergedInfo);

    useEffect(() => {
        if (device) return;
        if (!devices?.length) return;

        // Set first device as selected
        deviceStateService.storeSelectedDeviceCodentify(devices[0].codentify);
    }, [device, devices]);
};

export default useInitSelectedDeviceCodentify;
