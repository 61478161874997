import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import AemEditableText from '../../../components/Aem/AemEditableText/AemEditableText';
import AppButton from '../../../components/Button/AppButton/AppButton';
import {TncDataCollectionIcon} from '../../../components/Icons';
import {StickyPageLayout} from '../../../components/Layout';
import {DATA_COLLECTION_HEADER_TITLE} from '../../../consts/localization/localizationKeys';
import * as tncTypes from '../../../consts/tnc/tncTypes';
import amplitudeProfileTracking from '../../../services/amplitude/amplitudeProfileTracking';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import appRouterService from '../../../services/route/appRouterService';
import tncService from '../../../services/tncService';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../state/selectors/consumer';
import {hideLoader, showLoader} from '../../../state/slices/loadersSlice';
import {dispatch} from '../../../state/store';
import PageWarningBannersContainer from '../../WarningBanners/PageWarningBannersContainer';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';

const mapStateToProps = createStructuredSelector({
    dataCollectionTnc: makeSelectTncByType(tncTypes.WEB_PREFERENCE_CENTER),
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.WEB_PREFERENCE_CENTER),
});

const DataCollectionPage = (props) => {
    const {dataCollectionTnc, acceptedTermsOfUse} = props;
    const isUpdatedTnc = acceptedTermsOfUse != null;
    const {description, legals, updateDescription} = dataCollectionTnc || {};
    const localizedStrings = getLocalizedStrings();

    useEffect(() => {
        amplitudeProfileTracking.trackUpdateYourDataScreen();
    }, []);

    useEffect(() => {
        if (!isUpdatedTnc) {
            appRouterService.forwardToTNCPreferenceCenterPage();
            return;
        }

        if (dataCollectionTnc && legals) return;

        appRouterService.forwardToEnableNotificationPageIfNeeded();
    }, [dataCollectionTnc, legals]);

    const setTnc = async (isAccepted) => {
        dispatch(showLoader());

        await tncService.setDataCollectionTnc(legals, isAccepted);

        dispatch(hideLoader());
    };

    const onAcceptButtonClick = () => setTnc(true);
    const onDeclineButtonClick = () => setTnc(false);

    const descriptionText = isUpdatedTnc ? updateDescription : description;

    return (
        <StickyPageLayout
            className='items-center'
            headerOptions={{
                title: localizedStrings[DATA_COLLECTION_HEADER_TITLE],
            }}
            contentClassName='flex flex-1 flex-col items-center py-4 pt-safe-offset-14'
            footer={
                <div className='flex flex-col gap-4'>
                    <AppButton className='mt-0' primary light onClick={onAcceptButtonClick}>
                        <AemEditableText pageName='tnc' itemName='dataCollectionAgree' />
                    </AppButton>
                    <AppButton className='mt-0' secondary light onClick={onDeclineButtonClick}>
                        <AemEditableText pageName='tnc' itemName='dataCollectionDecline' />
                    </AppButton>
                    <AppButton
                        className='mx-auto mt-0'
                        tertiary
                        onClick={appRouterService.forwardToTNCPreferenceCenterPage}
                    >
                        <AemEditableText pageName='tnc' itemName='dataCollectionPreferenCecenter' />
                    </AppButton>
                </div>
            }
        >
            <div className='mb-4 flex flex-1 flex-col items-center px-4'>
                <TncDataCollectionIcon />
                <h1 className='mt-9 text-28'>
                    {!isUpdatedTnc && <AemEditableText pageName='tnc' itemName='dataCollectionTitle' />}
                    {isUpdatedTnc && <AemEditableText pageName='tnc' itemName='dataCollectionTitleUpdate' />}
                </h1>
                <TermsOfUseDescription
                    className='mt-8'
                    innerHtml={descriptionText}
                    tncType={tncTypes.WEB_PREFERENCE_CENTER}
                />
            </div>
            <PageWarningBannersContainer />
        </StickyPageLayout>
    );
};

export default connect(mapStateToProps)(DataCollectionPage);
