import {MD5} from 'crypto-js/core';

const btoa = (string) => {
    if (string) {
        return window.btoa(string);
    }

    return null;
};

const atob = (string) => {
    if (string) {
        return window.atob(string);
    }

    return null;
};

const hashMD5 = (string) => {
    return MD5(string).toString();
};

export default {
    btoa,
    atob,
    hashMD5,
};
