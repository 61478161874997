import formatTokenKeys from '../consts/app/formatTokenKeys';
import stringUtils from '../utils/stringUtils';
import {getMappedInitialLanguageCode} from './localization/localizationService';

const getLanguageFormattedString = (str) => {
    const language = getMappedInitialLanguageCode();

    return formatStringWithLanguage(str, language?.toLowerCase());
};

const formatStringWithLanguage = (str, language) =>
    stringUtils.formatByName(str, {[formatTokenKeys.CULTURE_CODE]: language});

export default {getLanguageFormattedString, formatStringWithLanguage};
