import React from 'react';

import AemEditableText from '../../../../components/Aem/AemEditableText/AemEditableText';
import appIntlUtils from '../../../../services/appIntlUtils';
import {twx} from '../../../../utils/tailwindUtils';
import AutoDeliveryEditAddressButton from './AutoDeliveryEditAddressButton';

const getFullAddress = ({address1, address2, city, postalCode}) => {
    return `${address2} ${address1}, ${postalCode} ${city},`;
};

const AutoDeliveryOrderAddress = ({className, address, isEditAllowed}) => {
    const fullAddress = getFullAddress(address);
    const countryName = appIntlUtils.getCountryNameByCountryCode(address.countryCode);

    return (
        <div className={twx('px-4 py-8', className)}>
            <div className='flex items-center justify-between gap-2'>
                <h3 className='mb-0 overflow-hidden text-ellipsis whitespace-nowrap text-24 font-bold'>
                    <AemEditableText pageName='auto-delivery' itemName='addressTitle' />
                </h3>
                {isEditAllowed && <AutoDeliveryEditAddressButton />}
            </div>
            <h4 className='mt-6 text-18 font-bold tracking-02 text-black'>
                <AemEditableText pageName='auto-delivery' itemName='homeAddressTitle' />
            </h4>
            <p className='text-black'>{fullAddress}</p>
            <p className='text-black'>{countryName}</p>
        </div>
    );
};

export default AutoDeliveryOrderAddress;
