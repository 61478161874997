import React from 'react';

import Icon from '../Icon/Icon';

const ConfirmationIcon = (props) => {
    return (
        <Icon width='64' height='64' fill='transparent' {...props}>
            <path
                d='M43.6188 12.9338C40.3432 11.0058 36.5258 9.90002 32.45 9.90002C20.2721 9.90002 10.4 19.7721 10.4 31.95C10.4 44.1279 20.2721 54 32.45 54C44.6279 54 54.5 44.1279 54.5 31.95'
                stroke='#34303D'
                strokeWidth='2.5'
                strokeLinecap='round'
            />
            <path
                d='M41.6927 16.4834C39.0113 14.9052 35.8864 14 32.55 14C22.5813 14 14.5 22.0813 14.5 32.05C14.5 42.0187 22.5813 50.1 32.55 50.1C42.5187 50.1 50.6 42.0187 50.6 32.05'
                stroke='#00D1D2'
                strokeWidth='2.5'
                strokeLinecap='round'
            />
            <path
                d='M21.7148 30.1366L30.6296 39.4667L53.2408 16.2371'
                stroke='#34303D'
                strokeWidth='2.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </Icon>
    );
};

export default ConfirmationIcon;
