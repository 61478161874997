import React from 'react';

import HealthWarning from './components/HealthWarning';
import HealthWarningExtended from './components/HealthWarningExtended';
import WeCare from './components/WeCare';

const WARNING_BANNERS_TYPES = {
    HEALTH_WARNING: 'HEALTH_WARNING',
    WE_CARE: 'WE_CARE',
    HEALTH_WARNING_EXTENDED: 'HEALTH_WARNING_EXTENDED',
};

const getBannerComponent = (type) => {
    switch (type) {
        case WARNING_BANNERS_TYPES.HEALTH_WARNING:
            return HealthWarning;
        case WARNING_BANNERS_TYPES.WE_CARE:
            return WeCare;
        case WARNING_BANNERS_TYPES.HEALTH_WARNING_EXTENDED:
            return HealthWarningExtended;
        default:
            return null;
    }
};

const WarningBanners = ({warningBanners, className}) => {
    return warningBanners.map(({type, titleKey, descriptionKey}, index) => {
        const Component = getBannerComponent(type);
        const isPreviousBannerWeCare =
            index > 0 ? warningBanners[index - 1].type === WARNING_BANNERS_TYPES.WE_CARE : false;
        const isSeparatorVisible = index > 0 && !isPreviousBannerWeCare;

        return (
            <Component
                key={index}
                className={className}
                titleKey={titleKey}
                descriptionKey={descriptionKey}
                isSeparatorVisible={isSeparatorVisible}
            />
        );
    });
};

export default WarningBanners;
