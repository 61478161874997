import React from 'react';

import {AppButton} from '../../components/Button';
import useConfigExternalLink from '../../hooks/useConfigExternalLink';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import helpers from '../../utils/helpers';

const AutoDeliveryButton = ({localizationKey, text, externalLinkConfig, amplitudeEvent, ...rest}) => {
    const localizedStrings = getLocalizedStrings();
    const redirectToExternalLink = useConfigExternalLink(externalLinkConfig);
    const buttonText = text || localizedStrings[localizationKey];

    const onClick = () => {
        helpers.runFunction(amplitudeEvent);
        redirectToExternalLink();
    };

    return (
        buttonText && (
            <AppButton onClick={onClick} {...rest}>
                {buttonText}
            </AppButton>
        )
    );
};

export default AutoDeliveryButton;
