export const DEVICE_TYPES = {
    DEV_TYPE_UNKNOWN: 'DEV_TYPE_UNKNOWN',
    DEV_TYPE_V24_CHARGER: 'DEV_TYPE_V24_CHARGER',
    DEV_TYPE_V24_HOLDER: 'DEV_TYPE_V24_HOLDER',
    DEV_TYPE_V24P_CHARGER: 'DEV_TYPE_V24P_CHARGER',
    DEV_TYPE_V24P_HOLDER: 'DEV_TYPE_V24P_HOLDER',
    DEV_TYPE_P1V30_CHARGER: 'DEV_TYPE_P1V30_CHARGER',
    DEV_TYPE_P1V30_HOLDER: 'DEV_TYPE_P1V30_HOLDER',
    DEV_TYPE_P1V30M: 'DEV_TYPE_P1V30M',
    DEV_TYPE_P1V31M: 'DEV_TYPE_P1V31M',
    DEV_TYPE_P1V31_CHARGER: 'DEV_TYPE_P1V31_CHARGER',
    DEV_TYPE_P1V31_HOLDER: 'DEV_TYPE_P1V31_HOLDER',
    DEV_TYPE_P1V31_ORIGINALS_CHARGER: 'DEV_TYPE_P1V31_ORIGINALS_CHARGER',
    DEV_TYPE_P1V31_ORIGINALS_HOLDER: 'DEV_TYPE_P1V31_ORIGINALS_HOLDER',
    DEV_TYPE_P4_M3_G1: 'DEV_TYPE_P4_M3_G1',
    DEV_TYPE_P4_M3_G2: 'DEV_TYPE_P4_M3_G2',
    DEV_TYPE_P1_ARIANE: 'DEV_TYPE_P1_ARIANE',
    DEV_TYPE_P1V40V_CHARGER: 'DEV_TYPE_P1V40V_CHARGER',
    DEV_TYPE_P1V40V_HOLDER_CONTROL: 'DEV_TYPE_P1V40V_HOLDER_CONTROL',
    DEV_TYPE_P1V40C_CHARGER: 'DEV_TYPE_P1V40C_CHARGER',
    DEV_TYPE_P1V40C_HOLDER_CONTROL: 'DEV_TYPE_P1V40C_HOLDER_CONTROL',
    DEV_TYPE_P1V40D_CONTROL: 'DEV_TYPE_P1V40D_CONTROL',
    DEV_TYPE_P1V41P_CHARGER: 'DEV_TYPE_P1V41P_CHARGER',
    DEV_TYPE_P1V41P_HOLDER_CONTROL: 'DEV_TYPE_P1V41P_HOLDER_CONTROL',
    DEV_TYPE_P1V41M_CHARGER: 'DEV_TYPE_P1V41M_CHARGER',
    DEV_TYPE_P1V41M_HOLDER_CONTROL: 'DEV_TYPE_P1V41M_HOLDER_CONTROL',
    DEV_TYPE_P1V41J_CONTROL: 'DEV_TYPE_P1V41J_CONTROL',
    DEV_TYPE_P1: 'P1',
    DEV_TYPE_P4: 'P4',
    DEV_TYPE_P1_V4: 'P1_V4',
};
