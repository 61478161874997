import React, {useEffect, useId} from 'react';
import {useDispatch} from 'react-redux';

import {clearHwContainerId, setHwContainerId} from '../../state/slices/globalSlice';

const PageWarningBannersContainer = () => {
    const id = useId();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHwContainerId(id));

        return () => {
            dispatch(clearHwContainerId(id));
        };
    }, []);

    return <div className='mt-auto flex w-full flex-col' id={id} />;
};

export default PageWarningBannersContainer;
