import React from 'react';

import Icon from '../Icon/Icon';

const AboutDeviceRestrictedIcon = (props) => {
    return (
        <Icon width='48' height='48' fill='none' {...props}>
            <circle cx='24' cy='24' r='17' stroke='#34303D' strokeWidth='2' />
            <circle cx='24' cy='24' r='14.063' fill='#00D1D2' />
            <circle cx='24' cy='17.063' r='1.313' fill='#34303D' />
            <rect x='22.875' y='20.557' width='2.25' height='12' rx='1.125' fill='#34303D' />
        </Icon>
    );
};

export default AboutDeviceRestrictedIcon;
