import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {useGetInboxStatus} from '../../../api/iccNotificationApi';
import {ICC_INBOX_MESSAGE_STATUS_TYPES} from '../../../consts/icc/iccInboxMessageStatusTypes';
import pushNotificationService from '../../../services/pushNotificationService';
import {makeSelectInboxMessage, makeSelectNextInboxMessage} from '../../../state/selectors/inbox';
import inboxMessageService from '../InboxView/inboxMessageService';
import useInboxMessages from '../InboxView/useInboxMessages';

const useNotificationDetails = ({messageId, onErrorHandler}) => {
    const currentMessage = useSelector(makeSelectInboxMessage(messageId));
    const nextMessage = useSelector(makeSelectNextInboxMessage(messageId));
    const {refetch: refetchInboxStatus} = useGetInboxStatus();

    const [detailedMessage, setDetailedMessage] = useState(null);
    const [isLoaderActive, setIsLoaderActive] = useState(false);

    const fetchMessageData = async () => {
        setIsLoaderActive(true);
        try {
            const messageData = await inboxMessageService.fetchDetailedInboxMessage(messageId);

            setDetailedMessage(messageData);
        } catch (error) {
            onErrorHandler();
        }
        setIsLoaderActive(false);
    };

    useEffect(() => {
        fetchMessageData();
    }, [messageId]);

    useEffect(() => {
        const run = async () => {
            if (currentMessage?.status === ICC_INBOX_MESSAGE_STATUS_TYPES.UNREAD) {
                pushNotificationService.removeNotification(messageId);
                await inboxMessageService.readInboxMessage(messageId);
                refetchInboxStatus();
            }
        };

        run();
    }, [currentMessage]);

    const {inboxMessages, loadMore, isLoading, hasNextPage} = useInboxMessages();

    useEffect(() => {
        if (nextMessage) return;
        if (isLoading) return;

        if (hasNextPage || inboxMessages === undefined) {
            loadMore();
        }
    }, [hasNextPage, nextMessage]);

    return {detailedMessage, nextMessage, isLoaderActive};
};

export default useNotificationDetails;
