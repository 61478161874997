import * as advanceDiagnosticScreenTypes from '../../consts/advanceDiagnostic/advanceDiagnosticScreenTypes';
import ROUTE_PATHS from '../../consts/route/routePaths';
import {
    selectIsAem,
    selectIsRedirectionRequiredAfterNotificationClick,
    selectLanguageCode,
} from '../../state/selectors/global';
import {getState} from '../../state/store';
import urlUtils from '../../utils/urlUtils';
import pushNotificationService from '../pushNotificationService';
import routerService from './routerService';

const withLocale = (path) => {
    let result = path;

    try {
        const state = getState();
        const isAem = selectIsAem(state);

        if (!isAem) return path;

        const languageCode = selectLanguageCode(state);

        if (!languageCode) return path;

        result = urlUtils.join('/' + languageCode, path);
    } catch (error) {}

    return result;
};

const forwardToRootPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ROOT));
const forwardToErrorPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ERROR_PAGE));
const replaceToErrorPage = () => routerService.replace(withLocale(ROUTE_PATHS.ERROR_PAGE));
const redirectToRootPage = () => routerService.redirect(withLocale(ROUTE_PATHS.ROOT));

const forwardToInstallPwaPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.INSTALL_PWA));
const forwardToLoginPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.LOGIN));
const forwardToHomePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.HOME_PAGE));
const forwardToContactUsPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.CONTACT_US));
const forwardToShopPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.SHOP_PAGE));
const forwardToDevicesPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.DEVICES_PAGE));
const forwardToQuickOrderPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.QUICK_ORDER));
const forwardToAutoDeliveryPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.AUTO_DELIVERY));

const forwardToTncPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.TNC));
const forwardToTNCTermsOfUsePage = () => routerService.replace(withLocale(ROUTE_PATHS.TNC_TERMS_OF_USE));
const forwardToTNCDataCollectionPage = () => routerService.replace(withLocale(ROUTE_PATHS.TNC_DATA_COLLECTION));
const forwardToTNCPreferenceCenterPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.TNC_DATA_PREFERENCE));
const forwardToPreferenceCenterProfilePage = () =>
    routerService.forwardTo(withLocale(ROUTE_PATHS.PREFERENCE_CENTER_PROFILE));

// IA: this page is used to open all available popups in the app from AEM. it is not a part of user flow
const forwardToModalsPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.MODALS));
const isModalsPage = () => urlUtils.getCurrentPathname().includes(withLocale(ROUTE_PATHS.MODALS));

const forwardToQuickStartGuidePage = (deviceType = '') =>
    routerService.forwardTo(urlUtils.join(withLocale(ROUTE_PATHS.QUICK_START_GUIDE), deviceType));
const forwardToTipsAndTutorialsList = (deviceType = '') =>
    routerService.forwardTo(urlUtils.join(withLocale(ROUTE_PATHS.TIPS_AND_TUTORIALS), deviceType));
const forwardToADPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ADVANCE_DIAGNOSTIC));
const forwardToAdvancedDiagnosticScreen = ({screenName}) =>
    routerService.forwardTo(
        withLocale(ROUTE_PATHS.ADVANCE_DIAGNOSTIC) + '?' + urlUtils.stringify({screen: screenName})
    );
const forwardToDamageCheckAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.DAMAGE_CHECK_SCREEN});
const forwardToSelectIssueAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.SELECT_ISSUE_SCREEN});
const forwardToCleaningContactsScreenAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.CLEANING_CONTACTS_SCREEN});
const forwardToFittingConsumablesResolvedAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.FITTING_CONSUMABLES_SOLVED});
const forwardToPostCleaningAD = () =>
    forwardToAdvancedDiagnosticScreen({screenName: advanceDiagnosticScreenTypes.POST_CLEANING_SCREEN});

const forwardToAboutDevicePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ABOUT_DEVICE));
const forwardToDeviceRenamePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.DEVICE_RENAME));

const forwardToCleaningGuide = ({isAdvancedDiagnostic, replace} = {}) => {
    const redirectFunction = replace ? routerService.replace : routerService.forwardTo;

    redirectFunction(withLocale(ROUTE_PATHS.CLEAN_TUTORIAL) + '?' + urlUtils.stringify({isAdvancedDiagnostic}));
};

const forwardToInboxPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.INBOX_PAGE));
const forwardToConsumerProfilePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.CONSUMER_PROFILE));

const forwardToNotificationDetailsPage = (notificationId, source) =>
    routerService.forwardTo(
        urlUtils.join(
            withLocale(ROUTE_PATHS.INBOX_PAGE),
            notificationId.toString() + '?' + urlUtils.stringify({source})
        )
    );

const forwardToSelectYourDevicePage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.SELECT_YOUR_DEVICE));
const forwardToUsbRequiredPage = (deviceType = '') =>
    routerService.forwardTo(
        urlUtils.join(withLocale(ROUTE_PATHS.USB_REQUIRED) + '?' + urlUtils.stringify({deviceType}))
    );

const forwardToEnableNotificationPage = () => routerService.forwardTo(withLocale(ROUTE_PATHS.ENABLE_NOTIFICATION));
const forwardToEnableNotificationPageIfNeeded = async () => {
    const isRedirectionRequiredAfterNotificationClick = selectIsRedirectionRequiredAfterNotificationClick(getState());

    // Redirection will be done from useSWMessageHandler hook
    if (isRedirectionRequiredAfterNotificationClick) return;

    const shouldNotificationScreenBeDisplayed =
        await pushNotificationService.checkIfNotificationScreenShouldBeDisplayed();

    if (shouldNotificationScreenBeDisplayed) {
        forwardToEnableNotificationPage();
        return;
    }

    forwardToHomePage();
};

if (process.env.REACT_APP_ENV !== 'prod') {
    window.forwardTo = routerService.forwardTo;
}

export default {
    forwardToRootPage,
    forwardToErrorPage,
    replaceToErrorPage,
    forwardToInstallPwaPage,
    forwardToLoginPage,
    forwardToTncPage,
    forwardToTNCTermsOfUsePage,
    forwardToTNCDataCollectionPage,
    forwardToTNCPreferenceCenterPage,
    forwardToPreferenceCenterProfilePage,
    forwardToHomePage,
    forwardToContactUsPage,
    forwardToQuickStartGuidePage,
    forwardToTipsAndTutorialsList,
    forwardToADPage,
    forwardToAdvancedDiagnosticScreen,
    forwardToDamageCheckAD,
    forwardToSelectIssueAD,
    forwardToCleaningContactsScreenAD,
    forwardToFittingConsumablesResolvedAD,
    forwardToPostCleaningAD,
    forwardToAboutDevicePage,
    forwardToDeviceRenamePage,
    forwardToCleaningGuide,
    forwardToNotificationDetailsPage,
    forwardToInboxPage,
    forwardToModalsPage,
    isModalsPage,
    redirectToRootPage,
    forwardToConsumerProfilePage,
    forwardToEnableNotificationPage,
    forwardToEnableNotificationPageIfNeeded,
    forwardToShopPage,
    forwardToDevicesPage,
    forwardToSelectYourDevicePage,
    forwardToQuickOrderPage,
    forwardToUsbRequiredPage,
    forwardToAutoDeliveryPage,
};
