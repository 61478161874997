import {selectLanguageCode} from '../state/selectors/global';
import {getState} from '../state/store';
import intlUtils from '../utils/intlUtils';

const getCountryNameByCountryCode = (countryCode) => {
    const languageCode = selectLanguageCode(getState());

    return intlUtils.getCountryNameByCountryCode(countryCode, languageCode);
};

export default {getCountryNameByCountryCode};
