import cn from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import appConfig from '../../config/appConfig';
import DeviceImage from '../../containers/DeviceImage/DeviceImage';
import {selectCurrentProductInfo} from '../../state/selectors/device';
import DevicePrimaryColorContainer from '../DevicePrimaryColorContainer/DevicePrimaryColorContainer';

const DeviceHero = (props) => {
    const {containerClassName} = props;
    const device = useSelector(selectCurrentProductInfo);
    const isRestrictedMarket = appConfig.getIsRestrictedMarket();

    return (
        <DevicePrimaryColorContainer isBackgroundImageEnabled className={cn('relative pt-safe', containerClassName)}>
            <div className='h-[180px] overflow-hidden'>
                <DeviceImage
                    device={device}
                    holder={device?.holder}
                    className={cn({
                        'top-[11%] h-[160%]': !isRestrictedMarket,
                        'top-[10%] h-[80%]': isRestrictedMarket,
                    })}
                    imgClassName={cn({'drop-shadow': !isRestrictedMarket}, 'top-0 inset-x-0 even:absolute')}
                    showHolder
                />
            </div>
        </DevicePrimaryColorContainer>
    );
};

export default DeviceHero;
